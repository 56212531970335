import type { ICardType6 } from './CardsType6.interfaces';

export const blankCardType6 = (): ICardType6 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	description_1_icon: null,
	socials: [],
	description_2_icon: null,
	description_3_icon: null,
	description_1: '',
	description_3: '',
	description_2: '',
	subtitle_1: '',
	subtitle_2: '',
	subtitle_3: '',
	yandexCode: '',
	yandexUrl: '',
});
