const LeftMenuIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
	>
		<g fill='#333'>
			<path d='M14 0H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2Zm.667 14c0 .368-.3.667-.667.667H2A.667.667 0 0 1 1.333 14V2c0-.368.3-.667.667-.667h12c.367 0 .667.299.667.667v12Z' />
			<path d='M5.666 2.667h-2c-.55 0-1 .523-1 1.166v8.334c0 .643.45 1.166 1 1.166h2c.552 0 1-.523 1-1.166V3.833c0-.643-.448-1.166-1-1.166Z' />
		</g>
	</svg>
);
export default LeftMenuIcon;
