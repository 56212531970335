import type { ISVGProps } from '@assets/svgs/interfaces';

const Public = ({ className }: ISVGProps) => (
	<svg
		className={className}
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
	>
		<path d='M11.656 6.01h1.834c.46 0 .833.374.833.834v5.917c0 .69-.56 1.25-1.25 1.25h-.167a1.25 1.25 0 0 1-1.25-1.25V6.01Z' />
		<path
			fillRule='evenodd'
			d='M3.323 1.344A1.667 1.667 0 0 0 1.656 3.01v10a1.667 1.667 0 0 0 1.667 1.667h8.818c.007 0 .014 0 .02-.003a1.25 1.25 0 0 1-1.171-1.247V3.01a1.667 1.667 0 0 0-1.667-1.666h-6Zm-.167 3.333a.5.5 0 0 1 .5-.5H8.99a.5.5 0 0 1 0 1H3.656a.5.5 0 0 1-.5-.5Zm.5 1.5a.5.5 0 1 0 0 1H8.99a.5.5 0 0 0 0-1H3.656Zm3.167 4.04.48.48a.5.5 0 1 0 .707-.707L6.736 8.716a.583.583 0 0 0-.826 0L4.636 9.99a.5.5 0 1 0 .707.707l.48-.48v1.793a.5.5 0 0 0 1 0v-1.793Z'
			clipRule='evenodd'
		/>
	</svg>
);
export default Public;
