import { Button } from '@mui/material';
import cn from 'classnames';
import Image from 'mui-image';
import React from 'react';

import { CloseFileIcon } from 'shared/assets/svgs';
import ButtonIcon from 'shared/assets/svgs/ButtonIcon';
import { useModal } from 'shared/hooks';
import { EMediaProcessingStatuses } from 'shared/lib/constants/processing';
import { Button as CustomButton } from 'shared/ui';
// import { Spinner } from 'shared/ui';
import { imageMimes, imageTypes } from 'shared/ui/FileUploader/FileUploader.meta';
import { SelectMediaModal } from 'shared/ui/SelectMediaModal';
import { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

import type { FC } from 'react';
import type { IMediaFile } from 'shared/interfaces';

interface IProps {
	handleSelectFile: (newValue: IMediaFile) => void;
	handleDeleteImage: () => void;
	mediaItem: Nullable<IMediaFile>;
}
const SelectImageWithControls: FC<IProps> = ({ handleSelectFile, handleDeleteImage, mediaItem }) => {
	const { isModalOpen, openModal, closeModal } = useModal();

	return (
		<>
			{mediaItem?.url ? (
				<>
					<div
						className={cn('relative flex max-h-[300px] max-w-[300px] flex-col justify-between justify-self-center shadow-md ', {
							'shadow-lg shadow-red transition-shadow': mediaItem?.status === EMediaProcessingStatuses.Processing_error,
						})}
					>
						{/* {mediaItem.status === EMediaProcessingStatuses.Processing && <Spinner className='absolute right-0 z-10' />} */}
						<Image
							src={mediaItem.url}
							alt={mediaItem.name}
							className='max-w-full rounded-t-lg'
						/>
						<div
							className={cn(
								'drop-shadow(0 1px 2px white) drop-shadow(0 0 1px white)',
								'overflow-hidden text-ellipsis whitespace-nowrap p-1 text-center'
							)}
							title={mediaItem.name || ''}
						>
							{`${mediaItem.name}`}
							{mediaItem.status === EMediaProcessingStatuses.Processing_error && <div className='text-error'>ошибка обработки</div>}
						</div>
						<Button
							onClick={handleDeleteImage}
							className='absolute right-0 top-0 h-[40px] w-[40px] min-w-[40px] rounded-full bg-white hover:bg-gray-bright'
						>
							<CloseFileIcon />
						</Button>
					</div>
				</>
			) : (
				<>
					<CustomButton
						variant='contained'
						onClick={openModal}
						classes={{
							root: 'h-full border-2 border-dashed border-enabled bg-dropzone p-3 text-enabled',
						}}
					>
						<div className='mr-2'>
							<ButtonIcon />
						</div>
						<div>Загрузить изображение</div>
					</CustomButton>
					<SelectMediaModal
						accept={imageMimes}
						isOpen={isModalOpen}
						onClose={closeModal}
						onSelectFile={handleSelectFile}
						variant={ESelectFileVariant.IMAGE}
						mediaTypes={imageTypes}
					/>
				</>
			)}
		</>
	);
};

export default SelectImageWithControls;
