import { EEditorTools } from 'shared/ui/Editor/Editor.interfaces';

import type { EditorConfig } from '@editorjs/editorjs';

export const INLINE_TOOLS = [EEditorTools.COLOR, EEditorTools.MARKER];

export const TEXT_ONLY_TOOLS = [EEditorTools.HEADER, EEditorTools.PARAGRAPH, ...INLINE_TOOLS];
export const ALL_TOOLS = Object.values(EEditorTools) as EEditorTools[];

export const removeBannedTools = ({ allowedTools, config }: { allowedTools: EEditorTools[]; config: EditorConfig }) => {
	if (config.tools) {
		Object.keys(config.tools).forEach((key) => {
			const toolKey = key as EEditorTools;
			if (!allowedTools.includes(toolKey)) {
				delete config.tools?.[toolKey];
			}
		});
	}
	return config.tools;
};
