import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const OlMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		viewBox='0 0 1920 1920'
	>
		<path
			fillRule='evenodd'
			d='M169.412 1355.294c93.402 0 169.412 76.01 169.412 169.412 0 43.595-16.942 82.899-44.16 112.941 27.218 30.042 44.16 69.346 44.16 112.941 0 93.403-76.01 169.412-169.412 169.412H0v-112.941h169.412c31.059 0 56.47-25.412 56.47-56.47 0-31.06-25.411-56.471-56.47-56.471H0v-112.942h169.412c31.059 0 56.47-25.411 56.47-56.47 0-31.059-25.411-56.47-56.47-56.47H0v-112.942ZM1920 1468.235v112.941H564.706v-112.94H1920ZM188.058 677.67c61.78 0 118.814 38.4 145.356 97.694 28.8 64.037 15.36 136.546-35.916 194.033-16.286 18.262-34.108 37.88-52.187 57.582l-3.101 3.377c-2.07 2.252-4.14 4.505-6.213 6.755l-3.108 3.374-3.107 3.37c-28.478 30.87-56.688 61.043-79.672 85.58h188.725v112.94H56.482c-31.285 0-56.47-25.298-56.47-56.47v-39.53c0-14.456 5.533-28.46 15.585-38.964.113-.113 117.459-123.558 197.647-213.233 21.346-23.944 27.445-49.807 17.167-72.621-8.131-18.297-25.637-30.946-42.353-30.946-40.546 0-82.898 48.452-94.418 65.506L.01 792.983C7.804 781.237 80.425 677.67 188.058 677.67ZM1920 903.53v112.94H564.706V903.53H1920ZM169.412 0c31.172 0 56.47 25.299 56.47 56.47v508.236h-112.94V112.94H0V0ZM1920 338.824v112.94H564.706v-112.94H1920Z'
		/>
	</svg>
);
export default OlMenuIcon;
