import './modal.scss';

export class cmsModal {
	wrapper: HTMLDivElement;

	editorContent: HTMLDivElement;

	constructor(private editorJS: HTMLDivElement) {
		this.wrapper = document.createElement('div');
		this.editorContent = document.createElement('div');
	}

	stopPropagationOnElement(event: Event) {
		event.stopImmediatePropagation();
	}

	render() {
		this.wrapper.classList.add('wrapper');
		this.editorContent.classList.add('elementContent');
		this.editorContent.tabIndex = 0;
		this.editorContent.append(this.editorJS);
		this.wrapper.append(this.editorContent);

		this.wrapper.addEventListener('focusin', this.stopPropagationOnElement);
	}

	openModal() {
		this.render();
		this.wrapper.focus();
	}

	closeModal() {
		this.wrapper.removeEventListener('focusin', this.stopPropagationOnElement);
		this.wrapper.remove();
	}
}
