import cn from 'classnames';

import type { FC, ReactNode, MutableRefObject } from 'react';

interface IProps {
	isModalOpen?: boolean;
	pickElement?: (name: string) => void;
	checkedNames?: (string | boolean)[];
	menuRef?: MutableRefObject<Nullable<HTMLDivElement>>;
	items?: {
		icon: ReactNode;
		title: string;
		name: string;
	}[];
}
const SelectedMenu: FC<IProps> = ({ menuRef, checkedNames, isModalOpen, items = [], pickElement }) => {
	const dropDown = cn('top-[40px] absolute right-0 w-[300px] p-2 bg-white flex-col gap-2 rounded-lg z-10 shadow-md', {
		hidden: !isModalOpen,
		flex: isModalOpen,
	});

	const getCheckedName = (checkedName: string) => {
		if (checkedNames) {
			for (let i = 0; i < checkedNames.length; i++) {
				if (checkedName === checkedNames[i]) {
					return 'bg-dropzone';
				}
			}
		}
	};

	return (
		<div
			className={dropDown}
			ref={menuRef}
		>
			{items.map((el) => (
				<div
					key={el.name}
					className={`flex cursor-pointer items-center gap-2 ${getCheckedName(el.name)} p-2 hover:bg-dropzone`}
					onClick={() => {
						if (pickElement) pickElement(el.name);
					}}
				>
					{el.icon}
					<span>{el.title}</span>
				</div>
			))}
		</div>
	);
};
export default SelectedMenu;
