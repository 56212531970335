const WidthMenuIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			fill='#0F0F0F'
			d='M7 16H2a1 1 0 1 1 0-2h5a3 3 0 0 1 3 3v5a1 1 0 1 1-2 0v-5a1 1 0 0 0-1-1ZM10 2a1 1 0 1 0-2 0v5a1 1 0 0 1-1 1H2a1 1 0 1 0 0 2h5a3 3 0 0 0 3-3V2ZM14 22a1 1 0 1 0 2 0v-5a1 1 0 0 1 1-1h5a1 1 0 1 0 0-2h-5a3 3 0 0 0-3 3v5ZM14 7a3 3 0 0 0 3 3h5a1 1 0 1 0 0-2h-5a1 1 0 0 1-1-1V2a1 1 0 1 0-2 0v5Z'
		/>
	</svg>
);
export default WidthMenuIcon;
