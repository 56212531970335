import type { IAccordionMenuAccordionItem, TAccordionMenuItem } from './AccordionMenu.interfaces';
import type { IPermission } from 'shared/interfaces/permissions';

export const isAccordionItemGuard = (item: TAccordionMenuItem): item is IAccordionMenuAccordionItem => 'items' in item;

export const findActive = (items: TAccordionMenuItem[], pathName: string): string[] => {
	for (const item of items) {
		// @ts-ignore
		for (const subItem of item.items) {
			if (subItem.to === pathName) {
				return [item.dataId];
			}
		}
	}
	return [''];
};

const filterAllowedItems = (items: TAccordionMenuItem[], userPermissions: IPermission[]) => {
	return items.reduce((acc, item) => {
		if (item.permissions) {
			const isHasAccess = item.permissions.some((itemPerm) => userPermissions.some((userPerm) => userPerm.name === itemPerm));

			if (!isHasAccess) {
				return acc;
			}
		}

		if (!isAccordionItemGuard(item)) {
			acc.push(item);

			return acc;
		}

		const allowedItems = filterAllowedItems(item.items, userPermissions);

		if (allowedItems.length > 0) {
			acc.push({ ...item, items: allowedItems });
		}

		return acc;
	}, [] as TAccordionMenuItem[]);
};

export const filterAllowedMenuItems = (items: TAccordionMenuItem[], userPermissions?: IPermission[]) => {
	if (!userPermissions) return items;

	return filterAllowedItems(items, userPermissions);
};
