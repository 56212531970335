
import { BorderPoints } from './BorderPoints';
import { Capitals } from './Capitals';
import { Cities } from './Cities';

import type { Map as LMap } from 'leaflet';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	map: LMap;
	points: ILeafletMapData['points'];
	zoom: number
}

export const PointsLayer = ({ points, map, zoom }: IProps) => {
	return <>
		<Cities data={points.cities} map={map} zoom={zoom}/>
		<Capitals data={points.capitals} map={map} zoom={zoom} />
		<BorderPoints data={points.border_points} map={map} zoom={zoom} />
	</>;
};