import { MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

import { useGetFormSelectOptionsQuery } from 'shared/api/formsApi';
import { useModal } from 'shared/hooks';
import { ControlMenus } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { SelectInput } from 'shared/ui/SelectInput';
import { TextInput } from 'shared/ui/TextInput';

import type { IFormData, IFormToolFormProps } from 'shared/ui/Editor/EditorBlockTools/FormTool/FormTool.interface';

const FormToolForm = ({ deleteBlock, moveBlockDown, moveBlockUp, onDataChange }: IFormToolFormProps) => {
	const { values, getFieldProps } = useFormikContext<IFormData>();

	const { isModalOpen, openModal, closeModal } = useModal();

	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	useEffect(() => {
		onDataChange(values);
	}, [onDataChange, values]);

	const { data: formSelectOptions } = useGetFormSelectOptionsQuery();

	return (
		<div className='mt-3 flex flex-col gap-4'>
			<div className='mt-[12px] flex items-center justify-between'>
				<h2>Форма</h2>
				<ControlMenus
					menuRef={menuRef}
					openModal={openModal}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
				/>
			</div>
			<div className='flex gap-2'>
				<Field
					name='style'
					placeholder='Cтиль'
					as={TextInput}
				/>
				<Field
					name='headline'
					placeholder='Заголовок'
					as={TextInput}
				/>
				<Field
					name='subheadline'
					placeholder='Подзаголовок'
					as={TextInput}
				/>
				<SelectInput
					label='Тип формы'
					placeholder='Выберите тип формы'
					required
					{...getFieldProps('form_id')}
				>
					<MenuItem value=''>-</MenuItem>
					{formSelectOptions?.data.map(({ label, value }) => (
						<MenuItem
							key={value}
							value={value}
						>
							{label}
						</MenuItem>
					))}
				</SelectInput>
			</div>
		</div>
	);
};

export default FormToolForm;
