import { TextInput } from 'shared/ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { IContainerTemplate3Data } from './ContainerTemplate3.interfaces';
import type { FieldInputProps } from 'formik/dist/types';

interface IProps {
	data: IContainerTemplate3Data;
	getFieldProps: (field: string) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
}

export const ContainerTemplate3 = ({ data, setFieldValue, getFieldProps }: IProps) => {
	return (
		<div className='flex gap-m'>
			<div className='flex flex-col'>
				<div className=''>Изображение</div>
				<SelectImageWithControls
					handleSelectFile={(newFile) => {
						setFieldValue('icon', newFile);
					}}
					handleDeleteImage={() => setFieldValue('icon', null)}
					mediaItem={data.icon}
				/>
			</div>
			<div className='flex w-full flex-col gap-m'>
				<TextInput
					{...getFieldProps('subtitle')}
					label='Подзаголовк контейнера'
					placeholder='Введите подзаголовок контейнера'
				/>
				<TextInput
					{...getFieldProps('description')}
					label='Описание'
					placeholder='Введите описание'
				/>
			</div>
		</div>
	);
};
