import type { IButtonToolData } from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool.interfaces';

const defaultButtonToolValues: IButtonToolData = {
	align: 'width',
	style: '',
	text: '',
	url: '',
};

const buttonAlignStyles = {
	left: 'flex-start',
	right: 'flex-end',
	center: 'center',
	width: 'stretch',
};

const toolboxParams = {
	title: 'Кнопка',
	icon: `<svg width="800px" height="800px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <rect x="0" fill="none" width="20" height="20"/>
  <g>
  <path d="M17 5H3c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm1 7c0 .6-.4 1-1 1H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h14c.6 0 1 .4 1 1v5z"/>
  </g>
  </svg>`,
};

const tuneIcons = {
	left: `<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.375 7.875C7.375 6.94039 7.375 6.47308 7.57596 6.125C7.70761 5.89697 7.89697 5.70762 8.125 5.57596C8.47308 5.375 8.94038 5.375 9.875 5.375L18.875 5.375C19.8096 5.375 20.2769 5.375 20.625 5.57596C20.853 5.70761 21.0424 5.89697 21.174 6.125C21.375 6.47308 21.375 6.94038 21.375 7.875C21.375 8.80962 21.375 9.27692 21.174 9.625C21.0424 9.85303 20.853 10.0424 20.625 10.174C20.2769 10.375 19.8096 10.375 18.875 10.375L9.875 10.375C8.94038 10.375 8.47308 10.375 8.125 10.174C7.89697 10.0424 7.70761 9.85303 7.57596 9.625C7.375 9.27692 7.375 8.80962 7.375 7.875Z" fill="#1C274C"/>
  <path d="M7.375 16.875C7.375 15.9404 7.375 15.4731 7.57596 15.125C7.70761 14.897 7.89697 14.7076 8.125 14.576C8.47308 14.375 8.94038 14.375 9.875 14.375H15.875C16.8096 14.375 17.2769 14.375 17.625 14.576C17.853 14.7076 18.0424 14.897 18.174 15.125C18.375 15.4731 18.375 15.9404 18.375 16.875C18.375 17.8096 18.375 18.2769 18.174 18.625C18.0424 18.853 17.853 19.0424 17.625 19.174C17.2769 19.375 16.8096 19.375 15.875 19.375H9.875C8.94038 19.375 8.47308 19.375 8.125 19.174C7.89697 19.0424 7.70761 18.853 7.57596 18.625C7.375 18.2769 7.375 17.8096 7.375 16.875Z" fill="#1C274C"/>
  <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3.375 23.125C3.78921 23.125 4.125 22.7892 4.125 22.375L4.125 2.375C4.125 1.96079 3.78921 1.625 3.375 1.625C2.96079 1.625 2.625 1.96079 2.625 2.375L2.625 22.375C2.625 22.7892 2.96079 23.125 3.375 23.125Z" fill="#1C274C"/>
  </svg>`,
	right: `<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.625 7.875C16.625 6.94039 16.625 6.47308 16.424 6.125C16.2924 5.89697 16.103 5.70762 15.875 5.57596C15.5269 5.375 15.0596 5.375 14.125 5.375L5.125 5.375C4.19038 5.375 3.72308 5.375 3.375 5.57596C3.14697 5.70761 2.95761 5.89697 2.82596 6.125C2.625 6.47308 2.625 6.94038 2.625 7.875C2.625 8.80962 2.625 9.27692 2.82596 9.625C2.95761 9.85303 3.14697 10.0424 3.375 10.174C3.72308 10.375 4.19038 10.375 5.125 10.375L14.125 10.375C15.0596 10.375 15.5269 10.375 15.875 10.174C16.103 10.0424 16.2924 9.85303 16.424 9.625C16.625 9.27692 16.625 8.80962 16.625 7.875Z" fill="#1C274C"/>
  <path d="M16.625 16.875C16.625 15.9404 16.625 15.4731 16.424 15.125C16.2924 14.897 16.103 14.7076 15.875 14.576C15.5269 14.375 15.0596 14.375 14.125 14.375H8.125C7.19038 14.375 6.72308 14.375 6.375 14.576C6.14697 14.7076 5.95761 14.897 5.82596 15.125C5.625 15.4731 5.625 15.9404 5.625 16.875C5.625 17.8096 5.625 18.2769 5.82596 18.625C5.95761 18.853 6.14697 19.0424 6.375 19.174C6.72308 19.375 7.19038 19.375 8.125 19.375H14.125C15.0596 19.375 15.5269 19.375 15.875 19.174C16.103 19.0424 16.2924 18.853 16.424 18.625C16.625 18.2769 16.625 17.8096 16.625 16.875Z" fill="#1C274C"/>
  <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M20.625 23.125C20.2108 23.125 19.875 22.7892 19.875 22.375L19.875 2.375C19.875 1.96079 20.2108 1.625 20.625 1.625C21.0392 1.625 21.375 1.96079 21.375 2.375L21.375 22.375C21.375 22.7892 21.0392 23.125 20.625 23.125Z" fill="#1C274C"/>
  </svg>`,
	center: `<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7C5 6.44772 5.44772 6 6 6H18C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H6C5.44772 8 5 7.55228 5 7ZM5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12ZM8 17C8 16.4477 8.44772 16 9 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H9C8.44772 18 8 17.5523 8 17Z" fill="#000000"/>
  </svg>`,
	width: `<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 16L2 16C1.44772 16 1 15.5523 1 15C1 14.4477 1.44772 14 2 14L7 14C8.65685 14 10 15.3431 10 17V22C10 22.5523 9.55228 23 9 23C8.44772 23 8 22.5523 8 22V17C8 16.4477 7.55228 16 7 16Z" fill="#0F0F0F"/>
  <path d="M10 2C10 1.44772 9.55229 1 9 1C8.44772 1 8 1.44772 8 2L8 7C8 7.55228 7.55228 8 7 8L2 8C1.44772 8 1 8.44771 1 9C1 9.55228 1.44772 10 2 10L7 10C8.65685 10 10 8.65685 10 7L10 2Z" fill="#0F0F0F"/>
  <path d="M14 22C14 22.5523 14.4477 23 15 23C15.5523 23 16 22.5523 16 22V17C16 16.4477 16.4477 16 17 16H22C22.5523 16 23 15.5523 23 15C23 14.4477 22.5523 14 22 14H17C15.3431 14 14 15.3431 14 17V22Z" fill="#0F0F0F"/>
  <path d="M14 7C14 8.65686 15.3431 10 17 10L22 10C22.5523 10 23 9.55228 23 9C23 8.44772 22.5523 8 22 8L17 8C16.4477 8 16 7.55229 16 7L16 2C16 1.44772 15.5523 1 15 1C14.4477 1 14 1.44772 14 2L14 7Z" fill="#0F0F0F"/>
  </svg>`,
};

export { defaultButtonToolValues, buttonAlignStyles, toolboxParams, tuneIcons };
