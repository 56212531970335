import type { IAnchorData } from './AnchorTool.interfaces';

export const anchorTooltip = {
	title: 'Якорь',
	icon:
		'<svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n' +
		'\t viewBox="0 0 459.066 459.066" xml:space="preserve">\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M425.564,335.924L402.948,234.77c-0.628-2.811-2.771-5.038-5.556-5.774c-2.785-0.736-5.748,0.14-7.684,2.273\n' +
		'\t\t\tl-69.657,76.756c-2.104,2.319-2.598,5.679-1.25,8.505c1.348,2.826,4.271,4.556,7.397,4.38l26.453-1.496\n' +
		'\t\t\tc-4.419,14.98-15.347,29.032-31.841,40.608c-13.662,9.589-29.991,16.732-47.898,21.123c-5.634,1.382-11.59,0.096-16.153-3.485\n' +
		'\t\t\tc-4.563-3.581-7.225-9.063-7.225-14.863V189.573c18.257-6.661,32.279-22.174,36.88-41.316h56.989\n' +
		'\t\t\tc8.795,0,15.925-7.129,15.925-15.924c0-8.795-7.13-15.924-15.925-15.924h-58.266c-5.589-17.05-18.803-30.676-35.603-36.806V68.851\n' +
		'\t\t\tc10.416-6.624,17.333-18.259,17.333-31.517C266.867,16.715,250.152,0,229.534,0s-37.333,16.715-37.333,37.333\n' +
		'\t\t\tc0,13.258,6.917,24.894,17.333,31.517v10.753c-16.8,6.13-30.014,19.756-35.602,36.806h-58.267\n' +
		'\t\t\tc-8.795,0-15.924,7.129-15.924,15.924c0,8.795,7.129,15.924,15.924,15.924h56.99c4.601,19.142,18.623,34.654,36.88,41.316v173.224\n' +
		'\t\t\tc0,5.798-2.666,11.273-7.227,14.853c-4.561,3.58-10.52,4.877-16.151,3.496c-17.907-4.392-34.236-11.535-47.899-21.123\n' +
		'\t\t\tc-16.495-11.576-27.422-25.628-31.841-40.608l26.453,1.496c3.126,0.177,6.049-1.554,7.397-4.38\n' +
		'\t\t\tc1.348-2.826,0.854-6.186-1.25-8.505L69.359,231.27c-1.936-2.133-4.899-3.01-7.684-2.273c-2.785,0.736-4.927,2.963-5.556,5.774\n' +
		'\t\t\tL33.503,335.924c-0.683,3.056,0.548,6.221,3.117,8.011c2.569,1.79,5.965,1.85,8.595,0.151l22.455-14.503\n' +
		'\t\t\tc6.719,24.081,23.046,45.942,47.607,63.181c12.878,9.038,27.469,16.377,43.227,21.857c26.652,9.269,51.074,24.49,71.027,44.443\n' +
		'\t\t\tl0.002,0.002l0.002-0.003c20.12-20.12,44.151-35.095,71.026-44.442c15.758-5.48,30.349-12.819,43.228-21.858\n' +
		'\t\t\tc24.562-17.238,40.888-39.099,47.607-63.18l22.455,14.503c2.63,1.699,6.026,1.639,8.595-0.151S426.247,338.979,425.564,335.924z\n' +
		'\t\t\t M229.534,161.248c-14.7,0-26.66-11.959-26.66-26.66c0-14.7,11.959-26.66,26.66-26.66s26.659,11.959,26.659,26.66\n' +
		'\t\t\tC256.193,149.289,244.234,161.248,229.534,161.248z"/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'</svg>',
};

const initialBarsData: IAnchorData = {
	name: '',
};

export const getInitialAnchorData = (params: { copyWith?: Partial<IAnchorData> } = {}): IAnchorData => {
	const { copyWith } = params;
	return {
		...initialBarsData,
		...copyWith,
	};
};
