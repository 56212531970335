import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store/core';
import { theme } from 'app/theme/theme';
import ButtonTool from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool';
import { generateDefaultButtonToolValues } from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool.lib';
import { toolboxParams } from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool.meta';

import type { API, BlockTool } from '@editorjs/editorjs';
import type { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools/block-tool';
import type { IButtonToolData } from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool.interfaces';

export class CButtonTool implements BlockTool {
	data: IButtonToolData;

	api: API;

	constructor({ data, api }: BlockToolConstructorOptions) {
		this.api = api;
		this.data = Object.keys(data).length ? data : generateDefaultButtonToolValues();
	}

	static get toolbox() {
		return toolboxParams;
	}

	save() {
		return this.data;
	}

	render() {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		const onDataChangeHandler = (newData: IButtonToolData) => {
			this.data = newData;
		};

		renderRoot.render(
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<ButtonTool
							onDataChange={onDataChangeHandler}
							data={this.data}
							deleteBlock={deleteBlock}
							moveBlockDown={moveBlockDown}
							moveBlockUp={moveBlockUp}
						/>
					</Provider>
				</ThemeProvider>
			</BrowserRouter>
		);

		return rootNode;
	}
}
