import { Add as AddIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { Button } from 'shared/ui';

import { BorderPointCard } from './BorderPointCard/BorderPointCard';
import { CapitalPointCard } from './CapitalPointCard/CapitalPointCard';
import { CityPointCard } from './CityPointCard/CityPointCard';
import { getBlankPoint } from './PointsControl.lib';

import type { FieldInputProps } from 'formik/dist/types';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	values: ILeafletMapData;
	setFieldValue: (propName: string, newValue: any) => void;
	getFieldProps: (propName: string) => FieldInputProps<string> | FieldInputProps<number>;
}

export const PointsControl = ({ values, setFieldValue, getFieldProps }: IProps) => {
	const handleDeletePoint = (category: keyof typeof values.points, id: string) => {
		setFieldValue(
			`points.${category}`,
			values.points[category].filter((item) => item.id !== id)
		);
	};
	return (
		<Accordion>
			<AccordionSummary>Точки</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<Accordion>
						<AccordionSummary>Столицы</AccordionSummary>
						<AccordionDetails>
							<div className='flex flex-col gap-m'>
								<div className='flex flex-col'>
									{values.points.capitals.map((point, index) => (
										<CapitalPointCard
											pointData={point}
											key={point.id}
											index={index}
											handleDeletePoint={(id) => handleDeletePoint('capitals', id)}
											getFieldProps={getFieldProps}
										/>
									))}
								</div>
								<Button
									variant='outlined'
									onClick={() => {
										setFieldValue('points.capitals', [...values.points.capitals, getBlankPoint()]);
									}}
								>
									<div className='flex gap-m'>
										<AddIcon />
										<div>Добавить столицу</div>
									</div>
								</Button>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary>Города</AccordionSummary>
						<AccordionDetails>
							<div className='flex flex-col gap-m'>
								<div className='flex flex-col'>
									{values.points.cities.map((point, index) => (
										<CityPointCard
											key={point.id}
											index={index}
											pointData={point}
											handleDeletePoint={(id) => handleDeletePoint('cities', id)}
											getFieldProps={getFieldProps}
										/>
									))}
								</div>
								<Button
									variant='outlined'
									onClick={() => {
										setFieldValue('points.cities', [...values.points.cities, getBlankPoint()]);
									}}
								>
									<div className='m flex'>
										<AddIcon />
										<div>Добавить город</div>
									</div>
								</Button>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary>Приграничные точки</AccordionSummary>
						<AccordionDetails>
							<div className='m flex flex-col'>
								<div className='flex flex-col'>
									{values.points.border_points.map((point, index) => (
										<BorderPointCard
											key={point.id}
											index={index}
											pointData={point}
											handleDeletePoint={(id) => handleDeletePoint('border_points', id)}
											getFieldProps={getFieldProps}
										/>
									))}
								</div>
								<Button
									variant='outlined'
									onClick={() => {
										setFieldValue('points.border_points', [...values.points.border_points, getBlankPoint()]);
									}}
								>
									<div className='flex gap-m'>
										<AddIcon />
										<div>Добавить приграничную точку</div>
									</div>
								</Button>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
