import { Add as AddIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { Button, SelectInput, TextInput } from 'shared/ui';
import { EContainerTemplate1FieldTypes } from 'shared/ui/Editor/EditorBlockTools/ContainerTemplatesTool/ContainerTemplatesTool.interfaces';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';

import { ELinkTypes } from './ContainerTemplate1.interfaces';
import { blankContainerTemplate1TextItem, selectLinkTypeOptions, selectTextItemTypeOptions } from './ContainerTemplate1.lib';

import type { IContainerTemplate1Data } from './ContainerTemplate1.interfaces';
import type { FieldInputProps } from 'formik/dist/types';

interface IProps {
	data: IContainerTemplate1Data;
	getFieldProps: (field: string) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
}

export const ContainerTemplate1 = ({ data, getFieldProps, setFieldValue }: IProps) => {
	useEffect(() => {
		if (data.text_items.length === 0) {
			setFieldValue('data.text_items', [blankContainerTemplate1TextItem()]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTextItemUp = useCallback(
		(id: number | string) => {
			setFieldValue('data.text_items', swapToTopArrayItems(id, data.text_items));
		},
		[setFieldValue, data.text_items]
	);
	const handleTextItemUpDown = useCallback(
		(id: number | string) => {
			setFieldValue('data.text_items', swapToBottomArrayItems(id, data.text_items));
		},
		[setFieldValue, data.text_items]
	);
	const handleTextItemClose = useCallback(
		(id: number | string) => {
			setFieldValue(
				'data.text_items',
				data.text_items.filter((item) => {
					return item.id !== id;
				})
			);
		},
		[setFieldValue, data.text_items]
	);

	return (
		<div className='flex flex-col gap-m'>
			<div>Текстовые элементы</div>
			{data.text_items.map((item, itemIndex) => {
				return (
					<Accordion key={item.id}>
						<AccordionSummary>Текстовый элемент {itemIndex + 1}</AccordionSummary>
						<AccordionDetails>
							<div className='flex flex-col gap-m'>
								<SelectInput
									value={item.type}
									onChange={(e) => {
										const newType = e.target.value as EContainerTemplate1FieldTypes;
										setFieldValue(`data.text_items.${itemIndex}.type`, e.target.value);
										if (newType === EContainerTemplate1FieldTypes.LINK) {
											setFieldValue(`data.text_items.${itemIndex}.linkType`, ELinkTypes.DOWNLOAD);
											setFieldValue(`data.text_items.${itemIndex}.url`, '');
										}
									}}
									label='Вид элемента'
								>
									{selectTextItemTypeOptions.map(({ value: optionValue, label }) => (
										<MenuItem
											key={optionValue}
											value={optionValue}
										>
											{label}
										</MenuItem>
									))}
								</SelectInput>
								<TextInput
									{...getFieldProps(`data.text_items.${itemIndex}.style`)}
									label='Стиль элемента списка'
									placeholder='Введите стиль элемента списка'
								/>
								<TextInput
									{...getFieldProps(`data.text_items.${itemIndex}.text`)}
									label='Текст элемента списка'
									placeholder='Введите текст элемента списка'
								/>
								{item.type === EContainerTemplate1FieldTypes.LINK && (
									<div className='flex flex-col gap-m'>
										<SelectInput
											value={item.linkType}
											onChange={(e) => {
												setFieldValue(`data.text_items.${itemIndex}.linkType`, e.target.value);
											}}
											label='Вид ссылки'
										>
											{selectLinkTypeOptions.map(({ value: optionValue, label }) => (
												<MenuItem
													key={optionValue}
													value={optionValue}
												>
													{label}
												</MenuItem>
											))}
										</SelectInput>
										<TextInput
											{...getFieldProps(`data.text_items.${itemIndex}.url`)}
											label='Url ссылки'
											placeholder='Введите URl'
										/>
									</div>
								)}
								<SubTuner
									handleUp={handleTextItemUp}
									handleDown={handleTextItemUpDown}
									handleClose={handleTextItemClose}
									tuneItem={item.id}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
				);
			})}
			<Button
				variant='outlined'
				onClick={() => {
					setFieldValue('data.text_items', [...data.text_items, blankContainerTemplate1TextItem()]);
				}}
			>
				<div className='flex gap-m'>
					<AddIcon />
					<div>Добавить элемент списка</div>
				</div>
			</Button>
		</div>
	);
};
