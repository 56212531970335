import { transformToFormikErrors } from './transformToFormikErrors';

import type { FormikErrors } from 'formik';

interface IProcessRequestResultCB<Data, Error> {
	onSuccess?: (data: Data) => any | Promise<any>;
	onError?: (error: Error, errors: FormikErrors<Record<string, any>>) => void | Promise<void>;
}

type TData<Data, Error> = { data: Data } | { error: Error };

type GetData<Data extends TData<any, any>> = Data extends { data: infer D } ? D : never;
type GetError<Data extends TData<any, any>> = Data extends { error: infer D } ? D : never;

const processRequestResult = async <Data extends TData<any, any>>(
	data: Data,
	{ onError, onSuccess }: IProcessRequestResultCB<GetData<Data>, GetError<Data>>
): Promise<void> => {
	if (onSuccess && 'data' in data) {
		const isDataData = typeof data.data === 'object' && 'data' in data.data;
		return onSuccess(isDataData ? data.data.data : data.data);
	}
	if (onError && 'error' in data) {
		return onError(data.error, transformToFormikErrors(data.error.response?.data.errors));
	}
};

export { processRequestResult };
