export const extractIframeSrc = (iframeCode: string) => {
	const pattern = /<iframe.*?src="(.*?)".*?>/i;
	const match = iframeCode.match(pattern);

	if (match) {
		return match[1];
	} else {
		return null;
	}
};
