import type { ICardType9 } from './CardsType9.interfaces';

export const blankCardType9 = (): ICardType9 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	image_background: null,
	description: '',
	subtitle: ''
});
