import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';

import { SelectInput, TextInput } from 'shared/ui';

import { EBarsVariants } from './BarsTool.interfaces';
import { getInitialBarsData, selectBarsOptions } from './BarsTool.lib';
import { BarsType1 } from './BarsVariants';

import type { IBarsData } from './BarsTool.interfaces';

interface IProps {
	data: IBarsData;
	handleDataChange: (newData: IBarsData) => void;
}

export const BarsTool = ({ data: initialData, handleDataChange }: IProps) => {
	const formik = useFormik({
		initialValues: initialData,
		onSubmit: () => {},
		enableReinitialize: true,
	});

	useEffect(() => {
		handleDataChange(formik.values);
	}, [formik.values, handleDataChange]);

	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>
				Инструмент {'"'}Плитки{'"'}
			</AccordionSummary>
			<AccordionDetails>
				<form className='flex flex-col gap-m'>
					<SelectInput
						value={formik.values.variant}
						onChange={(e) => {
							formik.setValues(
								getInitialBarsData({
									copyWith: { variant: e.target.value as EBarsVariants },
								})
							);
						}}
						label='Вид инструмента Плитки'
					>
						{selectBarsOptions.map(({ value: optionValue, label }) => (
							<MenuItem
								key={optionValue}
								value={optionValue}
							>
								{label}
							</MenuItem>
						))}
					</SelectInput>

					<TextInput
						{...formik.getFieldProps('style')}
						label='Стиль инструмента Плитки'
						placeholder='Стиль плиток'
					/>
					<TextInput
						{...formik.getFieldProps('max_row_count')}
						type='number'
						label='Максимальное кол-во плиток в строке'
						placeholder='Введите количество'
					/>
					{(() => {
						switch (formik.values.variant) {
							case EBarsVariants.TYPE_1: {
								return (
									<BarsType1
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							default: {
								return null;
							}
						}
					})()}
				</form>
			</AccordionDetails>
		</Accordion>
	);
};
