import { useState, type FC, useEffect, useRef } from 'react';

import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { extractIframeSrc } from 'shared/lib/helpers/extractFromStringHelpers';
import { Button } from 'shared/ui';
import { ControlMenus, MapFrame } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { TextInput } from 'shared/ui/TextInput';

import type { ChangeEvent } from 'react';
import type { IYandexToolProps } from 'shared/ui/Editor/EditorBlockTools/YandexMapTool/YandexMapTool.interfaces';

const YandexMapTool: FC<IYandexToolProps> = ({ data, onDataChange, deleteBlock, moveBlockUp, moveBlockDown }) => {
	const { isModalOpen, openModal, closeModal } = useModal();
	const [toolData, setToolData] = useState(data);

	useEffect(() => {
		onDataChange(toolData);
	}, [onDataChange, toolData]);

	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	useClickOutside(menuRef, closeModal);

	const onIframeUrlChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setToolData((prev) => ({ ...prev, yandexCode: e.target.value }));
	};

	const onUploadClickHandler = () => {
		const extractedUrl = extractIframeSrc(toolData.yandexCode);
		if (!extractedUrl) {
			return;
		}

		setToolData((prev) => ({
			...prev,
			url: extractedUrl,
		}));
	};

	const onDeleteMap = () => {
		setToolData({
			url: '',
			yandexCode: '',
		});
	};

	const isUrlControlsDisabled = !!toolData.url?.trim();

	return (
		<div className='flex flex-col gap-3'>
			<h2 className='mb-3'>Яндекс карта</h2>

			<div className='absolute right-[20px] top-[10px]'>
				<ControlMenus
					menuRef={menuRef}
					openModal={openModal}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
				/>
			</div>
			<div className='flex items-center justify-between gap-4'>
				<TextInput
					value={toolData.yandexCode}
					onChange={onIframeUrlChange}
					placeholder='Введите код карты'
					rows={5}
					disabled={isUrlControlsDisabled}
					label='Код карты'
				/>
				<Button
					variant='outlined'
					classes={{
						root: 'p-[22px]',
					}}
					disabled={isUrlControlsDisabled}
					onClick={onUploadClickHandler}
				>
					Загрузить
				</Button>
			</div>
			<MapFrame
				url={toolData.url}
				onDeleteMap={onDeleteMap}
			/>
		</div>
	);
};

export default YandexMapTool;
