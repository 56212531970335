import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';

import { TextInput } from 'shared/ui';

import type { IAnchorData } from 'shared/ui/Editor/EditorBlockTools/AnchorTool/AnchorTool.interfaces';

interface IProps {
	data: IAnchorData;
	handleDataChange: (newData: IAnchorData) => void;
}

export const AnchorTool = ({ data, handleDataChange }: IProps) => {
	const formik = useFormik({
		initialValues: data,
		onSubmit: () => {},
	});

	useEffect(() => {
		handleDataChange(formik.values);
	}, [formik.values, handleDataChange]);

	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>
				Инструмент {'"'}Якорь{'"'}
			</AccordionSummary>
			<AccordionDetails>
				<form className='flex flex-col'>
					<TextInput
						{...formik.getFieldProps('name')}
						label='Имя якоря'
						placeholder='Введите имя якоря'
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};
