import { Button, DialogContentText } from '@mui/material';
import { useCallback } from 'react';

import { useDialog } from './useDialog';

export const useNotSavedDialog = () => {
	const { dialog } = useDialog();

	return useCallback(
		({ isChangesValid = true, onSave = () => {}, onDismiss = () => {} }: { isChangesValid?: boolean; onSave: () => void; onDismiss: () => void }) => {
			const dismissBtn = (
				<Button
					data-id='DoNotSaveButton'
					key='dismiss'
					color='error'
					variant='outlined'
					onClick={onDismiss}
				>
					Не сохранять
				</Button>
			);

			const buttons = isChangesValid
				? [
						<Button
							data-id='SaveButton'
							key='save'
							onClick={onSave}
							autoFocus
							variant='outlined'
						>
							Сохранить
						</Button>,
						dismissBtn,
				  ]
				: [dismissBtn];

			dialog({
				title: 'Сохранить изменения?',
				isCancelBtn: true,
				buttons,
				body: <DialogContentText data-id='Description'>Внесенные изменения не сохранены. Продолжить?</DialogContentText>,
			});
		},
		[dialog]
	);
};
