import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const ListMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			stroke='#1C274C'
			strokeLinecap='round'
			strokeWidth={1.5}
			d='M20 7H4M15 12H4M9 17H4'
		/>
	</svg>
);
export default ListMenuIcon;
