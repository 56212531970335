import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigator = () => {
	const navigate = useNavigate();
	const location = useLocation();

	return {
		navigate,
		location,
	};
};
