import { Add as AddIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useCallback } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { extractIframeSrc } from 'shared/lib/helpers/extractFromStringHelpers';
import { Button, TextInput } from 'shared/ui';
import { SingleContact } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType5/SingleContact';
import { MapFrame, SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType5 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType5/CardsType5.interfaces';

interface IProps {
	cardData: ICardType5;
	index: number;
	handleChangeCardData: (newData: ICardType5) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType5 = ({ cardData, index, handleChangeCardData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	const isUrlControlsDisabled = !!cardData.yandexUrl?.trim();
	const onUploadClickHandler = () => {
		const extractedUrl = extractIframeSrc(cardData.yandexCode);
		if (!extractedUrl) {
			return;
		}

		handleChangeCardData({ ...cardData, yandexUrl: extractedUrl });
	};

	const onDeleteMap = () => {
		handleChangeCardData({
			...cardData,
			yandexUrl: '',
			yandexCode: '',
		});
	};

	const handleCardContactUp = useCallback(
		(id: number | string) => {
			handleChangeCardData({ ...cardData, contacts: swapToTopArrayItems(id, cardData.contacts) ?? cardData.contacts });
		},
		[handleChangeCardData, cardData]
	);
	const handleCardContactDown = useCallback(
		(id: number | string) => {
			handleChangeCardData({
				...cardData,
				contacts: swapToBottomArrayItems(id, cardData.contacts) ?? cardData.contacts,
			});
		},
		[handleChangeCardData, cardData]
	);
	const handleCardContactClose = useCallback(
		(id: number | string) => {
			handleChangeCardData({
				...cardData,
				contacts: cardData.contacts.filter((card) => {
					return card.id !== id;
				}),
			});
		},
		[handleChangeCardData, cardData]
	);

	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<div>Карта:</div>
					<div className='flex items-center justify-between gap-4'>
						<TextInput
							value={getFieldProps(`cards.${index}.yandexCode`).value}
							onChange={(event) => {
								handleChangeCardData({ ...cardData, yandexCode: event.target.value });
							}}
							placeholder='Введите код карты'
							rows={5}
							disabled={isUrlControlsDisabled}
							label='Код карты'
						/>
						<Button
							variant='outlined'
							classes={{
								root: 'p-[22px]',
							}}
							disabled={isUrlControlsDisabled}
							onClick={onUploadClickHandler}
						>
							Загрузить
						</Button>
					</div>

					<MapFrame
						url={cardData.yandexUrl}
						onDeleteMap={onDeleteMap}
					/>

					<div className='flex w-full flex-col gap-m '>
						<TextInput
							{...getFieldProps(`cards.${index}.style`)}
							label='Стиль'
							placeholder='Введите стиль'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.title`)}
							label='Заголовок'
							placeholder='Введите текст заголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle_1`)}
							label='Подзаголовок №1'
							placeholder='Введите текст подзаголовка №1'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle_2`)}
							label='Подзаголовок №2'
							placeholder='Введите текст подзаголовка №2'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle_3`)}
							label='Подзаголовок №3'
							placeholder='Введите текст подзаголовка №3'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.description_1`)}
							label='Описание №1'
							placeholder='Введите текст описания №1'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.description_2`)}
							label='Описание №2'
							multiline
							minRows={3}
							placeholder='Введите текст описания №2'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.description_3`)}
							label='Описание №3'
							multiline
							minRows={3}
							placeholder='Введите текст описания №3'
						/>
					</div>

					<div>Контакты:</div>
					<div className='flex flex-col gap-m'>
						{cardData.contacts.map((contact, contactIndex) => (
							<SingleContact
								key={contact.id}
								parentIndex={index}
								index={contactIndex}
								getFieldProps={getFieldProps}
								cardContactData={contact}
								handleChangeCardContactData={(newContactData) => {
									handleChangeCardData({
										...cardData,
										contacts: cardData.contacts.map((item) => (item.id === newContactData.id ? { ...item, ...newContactData } : item)),
									});
								}}
								handleClose={handleCardContactClose}
								handleUp={handleCardContactUp}
								handleDown={handleCardContactDown}
							/>
						))}
						<Button
							variant='outlined'
							onClick={() => {
								handleChangeCardData({
									...cardData,
									contacts: [
										...cardData.contacts,
										{
											icon: null,
											icon_text: '',
											icon_url: '',
											id: crypto.randomUUID(),
										},
									],
								});
							}}
							className=''
						>
							<div className='flex gap-m'>
								<AddIcon />
								<div>Добавить контакт</div>
							</div>
						</Button>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
