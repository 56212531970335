import type { EContainerTemplate1FieldTypes } from 'shared/ui/Editor/EditorBlockTools/ContainerTemplatesTool/ContainerTemplatesTool.interfaces';

export enum ELinkTypes {
	DEFAULT = 'default',
	DOWNLOAD = 'download',
}

export interface IContainerTemplate1TextItem {
	id: string;
	text: string;
	style: string;
	type: EContainerTemplate1FieldTypes.DESCRIPTION | EContainerTemplate1FieldTypes.TITLE;
}

export interface IContainerTemplate1LinkItem {
	id: string;
	text: string;
	style: string;
	url: string;
	linkType: ELinkTypes;
	type: EContainerTemplate1FieldTypes.LINK;
}

export type IContainerTemplate1Item = IContainerTemplate1TextItem | IContainerTemplate1LinkItem;

export interface IContainerTemplate1Data {
	text_items: IContainerTemplate1Item[];
}
