import type { INewsComponentData } from 'shared/interfaces';

export const initialNewsData: INewsComponentData = {
	title: '',
	external_url: '',
	accompanying_text: '',
	count: 0,
	material_type_id: '',
	material_id: -1,
	style: '',
	useFilters: false,
};

export const toolboxParams = {
	title: 'Новости',
	icon: '',
};
