import type { TField } from './form';
import type { OutputData } from '@editorjs/editorjs';
import type { ILocale } from 'shared/interfaces/locales';
import type { IMediaFile } from 'shared/interfaces/media';
import type { IUser } from 'shared/interfaces/user';
import type { EStatus } from 'shared/lib/constants';
import type { EAccess } from 'shared/lib/constants/accesses';

interface IMaterialTranslation {
	description: Nullable<string>;
	title: string;
	status: EStatus;
	name: string;
	meta_description: Nullable<string>;
	meta_keywords: Nullable<string>;
	meta_rights: Nullable<string>;
	meta_author: Nullable<string>;
	meta_robots: string;
	og_site_name: Nullable<string>;
	og_type: Nullable<string>;
	og_image: Nullable<string>;
	og_url: Nullable<string>;
	og_title: Nullable<string>;
	og_image_alt: Nullable<string>;
	og_description: Nullable<string>;
	content: OutputData;
	material_type_fields: {
		[key: TField['key']]:
			| string
			| number
			| boolean
			| null
			| [string | null, string | null]
			| Array<{ uuid: string; value: string | null }>
			| Array<{ uuid: string; value: [string | null, string | null] }>;
	};
}

export interface IImageUrl {
	url: string;
	miniature: string;
	regular: string;
	large: string;
	max: string;
}

export interface IMaterialParentObject extends IMaterial {}

export interface IMaterial {
	id: number;
	user: Nullable<IUser>;
	alias: string;
	sort: number;
	full_alias: string;
	translations: Record<ILocale['code'], IMaterialTranslation>;
	is_favorite: boolean;
	status: EStatus;
	access: EAccess;
	published_at: Nullable<string>;
	published_to: string;
	created_at: string;
	updated_at: string;
	parent_object: IMaterialParentObject;
	parent_id?: number;
	media?: Nullable<IMediaFile>;
	material_type?: {
		id: number;
		name: string;
		data: {};
	};
	components?: TMaterialComponent[];
}

export type IMaterialFormValues = Omit<IMaterial, 'id' | 'updated_at' | 'parent_id' | 'media'> & {
	media: Nullable<IMediaFile>;
	material_type_id: number | string; // in DB - number, but for select we should use string
	activeTranslation: string;
};

export enum EMaterialComponent {
	News = 'latest_material',
	Top = 'top_material',
}

export interface IMaterialComponent<Data> {
	type: EMaterialComponent;
	sort: number;
	data: Data;
}

export interface INewsComponentData {
	title: string;
	external_url: string;
	accompanying_text: string;
	material_type_id: number | string;
	material_id: IMaterial['id'];
	count: number;
	useFilters: boolean;
	style: string;
	filters?: {
		[key: string]: {
			active: boolean;
			field_type: string;
		};
	};
}

export enum ESocialNetworks {
	VK = 'VK',
	X = 'X',
	Instagram = 'Instagram',
	Facebook = 'Facebook',
}

export interface ISocialNetworksPost {
	id: number;
	socialNetwork: ESocialNetworks;
	link: string;
}

export interface ISocialNetworksPostsData {
	data: ISocialNetworksPost[];
}

export type TMaterialComponent = IMaterialComponent<INewsComponentData>;

export type { IMaterialTranslation };
