import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { cloneElement, createContext, useCallback, useState } from 'react';

import { useModal } from 'shared/hooks';

import type { ReactNode, ReactElement } from 'react';

interface IModalState {
	title: string;
	isCancelBtn?: true;
	body?: ReactNode;
	buttons?: ReactElement[];
}

const initialModalState: IModalState = {
	title: '',
	body: null,
	buttons: [],
};

export const DialogContext = createContext({ dialog: (_: IModalState) => {}, closeDialogModal: () => {} });

export const DialogProvider = ({ children }: { children: ReactNode }) => {
	const { isModalOpen, openModal, closeModal } = useModal(false);
	const [modalState, setModalState] = useState<IModalState>(initialModalState);

	const openDialog = useCallback(
		(state: IModalState) => {
			setModalState(state);
			openModal();
		},
		[openModal]
	);

	return (
		<DialogContext.Provider value={{ closeDialogModal: closeModal, dialog: openDialog }}>
			{children}

			{isModalOpen && (
				<Dialog
					data-id='Title'
					open={true}
					onClose={closeModal}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					fullWidth
				>
					<div
						className='min-w-[30%] p-5 text-justify'
						data-id='DialogForm'
					>
						<DialogTitle id='alert-dialog-title'>{modalState.title}</DialogTitle>

						{modalState.body && <DialogContent id='alert-dialog-description'>{modalState.body}</DialogContent>}

						<DialogActions>
							{modalState.isCancelBtn && (
								<Button
									data-id='CancelButton'
									onClick={closeModal}
									autoFocus
									variant='outlined'
								>
									Отмена
								</Button>
							)}
							{modalState.buttons?.map((btn) => {
								const onClick = () => {
									btn.props.onClick();
									closeModal();
								};

								return cloneElement(btn, { onClick });
							})}
						</DialogActions>
					</div>
				</Dialog>
			)}
		</DialogContext.Provider>
	);
};
