import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType7 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType7/CardsType7.interfaces';

interface IProps {
	cardData: ICardType7;
	index: number;
	handleChangeCardData: (newData: ICardType7) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType7 = ({ cardData, index, handleChangeCardData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<div className='flex gap-m'>
						<div className='flex flex-col gap-m'>
							<div className='flex flex-col'>
								<div className=''>Изображение</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										handleChangeCardData({ ...cardData, image_main: newFile });
									}}
									handleDeleteImage={() => handleChangeCardData({ ...cardData, image_main: null })}
									mediaItem={cardData.image_main}
								/>
							</div>
						</div>
						<div className='flex w-full flex-col gap-m '>
							<TextInput
								{...getFieldProps(`cards.${index}.style`)}
								label='Стиль'
								placeholder='Введите стиль'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.title`)}
								label='Заголовок'
								placeholder='Введите текст заголовка'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.subtitle_1`)}
								label='Подзаголовок №1'
								placeholder='Введите текст подзаголовка №1'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.subtitle_2`)}
								label='Подзаголовок №2'
								placeholder='Введите текст подзаголовка №1'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.description_1`)}
								label='Описание №1'
								placeholder='Введите текст описания №1'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.description_2`)}
								label='Описание №2'
								placeholder='Введите текст описания №2'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.description_3`)}
								label='Описание №3'
								placeholder='Введите текст описания №3'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.description_4`)}
								label='Описание №4'
								placeholder='Введите текст описания №4'
							/>
						</div>
					</div>

					<div className='flex flex-col gap-m'>
						<div className='flex flex-col gap-m'>
							<div className=''>Иконка №1</div>
							<div className='flex gap-m'>
								<div>
									<SelectImageWithControls
										handleSelectFile={(newFile) => {
											handleChangeCardData({ ...cardData, icon_1: newFile });
										}}
										handleDeleteImage={() => handleChangeCardData({ ...cardData, icon_1: null })}
										mediaItem={cardData.icon_1}
									/>
								</div>
								<div className='flex w-full flex-col gap-m'>
									<TextInput
										{...getFieldProps(`cards.${index}.icon_1_url`)}
										label='Url иконки №1'
										placeholder='Введите URL №1'
									/>
									<TextInput
										{...getFieldProps(`cards.${index}.icon_1_text`)}
										label='Текст иконки №1'
										placeholder='Введите текст иконки №1'
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='flex flex-col gap-m'>
						<div className='flex flex-col gap-m'>
							<div className=''>Иконка №2</div>
							<div className='flex gap-m'>
								<div>
									<SelectImageWithControls
										handleSelectFile={(newFile) => {
											handleChangeCardData({ ...cardData, icon_2: newFile });
										}}
										handleDeleteImage={() => handleChangeCardData({ ...cardData, icon_2: null })}
										mediaItem={cardData.icon_2}
									/>
								</div>
								<div className='flex w-full flex-col gap-m'>
									<TextInput
										{...getFieldProps(`cards.${index}.icon_2_url`)}
										label='Url иконки №2'
										placeholder='Введите URL №2'
									/>
									<TextInput
										{...getFieldProps(`cards.${index}.icon_2_text`)}
										label='Текст иконки №2'
										placeholder='Введите текст иконки №2'
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='flex flex-col gap-m'>
						<div className='flex flex-col gap-m'>
							<div className=''>Иконка №3</div>
							<div className='flex gap-m'>
								<div>
									<SelectImageWithControls
										handleSelectFile={(newFile) => {
											handleChangeCardData({ ...cardData, icon_3: newFile });
										}}
										handleDeleteImage={() => handleChangeCardData({ ...cardData, icon_3: null })}
										mediaItem={cardData.icon_3}
									/>
								</div>
								<div className='flex w-full flex-col gap-m'>
									<TextInput
										{...getFieldProps(`cards.${index}.icon_3_url`)}
										label='Url иконки №3'
										placeholder='Введите URL №3'
									/>
									<TextInput
										{...getFieldProps(`cards.${index}.icon_3_text`)}
										label='Текст иконки №3'
										placeholder='Введите текст иконки №3'
									/>
								</div>
							</div>
						</div>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
