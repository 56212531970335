import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'app/styles/index.css';

import { store } from '@store';
import { theme } from 'app/theme/theme';
import { Pages } from 'pages/_routes';
import { DialogProvider } from 'shared/providers';
import { ScrollToTop, CenteredSpinner } from 'shared/ui';

import Layout from './ui/Layout/Layout';

import type { FC } from 'react';

const App: FC = () => (
	<Provider store={store}>
		<BrowserRouter>
			<ScrollToTop />
			<ToastContainer position='top-right' />
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<DialogProvider>
						<Layout>
							<Suspense fallback={<CenteredSpinner />}>
								<Pages />
							</Suspense>
						</Layout>
					</DialogProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</BrowserRouter>
	</Provider>
);

export default App;
