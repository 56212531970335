const EditorMenuIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={9}
		height={13}
		fill='none'
	>
		<path
			fill='#333'
			fillRule='evenodd'
			d='M.8 2A1.3 1.3 0 0 1 2.1.7h.01a1.3 1.3 0 0 1 0 2.6H2.1A1.3 1.3 0 0 1 .8 2ZM5.99 2A1.3 1.3 0 0 1 7.29.7h.01a1.3 1.3 0 0 1 0 2.6h-.01A1.3 1.3 0 0 1 5.99 2ZM.7 6.698a1.3 1.3 0 0 1 1.3-1.3h.01a1.3 1.3 0 0 1 0 2.6H2a1.3 1.3 0 0 1-1.3-1.3ZM5.99 6.698a1.3 1.3 0 0 1 1.3-1.3h.01a1.3 1.3 0 0 1 0 2.6h-.01a1.3 1.3 0 0 1-1.3-1.3ZM.8 11.398a1.3 1.3 0 0 1 1.3-1.3h.01a1.3 1.3 0 1 1 0 2.6H2.1a1.3 1.3 0 0 1-1.3-1.3ZM5.99 11.398a1.3 1.3 0 0 1 1.3-1.3h.01a1.3 1.3 0 1 1 0 2.6h-.01a1.3 1.3 0 0 1-1.3-1.3Z'
			clipRule='evenodd'
		/>
	</svg>
);
export default EditorMenuIcon;
