import { ThemeProvider } from '@emotion/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store/core';
import { theme } from 'app/theme/theme';
import { EventMixin } from 'shared/lib/helpers';
import { changeBlocksId } from 'shared/lib/helpers/changeBlocksId';

import BlockContainerTool from './BlockContainerTool';
import { toolboxParams } from './BlockContainerTool.meta';

import type { IBlockContainerParams, TBlockContainerData } from './BlockContainerTool.types';
import type { API, BlockAPI, BlockTool } from '@editorjs/editorjs';

export class CBlockContainerTool implements BlockTool {
	data: TBlockContainerData;

	api: API;

	block: BlockAPI;

	emitter: EventMixin;

	currentBlockToCut: any = null;

	constructor({ data, api, block }: IBlockContainerParams) {
		this.data = Object.keys(data).length ? data : { items: { blocks: [] }, style: '' };
		this.api = api;
		this.block = block;

		this.emitter = EventMixin.getInstance();
		this.emitterEvent();
	}

	emitterEvent() {
		this.emitter.on('currentBlockToCut', (data) => {
			this.currentBlockToCut = data;
		});
	}

	static get toolbox() {
		return toolboxParams;
	}

	save() {
		return this.data;
	}

	render(): HTMLElement {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const onDataChange = (data: TBlockContainerData) => {
			this.data = data;
		};

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};

		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		const copyBlock = async () => {
			const blockId = this.block.id;
			const saveData = await this.api.saver.save();
			const pickedBlock = saveData.blocks.find((b) => b.id === blockId);
			if (pickedBlock) {
				this.emitter.emit('currentBlockToCut', pickedBlock);
			}
		};

		const pasteBlock = async () => {
			if (this.currentBlockToCut) {
				const copyObj = JSON.parse(JSON.stringify(this.currentBlockToCut));
				const changedIds = changeBlocksId(copyObj);
				this.data.items.blocks.push(changedIds);
				const saveData = await this.api.saver.save();
				await this.api.blocks.render(saveData);
			}
		};

		renderRoot.render(
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<BlockContainerTool
							handleChange={onDataChange}
							deleteBlock={deleteBlock}
							moveBlockUp={moveBlockUp}
							moveBlockDown={moveBlockDown}
							pasteBlock={pasteBlock}
							copyBlock={copyBlock}
							data={this.data}
						/>
					</Provider>
				</ThemeProvider>
			</BrowserRouter>
		);

		return rootNode;
	}
}
