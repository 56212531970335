import { Formik } from 'formik';

import BlockContainerForm from './ui/BlockContainerForm';

import type { TBlockContainerToolProps } from './BlockContainerTool.types';

const BlockContainerTool = ({ data, handleChange, deleteBlock, moveBlockDown, moveBlockUp, pasteBlock, copyBlock }: TBlockContainerToolProps) => {
	return (
		<Formik
			initialValues={data}
			onSubmit={(values) => {
				handleChange(values);
			}}
		>
			<BlockContainerForm
				pasteBlock={pasteBlock}
				copyBlock={copyBlock}
				handleChange={handleChange}
				deleteBlock={deleteBlock}
				moveBlockDown={moveBlockDown}
				moveBlockUp={moveBlockUp}
			/>
		</Formik>
	);
};

export default BlockContainerTool;
