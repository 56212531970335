import type {
	IContainerTemplate2Data,
	IContainerTemplate2DataListItem,
} from './ContainerTemplate2.interfaces';


export const blankListItem = (): IContainerTemplate2DataListItem => ({
	id: crypto.randomUUID(),
	icon: null,
	style: '',
	text: '',
});

export const blankContainerTemplate2Data = (): IContainerTemplate2Data => ({
	subtitle: '',
	list_style: '',
	listItems: [],
	description: '',
});