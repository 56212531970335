export const normalizeParams = (params: Record<string, string | number>): Record<string, string | number> => {
	let newParams: Record<string, string | number> = {};

	for (const key in params) {
		if (typeof params[key] === 'number' && params[key] === -1) {
			newParams[key] = '';
		} else {
			newParams[key] = params[key];
		}
	}

	return newParams;
};
