import EditorJS from '@editorjs/editorjs';
import { Field, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { stopPropagationHandler } from 'shared/lib/helpers/editorJsEvent';
import { getEditorConfigs } from 'shared/ui/Editor/Editor.lib';
import { COPY, PASTE, tuneCopyElement, tunePasteElement } from 'shared/ui/Editor/EditorBlockTools/EditorBlockTools.meta';
import { ControlMenus } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { TextInput } from 'shared/ui/TextInput';

import type { IBlockContainerFormProps, TBlockContainerData } from 'shared/ui/Editor/EditorBlockTools/BlockContainerTool/BlockContainerTool.types';

const BlockContainerForm = ({ handleChange, deleteBlock, moveBlockDown, moveBlockUp, pasteBlock, copyBlock }: IBlockContainerFormProps) => {
	const { values, setFieldValue } = useFormikContext<TBlockContainerData>();
	const { isModalOpen, openModal, closeModal } = useModal();

	useEffect(() => {
		handleChange(values);
	}, [handleChange, values]);

	const ref = useRef<EditorJS | null>(null);
	const editorWrapper = useRef<HTMLDivElement>(null);

	const editorHolder = useMemo(() => uuidv4(), []);

	useEffect(() => {
		if (ref.current) return;

		editorWrapper.current?.addEventListener('keydown', stopPropagationHandler);

		const config = getEditorConfigs();
		config.holder = editorHolder;
		config.minHeight = 100;

		ref.current = new EditorJS({
			...config,
			data: values.items,
			onChange: async (api) => {
				const newData = await api.saver.save();
				setFieldValue('items', newData);
			},
		});

		return () => {
			if (ref.current && ref.current.destroy) {
				ref.current.destroy();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
			editorWrapper.current?.removeEventListener('keydown', stopPropagationHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const pickElement = (name: string) => {
		if (name === PASTE) {
			pasteBlock();
			closeModal();
		}
		if (name === COPY) {
			copyBlock();
			closeModal();
		}
	};
	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	useClickOutside(menuRef, closeModal);

	return (
		<div className='relative z-0'>
			<h2 className='mb-3'>Контейнер</h2>
			<div className='absolute right-[17px] top-[10px]'>
				<ControlMenus
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
					items={[...tuneCopyElement, ...tunePasteElement]}
					pickElement={pickElement}
					isModalOpen={isModalOpen}
					openModal={openModal}
					closeModal={closeModal}
					menuRef={menuRef}
				/>
			</div>
			<div className='flex flex-col gap-y-4'>
				<Field
					name='style'
					as={TextInput}
					placeholder='Введите стиль'
					label='Стиль'
				/>
			</div>
			<div className='mb-4 mt-4'>
				<hr className='h-[1px] text-border-secondary' />
			</div>
			<div
				id={editorHolder}
				ref={editorWrapper}
			/>
		</div>
	);
};

export default BlockContainerForm;
