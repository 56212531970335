import EditorJS from '@editorjs/editorjs';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { stopPropagationHandler } from 'shared/lib/helpers/editorJsEvent';
import { getEditorConfigs } from 'shared/ui/Editor/Editor.lib';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';

import type { MutableRefObject } from 'react';
import type { ISliderData, ISliderItemProps } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.interface';

const SliderItem = ({ item, index }: ISliderItemProps) => {
	const sliderRef = useRef<EditorJS | null>(null);

	const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

	const { values, setFieldValue } = useFormikContext<ISliderData>();

	useEffect(() => {
		if (sliderRef.current) return;

		containerRef.current?.addEventListener('keydown', stopPropagationHandler);

		const config = getEditorConfigs();

		config.holder = String(item.id);
		config.minHeight = 100;

		sliderRef.current = new EditorJS({
			...config,
			data: item.data,
			onChange: async (api) => {
				const newData = await api.saver.save();

				setFieldValue(`items.${index}.data`, newData);
			},
		});

		return () => {
			if (sliderRef.current && sliderRef.current?.destroy) {
				sliderRef.current?.destroy();
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
			containerRef.current?.removeEventListener('keydown', stopPropagationHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUp = useCallback(
		(id: number | string) => {
			setFieldValue('items', swapToTopArrayItems(id, values.items));
		},
		[setFieldValue, values.items]
	);

	const handleDown = useCallback(
		(id: number | string) => {
			setFieldValue('items', swapToBottomArrayItems(id, values.items));
		},
		[setFieldValue, values.items]
	);

	const handleClose = useCallback(
		(id: number | string) => {
			setFieldValue(
				'items',
				values.items.filter((slide) => slide.id !== id)
			);
		},
		[setFieldValue, values.items]
	);

	return (
		<div
			className='flex flex-col gap-4'
			ref={containerRef}
		>
			<div
				className='rounded bg-white p-4'
				id={String(item.id)}
			/>
			<SubTuner
				index={index}
				tuneItem={item.id}
				handleUp={handleUp}
				handleDown={handleDown}
				handleClose={handleClose}
			/>
		</div>
	);
};

export default SliderItem;
