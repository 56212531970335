import { ECardsVariants } from './CardsTool.interfaces';

import type { ICardsData } from './CardsTool.interfaces';

export const cardsTooltip = {
	title: 'Карточки',
	icon:
		'<svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n' +
		'\t viewBox="0 0 512 512" xml:space="preserve">\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M494.029,178.04H109.116c-4.366,0-7.903,3.537-7.903,7.903c0,4.366,3.537,7.903,7.903,7.903h384.912\n' +
		'\t\t\tc1.194,0,2.165,0.972,2.165,2.165v119.978c0,1.194-0.972,2.165-2.165,2.165h-60.21c-4.366,0-7.903,3.537-7.903,7.903\n' +
		'\t\t\ts3.537,7.903,7.903,7.903h60.21c9.909,0,17.971-8.062,17.971-17.971V196.011C512,186.101,503.938,178.04,494.029,178.04z"/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M402.207,318.155H17.971c-1.194,0-2.165-0.972-2.165-2.165V196.011c0-1.194,0.971-2.165,2.165-2.165h59.533\n' +
		'\t\t\tc4.366,0,7.903-3.537,7.903-7.903c0-4.366-3.537-7.903-7.903-7.903H17.971C8.061,178.04,0,186.102,0,196.011v119.978\n' +
		'\t\t\tc0,9.909,8.061,17.971,17.971,17.971h384.236c4.366,0,7.903-3.537,7.903-7.903C410.11,321.692,406.573,318.155,402.207,318.155z"\n' +
		'\t\t\t/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M316.09,212.084H195.91c-4.366,0-7.903,3.537-7.903,7.903v72.023c0,4.366,3.537,7.903,7.903,7.903H316.09\n' +
		'\t\t\tc4.365,0,7.903-3.537,7.903-7.903v-72.023C323.993,215.622,320.456,212.084,316.09,212.084z M308.187,284.108H203.813h0V227.89\n' +
		'\t\t\th104.375V284.108z"/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M161.758,212.084H41.578c-4.366,0-7.903,3.537-7.903,7.903v72.023c0,4.366,3.537,7.903,7.903,7.903h120.181\n' +
		'\t\t\tc4.365,0,7.903-3.537,7.903-7.903v-72.023C169.661,215.622,166.124,212.084,161.758,212.084z M153.856,284.108H49.481V227.89\n' +
		'\t\t\th104.375V284.108z"/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M470.422,212.084H350.242c-4.366,0-7.903,3.537-7.903,7.903v72.023c0,4.366,3.537,7.903,7.903,7.903h120.181\n' +
		'\t\t\tc4.365,0,7.903-3.537,7.903-7.903v-72.023C478.325,215.622,474.788,212.084,470.422,212.084z M462.519,284.108H358.144V227.89\n' +
		'\t\t\th104.375V284.108z"/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'</svg>',
};

const initialCardsData: ICardsData = {
	variant: ECardsVariants.TYPE_1,
	max_row_count: 3,
	cards_title: '',
	cards: [],
	style: '',
};

export const getInitialCardsData = (params: { copyWith?: Partial<ICardsData> } = {}): ICardsData => {
	const { copyWith } = params;
	return {
		...initialCardsData,
		...copyWith,
	};
};

export const selectCardsOptions = [
	{
		value: ECardsVariants.TYPE_1,
		label: 'Шаблон 1',
	},
	{
		value: ECardsVariants.TYPE_2,
		label: 'Одна большая карточка',
	},
	{
		value: ECardsVariants.TYPE_3,
		label: 'Карточки "Наши ценности"',
	},
	{
		value: ECardsVariants.TYPE_4,
		label: 'Одна Карточка Учредитель',
	},
	{
		value: ECardsVariants.TYPE_5,
		label: 'Одна карточка с картой и контактами (О предприятии)',
	},
	{
		value: ECardsVariants.TYPE_6,
		label: 'Одна карточка с картой и контактами (Образование)',
	},
	{
		value: ECardsVariants.TYPE_7,
		label: 'Карточки "Руководство"',
	},
	{
		value: ECardsVariants.TYPE_8,
		label: 'Одна карточка с картинкой (справа)',
	},
	{
		value: ECardsVariants.TYPE_9,
		label: 'Порядок оказания услуг',
	},
	{
		value: ECardsVariants.TYPE_10,
		label: 'Услуги кибербезопасности',
	},
	{
		value: ECardsVariants.TYPE_11,
		label: 'Маленькие с иконкой и кнопкой',
	},
	{
		value: ECardsVariants.TYPE_12,
		label: 'Услуги с иконкой в центре',
	},
	{
		value: ECardsVariants.TYPE_13,
		label: 'СМИ (для блока новостей)',
	},
	{
		value: ECardsVariants.TYPE_14,
		label: 'Партнеры',
	},
];
