export enum ERoutes {
	HOME = '/',

	PROFILE = '/profile',

	NOT_FOUND = '/404',

	LOGIN = '/login',

	USERS_MANAGER = '/users-manager',
	USERS = `${ERoutes.USERS_MANAGER}/users`,
	ROLES = `${ERoutes.USERS_MANAGER}/roles`,

	MATERIALS_MANAGER = '/materials-manager',
	MATERIALS = `${ERoutes.MATERIALS_MANAGER}/materials`,
	MATERIAL_TYPES = `${ERoutes.MATERIALS_MANAGER}/material-types`,
	FORMS = `${ERoutes.MATERIALS_MANAGER}/forms`,

	MENU_MANAGER = '/menu-manager',
	MENU_BLOCK = `${ERoutes.MENU_MANAGER}/menu-block`,
	MENU_ITEMS = `${ERoutes.MENU_MANAGER}/menu-items`,

	MEDIA_MANAGER = '/media-manager',
	MEDIA = `${ERoutes.MEDIA_MANAGER}/media`,

	BASKET = '/basket',
	BASKET_LOCALES = `${ERoutes.BASKET}/locales`,
	BASKET_MATERIALS = `${ERoutes.BASKET}/materials`,
	BASKET_MENU_ITEMS = `${ERoutes.BASKET}/menu-items`,

	SETTINGS = '/settings',
	GENERAL = `${ERoutes.SETTINGS}/general`,
	LOCALES = `${ERoutes.SETTINGS}/locales`,
	HEADER_SITE = `${ERoutes.SETTINGS}/header-sites`,
	FOOTER_SITE = `${ERoutes.SETTINGS}/footer-sites`,
	EVENT_LOG = `${ERoutes.SETTINGS}/logs`,
	WATERMARK = `${ERoutes.SETTINGS}/watermark`,
	SYSTEMS = `${ERoutes.SETTINGS}/systems`,
	MAIL = `${ERoutes.SETTINGS}/mail`,
}
