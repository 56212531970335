import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { EvaEditor, SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType12 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType12/CardsType12.interfaces';

interface IProps {
	cardData: ICardType12;
	index: number;
	handleChangeCardData: (newData: ICardType12) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType12 = ({
	cardData,
	index,
	handleChangeCardData,
	handleUp,
	handleDown,
	handleClose,
	getFieldProps,
	setFieldValue,
}: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<div className='flex gap-m'>
						<div className='flex flex-col gap-m'>
							<div className='flex flex-col gap-m'>
								<div className=''>Изображение заголовка</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										handleChangeCardData({ ...cardData, title_image: newFile });
									}}
									handleDeleteImage={() => handleChangeCardData({ ...cardData, title_image: null })}
									mediaItem={cardData.title_image}
								/>
							</div>
						</div>
						<div className='flex w-full flex-col gap-m '>
							<TextInput
								{...getFieldProps(`cards.${index}.style`)}
								label='Стиль'
								placeholder='Введите стиль'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.title`)}
								label='Заголовок'
								placeholder='Введите текст заголовка'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.link_url`)}
								label='URL ссылки'
								placeholder='Введите URL'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.link_text`)}
								label='Текст ссылки'
								placeholder='Введите текст ссылки'
							/>
						</div>
					</div>
					<div className='flex flex-col gap-m rounded-2xl border-[1px] p-m'>
						<div>Контент карточки:</div>
						<div>
							<EvaEditor
								data={cardData.cardContent}
								handleChangeData={(newData) => {
									setFieldValue(`cards.${index}.cardContent`, newData);
								}}
							/>
						</div>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
