import type { ISVGProps } from './interfaces';
import type { FC } from 'react';

const ButtonIcon: FC<ISVGProps> = () => (
	<svg
		width='17'
		height='16'
		viewBox='0 0 17 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M8.4994 11.5158L8.50063 11.5158C8.54669 11.5159 8.59228 11.5067 8.63475 11.4889L8.4994 11.5158ZM8.4994 11.5158C8.40858 11.5159 8.32134 11.4804 8.25643 11.4169L8.4994 11.5158ZM2.61539 13.8846L2.61537 13.8846C2.10025 13.3695 1.81748 12.6868 1.81748 11.9583V11.1666C1.81748 10.9737 1.97381 10.8174 2.16667 10.8174C2.35953 10.8174 2.51587 10.9737 2.51587 11.1666V11.9583C2.51587 12.4986 2.7272 13.0087 3.10916 13.3907L3.10919 13.3908C3.49122 13.7727 4.00134 13.9841 4.54166 13.9841H12.4583C12.9986 13.9841 13.5087 13.7727 13.8907 13.3907L13.8908 13.3907C14.2727 13.0087 14.4841 12.4986 14.4841 11.9583V11.1666C14.4841 10.9737 14.6405 10.8174 14.8333 10.8174C15.0262 10.8174 15.1825 10.9738 15.1825 11.1666V11.9583C15.1825 12.6867 14.8998 13.3694 14.3846 13.8846C13.8694 14.3998 13.1867 14.6825 12.4583 14.6825H4.54166C3.81323 14.6825 3.13056 14.3998 2.61539 13.8846ZM8.24689 11.4074L5.08642 8.2469L4.77354 8.55977L5.08642 8.2469C4.95007 8.11055 4.95005 7.88943 5.08643 7.75304C5.22281 7.61668 5.44392 7.61669 5.58025 7.75303L5.58027 7.75305L7.39547 9.56825L8.15082 10.3236V9.25537V1.66667V1.66664C8.15081 1.47379 8.3071 1.31748 8.49999 1.31748C8.69288 1.31748 8.84921 1.47382 8.84921 1.66667V9.25534V10.3236L9.60456 9.56822L11.4197 7.75305L11.4197 7.75303C11.5561 7.6167 11.7772 7.6167 11.9136 7.75304L11.9136 7.75305C12.0499 7.8894 12.0499 8.11054 11.9136 8.2469L8.74928 11.4112L8.74926 11.4112L8.74718 11.4132L8.74232 11.4178L8.73979 11.4205C8.73721 11.4229 8.73477 11.4252 8.73243 11.4272L8.73169 11.4279C8.70342 11.4532 8.67117 11.4736 8.63624 11.4883L8.63516 11.4888C8.63504 11.4888 8.63492 11.4889 8.6348 11.4889L8.24689 11.4074ZM8.25862 11.4191L8.25826 11.4188C8.25823 11.4187 8.2582 11.4187 8.25817 11.4187C8.25834 11.4188 8.25849 11.419 8.25863 11.4191L8.25862 11.4191Z'
			fill='#333333'
			stroke='#333333'
			strokeWidth='0.884951'
		/>
	</svg>
);

export default ButtonIcon;
