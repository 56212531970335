import { TextareaAutosize } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';

import type { TextareaAutosizeProps } from '@mui/material';

interface IProps extends TextareaAutosizeProps {
	label?: string;
}

export const CMSTextarea = ({ label, ...rest }: IProps) => {
	const [isFocused, setIsFocused] = useState<boolean>(false);
	return (
		<div className='relative w-full'>
			<label
				className={classNames('absolute left-[14px] top-0 origin-left -translate-y-1/2 scale-75 transform bg-white px-s', {
					'text-enabled': isFocused,
					'text-gray': !isFocused,
				})}
			>
				{label}
			</label>
			<TextareaAutosize
				{...rest}
				onFocus={(e) => {
					setIsFocused(true);
					if (rest.onFocus) rest.onFocus(e);
				}}
				onBlur={(e) => {
					setIsFocused(false);
					if (rest.onBlur) rest.onBlur(e);
				}}
			/>
		</div>
	);
};
