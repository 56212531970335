import type { IContainerTemplate4Data } from './ContainerTemplate4.interfaces';

export const blankContainerTemplate4Data = (): IContainerTemplate4Data => ({
	subtitle: '',
	description: '',
	url: '',
	url_text: '',
	main_image: null,
	content: { blocks: [] },
});
