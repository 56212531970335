import { LogoutIcon, PersonSoloIcon } from '@assets/svgs';
import { ERoutes } from 'shared/lib/constants';

import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

interface IAuthMenuItem {
	dataId: string;
	label: string;
	link: ERoutes;
	icon: FC<ISVGProps>;
}

export const AuthMenuItems: IAuthMenuItem[] = [
	{ dataId: 'Profile', label: 'Профиль', icon: PersonSoloIcon, link: ERoutes.PROFILE },
	// { label: 'Журнал событий', icon: BookIcon },
	{ dataId: 'Exit', label: 'Выйти', icon: LogoutIcon, link: ERoutes.LOGIN },
];
