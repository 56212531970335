import { layerGroup, polyline } from 'leaflet';
import { useEffect, useRef } from 'react';

import {
	InternationalLinesZoomMaxLimit, InternationalLinesZoomMinLimit,
} from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.constants';

import type { LayerGroup, Map as LMap } from 'leaflet';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	data: ILeafletMapData['lines']['international'];
	map: LMap;
	zoom: number;
}

export const International = ({ data, zoom, map }: IProps) => {

	const groupRef = useRef<LayerGroup>();

	useEffect(() => {
		if (map) {
			if (groupRef.current) {
				groupRef.current?.remove();
				groupRef.current = undefined;
			}
			const createdLinesGroup = data.reduce((linesLayerGroup, line) => {
				const createdLine = polyline(line.coordinates, {});
				createdLine.addTo(linesLayerGroup);
				return linesLayerGroup;
			}, layerGroup());

			groupRef.current = createdLinesGroup;
			if (zoom > InternationalLinesZoomMinLimit && zoom < InternationalLinesZoomMaxLimit) {
				createdLinesGroup.addTo(map);
			}
		}

	}, [data, map]);

	useEffect(() => {
		const group = groupRef.current;
		if (map && group) {
			if (zoom > InternationalLinesZoomMinLimit && zoom < InternationalLinesZoomMaxLimit) group.addTo(map);
			else group.remove();
		}
	}, [map, zoom]);

	return null;
};