import type { ReactNode } from 'react';

interface TabPanelProps {
	children?: ReactNode;
	index: number | string;
	value: number | string;
	className?: string;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, className, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			className='w-full overflow-auto'
			{...other}
		>
			{value === index && <div className={className}>{children}</div>}
		</div>
	);
};

export default TabPanel;
