import type { ISVGProps } from './interfaces';
import type { FC } from 'react';

const MenuArrow: FC<ISVGProps> = () => (
	<svg
		width='7'
		height='14'
		viewBox='0 0 7 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7 14L0.720175 8.50515C-0.190479 7.70833 -0.190481 6.29167 0.720173 5.49485L7 -3.49691e-07L7 14Z'
			fill='white'
		/>
	</svg>
);

export default MenuArrow;
