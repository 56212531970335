import { createApi } from '@reduxjs/toolkit/query/react';

import instance from 'shared/api/axios';
import { ETagTypes } from 'shared/lib/constants/tagTypes';
import { RequestError } from 'shared/lib/helpers/requestError';

import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

// TODO: transformResponse?

export interface AxiosErrorResponse {
	status: boolean;
	message: string;
	errors: string[];
}

const axiosBaseQuery = (): BaseQueryFn<AxiosRequestConfig, AxiosResponse, AxiosError<Partial<AxiosErrorResponse>>> => async (config) => {
	try {
		const res = await instance(config);

		return { data: typeof res === 'object' ? res.data : res };
	} catch (err: any) {
		const typedErr = err as AxiosError<Partial<AxiosErrorResponse>>;
		RequestError.notify(typedErr);

		return {
			error: err,
		};
	}
};

const baseApi = createApi({
	reducerPath: 'api',
	baseQuery: axiosBaseQuery(),
	tagTypes: Object.values(ETagTypes),
	endpoints: () => ({}),
	keepUnusedDataFor: 0,
});

export default baseApi;
