import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import type { TAccordionMenuItem } from 'app/ui/AccordionMenu/AccordionMenu.interfaces';

interface Props {
	item: TAccordionMenuItem;
	hideMenu: () => void;
	isOpenCollapsedMenu: boolean;
	isLastItemOfTab: boolean;
}

const NestedMenuItem = ({ item, hideMenu, isOpenCollapsedMenu, isLastItemOfTab }: Props) => {
	const { pathname } = useLocation();

	const linkClassnames = cn('flex cursor-pointer overflow-hidden flex-col font-normal hover:bg-dropzone', {
		'px-8 py-3': isOpenCollapsedMenu,
		'p-[12px] pl-[30px]': !isOpenCollapsedMenu,
		'bg-[#F0EEFF]': pathname === ('to' in item ? item.to : '/'),
		'rounded-b-lg': isLastItemOfTab,
	});

	const linkTo = 'to' in item ? item.to : '/';

	return (
		<Link
			data-id={item.dataId}
			to={linkTo}
			key={item.title}
			onClick={hideMenu}
			className={linkClassnames}
		>
			{item.title}
		</Link>
	);
};

export default NestedMenuItem;
