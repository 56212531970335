import { Add as AddIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { Button, TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls
	from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import { blankListItem } from './ContainerTemplate2.lib';

import type {
	IContainerTemplate2Data,
} from './ContainerTemplate2.interfaces';
import type { FieldInputProps } from 'formik/dist/types';

interface IProps {
	data: IContainerTemplate2Data;
	getFieldProps: (field: string) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
}

export const ContainerTemplate2 = ({ data, setFieldValue, getFieldProps }: IProps) => {
	useEffect(() => {
		if (data.listItems.length === 0) {
			setFieldValue('data.listItems', [blankListItem()]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleListUp = useCallback(
		(id: number | string) => {
			setFieldValue('data.listItems', swapToTopArrayItems(id, data.listItems));
		},
		[setFieldValue, data.listItems],
	);
	const handleListDown = useCallback(
		(id: number | string) => {
			setFieldValue('data.listItems', swapToBottomArrayItems(id, data.listItems));
		},
		[setFieldValue, data.listItems],
	);
	const handleListClose = useCallback(
		(id: number | string) => {
			setFieldValue(
				'data.listItems',
				data.listItems.filter((item) => {
					return item.id !== id;
				}),
			);
		},
		[setFieldValue, data.listItems],
	);

	return <div className='flex flex-col gap-m'>
		<TextInput
			{...getFieldProps('subtitle')}
			label='Подзаголовк контейнера'
			placeholder='Введите подзаголовок контейнера'
		/>
		<TextInput
			{...getFieldProps('description')}
			label='Описание'
			placeholder='Введите описание'
		/>
		<div>Список:</div>
		<TextInput
			{...getFieldProps('list_style')}
			label='Стиль списка'
			placeholder='Введите стиль списка'
		/>
		{data.listItems.map((item, listIndex) => {
			return (
				<Accordion key={item.id}>
					<AccordionSummary>
						Элемент списка {listIndex + 1}
					</AccordionSummary>
					<AccordionDetails>
						<div className='flex gap-m'>
							<div className='flex flex-col'>
								<div className=''>Иконка</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										setFieldValue(`data.listItems.${listIndex}.icon`, newFile);
									}}
									handleDeleteImage={() => setFieldValue(`data.listItems.${listIndex}.icon`, null)}
									mediaItem={item.icon}
								/>
							</div>
							<div className='flex-col flex gap-m w-full'>

								<TextInput
									{...getFieldProps(`data.listItems.${listIndex}.style`)}
									label='Стиль элемента списка'
									placeholder='Введите стиль элемента списка'
								/>
								<TextInput
									{...getFieldProps(`data.listItems.${listIndex}.text`)}
									label='Текст элемента списка'
									placeholder='Введите текст элемента списка'
								/>
								<SubTuner
									tuneItem={item.id}
									handleUp={handleListUp}
									handleClose={handleListClose}
									handleDown={handleListDown}
								/>
							</div>

						</div>
					</AccordionDetails>
				</Accordion>
			);
		})}
		<Button
			variant='outlined'
			onClick={() => {
				setFieldValue('data.listItems', [...data.listItems, blankListItem()]);
			}}
		>
			<div className='flex gap-m'>
				<AddIcon />
				<div>Добавить элемент списка</div>
			</div>
		</Button>

	</div>;
};