import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import baseApi from 'shared/api/baseApi';

import createRootReducer from './reducers/root';

import type { AppDispatch, RootState } from '@store/interfaces';
import type { TypedUseSelectorHook } from 'react-redux';

export const store = configureStore({
	reducer: combineReducers({
		[baseApi.reducerPath]: baseApi.reducer,
		...createRootReducer(),
	}),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware),
});

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
