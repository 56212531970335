import { EMimeTypes } from 'shared/ui/FileUploader/FileUploader.interfaces';

const imageMimes = [EMimeTypes.GIF, EMimeTypes.JPG, EMimeTypes.JPEG, EMimeTypes.PNG, EMimeTypes.WEBP, EMimeTypes.SVG];
const audioMimes = [EMimeTypes.MP3, EMimeTypes.M4A, EMimeTypes.OGG_AUDIO, EMimeTypes.WAV, EMimeTypes.M4A_AUDIO];
const videoMimes = [
	EMimeTypes.AVI,
	EMimeTypes.MPEG,
	EMimeTypes.M4V,
	EMimeTypes.QUICKTIME,
	EMimeTypes.OGG_VIDEO,
	EMimeTypes.VTT,
	EMimeTypes.WINDOWS_MEDIA,
	EMimeTypes.VIDEO_3GPP,
	EMimeTypes.VIDEO_3GPP2,
	EMimeTypes.MP4,
];
const docsMimes = [
	EMimeTypes.DOC,
	EMimeTypes.DOCX,
	EMimeTypes.ODT,
	EMimeTypes.PPT,
	EMimeTypes.PPTX,
	EMimeTypes.PPS,
	EMimeTypes.PPSX,
	EMimeTypes.XLS,
	EMimeTypes.XLSX,
	EMimeTypes.ZIP,
];
const availableMimes = '*';

const imageTypes = ['.gif', '.jpeg', '.jpg', '.png', '.webp', '.svg'];

const pdfType = ['.pdf'];

const audioTypes = ['.mp3', '.m4a', '.ogg', '.wav'];

const videoTypes = ['.avi', '.mpg', '.mp4', '.m4v', '.mov', '.quicktime', '.ogv', '.vtt', '.wmv', '.3gp', '.3g2'];

const documentTypes = ['.doc', '.docx', '.odt', '.pdf', '.ppt', '.pptx', '.pps', '.ppsx', '.xls', '.xlsx', '.zip'];

const availableTypes = [...imageTypes, ...audioTypes, ...videoTypes, ...documentTypes];

const MAX_FILE_SIZE_GB = 16;

export {
	availableMimes,
	availableTypes,
	imageTypes,
	pdfType,
	audioTypes,
	videoTypes,
	imageMimes,
	audioMimes,
	videoMimes,
	docsMimes,
	documentTypes,
	MAX_FILE_SIZE_GB,
};
