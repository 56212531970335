
export const mapMinZoom = 3

export  const mapMaxZoom = 8

export const mapInitZoom = 4

export const initialMapCenter = {
	lat: 53.89301,
	lng: 27.56744,
};


export const CityZoomMaxLimit = 10
export const CityZoomMinLimit = 5
export const CapitalsZoomMaxLimit = 10
export const CapitalsZoomMinLimit = 0
export const BorderPointsZoomMaxLimit = 10
export const BorderPointsZoomMinLimit = 0


export const InternalLinesZoomMaxLimit = 10
export const InternalLinesZoomMinLimit = 5
export const InternationalLinesZoomMaxLimit = 6
export const InternationalLinesZoomMinLimit = 0
