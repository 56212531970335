import type { IRole } from 'shared/interfaces/roles';
import type { IColumnsConfig } from 'shared/ui/CMSTable/CmsTable.interfaces';

export const rolesColumnsConfigs: IColumnsConfig<IRole>[] = [
	{
		label: 'Название',
		dataId: 'NameColumn',
		renderRow: (row) => row.name,
		valueForOrdering: 'name',
	},
	{
		label: 'Описание',
		dataId: 'DescriptionColumn',
		renderRow: (row) => row.description,
	},
	{
		label: 'Пользователи',
		dataId: 'UsersCountColumn',
		renderRow: (row) => row.users_count,
	},
];

export const SUPER_ADMIN = 'superadmin';
export const SUPER_ADMIN_ROLE = 'Супер администратор';
