import cn from 'classnames';
import { useEffect, useRef } from 'react';

import NestedMenuItem from 'app/ui/AccordionMenu/ui/NestedMenuItem';
import MenuArrow from 'shared/assets/svgs/MenuArrow';
import iconArrow from 'shared/assets/svgs/arrow.svg';
import iconArrowActive from 'shared/assets/svgs/arrowActive.svg';
import { useToggle } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';

import type { IAccordionMenuAccordionItem } from 'app/ui/AccordionMenu/AccordionMenu.interfaces';

interface Props {
	item: IAccordionMenuAccordionItem;
	hideMenu: () => void;
	isOpenCollapsedMenu: boolean;
	activeItemId: string[];
	setActiveItemId: (value: any) => void;
}

const Accordion = ({ item, hideMenu, isOpenCollapsedMenu, activeItemId, setActiveItemId }: Props) => {
	const { setIsOn: setIsOpen } = useToggle(item.isActive);
	const nestedRef = useRef<Nullable<HTMLUListElement>>(null);
	const isOpen = activeItemId.includes(item.dataId);

	useClickOutside(nestedRef, () => {
		if (isOpenCollapsedMenu) {
			setActiveItemId((prevState: string[]) => {
				return prevState.filter((v) => v !== item.dataId);
			});
		}
	});

	useEffect(() => {
		if (isOpenCollapsedMenu) {
			setIsOpen(!!item.isActive);
		}
	}, [item, setIsOpen, isOpenCollapsedMenu]);

	useEffect(() => {
		if (activeItemId.includes(item.dataId)) {
			nestedRef.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	}, [activeItemId, item.dataId]);

	const itemClassnames = cn('whitespace-nowrap pl-[15px] text-[16px] font-medium', {
		'text-enabled': activeItemId.includes(item.dataId),
	});
	const rotateClassnames = cn('rounded-b-lg border-solid border-[1px] border-gray-light bg-white text-black', {
		'absolute left-[90px] z-10 w-max -translate-y-[60%] rounded-lg': isOpenCollapsedMenu,
	});
	const toggleClassnames = cn('mt-[10px] flex min-h-[56px] w-full cursor-pointer items-center justify-between px-[15px]', {
		'rounded-t-lg bg-dropzone': activeItemId.includes(item.dataId),
		'rounded-lg bg-[#ffffff14]': !activeItemId.includes(item.dataId) || isOpenCollapsedMenu,
	});

	const onMenuItemClick = () => {
		if (activeItemId.includes(item.dataId)) {
			if (isOpenCollapsedMenu) {
				setActiveItemId([]);
			} else {
				setActiveItemId((prevState: string[]) => {
					return prevState.filter((v) => v !== item.dataId);
				});
			}
		} else {
			if (isOpenCollapsedMenu) {
				setActiveItemId([item.dataId]);
			} else {
				setActiveItemId((prevState: string[]) => [...prevState, item.dataId]);
			}
		}
	};

	return (
		<div
			className='relative'
			data-id={item.dataId}
		>
			<div
				className={toggleClassnames}
				onClick={onMenuItemClick}
			>
				<div className='flex'>
					<img
						src={activeItemId.includes(item.dataId) ? item?.imgActive : item?.img}
						height={24}
						width={24}
						alt='icon'
					/>
					{!isOpenCollapsedMenu && <span className={itemClassnames}>{item.title}</span>}
				</div>

				{!isOpenCollapsedMenu && (
					<div className={cn('flex  transition-all duration-300', { 'rotate-90': activeItemId.includes(item.dataId) })}>
						<img
							src={activeItemId.includes(item.dataId) ? iconArrowActive : iconArrow}
							alt='arrow'
						/>
					</div>
				)}
			</div>
			{isOpen && (
				<ul
					className={rotateClassnames}
					ref={nestedRef}
				>
					{isOpenCollapsedMenu && (
						<div className='absolute -left-[7px] top-[40%]'>
							<MenuArrow />
						</div>
					)}
					{item.items.map((nestedItem, index) => (
						<NestedMenuItem
							key={nestedItem.dataId}
							item={nestedItem}
							hideMenu={() => {
								setActiveItemId((prevState: string[]) => {
									return prevState.filter((v) => v !== item.dataId);
								});
								hideMenu();
							}}
							isOpenCollapsedMenu={isOpenCollapsedMenu}
							isLastItemOfTab={item.items.length - 1 === index}
						/>
					))}
				</ul>
			)}
		</div>
	);
};

export default Accordion;
