import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store/core';
import { theme } from 'app/theme/theme';
import { DialogProvider } from 'shared/providers';

import SliderTool from './SliderTool';
import { ESliderType, type ISliderData, type ISliderToolParams } from './SliderTool.interface';
import { generateSliderItem, generateSocialNetwork, toolboxParams, tuneIcons } from './SliderTool.meta';

import type { API, BlockAPI, BlockTool } from '@editorjs/editorjs';
import type { TunesMenuConfig } from '@editorjs/editorjs/types/tools';

class CSliderTool implements BlockTool {
	data: ISliderData;

	api: API;

	block: BlockAPI;

	constructor({ api, block, data }: ISliderToolParams) {
		this.data = Object.keys(data).length
			? data
			: {
					items: [generateSliderItem()],
					style: '',
					items_amount: 1,
					delay: 0,
					headline: '',
					subHeadLine: '',
					type: ESliderType.TYPE_1,
					socialNetworks: [generateSocialNetwork()],
					socialNetworksHeadline: '',
			  };
		this.api = api;
		this.block = block;
	}

	static get toolbox() {
		return toolboxParams;
	}

	async save() {
		return this.data;
	}

	renderSettings(): HTMLElement | TunesMenuConfig {
		return [
			{
				title: 'Добавить слайд',
				icon: tuneIcons.add,
				onActivate: () => {
					// TODO: doesn't save data
					this.data = { ...this.data, items: [...this.data.items, generateSliderItem()] };

					return this.data;
				},
			},
		];
	}

	render(): HTMLElement {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const onDataChangeHandler = (newData: ISliderData) => {
			this.data = newData;
		};

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		renderRoot.render(
			<BrowserRouter>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Provider store={store}>
							<DialogProvider>
								<SliderTool
									data={this.data}
									onDataChange={onDataChangeHandler}
									deleteBlock={deleteBlock}
									moveBlockUp={moveBlockUp}
									moveBlockDown={moveBlockDown}
								/>
							</DialogProvider>
						</Provider>
					</ThemeProvider>
				</StyledEngineProvider>
			</BrowserRouter>
		);

		return rootNode;
	}
}

export default CSliderTool;
