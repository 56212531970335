import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store';
import { theme } from 'app/theme/theme';
import { DialogProvider } from 'shared/providers';
import NavTool from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool';
import { ENavVariant, ENavList } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.interfaces';
import { getNavInitValues, navToolboxParams, SKETCH } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.meta';

import type { API } from '@editorjs/editorjs';
import type { BlockTool, BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools/block-tool';
import type { ToolConfig } from '@editorjs/editorjs/types/tools/tool-config';
import type { INavToolData } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.interfaces';

class CNavTool implements BlockTool {
	renderNodes: {
		holder: HTMLElement | null;
	};

	api: API;

	readOnly: boolean;

	data: INavToolData;

	config: ToolConfig;

	constructor({ data, config, api, readOnly }: BlockToolConstructorOptions) {
		this.renderNodes = {
			holder: null,
		};
		this.api = api;
		this.data = {
			title: data.title || '',
			items: data.items || getNavInitValues().items,
			styletitle: data.styletitle || '',
			variant: data.variant || ENavVariant.inListView,
			lists: data.lists ? data.lists : data.variant === SKETCH ? null : ENavList.ul,
		};
		this.config = config;
		this.readOnly = readOnly;
	}

	static get toolbox() {
		return navToolboxParams;
	}

	static get isReadOnlySupported() {
		return true;
	}

	render() {
		const rootNode = document.createElement('div');
		this.renderNodes.holder = rootNode;

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		const handleNavigationChange = (newData: INavToolData) => {
			this.data = {
				...newData,
			};
		};

		const renderRoot = createRoot(rootNode);

		renderRoot.render(
			<BrowserRouter>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Provider store={store}>
							<DialogProvider>
								<NavTool
									handleChange={handleNavigationChange}
									data={this.data}
									moveBlockUp={moveBlockUp}
									moveBlockDown={moveBlockDown}
									deleteBlock={deleteBlock}
								/>
							</DialogProvider>
						</Provider>
					</ThemeProvider>
				</StyledEngineProvider>
			</BrowserRouter>
		);

		return this.renderNodes.holder;
	}

	save() {
		return this.data;
	}
}

export default CNavTool;
