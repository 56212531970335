import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType8 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType8/CardsType8.interfaces';

interface IProps {
	cardData: ICardType8;
	index: number;
	handleChangeCardData: (newData: ICardType8) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType8 = ({ cardData, index, handleChangeCardData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<div className='flex gap-m'>
						<div className='flex flex-col gap-m'>
							<div className='flex flex-col'>
								<div className=''>Изображение</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										handleChangeCardData({ ...cardData, image_main: newFile });
									}}
									handleDeleteImage={() => handleChangeCardData({ ...cardData, image_main: null })}
									mediaItem={cardData.image_main}
								/>
							</div>
						</div>
						<div className='flex w-full flex-col gap-m '>
							<TextInput
								{...getFieldProps(`cards.${index}.style`)}
								label='Стиль'
								placeholder='Введите стиль'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.title`)}
								label='Заголовок'
								placeholder='Введите текст заголовка'
							/>
							<div className='flex gap-m'>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_1_before_url_text`)}
									label='Текст подзаголовока №1 перед ссылкой'
									placeholder='Введите текст подзаголовка №1 перед ссылкой'
								/>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_1_after_url_text`)}
									label='Текст подзаголовока №1 после ссылки'
									placeholder='Введите текст подзаголовка №1 после ссылки'
								/>
							</div>
							<div className='flex gap-m'>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_1_url`)}
									label='URL подзаголовока №1'
									placeholder='Введите URL подзаголовка №1'
								/>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_1_url_text`)}
									label='Текст URL-а подзаголовок №1'
									placeholder='Введите текст URL-а подзаголовка №1'
								/>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_1_url_text_inside`)}
									label='Промежуточный текст'
									placeholder='Введите промежуточный текст'
								/>
							</div>
							<div className='flex gap-m'>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_1_add_url`)}
									label='URL подзаголовока №1 доп.'
									placeholder='Введите URL подзаголовка №1 доп.'
								/>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_1_add_url_text`)}
									label='Текст URL-а подзаголовок №1 доп.'
									placeholder='Введите текст URL-а подзаголовка №1 доп.'
								/>
							</div>
							<div className='flex gap-m'>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_2_before_url_text`)}
									label='Текст подзаголовока №2 перед ссылкой'
									placeholder='Введите текст подзаголовка №2 перед ссылкой'
								/>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_2_after_url_text`)}
									label='Текст подзаголовока №2 после ссылки'
									placeholder='Введите текст подзаголовка №2 после ссылки'
								/>
							</div>
							<div className='flex gap-m'>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_2_url`)}
									label='URL подзаголовока №2'
									placeholder='Введите URL подзаголовка №2'
								/>
								<TextInput
									{...getFieldProps(`cards.${index}.subtitle_2_url_text`)}
									label='Текст URL-а подзаголовок №2'
									placeholder='Введите текст URL-а подзаголовка №2'
								/>
							</div>
							<TextInput
								{...getFieldProps(`cards.${index}.description_1`)}
								label='Описание №1'
								placeholder='Введите текст описания №1'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.description_2`)}
								label='Описание №2'
								placeholder='Введите текст описания №2'
							/>
						</div>
					</div>

					<div className='flex flex-col gap-m'>
						<div className='flex flex-col gap-m'>
							<div className=''>Иконка №1</div>
							<div className='flex gap-m'>
								<div>
									<SelectImageWithControls
										handleSelectFile={(newFile) => {
											handleChangeCardData({ ...cardData, icon_1: newFile });
										}}
										handleDeleteImage={() => handleChangeCardData({ ...cardData, icon_1: null })}
										mediaItem={cardData.icon_1}
									/>
								</div>
								<div className='flex w-full flex-col gap-m'>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_1_url`)}
											label='Url иконки №1'
											placeholder='Введите URL иконки №1'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_1_url_text`)}
											label='Текст URL-а №1'
											placeholder='Введите текст URL-а иконки №1'
										/>
									</div>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_1_before_url_text`)}
											label='Текст перед URL иконки №1'
											placeholder='Введите текст перед URL иконки №1'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_1_after_url_text`)}
											label='Текст после URL-a иконки №1'
											placeholder='Введите текст после URL-а иконки №1'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='flex flex-col gap-m'>
						<div className='flex flex-col gap-m'>
							<div className=''>Иконка №2</div>
							<div className='flex gap-m'>
								<div>
									<SelectImageWithControls
										handleSelectFile={(newFile) => {
											handleChangeCardData({ ...cardData, icon_2: newFile });
										}}
										handleDeleteImage={() => handleChangeCardData({ ...cardData, icon_2: null })}
										mediaItem={cardData.icon_2}
									/>
								</div>
								<div className='flex w-full flex-col gap-m'>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_2_url`)}
											label='Url иконки №2'
											placeholder='Введите URL иконки №2'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_2_url_text`)}
											label='Текст URL-а №2'
											placeholder='Введите текст URL-а иконки №2'
										/>
									</div>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_2_before_url_text`)}
											label='Текст перед URL иконки №2'
											placeholder='Введите текст перед URL иконки №1'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_2_after_url_text`)}
											label='Текст после URL-a иконки №2'
											placeholder='Введите текст после URL-а иконки №2'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='flex flex-col gap-m'>
						<div className='flex flex-col gap-m'>
							<div className=''>Иконка №3</div>
							<div className='flex gap-m'>
								<div>
									<SelectImageWithControls
										handleSelectFile={(newFile) => {
											handleChangeCardData({ ...cardData, icon_3: newFile });
										}}
										handleDeleteImage={() => handleChangeCardData({ ...cardData, icon_3: null })}
										mediaItem={cardData.icon_3}
									/>
								</div>
								<div className='flex w-full flex-col gap-m'>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_3_url`)}
											label='Url иконки №3'
											placeholder='Введите URL иконки №3'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_3_url_text`)}
											label='Текст URL-а №3'
											placeholder='Введите текст URL-а иконки №3'
										/>
									</div>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_3_before_url_text`)}
											label='Текст перед URL иконки №3'
											placeholder='Введите текст перед URL иконки №3'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_3_after_url_text`)}
											label='Текст после URL-a иконки №3'
											placeholder='Введите текст после URL-а иконки №3'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='flex flex-col gap-m'>
						<div className='flex flex-col gap-m'>
							<div className=''>Иконка №4</div>
							<div className='flex gap-m'>
								<div>
									<SelectImageWithControls
										handleSelectFile={(newFile) => {
											handleChangeCardData({ ...cardData, icon_4: newFile });
										}}
										handleDeleteImage={() => handleChangeCardData({ ...cardData, icon_4: null })}
										mediaItem={cardData.icon_4}
									/>
								</div>
								<div className='flex w-full flex-col gap-m'>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_4_url`)}
											label='Url иконки №4'
											placeholder='Введите URL иконки №4'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_4_url_text`)}
											label='Текст URL-а №4'
											placeholder='Введите текст URL-а иконки №4'
										/>
									</div>
									<div className='flex w-full gap-m'>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_4_before_url_text`)}
											label='Текст перед URL иконки №4'
											placeholder='Введите текст перед URL иконки №4'
										/>
										<TextInput
											{...getFieldProps(`cards.${index}.icon_4_after_url_text`)}
											label='Текст после URL-a иконки №4'
											placeholder='Введите текст после URL-а иконки №4'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
