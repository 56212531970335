import { AddMenuIcon, CenterMenuIcon, LeftMenuIcon, RightMenuIcon, WidthMenuIcon } from '@assets/svgs';

const tuneIcons = {
	left: <LeftMenuIcon />,
	right: <RightMenuIcon />,
	center: <CenterMenuIcon />,
	width: <WidthMenuIcon />,
	add: <AddMenuIcon />,
};

const tuneAlignOptions = [
	{
		title: 'Выровнять по левому краю',
		icon: tuneIcons.left,
		name: 'left',
	},
	{
		title: 'Выровнять по правому краю',
		icon: tuneIcons.right,
		name: 'right',
	},
	{
		title: 'Выровнять по центру',
		icon: tuneIcons.center,
		name: 'center',
	},
	{
		title: 'Выровнять по ширине',
		icon: tuneIcons.width,
		name: 'width',
	},
];

const tuneAddElement = [
	{
		title: 'Добавить секцию',
		icon: tuneIcons.add,
		name: 'add',
	},
];

const tuneCopyElement = [
	{
		title: 'Копировать блок',
		icon: tuneIcons.add,
		name: 'copy',
	},
];

const tunePasteElement = [
	{
		title: 'Вставить блок',
		icon: tuneIcons.add,
		name: 'paste',
	},
];

const ADD = 'add';
const COPY = 'copy';

const PASTE = 'paste';

export { tuneAlignOptions, tuneAddElement, tunePasteElement, tuneCopyElement, ADD, COPY, PASTE };
