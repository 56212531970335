// import type { ISitemapToolData } from './SitemapTool.interfaces';

// interface IProps {
// 	data: ISitemapToolData;
// 	handleDataChange: (newData: ISitemapToolData) => void;
// }

export const SitemapTool = () => {
	return (
		<div className='flex h-[80px] w-full items-center justify-center rounded-[10px] border-[1px] border-gray-light'>
			Карта сайта (будет сгенерирована на основании Главного меню)
		</div>
	);
};
