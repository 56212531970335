import baseApi from 'shared/api/baseApi';
import { ETagTypes } from 'shared/lib/constants/tagTypes';

import type { IMaterial } from 'shared/interfaces';

const MATERIALS_URL = '/api/materials';

const transformResponse = ({ data }: { data: IMaterial }) => data;

export const materialsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getMaterials: builder.query<{ data: IMaterial[]; meta: { total: number } }, void | Record<string, string | number>>({
			query: (params = { per_page: -1 }) => ({
				method: 'GET',
				url: MATERIALS_URL,
				params,
			}),

			providesTags: (result) => {
				if (result?.data) {
					return [...result.data.map(({ id }) => ({ type: ETagTypes.Materials, id } as const)), { type: ETagTypes.Materials, id: 'LIST' }];
				}

				return [{ type: ETagTypes.Materials, id: 'LIST' }];
			},
		}),

		getMaterial: builder.query<IMaterial, IMaterial['id']>({
			query: (id) => ({
				method: 'GET',
				url: `${MATERIALS_URL}/${id}/`,
			}),

			transformResponse,

			providesTags: (result) => {
				if (result) {
					return [{ type: ETagTypes.Materials, id: result.id }];
				}

				return [{ type: ETagTypes.Materials, id: 'LIST' }];
			},
		}),

		getAvailableParents: builder.query<{ data: IMaterial[] }, void | IMaterial['id']>({
			query: (materialId) => ({
				method: 'GET',
				url: `${MATERIALS_URL}/available-parents`,
				params: {
					material: materialId,
				},
			}),
		}),

		createMaterial: builder.mutation<IMaterial, Partial<IMaterial>>({
			query: (data: IMaterial) => ({
				method: 'POST',
				url: MATERIALS_URL,
				data,
			}),

			transformResponse,

			invalidatesTags: (_result, error) => {
				if (error) return [];

				return [ETagTypes.Materials];
			},
		}),

		editMaterial: builder.mutation<IMaterial, Partial<IMaterial>>({
			query: (data: IMaterial) => ({
				method: 'PUT',
				url: `${MATERIALS_URL}/${data.id}`,
				data,
			}),

			transformResponse,

			invalidatesTags: (_result, error) => {
				if (error) return [];

				return [ETagTypes.Materials];
			},
		}),

		changeMaterialsIsFavorites: builder.mutation<{ success: boolean }, { idList: IMaterial['id'][]; value: boolean }>({
			query: ({ idList, value }) => ({
				method: 'PATCH',
				url: `${MATERIALS_URL}/update-favorites?ids=${idList}&action=${value ? 'add' : 'remove'}`,
			}),

			invalidatesTags: (result, _error, _id) => {
				if (result?.success) return [ETagTypes.Materials];

				return [];
			},
		}),

		changeMaterialsStatus: builder.mutation<{ success: boolean }, { idList: IMaterial['id'][]; value: IMaterial['status'] }>({
			query: ({ idList, value }) => ({
				method: 'PATCH',
				url: `${MATERIALS_URL}/update-statuses?ids=${idList}&status=${value}`,
			}),

			invalidatesTags: (result, _error, _id) => {
				if (result?.success) return [ETagTypes.Materials];

				return [];
			},
		}),
		deleteMaterials: builder.mutation<void, IMaterial['id'][]>({
			query: (ids) => ({
				method: 'DELETE',
				url: `${MATERIALS_URL}?ids=${ids}`,
			}),
			invalidatesTags: [ETagTypes.Materials],
		}),
		getMaterialsWithChildren: builder.query<{ data: IMaterial[] }, void | IMaterial['id']>({
			query: () => ({
				method: 'GET',
				baseURL: MATERIALS_URL + '/with-children',
			}),
			providesTags: [ETagTypes.Materials],
		}),
		copyMaterial: builder.mutation<void, { material_id: IMaterial['id'] }>({
			query: (payload) => ({
				method: 'POST',
				baseURL: MATERIALS_URL + '/copy',
				data: payload,
			}),
			invalidatesTags: [ETagTypes.Materials],
		}),
		getMaterialsChildren: builder.query<{ data: IMaterial[] }, void>({
			query: () => ({
				method: 'GET',
				baseURL: MATERIALS_URL + '/has-children',
			}),
		}),
	}),
});

export const {
	useChangeMaterialsIsFavoritesMutation,
	useChangeMaterialsStatusMutation,
	useCreateMaterialMutation,
	useDeleteMaterialsMutation,
	useEditMaterialMutation,
	useGetMaterialQuery,
	useGetAvailableParentsQuery,
	useGetMaterialsQuery,
	useCopyMaterialMutation,
	useGetMaterialsChildrenQuery,
	useLazyGetMaterialQuery,
	useGetMaterialsWithChildrenQuery,
} = materialsApi;
