import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { type Dispatch, type FC, type SetStateAction, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useUploadMediaMutation } from 'shared/api/mediaApi';
import { useDialog } from 'shared/hooks';
import { EMediaCollectionName } from 'shared/lib/constants/collectionName';
import { EEntryNames, errorsNames, eventNames, toastMessages } from 'shared/lib/constants/toastMessages';
import { processRequestResult } from 'shared/lib/helpers';
import { Button } from 'shared/ui';
import { FileUploader } from 'shared/ui/FileUploader';
import { ModalWindow } from 'shared/ui/ModalWindow';

import type { AxiosProgressEvent } from 'axios';
import type { IMediaFile } from 'shared/interfaces';
import type { IFileUploadError } from 'shared/interfaces/fileMediaError';
import type { TransformedFile } from 'shared/ui/FileUploader';

const FileUploadModal: FC<IFileUploadModalProps> = ({
	isOpen,
	onClose,
	setMedia,
	multiple = true,
	collectionNameForUpload = EMediaCollectionName.Media,
	mediaTypes,
	setAvatarFile,
	title,
	isModalFormStyle,
	setImageUrl,
}) => {
	const [upload, { isLoading }] = useUploadMediaMutation();
	const [files, setFiles] = useState<TransformedFile[]>([]);
	const [isValidSize, setIsValidSize] = useState(true);
	const [error, setError] = useState('');
	const canModalBeClosed = useRef(true);
	const [progress, setProgress] = useState<IProgress>({});

	const { dialog, closeDialogModal } = useDialog();

	const controllerRef = useRef<Nullable<AbortController>>(null);

	const onModalCloseHandler = (isTriggeredByUser = true) => {
		if (isLoading) {
			dialog({
				title: 'Вы уверены, что хотите закрыть модальное окно загрузки?',
				body: <div> В этом случае медиафайлы не будут загружены!</div>,
				isCancelBtn: true,
				buttons: [
					<Button
						data-id='CloseButton'
						onClick={() => {
							onClose();
							controllerRef.current?.abort();
							setFiles([]);
						}}
						color='error'
						key={'error'}
					>
						Закрыть
					</Button>,
				],
			});
		}
		if (!canModalBeClosed.current) return;
		if (isTriggeredByUser && files.length) toast.error(toastMessages.getErrorMessage(EEntryNames.ManyMediaFiles, errorsNames.notLoaded.many));
		onClose();
		setFiles([]);
	};

	const onCloseTabHandler = (e: BeforeUnloadEvent) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const onUploadProgress = (e: AxiosProgressEvent, id: string) => {
		if (e.progress) {
			setProgress((prevState) => ({
				...prevState,
				[id]: {
					id,
					isError: false,
					isLoading: e.progress !== 1,
					isLoaded: e.progress === 1,
					progress: Math.round(e.progress! * 100),
				},
			}));
		}
	};

	const onUploadHandler = async () => {
		const dto = new FormData();

		dto.append('collection_name', collectionNameForUpload);

		canModalBeClosed.current = false;

		const initialProgress = files.reduce(
			(acc, { id }) => ({
				[id]: {
					id: id,
					isError: false,
					isLoaded: false,
					isLoading: false,
					progress: 0,
				},
			}),
			{}
		);

		setProgress(initialProgress);

		controllerRef.current = new AbortController();

		let countForClose = 0;

		for await (const file of files) {
			dto.append('file', file.originalFile);
			dto.append('is_watermark', String(file.isWatermark));
			window.addEventListener('beforeunload', onCloseTabHandler);

			const result = await upload({ dto, onUploadProgress: (e) => onUploadProgress(e, file.id), controller: controllerRef.current.signal });
			canModalBeClosed.current = true;

			window.removeEventListener('beforeunload', onCloseTabHandler);

			processRequestResult(result, {
				onSuccess: (data) => {
					countForClose += 1;
					const toastMessageParams: [EEntryNames, string] =
						countForClose > 1
							? [EEntryNames.ManyMediaFiles, eventNames.uploadAndRegenerate.many]
							: [EEntryNames.MediaFile, eventNames.uploadAndRegenerate.singleMale];
					if (countForClose === files.length) {
						closeDialogModal();
						onModalCloseHandler(false);
						toast.success(toastMessages.getSuccessMessage(...toastMessageParams));
					}
					setMedia?.(data);
				},
				onError: (_, errors) => {
					if ('file' in errors) {
						const err = errors.file as IFileUploadError['file'];
						setError(err[0]);
						setImageUrl?.('');
						return;
					}
				},
			});

			dto.delete('file');
		}
	};

	const isBtnDisabled = !isValidSize || !files.length || isLoading;

	return (
		<ModalWindow
			isOpened={isOpen}
			handleClose={onModalCloseHandler as () => void}
			className={isModalFormStyle}
		>
			{/* <Paper className='flex max-h-[70%] w-full max-w-[900px] flex-col gap-10 self-center overflow-auto p-10'> */}
			{title && (
				<>
					<div
						data-id='Title'
						className='text-[32px] font-bold'
					>
						{title}
					</div>
					<div className='mb-4 mt-4'>
						<hr className='h-[1px] text-border-secondary' />
					</div>
				</>
			)}

			<FileUploader
				multiple={multiple}
				files={files}
				setProgress={setProgress}
				setFiles={setFiles}
				isValidFileSize={isValidSize}
				setIsValidFileSize={setIsValidSize}
				progress={progress}
				isLoading={isLoading}
				mediaTypes={mediaTypes}
				setOriginalFileForAvatar={setAvatarFile}
			/>
			{error && (
				<Typography
					color='error'
					align='center'
				>
					{error}
				</Typography>
			)}
			{files.length > 0 && (
				<div className='mt-8 flex justify-end'>
					<Button
						data-id='CloseButton'
						variant='contained'
						color={'enabledWhite'}
						onClick={onModalCloseHandler as () => void}
						className='mr-4'
					>
						Закрыть
					</Button>
					<Button
						data-id='SaveButton'
						variant='contained'
						onClick={onUploadHandler}
						disabled={isBtnDisabled}
					>
						Загрузить
					</Button>
				</div>
			)}
			<CloseIcon
				data-id='CloseIcon'
				className='absolute right-0 top-0 w-[30px] cursor-pointer'
				onClick={onModalCloseHandler as () => void}
			/>
			{/* </Paper> */}
		</ModalWindow>
	);
};

interface IFileUploadModalProps {
	isOpen: boolean;
	onClose: () => void;
	setMedia?: Dispatch<SetStateAction<Undefinable<IMediaFile>>>;
	multiple?: boolean;
	collectionNameForUpload?: EMediaCollectionName;
	mediaTypes?: string[];
	setAvatarFile?: (originalFile: TransformedFile[]) => void;
	title?: string;
	isModalFormStyle?: string;
	setImageUrl?: (url: string) => void;
}

export interface IProgress {
	[key: string]: {
		id: string;
		isLoaded: boolean;
		isError: boolean;
		progress: number;
		isLoading: boolean;
	};
}

export default FileUploadModal;
