import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store/core';
import { theme } from 'app/theme/theme';
import GoogleMapTool from 'shared/ui/Editor/EditorBlockTools/GoogleMapTool/GoogleMapTool';
import { toolboxParams } from 'shared/ui/Editor/EditorBlockTools/GoogleMapTool/GoogleMapTool.meta';

import type { API, BlockTool } from '@editorjs/editorjs';
import type { IGoogleMapToolData, IGoogleMapToolParams } from 'shared/ui/Editor/EditorBlockTools/GoogleMapTool/GoogleMapTool.interfaces';

export class CGoogleMapTool implements BlockTool {
	data: IGoogleMapToolData;

	api: API;

	constructor({ data, api }: IGoogleMapToolParams) {
		this.data = Object.keys(data).length ? data : { url: '', googleCode: '' };
		this.api = api;
	}

	static get toolbox() {
		return toolboxParams;
	}

	save() {
		return this.data;
	}

	render(): HTMLElement {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const onDataChange = (data: IGoogleMapToolData) => {
			this.data = data;
		};

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		renderRoot.render(
			<BrowserRouter>
				<Provider store={store}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<GoogleMapTool
								data={this.data}
								onDataChange={onDataChange}
								deleteBlock={deleteBlock}
								moveBlockUp={moveBlockUp}
								moveBlockDown={moveBlockDown}
							/>
						</ThemeProvider>
					</StyledEngineProvider>
				</Provider>
			</BrowserRouter>
		);

		return rootNode;
	}
}
