import { TablePagination, Stack, Pagination as MuiPagination, PaginationItem } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from 'shared/assets/svgs/arrow.svg';
import { rowsPerPage } from 'shared/ui/Pagination/Pagination.meta';

import './Pagination.scss';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { IUpdateParams } from 'shared/ui/CMSTable/CmsTable.interfaces';

interface IProps<T extends { id: string | number }> {
	totalRows?: number;
	actualRowsOnPage: number;
	page?: number;
	perPage?: number;
	clearItemsSelection: () => void;
	updateParams: (arg: Partial<IUpdateParams>) => void;
	setSelectedRows: Dispatch<SetStateAction<Map<T['id'], T>>>;
}

export const Pagination = <T extends { id: string | number }>({
	totalRows,
	setSelectedRows,
	actualRowsOnPage,
	updateParams,
	page: pageNumber = 1,
	perPage = -1, // all
	clearItemsSelection,
}: IProps<T>) => {
	const [rowsPerPageOptions] = useState(() => {
		if (perPage === -1) {
			return [{ value: -1, label: 'Все' }];
		}
		return rowsPerPage;
	});

	useEffect(() => {
		if (!totalRows || pageNumber === 1) return;

		const firstItemIndex = (pageNumber - 1) * perPage;

		if (firstItemIndex > totalRows) {
			updateParams({ page: 1 });
		}
	}, [pageNumber, perPage, updateParams, totalRows]);

	const changePageIndex = useCallback(
		(_: unknown, index: number) => {
			updateParams({ page: index + 1 });
			clearItemsSelection();
		},
		[clearItemsSelection, updateParams]
	);

	const handleChangeRowsPerPage = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			updateParams({ per_page: +event.target.value });
		},
		// eslint-disable-next-line
		[pageNumber, perPage, updateParams, updateParams]
	);

	if (totalRows === undefined) return null;

	const pageCount = Math.ceil(totalRows / perPage);

	const changeCurrentPage = (_: ChangeEvent<unknown>, value: number) => {
		setSelectedRows(new Map());
		updateParams({ page: value });
	};

	return (
		<div
			className={'flex items-center justify-between'}
			data-id='PaginationContainer'
		>
			<Stack spacing={2}>
				<MuiPagination
					className={'paginationWrapper'}
					count={pageCount || 1}
					page={pageNumber}
					renderItem={(items) => {
						return (
							<PaginationItem
								{...items}
								data-id={`page-${pageNumber}`}
							/>
						);
					}}
					onChange={changeCurrentPage}
					shape='rounded'
				/>
			</Stack>
			<div
				data-id='PaginationCount'
				className='flex flex-col items-center'
			>
				<TablePagination
					className={'tablePaginationWrapper'}
					rowsPerPageOptions={rowsPerPageOptions}
					labelRowsPerPage={'Строк на странице: '}
					labelDisplayedRows={({ from, to }) => `${from}-${to} из ${totalRows} записей`}
					component='div'
					count={totalRows || actualRowsOnPage}
					rowsPerPage={perPage}
					page={pageNumber - 1}
					onPageChange={changePageIndex}
					onRowsPerPageChange={handleChangeRowsPerPage}
					backIconButtonProps={{
						className: '!hidden',
					}}
					nextIconButtonProps={{
						className: '!hidden',
					}}
					SelectProps={{
						IconComponent: ArrowDownIcon,
						MenuProps: {
							transformOrigin: {
								vertical: 'bottom',
								horizontal: 'center',
							},
							classes: {
								paper: 'border-black border-[1px] shadow-none w-[72px]',
							},
						},

						classes: {
							icon: 'tr transform rotate-90',
						},
					}}
				/>
			</div>
		</div>
	);
};
