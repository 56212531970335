import { Add as AddIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { Button } from 'shared/ui';

import { InternalLineCard } from './InternalLineCard';
import { InternationalLineCard } from './InternationalLineCard';
import { getBlankLine } from './LinesControl.lib';

import type { FieldInputProps } from 'formik/dist/types';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	values: ILeafletMapData;
	setFieldValue: (propName: string, newValue: any) => void;
	getFieldProps: (propName: string) => FieldInputProps<string> | FieldInputProps<number>;
}

export const LinesControl = ({ values, setFieldValue, getFieldProps }: IProps) => {
	const handleDeleteLine = (category: keyof typeof values.lines, id: string) => {
		setFieldValue(
			`lines.${category}`,
			values.lines[category].filter((item) => item.id !== id)
		);
	};

	return (
		<Accordion>
			<AccordionSummary>Линии</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<Accordion>
						<AccordionSummary>Международные линии</AccordionSummary>
						<AccordionDetails>
							<div className='flex flex-col gap-m'>
								<div className='flex flex-col'>
									{values.lines.international.map((line, index) => (
										<InternationalLineCard
											lineData={line}
											key={line.id}
											index={index}
											handleDeleteLine={(id) => handleDeleteLine('international', id)}
											getFieldProps={getFieldProps}
											setFieldValue={setFieldValue}
										/>
									))}
								</div>
								<Button
									variant='outlined'
									onClick={() => {
										setFieldValue('lines.international', [...values.lines.international, getBlankLine()]);
									}}
								>
									<div className='flex gap-m'>
										<AddIcon />
										<div>Добавить международную линию</div>
									</div>
								</Button>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary>Внутренние линии</AccordionSummary>
						<AccordionDetails>
							<div className='flex flex-col gap-m'>
								<div className='flex flex-col'>
									{values.lines.internal.map((line, index) => (
										<InternalLineCard
											setFieldValue={setFieldValue}
											key={line.id}
											index={index}
											lineData={line}
											handleDeleteLine={(id) => handleDeleteLine('internal', id)}
											getFieldProps={getFieldProps}
										/>
									))}
								</div>
								<Button
									variant='outlined'
									onClick={() => {
										setFieldValue('lines.internal', [...values.lines.internal, getBlankLine()]);
									}}
								>
									<div className='flex gap-m'>
										<AddIcon />
										<div>Добавить внутренние линии</div>
									</div>
								</Button>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
