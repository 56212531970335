import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';

import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { TextInput } from 'shared/ui';
import { ADD, tuneAddElement } from 'shared/ui/Editor/EditorBlockTools/EditorBlockTools.meta';
import { getInitialValues } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.lib';
import { getNavInitValues, LIST, OL, SKETCH, tuneOptions, UL } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.meta';
import { NavToolFormItems } from 'shared/ui/Editor/EditorBlockTools/NavTool/ui';
import { ControlMenus } from 'shared/ui/Editor/EditorBlockTools/_ui';

import type { FC } from 'react';
import type { INavToolData } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.interfaces';

interface IProps {
	handleChange: (value: INavToolData) => void;
	data: INavToolData;
	deleteBlock: () => void;
	moveBlockUp: () => void;
	moveBlockDown: () => void;
}

const NavTool: FC<IProps> = ({ handleChange, data, deleteBlock, moveBlockUp, moveBlockDown }) => {
	const { isModalOpen, openModal, closeModal } = useModal();
	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	const formik = useFormik({
		initialValues: getInitialValues(data),
		onSubmit: (values) => {
			handleChange(values);
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		handleChange(formik.values);
	}, [formik.values, handleChange]);

	useClickOutside(menuRef, closeModal);

	const pickElement = (name: string) => {
		if (name === ADD) {
			formik.setFieldValue('items', [...formik.values.items, ...getNavInitValues().items]);
		}

		if (LIST === name) {
			formik.setFieldValue('variant', name);
			formik.setFieldValue('lists', UL);
		}

		if (SKETCH === name) {
			formik.setFieldValue('variant', name);
			formik.setFieldValue('lists', null);
		}

		if (OL === name || UL === name) {
			if (formik.values.variant === SKETCH) {
				formik.setFieldValue('variant', LIST);
			}
			formik.setFieldValue('lists', name);
		}

		closeModal();
	};

	return (
		<form
			onBlur={formik.submitForm}
			className='relative z-0 mt-5 flex flex-col gap-4 '
		>
			<div className='mt-[12px] flex items-center justify-between'>
				<h2>Навигационное меню</h2>
				<ControlMenus
					menuRef={menuRef}
					checkedNames={[formik.values.variant, formik.values.lists as string | boolean]}
					openModal={openModal}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
					items={[...tuneAddElement, ...tuneOptions]}
					pickElement={pickElement}
				/>
			</div>
			<div className='flex gap-2'>
				<TextInput
					{...formik.getFieldProps('title')}
					placeholder='Введите заголовок'
					label='Заголовок'
				/>
				<TextInput
					{...formik.getFieldProps('styletitle')}
					placeholder='Стиль'
				/>
			</div>
			<div className='mb-4 mt-4'>
				<hr className='h-[1px] text-border-secondary' />
			</div>

			<NavToolFormItems
				setFieldValue={formik.setFieldValue}
				items={formik.values.items}
				getFieldProps={formik.getFieldProps}
			/>
		</form>
	);
};

export default NavTool;
