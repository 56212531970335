import ru from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { TextInput } from 'shared/ui';

import type { ChangeEvent } from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';

registerLocale('ru', ru);

interface IProps extends Omit<ReactDatePickerProps, 'value' | 'onChange'> {
	label?: string;
	value?: string | null;
	onChange?: (e: ChangeEvent) => void;
	isError?: boolean;
	helperText?: string | null;
	formDateFormat?: string;
}

const FormDatePicker = ({
	label = '',
	value = null,
	onChange = () => {},
	onBlur,
	name,
	helperText,
	placeholderText = 'ДД.MM.ГГГГ',
	formDateFormat = 'YYYY-MM-DD',
	...restProps
}: IProps) => {
	return (
		<DatePicker
			enableTabLoop={false}
			className='w-full'
			placeholderText={placeholderText}
			selected={value ? new Date(value) : null}
			dateFormat='dd.MM.yyyy'
			locale='ru'
			autoComplete='off'
			isClearable
			customInput={
				<TextInput
					autoComplete='off'
					label={label}
					helperText={helperText}
					fullWidth
				/>
			}
			name={name}
			onChange={(date, e) => {
				let formattedDate = null;

				if (date) {
					formattedDate = dayjs(date).format(formDateFormat);
				}

				const customEvent = {
					...e,
					target: { ...e?.target, name, value: formattedDate },
				} as unknown as React.ChangeEvent;

				onChange(customEvent);
				setTimeout(() => {
					onBlur?.(customEvent as React.FocusEvent<HTMLInputElement, Element>);
				});
			}}
			onBlur={onBlur}
			{...restProps}
		/>
	);
};

export default FormDatePicker;
