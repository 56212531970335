import type { ICardType10 } from './CardsType10.interfaces';

export const blankCardType10 = (): ICardType10 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	cardContent: { blocks: [] },
	image_background: null,
	title_icon: null,
	title_icon_hover: null,
});
