import { useCallback, type FC } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { NavItem } from 'shared/ui/Editor/EditorBlockTools/NavTool/ui';

import type { FormikErrors, FieldInputProps } from 'formik/dist/types';
import type { INavToolItem } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.interfaces';

interface IProps {
	items: INavToolItem[];
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<FormikErrors<any>> | Promise<void>;
}
const NavToolFormItems: FC<IProps> = ({ items, getFieldProps, setFieldValue }) => {
	const handleUp = useCallback(
		(id: number | string) => {
			setFieldValue('items', swapToTopArrayItems(id, items));
		},
		[setFieldValue, items]
	);
	const handleDown = useCallback(
		(id: number | string) => {
			setFieldValue('items', swapToBottomArrayItems(id, items));
		},
		[setFieldValue, items]
	);
	const handleClose = useCallback(
		(id: number | string) => {
			setFieldValue(
				'items',
				items.filter((slide) => slide.id !== id)
			);
		},
		[setFieldValue, items]
	);

	const handleSelectFile = (newValue: INavToolItem['media'], field: string) => {
		setFieldValue(field, newValue);
	};
	const handleDeleteImage = (imageField: string) => {
		setFieldValue(imageField, '');
	};

	return (
		<>
			{items.map((item, index) => {
				return (
					<NavItem
						key={item.id}
						index={index}
						item={item}
						handleSelectFile={handleSelectFile}
						handleDeleteImage={handleDeleteImage}
						getFieldProps={getFieldProps}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				);
			})}
		</>
	);
};

export default NavToolFormItems;
