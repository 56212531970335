import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType1 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType1/CardsType1.interfaces';

interface IProps {
	cardData: ICardType1;
	index: number;
	handleChangeCardData: (newData: ICardType1) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType1 = ({ cardData, index, handleChangeCardData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex gap-m'>
					<div className='flex flex-col gap-m'>
						<div className='flex flex-col'>
							<div className=''>Иконка</div>
							<SelectImageWithControls
								handleSelectFile={(newFile) => {
									handleChangeCardData({ ...cardData, icon: newFile });
								}}
								handleDeleteImage={() => handleChangeCardData({ ...cardData, icon: null })}
								mediaItem={cardData.icon}
							/>
						</div>
						<div className='flex flex-col'>
							<div className=''>Изображение</div>
							<SelectImageWithControls
								handleSelectFile={(newFile) => {
									handleChangeCardData({ ...cardData, image_1: newFile });
								}}
								handleDeleteImage={() => handleChangeCardData({ ...cardData, image_1: null })}
								mediaItem={cardData.image_1}
							/>
						</div>
					</div>
					<div className='flex w-full flex-col gap-m '>
						<TextInput
							{...getFieldProps(`cards.${index}.style`)}
							label='Стиль'
							placeholder='Введите стиль'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.title`)}
							label='Заголовок'
							placeholder='Введите текст заголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle`)}
							label='Подзаголовок'
							placeholder='Введите текст подзаголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.description`)}
							label='Описание'
							placeholder='Введите текст описания'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.counter_1`)}
							type='number'
							label='Конечное значение для счетчика'
							placeholder='Введите значение для счетчика'
						/>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
