import { Accordion, AccordionDetails } from '@mui/material';
import React, { useRef } from 'react';

import { TextInput } from 'shared/ui';
import { NavImageWithControls } from 'shared/ui/Editor/EditorBlockTools/NavTool/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';

import type { FieldInputProps } from 'formik/dist/types';
import type { FC, MutableRefObject } from 'react';
import type { INavToolItem } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.interfaces';

interface IProps {
	item: INavToolItem;
	index: number;
	handleSelectFile: (newValue: INavToolItem['media'], field: string) => void;
	handleDeleteImage: (imageField: string) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}
const NavItem: FC<IProps> = ({ item, index, handleSelectFile, handleDeleteImage, getFieldProps, handleUp, handleDown, handleClose }) => {
	const containerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

	return (
		<>
			<Accordion
				disableGutters
				defaultExpanded={false}
				square
				className='!shadow-none'
			>
				<div className='rounded-lg bg-mainBgColor'>
					<AccordionDetails className='!p-3'>
						<div
							className='flex gap-3'
							ref={containerRef}
						>
							<div className='w-[25%]'>
								<NavImageWithControls
									handleSelectFile={handleSelectFile}
									handleDeleteImage={handleDeleteImage}
									index={index}
									item={item}
								/>
							</div>
							<div className='flex w-[75%] flex-col gap-[12px]'>
								<p className='mb-4 font-bold'> Пункт {index + 1}</p>
								<div className='flex gap-2'>
									<TextInput
										{...getFieldProps(`items.${index}.text`)}
										label='Текст'
										placeholder='Введите текст'
									/>
									<TextInput
										{...getFieldProps(`items.${index}.url`)}
										label='URL'
										placeholder='Введите URL'
									/>
								</div>
								<div className='flex gap-2'>
									<TextInput
										{...getFieldProps(`items.${index}.anchor`)}
										label='Имя якоря'
										placeholder='Введите имя якорь'
									/>
									<TextInput
										{...getFieldProps(`items.${index}.style`)}
										label='Стиль'
										placeholder='Введите стиль'
									/>
								</div>
							</div>
							<SubTuner
								tuneItem={item.id}
								handleUp={handleUp}
								handleDown={handleDown}
								handleClose={handleClose}
							/>
						</div>
					</AccordionDetails>
				</div>
			</Accordion>
		</>
	);
};

export default NavItem;
