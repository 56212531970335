import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'shared/assets/svgs/arrowDownGray.svg';

import type { SelectProps } from '@mui/material';
import type { FC } from 'react';

const SelectInput: FC<ISelectInputProps> = ({
	className = '',
	classNameLabel = '',
	children,
	label,
	error,
	helperText = '',
	placeholder,
	value,
	labelId,
	required,
	...rest
}) => {
	return (
		<FormControl
			fullWidth
			error={error}
		>
			<InputLabel
				classes={{
					root: 'text-gray',
				}}
				id={labelId ?? 'select-label'}
				shrink
				className={classNameLabel}
			>
				{label}
				{required ? ' *' : ''}
			</InputLabel>
			<Select
				{...rest}
				IconComponent={ArrowIcon}
				label={label}
				notched={true}
				className={classNames('bg-white', className)}
				required={required}
				classes={{
					root: 'p-0 text-gray',
					select: 'py-3 px-5',
					icon: 'right-2.5',
				}}
				value={value}
				placeholder={placeholder}
			>
				{children}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

interface ISelectInputProps extends SelectProps {
	helperText?: string;
	classNameLabel?: string;
}

export default SelectInput;
