import { Avatar, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@store';
import { logoutAction } from '@store/reducers/auth';
import { currentUserSelector } from '@store/reducers/auth/selectors.auth';
import { AuthMenuItems } from 'app/ui/AuthMenu/AuthMenu.meta';
import { useLazyMeQuery, useLogOutMutation } from 'shared/api/authApi';
import BellIcon from 'shared/assets/svgs/BellIcon';
import MessageIcon from 'shared/assets/svgs/MessageIcon';
import { useNavigator } from 'shared/hooks/useNavigator';
import { ERoutes } from 'shared/lib/constants/routes';
import * as storage from 'shared/lib/helpers/storageHelpers';

import type { MouseEvent } from 'react';

const AuthMenu = () => {
	const { navigate } = useNavigator();
	const currUser = useAppSelector(currentUserSelector);
	const dispatch = useAppDispatch();
	const [logout] = useLogOutMutation();
	const [me] = useLazyMeQuery();

	const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);

	useEffect(() => {
		if (storage.getToken()) me();
	}, [me]);

	const openMenuHandler = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

	const closeMenuHandler = useCallback(() => setAnchorEl(null), []);

	const moveTo = useCallback(
		async (to: ERoutes) => {
			if (to === ERoutes.LOGIN) {
				await logout();
				dispatch(logoutAction());
			}
			navigate(to);
			closeMenuHandler();
		},
		[dispatch, logout, closeMenuHandler, navigate]
	);

	if (currUser) {
		return (
			<div className='flex items-center'>
				{process.env.NODE_ENV === 'development' && (
					<div className='mr-[15px]'>
						<MessageIcon />
					</div>
				)}
				{process.env.NODE_ENV === 'development' && <BellIcon />}
				<hr className='border-radius-[2px] mx-[20px] w-[18px] rotate-90 border-[1px] border-solid border-[#ABABAB]' />
				<button
					data-id='AuthMenu'
					type='button'
					onClick={openMenuHandler}
					className='flex items-center bg-white'
				>
					<span
						data-id='Email'
						className='pr-[15px] text-[16px] font-semibold'
					>
						{currUser?.email}
					</span>
					<Avatar
						data-id='Avatar'
						alt='avatar'
						src={currUser.avatar?.url || ''}
						className='!h-[48px] !w-[48px]'
					/>
				</button>
				{anchorEl && (
					<Menu
						data-id='AuthMenuItems'
						anchorEl={anchorEl}
						id='account-menu'
						open={true}
						onClose={closeMenuHandler}
						onClick={closeMenuHandler}
						elevation={0}
						classes={{
							paper:
								'overflow-visible relative w-[199px] p-0 rounded shadow-cms1 before:block before:w-[14px] before:h-[8px] before:absolute before:top-[-16px] before:right-[8px] before:content-[url("shared/assets/svgs/svg/arrowBeforeBlock.svg")]',
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						{AuthMenuItems.map(({ dataId, label, link, icon: Icon }) => (
							<MenuItem
								data-id={dataId}
								classes={{
									root: classNames('leading-[24px] w-full flex items-center justify-start py-[8px]', {
										'text-fieldError': link === ERoutes.LOGIN,
									}),
								}}
								key={label + link}
								onClick={() => moveTo(link)}
							>
								<Icon className='mr-[16px]' />
								{label}
							</MenuItem>
						))}
					</Menu>
				)}
			</div>
		);
	}

	return <></>;
};

export default AuthMenu;
