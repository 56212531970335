import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import ButtonIcon from 'shared/assets/svgs/ButtonIcon';
import { validationMessages } from 'shared/lib/constants';
import { MAX_FILE_SIZE_GB, availableMimes } from 'shared/ui/FileUploader/FileUploader.meta';
import FileItem from 'shared/ui/FileUploader/_ui/FileItem';
import FileUploaderValidationMessage from 'shared/ui/FileUploader/_ui/FileUploaderValidationMessage';

import { addWatterMarkFields, checkIsSumFileSizeValid, serializeFiles } from './FileUploader.lib';
import styles from './FileUploader.module.css';

import type { ChangeEvent, Dispatch, DragEvent, FC, SetStateAction } from 'react';
import type { TransformedFile } from 'shared/ui';
import type { IProgress } from 'shared/ui/FileUploadModal/FileUploadModal';
import type { EMimeTypes } from 'shared/ui/FileUploader/FileUploader.interfaces';

interface IFileUploaderProps {
	multiple?: boolean;
	setFiles: Dispatch<SetStateAction<TransformedFile[]>>;
	setOriginalFileForAvatar?: (file: TransformedFile[]) => void;
	files: TransformedFile[];
	accept?: Nullable<EMimeTypes[]>;
	maxFileSize?: number;
	setIsValidFileSize: Dispatch<SetStateAction<boolean>>;
	isValidFileSize: boolean;
	progress?: IProgress;
	isLoading?: boolean;
	setProgress?: Dispatch<SetStateAction<IProgress>>;
	mediaTypes?: string[];
	withWatermark?: boolean;
}

const FileUploader: FC<IFileUploaderProps> = ({
	multiple = false,
	setFiles: setFilesForUpload,
	files: filesForUpload,
	accept = null,
	maxFileSize = MAX_FILE_SIZE_GB,
	setIsValidFileSize,
	isValidFileSize,
	progress,
	isLoading,
	setProgress,
	setOriginalFileForAvatar,
	mediaTypes,
	withWatermark = true,
}) => {
	const [isDragOver, setIsDragOver] = useState(false);

	const [validationError, setValidationError] = useState('');

	const dragZoneStyles = classNames(
		'border-[2px] bg-dropzone border-gray-light rounded-lg border-dashed min-h-[200px] flex items-center',
		styles.FileUploader__wrapper,
		{ 'border-gray': isDragOver }
	);

	const acceptFormates = accept?.join(', ') ?? availableMimes;

	const onDragOverHandler = (e: DragEvent) => {
		e.preventDefault();
		setIsDragOver(true);
	};

	const onDragLeaveHandler = (e: DragEvent) => {
		e.preventDefault();
		setIsDragOver(false);
	};

	const onFilesDropHandler = (e: DragEvent) => {
		e.preventDefault();
		const files = serializeFiles(e.dataTransfer.files, acceptFormates);
		if (files.length > 1 && !multiple) {
			setValidationError(validationMessages.moreThanOneFile);
			return;
		}

		const filesWithWaterMark = addWatterMarkFields(files, mediaTypes);

		const result = withWatermark ? filesWithWaterMark : files;

		setFilesForUpload([...result]);
		setIsDragOver(false);
		if (files.length) {
			setValidationError('');
			return;
		}
		setValidationError(validationMessages.wrongFileFormat);
	};

	const onFileInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		setProgress?.({});
		const files = serializeFiles(e.target.files, acceptFormates);
		const filesWithWaterMark = addWatterMarkFields(files, mediaTypes);
		setFilesForUpload((list) => (!multiple ? [...filesWithWaterMark] : [...list, ...filesWithWaterMark]));
		if (setOriginalFileForAvatar) setOriginalFileForAvatar(!multiple ? [...filesWithWaterMark] : [...files, ...filesWithWaterMark]);
		if (files.length) {
			setValidationError('');
			return;
		}
		setValidationError(validationMessages.wrongFileFormat);
	};

	useEffect(() => {
		setIsValidFileSize(checkIsSumFileSizeValid(filesForUpload, maxFileSize));
	}, [filesForUpload, maxFileSize, setIsValidFileSize]);

	return (
		<div>
			<div
				className={dragZoneStyles}
				onDrop={onFilesDropHandler}
				onDragOver={onDragOverHandler}
				onDragLeave={onDragLeaveHandler}
				onDragEnd={onDragLeaveHandler}
			>
				<div className='flex flex-1 flex-col items-center justify-center'>
					<div
						data-id='Label'
						className='mb-[8px] flex h-full flex-1 items-center justify-center text-2xl font-semibold '
					>
						Перетащите {!multiple ? 'файл' : 'файлы'} сюда или нажмите
					</div>
					<button
						data-id='SelectFileButton'
						className='rounded bg-white'
					>
						<label
							htmlFor='fileUploader'
							className='opacity delay-50 ml-1 -block flex cursor-pointer items-center px-[18px] py-[12px] text-base text-lg font-bold text-black transition hover:opacity-60'
						>
							<ButtonIcon />
							<div className='ml-[8px] text-[14px]'>Выбрать {!multiple ? 'файл' : 'файлы'}</div>
						</label>
					</button>

					<input
						data-id='FileUploaderInput'
						onChange={onFileInputChangeHandler}
						hidden
						type='file'
						id='fileUploader'
						accept={acceptFormates}
						multiple={multiple}
					/>
				</div>
			</div>
			{!!filesForUpload.length && (
				<ul
					data-id='UploadedFileContainer'
					className='mt-2 flex flex-col'
				>
					{filesForUpload.map((file) => (
						<FileItem
							progress={progress}
							isLoading={isLoading}
							key={file.id}
							file={file}
							setFiles={setFilesForUpload}
							mediaTypes={mediaTypes}
							withWatermark={withWatermark}
						/>
					))}
				</ul>
			)}
			<FileUploaderValidationMessage
				isValidFileSize={isValidFileSize}
				maxFileSize={maxFileSize}
				validationError={validationError}
			/>
		</div>
	);
};

export default FileUploader;
