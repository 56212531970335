import { EContainerTemplate1FieldTypes } from 'shared/ui/Editor/EditorBlockTools/ContainerTemplatesTool/ContainerTemplatesTool.interfaces';

import { ELinkTypes } from './ContainerTemplate1.interfaces';

import type { IContainerTemplate1Data, IContainerTemplate1TextItem } from './ContainerTemplate1.interfaces';

export const blankContainerTemplate1TextItem = (): IContainerTemplate1TextItem => ({
	id: crypto.randomUUID(),
	style: '',
	text: '',
	type: EContainerTemplate1FieldTypes.TITLE,
});

export const blankContainerTemplate1Data = (): IContainerTemplate1Data => ({
	text_items: [],
});

export const selectTextItemTypeOptions = [
	{
		value: EContainerTemplate1FieldTypes.TITLE,
		label: 'Заголовок',
	},
	{
		value: EContainerTemplate1FieldTypes.DESCRIPTION,
		label: 'Описание',
	},
	{
		value: EContainerTemplate1FieldTypes.LINK,
		label: 'Ссылка',
	},
];

export const selectLinkTypeOptions = [
	{
		value: ELinkTypes.DEFAULT,
		label: 'Стандартная',
	},
	{
		value: ELinkTypes.DOWNLOAD,
		label: 'Ссылка для скачивания',
	},
];
