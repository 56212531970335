import type { ICardType7 } from './CardsType7.interfaces';

export const blankCardType7 = (): ICardType7 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	image_main: null,
	subtitle_1: '',
	subtitle_2: '',
	description_1: '',
	description_2: '',
	description_3: '',
	description_4: '',
	icon_1: null,
	icon_1_text: '',
	icon_1_url: '',
	icon_2: null,
	icon_2_text: '',
	icon_2_url: '',
	icon_3: null,
	icon_3_text: '',
	icon_3_url: '',
});
