import EditorJS from '@editorjs/editorjs';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';

import { stopPropagationHandler } from 'shared/lib/helpers/editorJsEvent';
import { TextInput } from 'shared/ui';
import { getEditorConfigs } from 'shared/ui/Editor/Editor.lib';
import TabImageWithControls from 'shared/ui/Editor/EditorBlockTools/TabTool/ui/TabImageWithControls';
import { getMaxOrderItem, getMinOrderItem } from 'shared/ui/Editor/EditorBlockTools/TabTool/ui/TabItem.lib';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';

import type { OutputData } from '@editorjs/editorjs';
import type { FC, MutableRefObject } from 'react';
import type { ITabItemProps, ITabData } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.interfaces';

const TabItem: FC<ITabItemProps> = ({ item, handleSelectFile, handleDeleteImage }) => {
	const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
	const TabRef = useRef<Nullable<EditorJS>>(null);
	const { values, setFieldValue } = useFormikContext<ITabData>();

	const saveEditorData = (newData: OutputData, id: number | string) => {
		setFieldValue(`items.${id}.data`, newData);
	};

	useEffect(() => {
		if (TabRef.current) return;
		const config = getEditorConfigs();
		config.holder = String(item.id);
		config.minHeight = 100;
		containerRef.current?.addEventListener('keydown', stopPropagationHandler);

		if (config?.tools?.tabs) delete config.tools.tabs;
		TabRef.current = new EditorJS({
			...config,
			data: item.data,
			onChange: async (api) => {
				const newData = await api.saver.save();

				saveEditorData(newData, item.id);
			},
		});
		return () => {
			if (TabRef.current && TabRef.current?.destroy) TabRef.current?.destroy();
			// eslint-disable-next-line react-hooks/exhaustive-deps
			containerRef.current?.removeEventListener('keydown', stopPropagationHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUp = useCallback(
		(id: number | string) => {
			const items = Object.values(values.items);
			const currentItem = { ...values.items[id] };
			const currentOrder = currentItem.order;
			let prevItem = items.find((item1) => item1.order === currentItem.order - 1);
			if (!prevItem) {
				prevItem = getMaxOrderItem(values.items) || currentItem;
			}
			prevItem = { ...prevItem };

			currentItem.order = prevItem.order;
			prevItem.order = currentOrder;

			setFieldValue(`items.${prevItem.id}`, prevItem);
			setFieldValue(`items.${currentItem.id}`, currentItem);
		},
		[setFieldValue, values.items]
	);

	const handleDown = useCallback(
		(id: number | string) => {
			const items = Object.values(values.items);
			const currentItem = { ...values.items[id] };
			const currentOrder = currentItem.order;
			let nextItem = items.find((item1) => item1.order === currentItem.order + 1);
			if (!nextItem) {
				nextItem = getMinOrderItem(values.items) || currentItem;
			}
			nextItem = { ...nextItem };

			currentItem.order = nextItem.order;
			nextItem.order = currentOrder;

			setFieldValue(`items.${nextItem.id}`, nextItem);
			setFieldValue(`items.${currentItem.id}`, currentItem);
		},
		[setFieldValue, values.items]
	);

	const handleClose = useCallback(
		(id: number | string) => {
			const newItems = { ...values.items };
			delete newItems[id];
			setFieldValue('items', newItems);
		},
		[setFieldValue, values.items]
	);

	return (
		<Accordion>
			<AccordionSummary>
				<div className='flex w-full items-center justify-between'>
					<h3 key={`${item.order}-${item.tabTitle}`}>
						Пункт {item.order} {item.tabTitle ? `(${item.tabTitle})` : ''}
					</h3>
					<SubTuner
						variant='static'
						tuneItem={item.id}
						disableUp={item.order === 1}
						disableDown={getMaxOrderItem(values.items)?.order === item.order}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div className='flex w-full flex-col gap-m'>
					<TabImageWithControls
						handleSelectFile={handleSelectFile}
						handleDeleteImage={handleDeleteImage}
						index={item.id}
						item={item}
					/>
					<Field
						name={`items.${item.id}.tabTitle`}
						as={TextInput}
						placeholder='Введите заголовок вкладки'
						label='Заголовок'
					/>
					<Field
						name={`items.${item.id}.subTitle`}
						as={TextInput}
						placeholder='Подзаголовок'
						label='Подзаголовок'
					/>
					<div
						ref={containerRef}
						className='flex flex-col rounded border-[1px] border-gray-light p-m'
					>
						<p>Контент:</p>
						<div id={String(item.id)} />
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default TabItem;
