import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { TextInput } from 'shared/ui';
import { Map } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/Map';

import { LinesControl } from './LinesControl';
import { PointsControl } from './PointsControl';

import type { ILeafletMapData } from './LeafletMapTool.interfaces';

interface IProps {
	data: ILeafletMapData;
	handleDataChange: (newData: ILeafletMapData) => void;
}

export const LeafletMapTool = ({ data, handleDataChange }: IProps) => {
	const [mapId, setMapId] = useState<Undefinable<string>>(undefined);

	const formik = useFormik({
		initialValues: data,
		onSubmit: () => {},
	});

	useEffect(() => {
		const uuid = crypto.randomUUID();
		setMapId(uuid);
	}, []);

	useEffect(() => {
		handleDataChange(formik.values);
	}, [formik.values, handleDataChange]);

	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>
				Инструмент {'"'}Карта{'"'}
			</AccordionSummary>
			<AccordionDetails>
				<form className='flex flex-col gap-m'>
					<TextInput
						{...formik.getFieldProps('style')}
						label='Стиль'
						placeholder='Введите стиль'
					/>
					<div className='z-0 min-h-[500px] w-full min-w-[500px]'>
						<div
							className='h-full min-h-[500px] w-full'
							id={`map-${mapId}`}
						/>
						{mapId && (
							<Map
								data={formik.values}
								mapId={mapId}
							/>
						)}
					</div>
					<Accordion>
						<AccordionSummary>Данные карты</AccordionSummary>
						<AccordionDetails>
							<div className='flex flex-col gap-m'>
								<PointsControl
									values={formik.values}
									setFieldValue={formik.setFieldValue}
									getFieldProps={formik.getFieldProps}
								/>
								<LinesControl
									values={formik.values}
									setFieldValue={formik.setFieldValue}
									getFieldProps={formik.getFieldProps}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};
