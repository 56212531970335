import { Formik } from 'formik';

import { SliderForm } from './_ui';

import type { ISliderToolProps } from './SliderTool.interface';

const SliderTool = ({ data, onDataChange, deleteBlock, moveBlockDown, moveBlockUp }: ISliderToolProps) => {
	return (
		<div className=''>
			<Formik
				initialValues={data}
				enableReinitialize
				onSubmit={(values) => onDataChange(values)}
			>
				{() => (
					<SliderForm
						onDataChange={onDataChange}
						deleteBlock={deleteBlock}
						moveBlockDown={moveBlockDown}
						moveBlockUp={moveBlockUp}
					/>
				)}
			</Formik>
		</div>
	);
};

export default SliderTool;
