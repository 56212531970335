import Checklist from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import Marker from 'editorjs-text-color-plugin';

import { CNavTool, CMediaTool } from 'shared/ui/Editor/EditorBlockTools';
import { LayoutTool } from 'shared/ui/Editor/EditorBlockTools/LayoutTool/LayoutTool.class';
import { CTabTool } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.class';
import './LayoutTool.scss';

const editorJSConfigTools = {
	color: {
		class: Marker, // if load from CDN, please try: window.ColorPlugin
		config: {
			colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
			defaultColor: '#FF1300',
			type: 'text',
			customPicker: true, // add a button to allow selecting any colour
		},
	},
	marker: {
		class: Marker, // if load from CDN, please try: window.ColorPlugin
		config: {
			defaultColor: '#FFBF00',
			type: 'marker',
			icon: '<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>',
		},
	},
	nav: CNavTool,
	tabs: CTabTool,
	header: {
		class: Header,
		inlineToolbar: true,
	},
	paragraph: {
		class: Paragraph,
		inlineToolbar: true,
	},
	table: {
		class: Table,
		inlineToolbar: true,
	},
	checklist: {
		class: Checklist,
		inlineToolbar: true,
	},
	list: {
		class: List,
		inlineToolbar: true,
	},
	media: CMediaTool,
	quote: {
		class: Quote,
		inlineToolbar: true,
		config: {
			quotePlaceholder: 'Enter a quote',
			captionPlaceholder: "Quote's author",
		},
	},
	delimiter: {
		class: Delimiter,
		inlineToolbar: true,
	},
};

const layout = new LayoutTool({
	tools: editorJSConfigTools,
}).generateConfig({
	countInitDate: 1,
	classLayout: 'layoutWrapper',
	classChildren: 'layoutChildren',
	shortcut: 'CMD+7',
	title: 'Макет',
});
const twoColumns = new LayoutTool({ tools: editorJSConfigTools }).generateConfig({
	countInitDate: 2,
	classLayout: 'columnsWrapper',
	classChildren: 'columnsChildren',
	shortcut: 'CMD+8',
	title: '2 колонки',
});
const threeColumns = new LayoutTool({ tools: editorJSConfigTools }).generateConfig({
	countInitDate: 3,
	classLayout: 'columnsWrapper',
	classChildren: 'columnsChildren',
	shortcut: 'CMD+9',
	title: '3 колонки',
});
const fourColumns = new LayoutTool({ tools: editorJSConfigTools }).generateConfig({
	countInitDate: 4,
	classLayout: 'columnsWrapper',
	classChildren: 'columnsChildren',
	shortcut: 'CMD+-',
	title: '4 колонки',
});

export { layout, twoColumns, threeColumns, fourColumns };
