import type { Dispatch, SetStateAction } from 'react';
import type { IMediaFile } from 'shared/interfaces';
import type { EMediaCollectionName } from 'shared/lib/constants/collectionName';
import type { IProgress } from 'shared/ui/FileUploadModal/FileUploadModal';
import type { TransformedFile } from 'shared/ui/FileUploader';
import type { EMimeTypes } from 'shared/ui/FileUploader/FileUploader.interfaces';

export enum ESelectFileVariant {
	MEDIA = 'media',
	PDF = 'pdf',
	OTHERS = 'other',
	IMAGE = 'image',
}

interface ISelectMediaModalProps {
	onSelectFile: (file: IMediaFile) => void;
	isOpen: boolean;
	onClose: () => void;
	variant: ESelectFileVariant;
	maxFileSizeForUploadMB?: number;
	accept?: EMimeTypes[];
	mediaTypes?: string[];
	withWatermark?: boolean;
	collectionNameForUpload?: EMediaCollectionName;
}

interface IMediaTabProps {
	variant: Undefinable<ESelectFileVariant>;
	selectedMedia: Map<IMediaFile['id'], IMediaFile>;
	setSelectedMedia: Dispatch<SetStateAction<Map<IMediaFile['id'], IMediaFile>>>;
	collectionNameParam?: EMediaCollectionName;
	withWatermark: boolean;
}

interface IUploaderTabProps {
	files: TransformedFile[];
	setFiles: Dispatch<SetStateAction<TransformedFile[]>>;
	maxFileSizeForUploadMB?: number;
	accept?: EMimeTypes[];
	isValidSize: boolean;
	setIsValidSize: Dispatch<SetStateAction<boolean>>;
	progress: IProgress;
	isLoading?: boolean;
	mediaTypes?: string[];
	withWatermark?: boolean;
}

export type { ISelectMediaModalProps, IMediaTabProps, IUploaderTabProps };
