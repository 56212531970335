import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const LogoutIcon: FC<ISVGProps> = ({ className }) => {
	return (
		<>
			<svg
				className={className}
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fill='#DB2424'
					d='M14.4139 12.8046C13.969 12.8046 13.6093 13.1651 13.6093 13.6092V16.828C13.6093 17.2714 13.2488 17.6326 12.8045 17.6326H10.3905V5.56246C10.3905 4.87525 9.95276 4.26129 9.29456 4.03275L9.05632 3.95305H12.8045C13.2488 3.95305 13.6093 4.31432 13.6093 4.75783V7.17186C13.6093 7.61593 13.969 7.97649 14.4139 7.97649C14.8589 7.97649 15.2186 7.61593 15.2186 7.17186V4.75783C15.2186 3.4269 14.1355 2.34379 12.8045 2.34379H4.15428C4.12365 2.34379 4.098 2.35748 4.06824 2.36148C4.02947 2.35823 3.9925 2.34375 3.95316 2.34375C3.06559 2.34379 2.34375 3.06551 2.34375 3.95308V18.4373C2.34375 19.1245 2.78148 19.7385 3.43969 19.967L8.28229 21.5813C8.44644 21.6319 8.60969 21.6561 8.7812 21.6561C9.66877 21.6561 10.3905 20.9343 10.3905 20.0467V19.2421H12.8045C14.1354 19.2421 15.2185 18.1589 15.2185 16.828V13.6092C15.2185 13.1651 14.8589 12.8046 14.4139 12.8046H14.4139Z'
				/>
				<path
					fill='#DB2424'
					d='M21.4205 9.8218L18.2016 6.60314C18.0892 6.49052 17.9458 6.41381 17.7897 6.3827C17.6336 6.35159 17.4717 6.36749 17.3247 6.42838C17.0245 6.55319 16.8281 6.84684 16.8281 7.17202V9.58606H13.6095C13.1652 9.58606 12.8047 9.94647 12.8047 10.3907C12.8047 10.8349 13.1652 11.1953 13.6095 11.1953H16.8281V13.6094C16.8281 13.9345 17.0245 14.2282 17.3247 14.353C17.4717 14.4139 17.6335 14.4298 17.7897 14.3987C17.9458 14.3676 18.0891 14.2909 18.2016 14.1784L21.4205 10.9596C21.735 10.645 21.735 10.1364 21.4205 9.8218Z'
				/>
			</svg>
		</>
	);
};

export default LogoutIcon;
