import { map as lmap, tileLayer } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';

import {
	initialMapCenter, mapMaxZoom,
	mapMinZoom, mapInitZoom,
} from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.constants';
import { PolygonsLayer } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/Map/Polygons';

import { LinesLayer } from './Lines';
import { PointsLayer } from './Points';

import type { Map as LMap } from 'leaflet';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	data: ILeafletMapData;
	mapId: string;
}

export const Map = ({ data, mapId }: IProps) => {
	const mapRef = useRef<LMap>();
	const [isMapReady, setIsMapReady] = useState(false);
	const [zoom, setZoom] = useState(mapInitZoom);

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current?.invalidateSize();
		}
		if (!mapRef.current) {
			const createdMap = lmap(
				`map-${mapId}`,
				{
					center: [initialMapCenter.lat, initialMapCenter.lng],
					scrollWheelZoom: 'center',
					doubleClickZoom: false,
					attributionControl: false,
					zoomControl: false,
					zoom: mapInitZoom,
					minZoom: mapMinZoom,
					maxZoom: mapMaxZoom,
					maxBoundsViscosity: 1,
				},
			);
			tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 20,
			}).addTo(createdMap);
			createdMap.setMaxBounds([
				[-90, -180],
				[90, 180],
			]);

			createdMap.on('zoom', () => {
				setZoom(createdMap.getZoom());
			});

			mapRef.current = createdMap;
			setIsMapReady(true);
		}

		return () => {
			const mapClass = mapRef.current;
			if (mapClass) mapClass.remove();
		};
	}, [mapId]);

	return <>
		{isMapReady && (
			<>
				<PointsLayer points={data.points} map={mapRef.current!} zoom={zoom} />
				<LinesLayer lines={data.lines} map={mapRef.current!} zoom={zoom} />
				<PolygonsLayer map={mapRef.current!} />
			</>
		)}
	</>;
};
