import type { ITabToolItem } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.interfaces';

export const generateTabItem = (length: number): ITabToolItem => ({
	id: crypto.randomUUID(),
	order: length + 1,
	tabTitle: '',
	url: '',
	media: null,
	data: { blocks: [] },
});
