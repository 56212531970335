import { type FC } from 'react';

import { useMediaListQuery } from 'shared/api/mediaApi';
import useQueryParameters from 'shared/hooks/useQueryParameters';
import { CmsTable } from 'shared/ui/CMSTable';
import { EOrderDirection } from 'shared/ui/CMSTable/CmsTable.interfaces';
import { getMediaColumnConfigs } from 'shared/ui/SelectMediaModal/SelectMediaModal.meta';

import type { TMediaItem } from 'shared/interfaces';
import type { IMediaTabProps } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

const MediaTab: FC<IMediaTabProps> = ({ variant, selectedMedia, setSelectedMedia, withWatermark, collectionNameParam = '' }) => {
	const { controlledParams, updateParams } = useQueryParameters(
		{
			title: 'string',
			page: 'number',
			sort_by: 'string',
			per_page: 'number',
			sort_direction: EOrderDirection,
		},
		{
			page: 1,
			per_page: 15,
		}
	);
	const { data, isFetching } = useMediaListQuery({
		...controlledParams,
		type: variant,
		collection_name: collectionNameParam,
	});

	return (
		<CmsTable<TMediaItem>
			rows={data?.data || []}
			selectedRows={selectedMedia}
			setSelectedRows={setSelectedMedia}
			columnsConfigs={getMediaColumnConfigs(withWatermark)}
			areRowsFetching={isFetching}
			updateParams={updateParams}
			params={controlledParams}
			totalRows={data?.meta?.total || 0}
			isUserHasViewPermission
			singleSelectionMode
		/>
	);
};

export default MediaTab;
