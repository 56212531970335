import { Field, useFormikContext } from 'formik';

import { FormInputFileUploader } from 'shared/ui/FormInputFileUploader';
import { TextInput } from 'shared/ui/TextInput';

import type { TProps } from './SliderFormType7.types';
import type { IMediaFile } from 'shared/interfaces';
import type { ISliderData } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.interface';

const SliderFormType7 = ({ index }: TProps) => {
	const { values, setFieldValue } = useFormikContext<ISliderData>();

	const handleSelectFile = (newValue: Nullable<IMediaFile>, field: string) => {
		setFieldValue(field, newValue);
	};

	const handleDeleteImage = (field: string) => {
		setFieldValue(field, '');
	};

	return (
		<>
			<div className='flex justify-between gap-x-4 p-5 text-base font-semibold'>
				<p>Слайд {index + 1}</p>
				<Field
					name={`items.[${index}].style`}
					placeholder='Стиль'
					as={TextInput}
				/>
			</div>
			<div className=''>
				<div className='mb-5 flex flex-col gap-4'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].image || null}
						name={`items.[${index}].image`}
						text='Банер'
					/>
					<Field
						name={`items.[${index}].subheadline`}
						placeholder='Подзаголовок'
						label='Подзаголовок'
						as={TextInput}
					/>
					<Field
						name={`items.[${index}].headline`}
						placeholder='Заголовок'
						label='Заголовок'
						as={TextInput}
					/>
					<Field
						name={`items.[${index}].description`}
						placeholder='Описание'
						label='Описание'
						as={TextInput}
					/>
					<Field
						name={`items.[${index}].button_text`}
						placeholder='Текст кнопки'
						label='Текст кнопки'
						as={TextInput}
					/>
					<Field
						name={`items.[${index}].button_link`}
						placeholder='Ссылка кнопки'
						label='Ссылка кнопки'
						as={TextInput}
					/>
				</div>
			</div>
		</>
	);
};

export default SliderFormType7;
