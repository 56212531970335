import { useCallback, useEffect, useRef, useState } from 'react';

import { useMediaByIdQuery } from 'shared/api/mediaApi';
import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { EMediaToolFileAlignment } from 'shared/ui/Editor/EditorBlockTools/MediaTool/MediaTool.interfaces';
import { BACKGROUND, CENTER, LEFT, RIGHT } from 'shared/ui/Editor/EditorBlockTools/MediaTool/MediaTool.meta';
import { LINK, PREVIEW, STRETCHED, WITH_BORDER } from 'shared/ui/Editor/EditorBlockTools/PDFTool/PDFTool.meta';
import { ControlMenus, FilePreview } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { EFilePreviewViewModes } from 'shared/ui/Editor/EditorBlockTools/_ui/FilePreview/FIlePreview.interfaces';
import { SelectMediaModal } from 'shared/ui/SelectMediaModal';

import type { FC, ReactNode } from 'react';
import type { IMediaFile } from 'shared/interfaces';
import type { IAnyFileData } from 'shared/ui/Editor/EditorBlockTools/AnyFileTool/AnyFile.interfaces';
import type { IMediaToolData } from 'shared/ui/Editor/EditorBlockTools/MediaTool/MediaTool.interfaces';
import type { IPDFToolData } from 'shared/ui/Editor/EditorBlockTools/PDFTool/PDFTool.interfaces';
import type { EMimeTypes } from 'shared/ui/FileUploader/FileUploader.interfaces';
import type { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

interface IProps {
	handleChange: (value: any) => void;
	data: IMediaToolData | IPDFToolData | IAnyFileData;
	variant: ESelectFileVariant;
	deleteEmptyBlock: () => void;
	accept: EMimeTypes[];
	deleteBlock: () => void;
	moveBlockUp: () => void;
	moveBlockDown: () => void;
	mediaTypes?: string[];
	tunesOptions?: {
		icon: ReactNode;
		title: string;
		name: string;
	}[];
}

const FileTool: FC<IProps> = ({
	data,
	variant,
	handleChange,
	deleteEmptyBlock,
	accept,
	mediaTypes,
	moveBlockUp,
	moveBlockDown,
	deleteBlock,
	tunesOptions = [],
}) => {
	const [media, setMedia] = useState(data.media);
	const { openModal, closeModal, isModalOpen } = useModal(!data.media?.url);
	const [captureText, setCaptureText] = useState<string>(data.captureText);
	const [style, setStyle] = useState<string | null>(data.style || null);
	const [viewMode, setViewMode] = useState<EFilePreviewViewModes | false>('viewMode' in data && data.viewMode);
	const [stretched, setStretched] = useState<boolean>('stretched' in data && data.stretched);
	const [withBorder, setWithBorder] = useState('withBorder' in data && data.withBorder);
	const [withBackground, setWithBackground] = useState('withBackground' in data && data.withBackground);
	const [alignment, setAlignment] = useState('alignment' in data && data.alignment);
	const isMediaExistRef = useRef<Nullable<IMediaFile>>(null);

	const { isModalOpen: isMenuOpen, openModal: openMenu, closeModal: closeMenu } = useModal();

	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	const { data: mediaByIy } = useMediaByIdQuery(data.media?.id || '');

	useEffect(() => {
		if (data.media && mediaByIy && mediaByIy.data.id === data.media.id) {
			setMedia(mediaByIy.data);
		}
	}, [mediaByIy]);

	useClickOutside(menuRef, closeMenu);

	const handleSetFile = useCallback(
		(file: IMediaFile) => {
			isMediaExistRef.current = file;
			setMedia({ ...file });
		},
		[setMedia]
	);

	const handleOnClose = () => {
		if (!isMediaExistRef.current) deleteEmptyBlock();
		closeModal();
	};

	useEffect(() => {
		handleChange({
			media,
			captureText,
			style,
			viewMode,
			stretched,
			withBorder,
			withBackground,
			alignment,
		});
	}, [media, handleChange, captureText, style, viewMode, stretched, withBorder, withBackground, alignment]);

	const pickElement = (name: string) => {
		if (name === LINK) {
			setViewMode(EFilePreviewViewModes.LINK);
			setWithBorder(false);
			setStretched(false);
		}
		if (name === PREVIEW) {
			setViewMode(EFilePreviewViewModes.PREVIEW);
		}
		if (name === WITH_BORDER) {
			setWithBorder((value) => !value);
			setViewMode(EFilePreviewViewModes.PREVIEW);
		}
		if (name === LEFT) {
			setAlignment(EMediaToolFileAlignment.LEFT);
		}
		if (name === RIGHT) {
			setAlignment(EMediaToolFileAlignment.RIGHT);
		}
		if (name === CENTER) {
			setAlignment(EMediaToolFileAlignment.CENTER);
		}

		if (name === BACKGROUND) {
			setWithBackground((prevState) => !prevState);
		}

		if (name === STRETCHED) {
			setStretched((prevState) => {
				if (!prevState) setAlignment(EMediaToolFileAlignment.EMPTY);
				setViewMode(EFilePreviewViewModes.PREVIEW);
				return !prevState;
			});
		}

		closeMenu();
	};

	const border = withBorder ? WITH_BORDER : false;
	const stretch = stretched ? STRETCHED : false;
	const backGround = withBackground ? BACKGROUND : false;

	const names = [viewMode, stretch, border, backGround, alignment];
	const checkedNames = names.filter((el) => el);

	return (
		<>
			<div>
				<div className='mt-[12px] flex items-center justify-end'>
					<ControlMenus
						checkedNames={checkedNames}
						menuRef={menuRef}
						openModal={openMenu}
						closeModal={closeMenu}
						pickElement={pickElement}
						isModalOpen={isMenuOpen}
						items={tunesOptions}
						moveBlockUp={moveBlockUp}
						deleteBlock={deleteBlock}
						moveBlockDown={moveBlockDown}
					/>
				</div>
				{media?.url && (
					<FilePreview
						viewMode={viewMode}
						providedText={captureText}
						variant={variant}
						media={media}
						onProvidedChanged={setCaptureText}
						openEditModal={openModal}
						stretched={stretched}
						withBorder={withBorder}
						withBackground={withBackground}
						alignment={alignment}
						style={style}
						onChangeStyle={setStyle}
					/>
				)}
				<SelectMediaModal
					variant={variant}
					onSelectFile={handleSetFile}
					isOpen={isModalOpen}
					onClose={handleOnClose}
					accept={accept}
					mediaTypes={mediaTypes}
				/>
			</div>
		</>
	);
};

export default FileTool;
