const EditorCancelIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
	>
		<path
			stroke='#333'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='m7.05 7.05 9.9 9.9M7.05 16.95l9.9-9.9'
		/>
	</svg>
);
export default EditorCancelIcon;
