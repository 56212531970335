export const RuLongDate = new Intl.DateTimeFormat('ru', {
	weekday: 'short',
	day: 'numeric',
	month: 'long',
	year: 'numeric',
});

export const RuDateTime = new Intl.DateTimeFormat('ru', {
	day: 'numeric',
	month: 'numeric',
	year: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	second: 'numeric',
});

export const RuNumericDate = new Intl.DateTimeFormat('ru');

export const formatDateApi = (date: Date) => date.toISOString().split('T')[0];
