import { divIcon, layerGroup, marker } from 'leaflet';
import { useEffect, useRef } from 'react';

import {
	CityZoomMaxLimit, CityZoomMinLimit,
} from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.constants';

import type { LayerGroup, Map as LMap } from 'leaflet';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	data: ILeafletMapData['points']['cities'];
	map: LMap;
	zoom: number;
}

export const Cities = ({ data, map, zoom }: IProps) => {
	const groupRef = useRef<LayerGroup>();

	useEffect(() => {
		if (map) {
			if (groupRef.current) {
				groupRef.current?.remove();
				groupRef.current = undefined;
			}
			const createdLayerGroup = data.reduce((layerGroupConsumer, point) => {
				const newCity = marker(point.coordinates, {
					icon: divIcon({
						html: '<div class="rounded-full w-full h-full p-[1px] bg-white"><div class="w-full h-full bg-red rounded-full" /></div>',
						className: 'rounded-full',
						iconSize: [10, 10],
						iconAnchor: [5, 5],
					}),
				});
				newCity.addTo(layerGroupConsumer);

				return layerGroupConsumer;
			}, layerGroup());

			groupRef.current = createdLayerGroup;
			if (zoom > CityZoomMinLimit && zoom < CityZoomMaxLimit) {
				createdLayerGroup.addTo(map);
			}
		}

		return () => {
			if (map && groupRef.current) {
				groupRef.current?.remove();
				groupRef.current = undefined;
			}
		};
	}, [data, map]);

	useEffect(() => {
		const group = groupRef.current;
		if (map && group) {
			if (zoom > CityZoomMinLimit && zoom < CityZoomMaxLimit) group.addTo(map);
			else group.remove();
		}
	}, [map, zoom]);

	return null;
};