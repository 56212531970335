import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store/core';
import { theme } from 'app/theme/theme';
import { EventMixin } from 'shared/lib/helpers';
import { changeBlocksId } from 'shared/lib/helpers/changeBlocksId';
import AccordionTool from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool';
import { generateAccordionItem } from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool.lib';
import { toolboxParams } from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool.meta';

import type { API, BlockAPI, BlockTool } from '@editorjs/editorjs';
import type { IAccordionData, IAccordionToolParams } from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool.interfaces';

export class CAccordionTool implements BlockTool {
	data: IAccordionData;

	api: API;

	block: BlockAPI;

	emitter: EventMixin;

	currentBlockToCut: any = null;

	constructor({ data, api, block }: IAccordionToolParams) {
		this.data = Object.keys(data).length ? data : { items: [generateAccordionItem()], style: '', title: '' };
		this.api = api;
		this.block = block;
		this.emitter = EventMixin.getInstance();
		this.emitterEvent();
	}

	emitterEvent() {
		this.emitter.on('currentBlockToCut', (data) => {
			this.currentBlockToCut = data;
		});
	}

	static get toolbox() {
		return toolboxParams;
	}

	async save() {
		return this.data;
	}

	render(): HTMLElement {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		const onDataChange = (data: IAccordionData) => {
			this.data = data;
			this.block.dispatchChange();
		};

		const copyBlock = async () => {
			const blockId = this.block.id;
			const saveData = await this.api.saver.save();
			const pickedBlock = saveData.blocks.find((b) => b.id === blockId);
			if (pickedBlock) {
				this.emitter.emit('currentBlockToCut', pickedBlock);
			}
		};

		const pasteBlock = async (i: string | number) => {
			if (this.currentBlockToCut) {
				const copyObj = JSON.parse(JSON.stringify(this.currentBlockToCut));
				const changedIds = changeBlocksId(copyObj);
				this.data.items[+i].data.blocks.push(changedIds);
				const saveData = await this.api.saver.save();
				await this.api.blocks.render(saveData);
			}
		};

		renderRoot.render(
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<AccordionTool
							copyBlock={copyBlock}
							pasteBlock={pasteBlock}
							deleteBlock={deleteBlock}
							moveBlockUp={moveBlockUp}
							moveBlockDown={moveBlockDown}
							handleChange={onDataChange}
							data={this.data}
						/>
					</Provider>
				</ThemeProvider>
			</BrowserRouter>
		);

		return rootNode;
	}
}
