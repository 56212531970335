import baseApi from 'shared/api/baseApi';
import { ETagTypes } from 'shared/lib/constants/tagTypes';

import type {
	TCreateFormPayload,
	TCreateFormResponse,
	TDeleteFormPayload,
	TDeleteFormResponse,
	TGetFormPayload,
	TGetFormRepsonse,
	TGetFormSelectOptionsPayload,
	TGetFormSelectOptionsPersponse,
	TGetFormsListPayload,
	TGetFormsListResponse,
	TUpdateFormPayload,
	TUpdateFormResponse,
	TUpdateFormStatusPayload,
	TUpdateFormStatusResponse,
} from './formsApi.types';

const BASE_URL = '/api/forms';

export const formsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getFormsList: builder.query<TGetFormsListResponse, TGetFormsListPayload>({
			query: ({ params }) => ({
				method: 'GET',
				url: `${BASE_URL}`,
				params,
			}),
			providesTags: [ETagTypes.FORMS_LIST],
		}),
		getForm: builder.query<TGetFormRepsonse, TGetFormPayload>({
			query: ({ formId }) => ({
				method: 'GET',
				url: `${BASE_URL}/${formId}`,
			}),
			providesTags: [ETagTypes.FORM],
		}),
		getFormSelectOptions: builder.query<TGetFormSelectOptionsPersponse, TGetFormSelectOptionsPayload>({
			query: () => ({
				method: 'GET',
				url: `${BASE_URL}/select-options`,
			}),
			providesTags: [ETagTypes.FORM_SELECT_OPTIONS],
		}),
		createForm: builder.mutation<TCreateFormResponse, TCreateFormPayload>({
			query: (data) => ({
				method: 'POST',
				url: `${BASE_URL}`,
				data,
			}),
			invalidatesTags: [ETagTypes.FORMS_LIST],
		}),
		updateFormsStatus: builder.mutation<TUpdateFormStatusResponse, TUpdateFormStatusPayload>({
			query: (params) => ({
				method: 'PATCH',
				url: `${BASE_URL}/update-statuses`,
				params,
			}),
			invalidatesTags: [ETagTypes.FORMS_LIST],
		}),
		updateForm: builder.mutation<TUpdateFormResponse, TUpdateFormPayload>({
			query: ({ formId, data }) => ({
				method: 'PUT',
				url: `${BASE_URL}/${formId}`,
				data,
			}),
			invalidatesTags: [ETagTypes.FORMS_LIST, ETagTypes.FORM],
		}),
		daleteForm: builder.mutation<TDeleteFormResponse, TDeleteFormPayload>({
			query: ({ formId }) => ({
				method: 'DELETE',
				url: `${BASE_URL}/${formId}`,
			}),
			invalidatesTags: [ETagTypes.FORMS_LIST],
		}),
	}),
});

export const {
	useGetFormsListQuery,
	useLazyGetFormsListQuery,
	useGetFormQuery,
	useLazyGetFormQuery,
	useGetFormSelectOptionsQuery,
	useLazyGetFormSelectOptionsQuery,
	useCreateFormMutation,
	useUpdateFormsStatusMutation,
	useUpdateFormMutation,
	useDaleteFormMutation,
} = formsApi;
