type TCBHandler = (...args: any[]) => void;

export class EventMixin {
	public handlers: Record<string, Array<TCBHandler>>;

	static instance: EventMixin;

	constructor() {
		this.handlers = {};
	}

	static getInstance() {
		if (!this.instance) {
			this.instance = new EventMixin();
		}
		return this.instance;
	}

	public on(eventName: string, handler: TCBHandler) {
		if (!this.handlers[eventName]) this.handlers[eventName] = [];
		this.handlers[eventName].push(handler);
	}

	public off(eventName: string, handler: TCBHandler) {
		let handlers = this.handlers && this.handlers[eventName];
		if (!handlers) return;

		for (let i = 0; i < handlers.length; i++) {
			if (handlers[i] === handler) {
				handlers.splice(i--, 1);
			}
		}
	}

	public emit(eventName: string, ...args: any[]) {
		if (!this.handlers || !this.handlers[eventName]) return;
		this.handlers[eventName].forEach((handler) => handler.apply(this, args));
	}
}
