import cn from 'classnames';
import { Field, useFormikContext } from 'formik';
import { useEffect, type FC, useRef } from 'react';

import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { generateAccordionItem } from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool.lib';
import AccordionItem from 'shared/ui/Editor/EditorBlockTools/AccordionTool/ui/AccordionItem';
import { ADD, COPY, tuneAddElement, tuneCopyElement } from 'shared/ui/Editor/EditorBlockTools/EditorBlockTools.meta';
import { ControlMenus } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { TextInput } from 'shared/ui/TextInput';

import type { IAccordionData, IAccordionFormProps } from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool.interfaces';

const AccordionForm: FC<IAccordionFormProps> = ({ handleChange, moveBlockUp, moveBlockDown, deleteBlock, pasteBlock, copyBlock }) => {
	const { values, setFieldValue } = useFormikContext<IAccordionData>();
	const { isModalOpen, openModal, closeModal } = useModal();

	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	useEffect(() => {
		handleChange(values);
	}, [handleChange, values]);

	const pickElement = (name: string) => {
		if (name === ADD) {
			setFieldValue('items', [...values.items, generateAccordionItem()]);
		}
		if (name === COPY) {
			copyBlock();
		}
		closeModal();
	};

	useClickOutside(menuRef, closeModal);

	const accordionClassnames = cn('z-0 flex flex-col gap-4 relative rounded-lg p-4', {
		'bg-mainBgColor': values.items.length > 0,
	});

	return (
		<div className='flex flex-col gap-3'>
			<div className='mt-[12px] flex items-center justify-between'>
				<h2>Аккордеон</h2>
				<ControlMenus
					menuRef={menuRef}
					openModal={openModal}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
					items={[...tuneAddElement, ...tuneCopyElement]}
					pickElement={pickElement}
				/>
			</div>
			<Field
				name='style'
				as={TextInput}
				placeholder='Стиль'
				label='Стиль'
			/>
			<Field
				name='title'
				as={TextInput}
				placeholder='Заголовок'
				label='Заголовок'
			/>
			<div className={accordionClassnames}>
				{values.items.map((accordion, index) => (
					<div
						className='relative'
						key={accordion.id}
					>
						<div className='p-5 text-base font-semibold'>{`Аккордеон ${index + 1}`}</div>
						<AccordionItem
							index={index}
							item={accordion}
							pasteBlock={pasteBlock}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default AccordionForm;
