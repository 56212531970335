import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';

import { SelectInput, TextInput } from 'shared/ui';

import { ContainerTemplate1, ContainerTemplate2, ContainerTemplate3, ContainerTemplate4 } from './ContainerTemaplates';
import { EContainerTemplates } from './ContainerTemplatesTool.interfaces';
import { getInitialContainerTemplatesData, selectContainerTemplateOptions } from './ContainerTemplatesTool.lib';

import type { IContainerTemplatesToolData } from './ContainerTemplatesTool.interfaces';

interface IProps {
	data: IContainerTemplatesToolData;
	handleDataChange: (newData: IContainerTemplatesToolData) => void;
}

export const ContainerTemplatesTool = ({ data: initialData, handleDataChange }: IProps) => {
	const formik = useFormik({
		initialValues: initialData,
		onSubmit: () => {},
	});

	useEffect(() => {
		handleDataChange(formik.values);
	}, [formik.values, handleDataChange]);
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>
				Инструмент {'"'}Шаблонный контейнер{'"'}
			</AccordionSummary>
			<AccordionDetails>
				<form className='flex flex-col gap-m'>
					<SelectInput
						value={formik.values.template}
						onChange={(e) => {
							formik.setValues(getInitialContainerTemplatesData(e.target.value as EContainerTemplates));
						}}
						label='Вид инструмента Шаблонный контейнер'
					>
						{selectContainerTemplateOptions.map(({ value: optionValue, label }) => (
							<MenuItem
								key={optionValue}
								value={optionValue}
							>
								{label}
							</MenuItem>
						))}
					</SelectInput>

					<TextInput
						{...formik.getFieldProps('style')}
						label='Стиль инструмента Шаблонный контейнер'
						placeholder='Введите стиль контейнера'
					/>
					<TextInput
						{...formik.getFieldProps('title')}
						label='Заголовок контейнера'
						placeholder='Введите заголовок для контейнера'
					/>
					{(() => {
						switch (formik.values.template) {
							case EContainerTemplates.TITLE_TEXT_TYPE: {
								return (
									<ContainerTemplate1
										data={formik.values.data}
										getFieldProps={formik.getFieldProps}
										setFieldValue={formik.setFieldValue}
									/>
								);
							}
							case EContainerTemplates.WITH_LIST: {
								return (
									<ContainerTemplate2
										data={formik.values.data}
										getFieldProps={formik.getFieldProps}
										setFieldValue={formik.setFieldValue}
									/>
								);
							}
							case EContainerTemplates.ALERT: {
								return (
									<ContainerTemplate3
										data={formik.values.data}
										getFieldProps={formik.getFieldProps}
										setFieldValue={formik.setFieldValue}
									/>
								);
							}
							case EContainerTemplates.LICENCES: {
								return (
									<ContainerTemplate4
										data={formik.values.data}
										getFieldProps={formik.getFieldProps}
										setFieldValue={formik.setFieldValue}
									/>
								);
							}
							default: {
								return null;
							}
						}
					})()}
				</form>
			</AccordionDetails>
		</Accordion>
	);
};
