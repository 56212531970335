import { Route } from 'react-router-dom';

import { PrivateRoute } from 'pages/_routes/_ui';

import type { IPageRoute, IPageRouteWithChildren } from 'pages/_routes/Pages.interfaces';
import type { ReactNode } from 'react';

export const getRoute = (path: IPageRoute['path'], component: IPageRoute['component'], permissions?: IPageRoute['permissions']) => {
	const route: IPageRoute = {
		path,
		component,
	};
	if (permissions !== undefined) route.permissions = permissions;
	return route;
};

export const getRouteWithChildren = (
	path: IPageRoute['path'],
	component: IPageRoute['component'],
	permissions: string[],
	childrenRoutes: IPageRoute[]
): IPageRouteWithChildren => {
	return {
		childrenRoutes,
		component,
		permissions,
		path,
	};
};

const getElementForRoute = (permissions: IPageRoute['permissions'], Component: IPageRoute['component']) => {
	let element = null;

	if (permissions === undefined) {
		element = <Component />;
	} else if (permissions === null) {
		element = (
			<PrivateRoute>
				<Component />
			</PrivateRoute>
		);
	} else {
		element = (
			<PrivateRoute permissions={permissions}>
				<Component />
			</PrivateRoute>
		);
	}
	return element;
};

export const renderSingleRoute = (route: IPageRoute) => {
	const { component, path, permissions } = route;

	const element = getElementForRoute(permissions, component);

	return (
		<Route
			path={path}
			element={element as ReactNode}
			key={path}
		/>
	);
};

export const renderRoute = (route: IPageRoute | IPageRouteWithChildren) => {
	const isWithChildren = 'childrenRoutes' in route;
	if (!isWithChildren) return renderSingleRoute(route);

	const { path, component, permissions, childrenRoutes } = route;
	const element = getElementForRoute(permissions, component);

	return (
		<Route
			path={path}
			element={element}
			key={path}
		>
			{childrenRoutes.map((child) => renderSingleRoute(child))}
		</Route>
	);
};
