import type { IBarType1 } from 'shared/ui/Editor/EditorBlockTools/BarsTool/BarsVariants/BarsType1/BarsType1.interfaces';

export enum EBarsVariants {
	NOT_SELECTED = 'not_selected',
	TYPE_1 = 'type_1',
}

export interface IBarsData {
	variant: EBarsVariants;
	max_row_count: number;
	style: string;
	bars: IBarType1[];
}
