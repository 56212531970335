import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const DownloadIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
	>
		<path
			fill='#fff'
			d='M11.958 15.125H4.042c-.846 0-1.641-.33-2.24-.928a3.146 3.146 0 0 1-.927-2.239v-.791a.792.792 0 1 1 1.583 0v.791c0 .423.165.82.464 1.12.3.299.697.464 1.12.464h7.916c.423 0 .82-.165 1.12-.464.299-.3.464-.697.464-1.12v-.791a.792.792 0 1 1 1.583 0v.791c0 .846-.33 1.641-.928 2.24a3.146 3.146 0 0 1-2.239.927ZM8 11.958a.79.79 0 0 1-.553-.225l-.002-.002-.001-.001h-.001l-.001-.002-.002-.002L4.274 8.56a.792.792 0 0 1 1.12-1.12l1.814 1.815V1.667a.792.792 0 1 1 1.584 0v7.588l1.815-1.815a.792.792 0 0 1 1.12 1.12L8.56 11.726l-.002.002v.001h-.002v.002h-.001l-.002.002a.788.788 0 0 1-.245.163h-.001a.788.788 0 0 1-.307.062Z'
		/>
	</svg>
);
export default DownloadIcon;
