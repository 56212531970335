export enum EEditorTools {
	ACCORDION = 'accordion',
	YANDEX_MAP = 'yandexMap',
	GOOGLE_MAP = 'googleMap',
	BUTTON = 'button',
	MEDIA = 'media',
	FILE = 'file',
	PDF = 'pdf',
	TABS = 'tabs',
	HEADER = 'header',
	PARAGRAPH = 'paragraph',
	TABLE = 'table',
	ANCHOR = 'anchor',
	NAV = 'nav',
	BARS = 'bars',
	LEAFLET_MAP = 'leafletMap',
	CARDS = 'cards',
	CONTAINER_TEMPLATES = 'containerTemplates',
	CHECKLIST = 'checklist',
	LIST = 'list',
	QUOTE = 'quote',
	DELIMITER = 'delimiter',
	LAYOUT = 'layout',
	TWO_COLUMNS = 'twoColumns',
	THREE_COLUMNS = 'threeColumns',
	FOUR_COLUMNS = 'fourColumns',
	COLOR = 'color',
	MARKER = 'marker',
	LATEST_MATERIAL = 'latest_material',
	SLIDER = 'slider',
	CONTAINER = 'container',
	FORM = 'form',
	SITEMAP = 'sitemap',
}
