import { EBarsVariants } from 'shared/ui/Editor/EditorBlockTools/BarsTool/BarsTool.interfaces';

import type { IBarsData } from 'shared/ui/Editor/EditorBlockTools/BarsTool/BarsTool.interfaces';

export const barsTooltip = {
	title: 'Плитки',
	icon:
		'<svg fill="#000000" width="800px" height="800px" viewBox="0 0 14 14" role="img" focusable="false" aria-hidden="true"\n' +
		'     xmlns="http://www.w3.org/2000/svg">\n' +
		'    <path style="fill-rule:evenodd"\n' +
		'          d="M 1,1 6.4,1 6.4,6.4 1,6.4 1,1 Z m 1.2,1.2 3,0 0,3 -3,0 0,-3 z M 1,7.6 6.4,7.6 6.4,13 1,13 1,7.6 Z m 1.2,1.2 3,0 0,3 -3,0 0,-3 z M 7.6,1 13,1 13,6.4 7.6,6.4 7.6,1 Z m 1.2,1.2 3,0 0,3 -3,0 0,-3 z m -1.2,5.4 5.4,0 0,5.4 -5.4,0 0,-5.4 z m 1.2,1.2 3,0 0,3 -3,0 0,-3 z"/>\n' +
		'</svg>',
};

const initialBarsData: IBarsData = {
	variant: EBarsVariants.TYPE_1,
	max_row_count: 3,
	bars: [],
	style: '',
};

export const getInitialBarsData = (params: { copyWith?: Partial<IBarsData> } = {}): IBarsData => {
	const { copyWith } = params;
	return {
		...initialBarsData,
		...copyWith,
	};
};

export const selectBarsOptions = [
	{
		value: EBarsVariants.NOT_SELECTED,
		label: 'Не выбрано',
	},
	{
		value: EBarsVariants.TYPE_1,
		label: 'Шаблон 1',
	},
];
