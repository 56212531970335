import { v4 as uuidv4 } from 'uuid';

import { cmsModal } from 'shared/ui/Editor/EditorBlockTools/Layout/itemContent/customModal';

// eslint-disable-next-line no-restricted-imports
import type { LayoutBlockToolConfig } from '../LayoutBlockTool.class';
import type { OutputData } from '@editorjs/editorjs';

const createDialog = ({
	EditorJS,
	data,
	editorJSConfig,
	onClose,
}: {
	EditorJS: LayoutBlockToolConfig['EditorJS'];
	data: OutputData;
	editorJSConfig: LayoutBlockToolConfig['editorJSConfig'];
	onClose?: (event: { editorJSData: OutputData }) => void;
}) => {
	const editorJSHolder = document.createElement('div');
	const editorJSHolderID = uuidv4();

	editorJSHolder.id = editorJSHolderID;

	const dialog = new cmsModal(editorJSHolder);

	const editorJS = new EditorJS({
		...editorJSConfig,
		holder: editorJSHolderID,
		data,
	});
	const handleDialogClick = async (event: MouseEvent) => {
		if (!(event.target instanceof Node) || !event.target.isEqualNode(dialog.wrapper)) {
			return;
		}
		dialog.wrapper.removeEventListener('click', handleDialogClick);
		const editorJSData = await editorJS.save();
		editorJS.destroy();
		dialog.closeModal();
		onClose?.({ editorJSData });
	};

	dialog.wrapper.addEventListener('click', handleDialogClick);

	return dialog;
};

export { createDialog };
