import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from '@store/core';
import { theme } from 'app/theme/theme';
import NewsTool from 'shared/ui/Editor/EditorBlockTools/NewsTool/NewsTool';
import { initialNewsData, toolboxParams } from 'shared/ui/Editor/EditorBlockTools/NewsTool/NewsTool.meta';

import type { API, BlockTool } from '@editorjs/editorjs';
import type { INewsComponentData } from 'shared/interfaces';
import type { INewsToolParams } from 'shared/ui/Editor/EditorBlockTools/NewsTool/NewsTool.interfaces';

export class CNewsTool implements BlockTool {
	data: INewsComponentData;

	api: API;

	constructor({ data, api }: INewsToolParams) {
		this.data = Object.keys(data).length ? data : initialNewsData;
		this.api = api;
	}

	static get toolbox() {
		return toolboxParams;
	}

	save() {
		return this.data;
	}

	render(): HTMLElement {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const onDataChangeHandler = (newData: INewsComponentData) => {
			this.data = newData;
		};

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		renderRoot.render(
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<NewsTool
						data={this.data}
						onDataChange={onDataChangeHandler}
						deleteBlock={deleteBlock}
						moveBlockUp={moveBlockUp}
						moveBlockDown={moveBlockDown}
					/>
				</Provider>
			</ThemeProvider>
		);

		return rootNode;
	}
}
