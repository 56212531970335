import { useCallback, useEffect, useRef, useState } from 'react';

export const useDebounce = (delay = 300) => {
	const timerRef = useRef<Undefinable<number>>();

	return useCallback(
		(cb: Function) => {
			clearTimeout(timerRef.current);

			timerRef.current = setTimeout(cb, delay);
		},
		[delay]
	);
};

export const useDebounceValue = <T>(initialValue: T, delay = 300) => {
	const [realValue, setRealValue] = useState(initialValue);
	const [debouncedValue, setDebouncedValue] = useState(initialValue);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(realValue);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [realValue, delay]);

	return { realValue, setRealValue, debouncedValue };
};
