import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store';
import { theme } from 'app/theme/theme';
import { DialogProvider } from 'shared/providers';
import { EMediaToolFileAlignment } from 'shared/ui/Editor/EditorBlockTools/MediaTool/MediaTool.interfaces';
import { mediaFormat, mediaToolBoxParams, mediaTuneOptions } from 'shared/ui/Editor/EditorBlockTools/MediaTool/MediaTool.meta';
import { pdfTuneOptions } from 'shared/ui/Editor/EditorBlockTools/PDFTool/PDFTool.meta';
import { EFilePreviewViewModes } from 'shared/ui/Editor/EditorBlockTools/_ui/FilePreview/FIlePreview.interfaces';
import FileTool from 'shared/ui/Editor/EditorBlockTools/_ui/FileTool/FileTool';
import { imageTypes, videoTypes } from 'shared/ui/FileUploader/FileUploader.meta';
import { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

import type { API } from '@editorjs/editorjs';
import type { BlockTool, BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools/block-tool';
import type { ToolConfig } from '@editorjs/editorjs/types/tools/tool-config';
import type { IMediaToolData } from 'shared/ui/Editor/EditorBlockTools/MediaTool/MediaTool.interfaces';

class CMediaTool implements BlockTool {
	api: API;

	readOnly: boolean;

	data: IMediaToolData;

	renderNodes: {
		holder: Nullable<HTMLElement>;
		settings: Nullable<HTMLElement>;
	};

	config: ToolConfig;

	static get isReadOnlySupported() {
		return true;
	}

	static get toolbox() {
		return mediaToolBoxParams;
	}

	constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<IMediaToolData>) {
		this.api = api;
		this.readOnly = readOnly;
		this.data = {
			media: data.media || null,
			viewMode: data.viewMode || EFilePreviewViewModes.PREVIEW,
			captureText: data.captureText || '',
			style: data.style || null,
			withBorder: data.withBorder || false,
			withBackground: data.withBackground || false,
			stretched: data.stretched || false,
			alignment: data.alignment || EMediaToolFileAlignment.EMPTY,
		};

		this.config = config;

		this.renderNodes = {
			holder: null,
			settings: null,
		};
	}

	render() {
		const rootNode = document.createElement('div');
		this.renderNodes.holder = rootNode;

		const handleChange = (newData: IMediaToolData) => {
			this.data = {
				...newData,
			};
		};

		const deleteEmptyBlock = () => {
			if (!this.data.media?.url) {
				const currentBlockIndex = this.api.blocks.getCurrentBlockIndex() - 1;
				if (currentBlockIndex < 0) return;
				this.api.blocks.delete(currentBlockIndex);
			}
		};
		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		const renderRoot = createRoot(rootNode);
		renderRoot.render(
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<DialogProvider>
							<FileTool
								variant={ESelectFileVariant.MEDIA}
								handleChange={handleChange}
								deleteBlock={deleteBlock}
								moveBlockDown={moveBlockDown}
								tunesOptions={[...pdfTuneOptions, ...mediaTuneOptions]}
								moveBlockUp={moveBlockUp}
								data={this.data}
								deleteEmptyBlock={deleteEmptyBlock}
								accept={mediaFormat}
								mediaTypes={[...imageTypes, ...videoTypes]}
							/>
						</DialogProvider>
					</Provider>
				</ThemeProvider>
			</BrowserRouter>
		);

		return this.renderNodes.holder;
	}

	save() {
		return this.data;
	}
}

export default CMediaTool;
