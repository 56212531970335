import { useEffect, useRef } from 'react';
import videojs from 'video.js';

import { videoJsConfig } from 'shared/ui/VideoPlayer/VideoPlayer.lib';

import 'video.js/dist/video-js.css';
import PlayerSettings from './_ui/PlayerSettings/PlayerSettings.class';

import type { FC, MutableRefObject } from 'react';
import type Player from 'video.js/dist/types/player';
import type { QualityLevelList } from 'videojs-contrib-quality-levels';

interface IProps {
	videoSrc: string;
}

const VideoPlayer: FC<IProps> = ({ videoSrc }) => {
	const playerRef = useRef() as MutableRefObject<HTMLVideoElement>;
	const videoJsRef = useRef<Player>();

	useEffect(() => {
		if (!videoJsRef.current) {
			const videoJSPlayer = videojs(playerRef.current, videoJsConfig);
			videoJSPlayer.src({
				src: videoSrc,
				type: 'application/x-mpegURL',
				// withCredentials: true
			});
			// @ts-ignore
			const qualityLevels: QualityLevelList = videoJSPlayer.qualityLevels();

			const settings = new PlayerSettings(videoJSPlayer, {
				qualityLevels,
			});

			// @ts-ignore
			videoJSPlayer.getChild('ControlBar')?.addChild(settings, {}, 12);

			videoJsRef.current = videoJSPlayer;
		}
	}, [videoSrc]);

	return (
		<>
			<video
				className='video-js'
				ref={playerRef}
			/>
		</>
	);
};

export default VideoPlayer;
