import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';

import { SelectInput, TextInput } from 'shared/ui';

import { ECardsVariants } from './CardsTool.interfaces';
import { getInitialCardsData, selectCardsOptions } from './CardsTool.lib';
import {
	CardsType1,
	CardsType2,
	CardsType3,
	CardsType4,
	CardsType5,
	CardsType6,
	CardsType7,
	CardsType8,
	CardsType9,
	CardsType10,
	CardsType11,
	CardsType12,
	CardsType13,
	CardsType14,
} from './CardsVariants';

import type { ICardsData, ICardsData12, ICardsData13 } from './CardsTool.interfaces';

interface IProps {
	data: ICardsData;
	handleDataChange: (newData: ICardsData) => void;
}

export const CardsTool = ({ data: initialData, handleDataChange }: IProps) => {
	const formik = useFormik({
		initialValues: initialData,
		onSubmit: () => {},
	});

	useEffect(() => {
		handleDataChange(formik.values);
	}, [formik.values, handleDataChange]);

	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>
				Инструмент {'"'}Карточки{'"'}
			</AccordionSummary>
			<AccordionDetails>
				<form className='flex flex-col gap-m'>
					<SelectInput
						value={formik.values.variant}
						onChange={(e) => {
							formik.setValues(
								getInitialCardsData({
									copyWith: { variant: e.target.value as ECardsVariants },
								})
							);
						}}
						label='Вид инструмента Карточки'
					>
						{selectCardsOptions.map(({ value: optionValue, label }) => (
							<MenuItem
								key={optionValue}
								value={optionValue}
							>
								{label}
							</MenuItem>
						))}
					</SelectInput>

					<TextInput
						{...formik.getFieldProps('style')}
						label='Стиль инструмента Карточки'
						placeholder='Стиль карточек'
					/>
					<TextInput
						{...formik.getFieldProps('max_row_count')}
						type='number'
						label='Максимальное кол-во карточек в строке'
						placeholder='Введите количество'
					/>
					<TextInput
						{...formik.getFieldProps('cards_title')}
						label='Заголовок карточек'
						placeholder='Введите заголовок для карточек'
					/>
					{(() => {
						switch (formik.values.variant) {
							case ECardsVariants.TYPE_1: {
								return (
									<CardsType1
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_2: {
								return (
									<CardsType2
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_3: {
								return (
									<CardsType3
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_4: {
								return (
									<CardsType4
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_5: {
								return (
									<CardsType5
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_6: {
								return (
									<CardsType6
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_7: {
								return (
									<CardsType7
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_8: {
								return (
									<CardsType8
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_9: {
								return (
									<CardsType9
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_10: {
								return (
									<CardsType10
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_11: {
								return (
									<CardsType11
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_12: {
								return (
									<CardsType12
										data={formik.values as ICardsData12}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_13: {
								return (
									<CardsType13
										data={formik.values as ICardsData13}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							case ECardsVariants.TYPE_14: {
								return (
									<CardsType14
										data={formik.values}
										setFieldValue={formik.setFieldValue}
										getFieldProps={formik.getFieldProps}
									/>
								);
							}
							default: {
								return null;
							}
						}
					})()}
				</form>
			</AccordionDetails>
		</Accordion>
	);
};
