import videojs from 'video.js';

export const videoJsConfig = {
	muted: true,
	controls: true,
	fluid: true,
	autoplay: false,
	preload: 'auto',
	// poster: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg',
	// aspectRatio: '16:9',
	responsive: true,
	playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2, 3, 4],
	controlBar: {
		playToggle: {
			replay: true,
		},
	},
	html5: {
		vhs: {
			overrideNative: !videojs.browser.IS_SAFARI,
			limitRenditionByPlayerDimensions: false,
			allowSeeksWithinUnsafeLiveWindow: true,
			smoothQualityChange: false,
		},
		nativeAudioTracks: false,
		nativeVideoTracks: false,
	},
};

window.HELP_IMPROVE_VIDEOJS = false;
