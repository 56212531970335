interface IObserverApi {
	isRefreshingPending: boolean;
	refreshSubscribers: Array<any>;
	subscribeToRefresh: (cb: any) => void;
	notifyRefreshed: VoidFunction;
}

const observerApi: IObserverApi = {
	isRefreshingPending: false,

	refreshSubscribers: [],

	subscribeToRefresh: (callback: any) => {
		observerApi.refreshSubscribers.push(callback);
	},

	notifyRefreshed: () => {
		observerApi.refreshSubscribers.forEach((callback) => callback());
		observerApi.refreshSubscribers = [];
	},
};

export { observerApi as apiCallbacksObserver };
