import { createSelector } from '@reduxjs/toolkit';

import type { IAuthSlice } from './auth.interfaces';
import type { RootState } from '@store/interfaces';

export const authStateSelector = createSelector(
	(state: RootState) => state,
	(state): IAuthSlice => state.auth
);

export const isAuthSelector = createSelector(authStateSelector, (auth) => !!auth.currUser);

export const isAuthLoadingSelector = createSelector(authStateSelector, (auth) => auth.isLoading);

export const currentUserSelector = createSelector(authStateSelector, (auth) => auth.currUser);

export const currentUserPermissionsSelector = createSelector(
	authStateSelector,
	(auth) => auth.currUser?.role?.permissions
);
