import { layerGroup, polyline } from 'leaflet';
import { useEffect, useRef } from 'react';

import {
	InternalLinesZoomMaxLimit, InternalLinesZoomMinLimit,
} from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.constants';

import type { LayerGroup, Map as LMap} from 'leaflet';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	data: ILeafletMapData['lines']['internal'];
	map: LMap;
	zoom: number;
}

export const Internal = ({ data, zoom, map }: IProps) => {

	const groupRef = useRef<LayerGroup>();

	useEffect(() => {
		if (map) {
			if (groupRef.current) {
				groupRef.current?.remove()
				groupRef.current = undefined
			}
			const createdLinesGroup = data.reduce((linesLayerGroup, line) => {
				const createdLine = polyline(line.coordinates, {})
				createdLine.addTo(linesLayerGroup)
				return linesLayerGroup
			}, layerGroup())

			groupRef.current = createdLinesGroup
			if (zoom > InternalLinesZoomMinLimit && zoom < InternalLinesZoomMaxLimit) {
				createdLinesGroup.addTo(map)
			}
		}

	}, [data, map]);

	useEffect(() => {
		const group = groupRef.current;
		if (map && group) {
			if (zoom > InternalLinesZoomMinLimit && zoom < InternalLinesZoomMaxLimit) group.addTo(map);
			else group.remove();
		}
	}, [map, zoom]);

	return null;
};