import EditorJS from '@editorjs/editorjs';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { stopPropagationHandler } from 'shared/lib/helpers/editorJsEvent';
import { getEditorConfigs } from 'shared/ui/Editor/Editor.lib';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { TextInput } from 'shared/ui/TextInput';

import type { FC, MutableRefObject } from 'react';
import type { IAccordionData, IAccordionItemProps } from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool.interfaces';

const AccordionItem: FC<IAccordionItemProps> = ({ item, index, pasteBlock }) => {
	const accordionRef = useRef<EditorJS | null>(null);

	const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

	const { values, setFieldValue } = useFormikContext<IAccordionData>();

	useEffect(() => {
		if (accordionRef.current) return;

		containerRef.current?.addEventListener('keydown', stopPropagationHandler);

		const config = getEditorConfigs();
		config.holder = String(item.id);
		config.minHeight = 100;
		accordionRef.current = new EditorJS({
			...config,
			data: item.data,
			autofocus: true,
			onChange: async (api) => {
				const newData = await api.saver.save();
				setFieldValue(`items.${index}.data`, newData);
			},
		});
		return () => {
			if (accordionRef.current && accordionRef.current?.destroy) accordionRef.current?.destroy();
			// eslint-disable-next-line react-hooks/exhaustive-deps
			containerRef.current?.removeEventListener('keydown', stopPropagationHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUp = useCallback(
		(id: number | string) => {
			setFieldValue('items', swapToTopArrayItems(id, values.items));
		},
		[setFieldValue, values.items]
	);
	const handleDown = useCallback(
		(id: number | string) => {
			setFieldValue('items', swapToBottomArrayItems(id, values.items));
		},
		[setFieldValue, values.items]
	);
	const handleClose = useCallback(
		(id: number | string) => {
			setFieldValue(
				'items',
				values.items.filter((slide) => slide.id !== id)
			);
		},
		[setFieldValue, values.items]
	);

	return (
		<div
			className='flex flex-col gap-4'
			ref={containerRef}
		>
			<Field
				name={`items.${index}.title`}
				as={TextInput}
				placeholder='Введите заголовок аккордеона'
				label='Заголовок'
			/>
			<div
				className='rounded bg-white p-4'
				id={String(item.id)}
			/>
			<SubTuner
				tuneItem={item.id}
				handleUp={handleUp}
				handleDown={handleDown}
				handleClose={handleClose}
				pasteBlock={pasteBlock}
				index={index}
			/>
		</div>
	);
};

export default AccordionItem;
