import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const Restore: FC<ISVGProps> = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		className={className}
	>
		<path d='M14 4.025v2.137a.75.75 0 0 1-.75.75h-2.137a.75.75 0 0 1 0-1.5h.573A4.472 4.472 0 0 0 8 3.5a4.475 4.475 0 0 0-4.192 2.86.75.75 0 0 1-1.397-.546A5.99 5.99 0 0 1 4.579 3.07 5.965 5.965 0 0 1 8 2c1.23 0 2.414.37 3.42 1.07.401.278.763.6 1.08.96v-.005a.75.75 0 0 1 1.5 0Zm-.836 5.19a.75.75 0 0 0-.972.424A4.475 4.475 0 0 1 8 12.5c-1.468 0-2.8-.695-3.632-1.838h.52a.75.75 0 0 0 0-1.5H2.75a.75.75 0 0 0-.75.75v2.138a.75.75 0 0 0 1.5 0v-.08c.317.36.679.681 1.08.96C5.585 13.63 6.77 14 8 14s2.414-.37 3.42-1.07a5.991 5.991 0 0 0 2.169-2.744.75.75 0 0 0-.425-.972Z' />
	</svg>
);

export default Restore;
