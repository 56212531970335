import { useToggle } from 'shared/hooks';

export const useModal = (initialValue?: boolean) => {
	const toggle = useToggle(initialValue);

	return {
		isModalOpen: toggle.isOn,
		toggleModalOpen: toggle.toggle,
		openModal: toggle.setOn,
		closeModal: toggle.setOff,
		serIsModalOpen: toggle.setIsOn,
	};
};
