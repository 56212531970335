import { initialMapCenter } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.constants';

import type {
	ILLine,
	ILatLng,
} from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

export const getBlankLine = (): ILLine => ({
	id: crypto.randomUUID(),
	name: '',
	coordinates: [
		{ id: crypto.randomUUID(), lng: initialMapCenter.lng - 1, lat: initialMapCenter.lat - 1 },
		{ id: crypto.randomUUID(), lng: initialMapCenter.lng + 1, lat: initialMapCenter.lat + 1 },
	],
});

export const getBlankLinePoint = (): ILatLng => ({
	id: crypto.randomUUID(),
	lng: initialMapCenter.lng,
	lat: initialMapCenter.lat,
});

