import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const UlMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			stroke='#000'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M9 17h10M9 12h10M9 7h10M5.002 17v.002H5V17h.002Zm0-5v.002H5V12h.002Zm0-5v.002H5V7h.002Z'
		/>
	</svg>
);
export default UlMenuIcon;
