import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store';
import { theme } from 'app/theme/theme';
import TabTool from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool';
import { generateTabItem } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.lib';
import { toolboxParams } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.meta';

import type { API } from '@editorjs/editorjs';
import type { BlockTool } from '@editorjs/editorjs/types/tools/block-tool';
import type { ITabToolParams, ITabData } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.interfaces';

export class CTabTool implements BlockTool {
	data: ITabData;

	readonly: boolean;

	api: API;

	constructor({ data, api, readOnly }: ITabToolParams) {
		const blankItem = generateTabItem(Object.keys(data).length ? Object.keys(data.items).length : 0);

		if (Object.keys(data).length) {
			if (Array.isArray(data.items)) {
				this.data = {
					...data,
					items: data.items.reduce((acc, item, index) => {
						acc[item.id] = { ...item, order: index + 1 };
						return acc;
					}, {} as ITabData['items']),
				};
			} else {
				this.data = data as ITabData;
			}
		} else {
			this.data = { items: { [blankItem.id]: blankItem }, style: '', headline: '' };
		}

		this.api = api;
		this.readonly = readOnly;
	}

	save() {
		return this.data;
	}

	static get toolbox() {
		return toolboxParams;
	}

	static get isReadOnlySupported() {
		return true;
	}

	render(): HTMLElement {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const onDataChange = (data: any) => {
			this.data = data;
		};
		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		renderRoot.render(
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<TabTool
							handleChange={onDataChange}
							data={this.data}
							deleteBlock={deleteBlock}
							moveBlockUp={moveBlockUp}
							moveBlockDown={moveBlockDown}
						/>
					</Provider>
				</ThemeProvider>
			</BrowserRouter>
		);

		return rootNode;
	}
}
