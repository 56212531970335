import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useState } from 'react';

import { ReactComponent as VisibilityIcon } from 'shared/assets/svgs/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from 'shared/assets/svgs/visibilityOff.svg';

import './PaswordInput.scss';

import type { OutlinedInputProps } from '@mui/material';
import type { FC, ReactNode, MouseEvent } from 'react';

interface Props extends OutlinedInputProps {
	helper: ReactNode;
	fullWidth?: boolean;
}

const PasswordInput: FC<Props> = ({ helper, label = '', name, className, error, fullWidth, ...restProps }) => {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const visibilityBtnTitle = `Toggle ${name} visibility`;
	const isError = error !== undefined ? error : !!helper;

	return (
		<div
			data-id='PasswordInputContainer'
			className={'passwordInputWrapper'}
		>
			<FormControl
				variant='outlined'
				className={className}
				fullWidth={fullWidth}
			>
				<InputLabel
					htmlFor={name}
					error={isError}
					classes={{
						root: 'text-gray',
					}}
					shrink
				>
					{label}
				</InputLabel>

				<OutlinedInput
					{...restProps}
					notched
					error={isError}
					id={name}
					classes={{
						input: 'px-5 py-3',
					}}
					label={label}
					type={showPassword ? 'text' : 'password'}
					endAdornment={
						<InputAdornment position='end'>
							<IconButton
								data-id='VisibilityButton'
								title={visibilityBtnTitle}
								aria-label={visibilityBtnTitle}
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge='end'
							>
								<div className='mr-[0.5rem]'>{!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</div>
							</IconButton>
						</InputAdornment>
					}
				/>

				<FormHelperText
					id={name}
					error={isError}
				>
					{helper}
				</FormHelperText>
			</FormControl>
		</div>
	);
};

export default PasswordInput;
