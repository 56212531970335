import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material';
import { useCallback } from 'react';

import { CloseFileIcon } from '@assets/svgs';
import ArrowDown from '@assets/svgs/ArrowDown';
import ArrowUp from '@assets/svgs/ArrowUp';
import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { Button, TextInput } from 'shared/ui';
import { getBlankLinePoint } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LinesControl/LinesControl.lib';

import type { FieldInputProps } from 'formik/dist/types';
import type { ILLine } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';



interface IProps {
	lineData: ILLine;
	index: number;
	getFieldProps: (propName: string) => FieldInputProps<string> | FieldInputProps<number>;
	setFieldValue: (propName: string, newValue: any) => void;
	handleDeleteLine: (id: ILLine['id']) => void;
}

export const InternalLineCard = ({ lineData, handleDeleteLine, index, getFieldProps, setFieldValue }: IProps) => {
	const handleLinePointUp = useCallback(
		(id: number | string) => {
			setFieldValue(`lines.internal.${index}.coordinates`, swapToTopArrayItems(id, lineData.coordinates) ?? lineData.coordinates);
		},
		// eslint-disable-next-line
		[lineData, index],
	);

	const handleLinePointDown = useCallback(
		(id: number | string) => {
			setFieldValue(`lines.internal.${index}.coordinates`, swapToBottomArrayItems(id, lineData.coordinates) ?? lineData.coordinates);
		},
		// eslint-disable-next-line
		[lineData, index],
	);
	const handleLinePointClose = useCallback(
		(id: number | string) => {
			setFieldValue(`lines.internal.${index}.coordinates`, lineData.coordinates.filter(coord => coord.id !== id));
		},
		// eslint-disable-next-line
		[lineData, index],
	);
	return (
		<Accordion>
			<AccordionSummary>
				<div className='flex w-full items-center justify-between'>
					<div>Внутренняя линия №{index + 1}</div>
					<IconButton onClick={() => handleDeleteLine(lineData.id)}>
						<CloseIcon />
					</IconButton>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<TextInput
						{...getFieldProps(`lines.internal.${index}.name`)}
						label='Имя внутренней линии'
						placeholder='Введите имя внутренней линии'
					/>
					<div className='flex flex-col gap-m'>
						<div>Точки:</div>
						{lineData.coordinates.map((coord, coordIndex) => (
							<div key={coord.id} className='flex gap-m relative w-full items-center'>
								<div className='min-w-[80px]'>Точка №{coordIndex + 1}</div>
								<TextInput
									{...getFieldProps(`lines.internal.${index}.coordinates.${coordIndex}.lat`)}
									label='Широта'
									type='number'
									placeholder='Введите широту точки'
								/>
								<TextInput
									{...getFieldProps(`lines.internal.${index}.coordinates.${coordIndex}.lng`)}
									label='Долгота'
									type='number'
									placeholder='Введите долготу точки'
								/>
								<div className='flex gap-s'>
									<div className='flex flex-col gap-1 px-2'>
										<div
											onClick={() => handleLinePointUp(coord.id)}
											className='hover:cursor-pointer'
										>
											<ArrowUp />
										</div>
										<div
											onClick={() => handleLinePointDown(coord.id)}
											className='hover:cursor-pointer'
										>
											<ArrowDown className='!rotate-90' />
										</div>
									</div>
									<div
										onClick={() => handleLinePointClose(coord.id)}
										className='px-1 hover:cursor-pointer'
									>
										<CloseFileIcon />
									</div>
								</div>
							</div>

						))}
						<Button
							variant='outlined'
							onClick={() => {
								setFieldValue(`lines.internal.${index}.coordinates`, [...lineData.coordinates, getBlankLinePoint()]);
							}}
						>
							<div className='flex gap-m'>
								<AddIcon />
								<div>Добавить точку линии</div>
							</div>
						</Button>
					</div>

				</div>
			</AccordionDetails>
		</Accordion>
	);
};
