import { v4 as uuidv4 } from 'uuid';

export const changeBlocksId = (block: any) => {
	for (const blockKey in block) {
		const isArray = Array.isArray(block[blockKey]);
		const isObject = typeof block[blockKey] === 'object' && !isArray && !!block[blockKey];

		if (isObject) {
			block.id = uuidv4();
			changeBlocksId(block[blockKey]);
		}
		if (isArray) {
			for (const itemElement of block[blockKey]) {
				changeBlocksId(itemElement);
			}
		}
	}

	return block;
};
