import { Button, Tab, Tabs, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useUploadMediaMutation } from 'shared/api/mediaApi';
import { useDialog } from 'shared/hooks';
import { EMediaCollectionName } from 'shared/lib/constants/collectionName';
import { processRequestResult } from 'shared/lib/helpers';
import { ModalWindow, TabPanel } from 'shared/ui';
import { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';
import MediaTab from 'shared/ui/SelectMediaModal/ui/MediaTab';
import UploaderTab from 'shared/ui/SelectMediaModal/ui/UploaderTab';

import type { AxiosProgressEvent } from 'axios';
import type { FC } from 'react';
import type { IMediaFile } from 'shared/interfaces';
import type { IFileUploadError } from 'shared/interfaces/fileMediaError';
import type { TransformedFile } from 'shared/ui';
import type { IProgress } from 'shared/ui/FileUploadModal/FileUploadModal';
import type { ISelectMediaModalProps } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

const SelectMediaModal: FC<ISelectMediaModalProps> = ({
	onSelectFile,
	isOpen,
	onClose,
	variant,
	mediaTypes,
	accept,
	maxFileSizeForUploadMB,
	collectionNameForUpload = EMediaCollectionName.Media,
	withWatermark = true,
}) => {
	const [currentTab, setCurrentTab] = useState(0);
	const [isValidFileSize, setIsValidFileSize] = useState(false);
	const [error, setError] = useState('');
	const [progress, setProgress] = useState<IProgress>({});

	const [upload, { isLoading }] = useUploadMediaMutation();

	const [selectedMedia, setSelectedMedia] = useState<Map<IMediaFile['id'], IMediaFile>>(new Map());
	const [files, setFiles] = useState<TransformedFile[]>([]);
	const controllerRef = useRef<Nullable<AbortController>>(null);

	const { dialog, closeDialogModal } = useDialog();

	useEffect(() => {
		setError('');
	}, [files]);

	const onTabChange = useCallback((_: any, value: number) => {
		setCurrentTab(value);
	}, []);

	const isMediaTab = currentTab === 0;

	const onModalCloseHandler = () => {
		if (isLoading) {
			dialog({
				title: 'Вы уверены, что хотите закрыть модальное окно загрузки? ',
				body: <div>В этом случае медиафайлы не будут загружены!</div>,
				isCancelBtn: true,
				buttons: [
					<Button
						data-id='CloseButton'
						onClick={() => {
							onClose();
							controllerRef.current?.abort();
							setFiles([]);
						}}
						color='error'
						key={'error1'}
					>
						Закрыть
					</Button>,
				],
			});
		} else {
			onClose();
		}
	};

	const onUploadProgress = (e: AxiosProgressEvent, id: string) => {
		if (e.progress) {
			setProgress({
				[id]: {
					id,
					isError: false,
					isLoading: e.progress !== 1,
					isLoaded: e.progress === 1,
					progress: Math.round(e.progress! * 100),
				},
			});
		}
	};
	const onCloseTabHandler = (e: BeforeUnloadEvent) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const onSelectBtnClick = async () => {
		if (isMediaTab) {
			onSelectFile(Array.from(selectedMedia)[0][1]);
			onClose();
			return;
		}

		const filesDto = new FormData();
		filesDto.append('collection_name', collectionNameForUpload);

		files.forEach((file) => {
			filesDto.append('file', file.originalFile);
			filesDto.append('is_watermark', String(file.isWatermark));
		});

		const initialProgress = {
			[files[0].id]: {
				id: files[0].id,
				isError: false,
				isLoaded: false,
				isLoading: false,
				progress: 0,
			},
		};

		setProgress(initialProgress);

		controllerRef.current = new AbortController();
		window.addEventListener('beforeunload', onCloseTabHandler);
		const result = await upload({
			dto: filesDto,
			onUploadProgress: (e) => onUploadProgress(e, files[0].id),
			controller: controllerRef.current.signal,
		});

		window.removeEventListener('beforeunload', onCloseTabHandler);
		processRequestResult(result, {
			onSuccess: (data) => {
				closeDialogModal();
				onSelectFile(data);
				onClose();
			},
			onError: (_, errors) => {
				if ('file' in errors) {
					const err = errors.file as IFileUploadError['file'];
					setError(err[0]);
				}
			},
		});
	};

	const isSelectBtnDisabled = isMediaTab ? !selectedMedia.size : !files.length || !isValidFileSize || isLoading;

	return (
		<ModalWindow
			isOpened={isOpen}
			handleClose={() => onModalCloseHandler()}
		>
			<div className='flex flex-col'>
				<div className='flex'>
					<Tabs
						TabIndicatorProps={{ className: 'left-0' }}
						orientation='vertical'
						value={currentTab}
						onChange={onTabChange}
					>
						<Tab
							data-id='MediaTab'
							label='Медиа библиотека'
						/>
						<Tab
							data-id='DiscTab'
							label='Загрузить с диска'
						/>
					</Tabs>
					<div className='flex-grow'>
						<TabPanel
							index={0}
							value={currentTab}
						>
							<MediaTab
								variant={variant === ESelectFileVariant.OTHERS ? undefined : variant}
								selectedMedia={selectedMedia}
								setSelectedMedia={setSelectedMedia}
								collectionNameParam={collectionNameForUpload}
								withWatermark={withWatermark}
							/>
						</TabPanel>
						<TabPanel
							index={1}
							value={currentTab}
						>
							<UploaderTab
								isLoading={isLoading}
								progress={progress}
								files={files}
								isValidSize={isValidFileSize}
								setIsValidSize={setIsValidFileSize}
								setFiles={setFiles}
								accept={accept}
								maxFileSizeForUploadMB={maxFileSizeForUploadMB}
								mediaTypes={mediaTypes}
								withWatermark={withWatermark}
							/>
						</TabPanel>
					</div>
				</div>
				{error && (
					<Typography
						color='error'
						align='center'
					>
						{error}
					</Typography>
				)}

				<div className='mt-6 flex justify-center'>
					<Button
						data-id='LoadButton'
						variant='contained'
						disabled={isSelectBtnDisabled}
						onClick={onSelectBtnClick}
					>
						{isMediaTab ? 'Выбрать' : 'Загрузить'}
					</Button>
				</div>
			</div>
		</ModalWindow>
	);
};

export default SelectMediaModal;
