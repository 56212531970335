export enum EStatus {
	Published = 'published',
	NotPublished = 'not_published',
	InBasket = 'in_basket',
	Archived = 'archived',
}

type IStatuses = {
	[key in EStatus]: { label: string };
};

type IStatusesWithoutArchive = Omit<IStatuses, `${EStatus.Archived}`>;
type IStatusesWithoutBasket = Omit<IStatuses, `${EStatus.InBasket}`>;
type IStatusesPublishAndUnPublish = Omit<IStatusesWithoutArchive, `${EStatus.InBasket}`>;

export const Statuses: IStatuses = {
	[EStatus.Published]: { label: 'Опубликовано' },
	[EStatus.Archived]: { label: 'В архиве' },
	[EStatus.InBasket]: { label: 'В корзине' },
	[EStatus.NotPublished]: { label: 'Не опубликовано' },
};

export const StatusesWithoutArchive: IStatusesWithoutArchive = {
	[EStatus.Published]: { label: 'Опубликовано' },
	[EStatus.NotPublished]: { label: 'Не опубликовано' },
	[EStatus.InBasket]: { label: 'В корзине' },
};

export const StatusesPublishAndUnPublish: IStatusesPublishAndUnPublish = {
	[EStatus.Published]: { label: 'Опубликовано' },
	[EStatus.NotPublished]: { label: 'Не опубликовано' },
};

export const StatuesWithoutBasket: IStatusesWithoutBasket = {
	[EStatus.Published]: { label: 'Опубликовано' },
	[EStatus.NotPublished]: { label: 'Не опубликовано' },
	[EStatus.Archived]: { label: 'В архиве' },
};

export enum EMaterialTypeStatuses {
	ACTIVE = 'active',
	IN_BASKET = 'in_basket',
}

export enum EFormTypeStatuses {
	ACTIVE = 'active',
	IN_BASKET = 'in_basket',
}
