import { Formik } from 'formik';

import { FormToolForm } from './ui';

import type { IFormToolProps } from './FormTool.interface';

const FormTool = ({ data, deleteBlock, moveBlockDown, moveBlockUp, onDataChange }: IFormToolProps) => {
	return (
		<div>
			<Formik
				initialValues={data}
				onSubmit={(values) => {
					onDataChange(values);
				}}
			>
				{() => (
					<FormToolForm
						onDataChange={onDataChange}
						deleteBlock={deleteBlock}
						moveBlockDown={moveBlockDown}
						moveBlockUp={moveBlockUp}
					/>
				)}
			</Formik>
		</div>
	);
};

export default FormTool;
