import { Add as AddIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useCallback } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { extractIframeSrc } from 'shared/lib/helpers/extractFromStringHelpers';
import { Button, TextInput } from 'shared/ui';
import { SingleSocial } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType6/SingleSocial';
import { MapFrame, SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType6 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType6/CardsType6.interfaces';

interface IProps {
	cardData: ICardType6;
	index: number;
	handleChangeCardData: (newData: ICardType6) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType6 = ({ cardData, index, handleChangeCardData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	const isUrlControlsDisabled = !!cardData.yandexUrl?.trim();
	const onUploadClickHandler = () => {
		const extractedUrl = extractIframeSrc(cardData.yandexCode);
		if (!extractedUrl) {
			return;
		}

		handleChangeCardData({ ...cardData, yandexUrl: extractedUrl });
	};

	const onDeleteMap = () => {
		handleChangeCardData({
			...cardData,
			yandexUrl: '',
			yandexCode: '',
		});
	};

	const handleCardContactUp = useCallback(
		(id: number | string) => {
			handleChangeCardData({ ...cardData, socials: swapToTopArrayItems(id, cardData.socials) ?? cardData.socials });
		},
		[handleChangeCardData, cardData]
	);
	const handleCardContactDown = useCallback(
		(id: number | string) => {
			handleChangeCardData({
				...cardData,
				socials: swapToBottomArrayItems(id, cardData.socials) ?? cardData.socials,
			});
		},
		[handleChangeCardData, cardData]
	);
	const handleCardContactClose = useCallback(
		(id: number | string) => {
			handleChangeCardData({
				...cardData,
				socials: cardData.socials.filter((card) => {
					return card.id !== id;
				}),
			});
		},
		[handleChangeCardData, cardData]
	);

	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<div>Карта:</div>
					<div className='flex items-center justify-between gap-4'>
						<TextInput
							value={getFieldProps(`cards.${index}.yandexCode`).value}
							onChange={(event) => {
								handleChangeCardData({ ...cardData, yandexCode: event.target.value });
							}}
							placeholder='Введите код карты'
							rows={6}
							disabled={isUrlControlsDisabled}
							label='Код карты'
						/>
						<Button
							variant='outlined'
							classes={{
								root: 'p-[22px]',
							}}
							disabled={isUrlControlsDisabled}
							onClick={onUploadClickHandler}
						>
							Загрузить
						</Button>
					</div>

					<MapFrame
						url={cardData.yandexUrl}
						onDeleteMap={onDeleteMap}
					/>

					<div className='flex w-full flex-col gap-m '>
						<TextInput
							{...getFieldProps(`cards.${index}.style`)}
							label='Стиль'
							placeholder='Введите стиль'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.title`)}
							label='Заголовок'
							placeholder='Введите текст заголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle_1`)}
							label='Подзаголовок №1'
							placeholder='Введите текст подзаголовка №1'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle_2`)}
							label='Подзаголовок №2'
							placeholder='Введите текст подзаголовка №2'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle_3`)}
							label='Подзаголовок №3'
							placeholder='Введите текст подзаголовка №3'
						/>
						<div className='flex w-full items-center  gap-m'>
							<div className='flex flex-col'>
								<div className=''>Иконка описания №1</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										handleChangeCardData({ ...cardData, description_1_icon: newFile });
									}}
									handleDeleteImage={() => handleChangeCardData({ ...cardData, description_1_icon: null })}
									mediaItem={cardData.description_1_icon}
								/>
							</div>
							<TextInput
								{...getFieldProps(`cards.${index}.description_1`)}
								label='Описание №1'
								rows={3}
								placeholder='Введите текст описания №1'
							/>
						</div>
						<div className='flex w-full items-center gap-m'>
							<div className='flex flex-col'>
								<div className=''>Иконка описания №2</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										handleChangeCardData({ ...cardData, description_2_icon: newFile });
									}}
									handleDeleteImage={() => handleChangeCardData({ ...cardData, description_2_icon: null })}
									mediaItem={cardData.description_2_icon}
								/>
							</div>
							<TextInput
								{...getFieldProps(`cards.${index}.description_2`)}
								label='Описание №2'
								rows={3}
								placeholder='Введите текст описания №2'
							/>
						</div>
						<div className='flex w-full items-center  gap-m'>
							<div className='flex flex-col'>
								<div className=''>Иконка описания №3</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										handleChangeCardData({ ...cardData, description_3_icon: newFile });
									}}
									handleDeleteImage={() => handleChangeCardData({ ...cardData, description_3_icon: null })}
									mediaItem={cardData.description_3_icon}
								/>
							</div>
							<TextInput
								{...getFieldProps(`cards.${index}.description_3`)}
								label='Описание №3'
								rows={3}
								placeholder='Введите текст описания №3'
							/>
						</div>
					</div>

					<div>Социальные сети:</div>
					<div className='flex flex-col gap-m'>
						{cardData.socials.map((social, socialIndex) => (
							<SingleSocial
								key={social.id}
								parentIndex={index}
								index={socialIndex}
								getFieldProps={getFieldProps}
								cardSocialData={social}
								handleChangeCardSocialData={(newContactData) => {
									handleChangeCardData({
										...cardData,
										socials: cardData.socials.map((item) => (item.id === newContactData.id ? { ...item, ...newContactData } : item)),
									});
								}}
								handleClose={handleCardContactClose}
								handleUp={handleCardContactUp}
								handleDown={handleCardContactDown}
							/>
						))}
						<Button
							variant='outlined'
							onClick={() => {
								handleChangeCardData({
									...cardData,
									socials: [
										...cardData.socials,
										{
											icon: null,
											icon_url: '',
											id: crypto.randomUUID(),
										},
									],
								});
							}}
							className=''
						>
							<div className='flex gap-m'>
								<AddIcon />
								<div>Добавить социальную сеть</div>
							</div>
						</Button>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
