import type { IBarType1 } from './BarsType1.interfaces';

export const blankBarType1 = (): IBarType1 => ({
	id: crypto.randomUUID(),
	counter_1: 0,
	counter_1_start: 0,
	counter_text_before: '',
	counter_text_after: '+',
	description: '',
	image_1: null,
	icon: null,
	style: '',
	subtitle: '',
	title: '',
});
