import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { type FC } from 'react';

import type { LinearProgressProps } from '@mui/material/LinearProgress';

interface IProps {
	valueLoading: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Box className='flex items-center'>
			<Box className='mr-5 w-full'>
				<LinearProgress
					variant='determinate'
					className='h-[2px]'
					{...props}
				/>
			</Box>
			<Box className='min-w-[35px]'>
				<Typography
					variant='body2'
					color='text.secondary'
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

const ProgressBar: FC<IProps> = ({ valueLoading }) => {
	return (
		<Box className={'w-full'}>
			<LinearProgressWithLabel value={valueLoading} />
		</Box>
	);
};

export default ProgressBar;
