import { Add as AddIcon } from '@mui/icons-material';
import { useCallback, useEffect } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { Button } from 'shared/ui';

import { blankBarType1 } from './BarsType1.lib';
import { SingleBarType1 } from './SingleBarType1';

import type { FieldInputProps } from 'formik/dist/types';
import type { IBarsData } from 'shared/ui/Editor/EditorBlockTools/BarsTool/BarsTool.interfaces';

interface IProps {
	data: IBarsData;
	getFieldProps: (field: string) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
}

export const BarsType1 = ({ data, setFieldValue, getFieldProps }: IProps) => {
	useEffect(() => {
		if (data.bars.length === 0) {
			setFieldValue('bars', [blankBarType1()]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBarUp = useCallback(
		(id: number | string) => {
			setFieldValue('bars', swapToTopArrayItems(id, data.bars));
		},
		[setFieldValue, data.bars]
	);
	const handleBarDown = useCallback(
		(id: number | string) => {
			setFieldValue('bars', swapToBottomArrayItems(id, data.bars));
		},
		[setFieldValue, data.bars]
	);
	const handleBarClose = useCallback(
		(id: number | string) => {
			setFieldValue(
				'bars',
				data.bars.filter((bar) => {
					return bar.id !== id;
				})
			);
		},
		[setFieldValue, data.bars]
	);

	return (
		<div className='flex flex-col gap-m'>
			<div className='flex flex-col'>
				<div className=''>Плитки:</div>
				{data.bars.map((bar, index) => (
					<SingleBarType1
						getFieldProps={getFieldProps}
						key={bar.id}
						barData={bar}
						index={index}
						handleClose={handleBarClose}
						handleDown={handleBarDown}
						handleUp={handleBarUp}
						handleChangeBarData={(newBarData) => {
							setFieldValue(`bars.${index}`, newBarData);
						}}
					/>
				))}
			</div>
			<Button
				variant='outlined'
				onClick={() => {
					setFieldValue('bars', [...data.bars, blankBarType1()]);
				}}
				className=''
			>
				<div className='flex gap-m'>
					<AddIcon />
					<div>Добавить плитку</div>
				</div>
			</Button>
		</div>
	);
};
