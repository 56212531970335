import EditorJS from '@editorjs/editorjs';

import { CLayoutBlockTool } from 'shared/ui/Editor/EditorBlockTools/Layout';

import type { EditorConfig } from '@editorjs/editorjs';
import type { ToolConstructable, ToolSettings } from '@editorjs/editorjs/types/tools';

export class LayoutTool {
	constructor(private editorJSConfig: EditorConfig) {}

	private createInitialData(count: number, classLayout: string, classChildren: string) {
		const initialData: {
			itemContent: {
				[key: number]: {
					blocks: [];
				};
			};
			layout: {
				type: 'container';
				id: string;
				className: string;
				children: {
					type: 'item';
					id: string;
					className: string;
					itemContentId: string;
				}[];
			};
		} = {
			itemContent: {},
			layout: {
				type: 'container',
				id: '',
				className: classLayout,
				children: [],
			},
		};

		for (let i = 0; i < count; i++) {
			initialData.itemContent[i] = {
				blocks: [],
			};

			initialData.layout.children[i] = {
				type: 'item',
				id: '',
				className: classChildren,
				itemContentId: i.toString(),
			};
		}

		return initialData;
	}

	generateConfig({
		countInitDate,
		classLayout,
		classChildren,
		shortcut,
		title,
	}: {
		countInitDate: number;
		classLayout: string;
		classChildren: string;
		shortcut: string;
		title: string;
	}): ToolConstructable | ToolSettings {
		return {
			class: CLayoutBlockTool as unknown as ToolConstructable,
			inlineToolbar: true,
			config: {
				EditorJS,
				editorJSConfig: this.editorJSConfig,
				enableLayoutEditing: true,
				enableLayoutSaving: true,
				initialData: this.createInitialData(countInitDate, classLayout, classChildren),
			},
			shortcut,
			toolbox: {
				icon: `
		      <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
		        <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
		        <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
		      </svg>
		    `,
				title,
			},
		};
	}
}
