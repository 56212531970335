import { Formik } from 'formik';

import { useGetMaterialsWithChildrenQuery } from 'shared/api/materialsApi';
import { CenteredSpinner } from 'shared/ui';
import NewsToolForm from 'shared/ui/Editor/EditorBlockTools/NewsTool/ui/NewsToolForm';

import type { FC } from 'react';
import type { INewsToolProps } from 'shared/ui/Editor/EditorBlockTools/NewsTool/NewsTool.interfaces';

const NewsTool: FC<INewsToolProps> = ({ data, onDataChange, deleteBlock, moveBlockUp, moveBlockDown }) => {
	const { data: materialsData, isFetching } = useGetMaterialsWithChildrenQuery();
	const parentMaterials = materialsData?.data;
	const isParentMaterialsExist = !isFetching && parentMaterials?.length;

	if (isFetching) {
		return <CenteredSpinner />;
	}

	return (
		<div>
			<Formik
				initialValues={data}
				onSubmit={(values) => {
					onDataChange(values);
				}}
			>
				{isParentMaterialsExist ? (
					() => (
						<NewsToolForm
							data={data}
							deleteBlock={deleteBlock}
							moveBlockUp={moveBlockUp}
							moveBlockDown={moveBlockDown}
							onDataChange={onDataChange}
							parentMaterials={parentMaterials}
						/>
					)
				) : (
					<div className='flex h-full items-center justify-center text-lg font-bold'>Отсутствуют созданные материалы!</div>
				)}
			</Formik>
		</div>
	);
};

export default NewsTool;
