import { Formik } from 'formik';
import React from 'react';

import { TabForm } from 'shared/ui/Editor/EditorBlockTools/TabTool/ui';

import type { FC } from 'react';
import type { ITabToolProps } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.interfaces';

const TabTool: FC<ITabToolProps> = ({ data, handleChange, deleteBlock, moveBlockDown, moveBlockUp }) => {
	return (
		<Formik
			initialValues={data}
			onSubmit={(values) => {
				handleChange(values);
			}}
		>
			<TabForm
				handleChange={handleChange}
				deleteBlock={deleteBlock}
				moveBlockDown={moveBlockDown}
				moveBlockUp={moveBlockUp}
			/>
		</Formik>
	);
};

export default TabTool;
