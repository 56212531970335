import { useEffect } from 'react';

import type { MutableRefObject } from 'react';

export const useClickOutside = (menuRef: MutableRefObject<Nullable<HTMLElement>>, closeModal: () => void) => {
	useEffect(() => {
		const closeOpenedMenu = (e: Event) => {
			if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
				closeModal();
			}
		};
		if (menuRef.current) document.addEventListener('click', closeOpenedMenu, true);

		return () => {
			document.removeEventListener('click', closeOpenedMenu, true);
		};
		// eslint-disable-next-line
	}, [closeModal, menuRef]);
};
