import { useNavigate } from 'react-router-dom';

import NotFoundIcon from 'shared/assets/svgs/NotFoundIcon';
import { ERoutes } from 'shared/lib/constants';
import { Button } from 'shared/ui';

export const NotFound = () => {
	const navigate = useNavigate();

	const handleToPrevPage = () => navigate(-1);
	const handleToHome = () => navigate(ERoutes.HOME);

	return (
		<div className='flex translate-y-1/2 justify-center'>
			<div className='max-w-[265px]'>
				<NotFoundIcon />
				<h2 className='mb-1 mt-3 text-center text-[32px] font-bold'>Ошибка 404</h2>
				<p className='text-center text-lg'>Запрашиваемой страницы нет или она была удалена</p>
				<div className='mt-6 flex justify-around'>
					<Button
						variant='outlined'
						className=''
						onClick={handleToPrevPage}
					>
						Назад
					</Button>
					<Button
						variant='contained'
						color='primary'
						className=''
						onClick={handleToHome}
					>
						На главную
					</Button>
				</div>
			</div>
		</div>
	);
};
