export const editorI18nConfig = {
	messages: {
		ui: {
			blockTunes: {
				toggler: {
					'Click to tune': 'Нажмите, чтобы настроить',
					'or drag to move': 'или перетащите',
				},
			},
			inlineToolbar: {
				converter: {
					'Convert to': 'Конвертировать в',
				},
			},
			toolbar: {
				toolbox: {
					Add: 'Добавить',
					Filter: 'Фильтр',
					'Click to tune': 'Нажмите для редактирования',
				},
			},
			popover: {
				Filter: 'Фильтр',
				'Nothing found': 'Ничего не найдено',
			},
		},
		toolNames: {
			Text: 'Параграф',
			Heading: 'Заголовок',
			Bars: 'Плитки',
			List: 'Список',
			Warning: 'Уведомление',
			Checklist: 'Список с флажками',
			Quote: 'Цитата',
			Code: 'Код',
			Delimiter: 'Разделитель',
			Table: 'Таблица',
			Link: 'Ссылка',
			Marker: 'Маркер',
			Bold: 'Полужирный',
			Italic: 'Курсив',
			InlineCode: 'Моноширинный',
		},
		tools: {
			link: {
				'Add a link': 'Ввставьте ссылку',
			},
			header: {
				'Heading 1': 'Заголовок №1',
				'Heading 2': 'Заголовок №2',
				'Heading 3': 'Заголовок №3',
				'Heading 4': 'Заголовок №4',
				'Heading 5': 'Заголовок №5',
				'Heading 6': 'Заголовок №6',
			},
			table: {
				'With headings': 'С заголовками',
				'Without headings': 'Без заголовков',
				'Add row above': 'Добавить строку сверху',
				'Add row below': 'Добавить строку снизу',
				'Delete row': 'Удалить строку',
				'Add column to left': 'Добавить столбец слева',
				'Add column to right': 'Добавить столбец справа',
				'Delete column': 'Удалить столбец',
				Heading: 'Заголовок',
			},
			quote: {
				'Align Left': 'Выравнять по левому краю',
				'Align Center': 'Выравнять по центру',
				'Align Right': 'Выровнять по правому краю',
				'Align Width': 'Выровнять по ширине',
			},
			list: {
				Unordered: 'Маркированный',
				Ordered: 'Нумерованный',
			},
			code: {
				'Enter a code': 'Код',
			},
			stub: {
				'The block can not be displayed correctly.': 'Блок не может отображаться корректно',
			},
		},
		blockTunes: {
			delete: {
				Delete: 'Удалить',
				'Click to delete': 'Нажмите чтобы удалить',
			},
			moveUp: {
				'Move up': 'Переместить вверх',
			},
			moveDown: {
				'Move down': 'Переместить вниз',
			},
		},
	},
};
