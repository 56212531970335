import { Add as AddIcon } from '@mui/icons-material';
import { useCallback, useEffect } from 'react';

import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { Button } from 'shared/ui';

import { blankCardType7 } from './CardsType7.lib';
import { SingleCardType7 } from './SingleCardType7';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardsData1to11 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsTool.interfaces';
import type { ICardType7 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType7/CardsType7.interfaces';

interface IProps {
	data: ICardsData1to11;
	getFieldProps: (field: string) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
}

export const CardsType7 = ({ data, setFieldValue, getFieldProps }: IProps) => {
	useEffect(() => {
		if (data.cards.length === 0) {
			setFieldValue('cards', [blankCardType7()]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCardUp = useCallback(
		(id: number | string) => {
			setFieldValue('cards', swapToTopArrayItems(id, data.cards));
		},
		[setFieldValue, data.cards]
	);
	const handleCardDown = useCallback(
		(id: number | string) => {
			setFieldValue('cards', swapToBottomArrayItems(id, data.cards));
		},
		[setFieldValue, data.cards]
	);
	const handleCardClose = useCallback(
		(id: number | string) => {
			setFieldValue(
				'cards',
				data.cards.filter((card) => {
					return card.id !== id;
				})
			);
		},
		[setFieldValue, data.cards]
	);

	return (
		<div className='flex flex-col gap-m'>
			<div className='flex flex-col'>
				<div className=''>Карточки:</div>
				{data.cards.map((card, index) => (
					<SingleCardType7
						getFieldProps={getFieldProps}
						key={card.id}
						cardData={card as ICardType7}
						index={index}
						handleClose={handleCardClose}
						handleDown={handleCardDown}
						handleUp={handleCardUp}
						handleChangeCardData={(newCardData) => {
							setFieldValue(`cards.${index}`, newCardData);
						}}
					/>
				))}
			</div>
			<Button
				variant='outlined'
				onClick={() => {
					setFieldValue('cards', [...data.cards, blankCardType7()]);
				}}
				className=''
			>
				<div className='flex gap-m'>
					<AddIcon />
					<div>Добавить карточку</div>
				</div>
			</Button>
		</div>
	);
};
