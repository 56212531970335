import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType14 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType14/CardsType14.interface';

interface IProps {
	cardData: ICardType14;
	index: number;
	handleChangeCardData: (newData: ICardType14) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType14 = ({ cardData, index, handleChangeCardData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex items-center gap-m'>
					<div className='flex flex-col gap-m'>
						<div className='flex flex-col'>
							<div className=''>Изображение</div>
							<SelectImageWithControls
								handleSelectFile={(newFile) => {
									handleChangeCardData({ ...cardData, image_1: newFile });
								}}
								handleDeleteImage={() => handleChangeCardData({ ...cardData, image_1: null })}
								mediaItem={cardData.image_1}
							/>
						</div>
					</div>
					<div className='flex w-full flex-col gap-m '>
						<TextInput
							{...getFieldProps(`cards.${index}.link_url`)}
							label='URL ссылки'
							placeholder='Введите URL'
						/>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
