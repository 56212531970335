import { Formik } from 'formik';

import AccordionForm from 'shared/ui/Editor/EditorBlockTools/AccordionTool/ui/AccordionForm';

import type { FC } from 'react';
import type { IAccordionToolProps } from 'shared/ui/Editor/EditorBlockTools/AccordionTool/AccordionTool.interfaces';

const AccordionTool: FC<IAccordionToolProps> = ({ data, handleChange, deleteBlock, moveBlockUp, moveBlockDown, copyBlock, pasteBlock }) => {
	return (
		<Formik
			initialValues={data}
			onSubmit={(values) => {
				handleChange(values);
			}}
		>
			{() => (
				<AccordionForm
					handleChange={handleChange}
					deleteBlock={deleteBlock}
					moveBlockUp={moveBlockUp}
					moveBlockDown={moveBlockDown}
					copyBlock={copyBlock}
					pasteBlock={pasteBlock}
				/>
			)}
		</Formik>
	);
};

export default AccordionTool;
