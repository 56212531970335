import cn from 'classnames';

import { useAppSelector } from '@store';
import { currentUserSelector } from '@store/reducers/auth/selectors.auth';
import AccordionMenu from 'app/ui/AccordionMenu/AccordionMenu';
import { AuthMenu } from 'app/ui/AuthMenu';

import 'app/styles/index.css';
import { menuItems } from './Layout.meta';

import type { FC, ReactNode } from 'react';

interface LayoutProps {
	children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
	const user = useAppSelector(currentUserSelector);

	return (
		<div className='m-auto flex min-h-full max-w-full'>
			{user && <AccordionMenu menuItems={menuItems} />}
			<div className='w-[100%]'>
				{user && (
					<header className='z-0 flex h-[96px] items-center justify-end bg-white pr-[25px]'>
						<AuthMenu />
					</header>
				)}
				<main
					className={cn('flex flex-col overflow-auto bg-mainBgColor', {
						'max-h-[calc(100vh_-_96px)]  min-h-[calc(100vh_-_96px)]': !!user,
						'min-h-screen': !user,
					})}
				>
					{children}
				</main>
			</div>
		</div>
	);
};

export default Layout;
