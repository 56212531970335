import { Button } from '@mui/material';
import cn from 'classnames';
import Image from 'mui-image';

import { CloseFileIcon } from 'shared/assets/svgs';
import ButtonIcon from 'shared/assets/svgs/ButtonIcon';
import { useModal } from 'shared/hooks';
import { EMediaProcessingStatuses } from 'shared/lib/constants/processing';
import { Button as CustomButton, SelectMediaModal } from 'shared/ui';
import { imageMimes, imageTypes } from 'shared/ui/FileUploader/FileUploader.meta';
import { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

import type { TProps } from './FormInputFileUploader.types';

const FormInputFileUploader = ({ handleDeleteImage, handleSelectFile, name, media, text }: TProps) => {
	const { isModalOpen, openModal, closeModal } = useModal();

	const errorProcessingStyle = cn('w-full h-[200px] relative flex flex-col justify-between justify-self-center shadow-md transition-shadow', {
		'shadow-red shadow-lg shadow-md transition-shadow': media?.status === EMediaProcessingStatuses.Processing_error,
	});

	return (
		<>
			{media?.url ? (
				<>
					<div className={`${errorProcessingStyle}`}>
						{/* {media.status === EMediaProcessingStatuses.Processing && <Spinner className='absolute right-0 z-10' />} */}
						<Image
							src={media.url}
							alt=''
							className='max-w-full rounded-t-lg'
						/>
						<div
							className={cn(
								'drop-shadow(0 1px 2px white) drop-shadow(0 0 1px white)',
								'overflow-hidden text-ellipsis whitespace-nowrap p-1 text-center'
							)}
							title={media.name || ''}
						>
							{`${media.name}`}
							{media.status === EMediaProcessingStatuses.Processing_error && <div className='text-error'>ошибка обработки</div>}
						</div>
					</div>
					<Button
						onClick={() => handleDeleteImage(name)}
						className='w-full'
					>
						<CloseFileIcon />
					</Button>
				</>
			) : (
				<>
					<CustomButton
						variant='contained'
						onClick={openModal}
						classes={{
							root: 'h-full border-2 border-dashed border-enabled bg-dropzone p-3 text-enabled min-w-fit w-fit',
						}}
					>
						<div className='flex flex-col'>
							<p className=''>{text}</p>
							<div className='flex items-center gap-x-2'>
								<ButtonIcon />
								<p className=''>Загрузить изображение</p>
							</div>
						</div>
					</CustomButton>
					<SelectMediaModal
						accept={imageMimes}
						isOpen={isModalOpen}
						onClose={closeModal}
						onSelectFile={(newValue) => handleSelectFile(newValue, name)}
						variant={ESelectFileVariant.IMAGE}
						mediaTypes={imageTypes}
					/>
				</>
			)}
		</>
	);
};

export default FormInputFileUploader;
