export enum EMediaProcessingStatuses {
	Processing = 'processing',
	Successfully_processed = 'successfully_processed',
	Processing_error = 'processing_error',
}

export const EProcessingStatuses = {
	processing: 'Обрабатывается',
	successfully_processed: 'Обработан',
	processing_error: 'Обработан с ошибкой',
};
