export enum EMimeTypes {
	GIF = 'image/gif',
	JPEG = 'image/jpeg',
	JPG = 'image/jpg',
	PNG = 'image/png',
	WEBP = 'image/webp',
	SVG = 'image/svg+xml',

	MP3 = 'audio/mpeg',
	M4A = 'audio/m4a',
	OGG_AUDIO = 'audio/ogg',
	WAV = 'audio/wav',
	M4A_AUDIO = 'audio/x-m4a',

	AVI = 'video/x-msvideo',
	MPEG = 'video/mpeg',
	M4V = 'video/x-m4v',
	VTT = 'text/vtt',
	MP4 = 'video/mp4',
	OGG_VIDEO = 'video/ogg',
	QUICKTIME = 'video/quicktime',
	WINDOWS_MEDIA = 'video/x-ms-wmv',
	VIDEO_3GPP = 'video/3gpp',
	VIDEO_3GPP2 = 'video/3gpp2',

	PDF = 'application/pdf',
	DOC = 'application/msword',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	ODT = 'application/vnd.oasis.opendocument.text',
	PPT = 'application/vnd.ms-powerpoint',
	PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	PPS = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
	PPSX = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
	XLS = 'application/vnd.ms-excel',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	ZIP = 'application/zip',

	ALL_FILES = '*',
}
