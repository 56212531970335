import type { IMediaFile } from 'shared/interfaces';
import type { ICardType1 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType1/CardsType1.interfaces';
import type { ICardType10 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType10/CardsType10.interfaces';
import type { ICardType11 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType11/CardsType11.interfaces';
import type { ICardType12 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType12/CardsType12.interfaces';
import type { ICardType13 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType13/CardsType13.interface';
import type { ICardType14 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType14/CardsType14.interface';
import type { ICardType2 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType2/CardsType2.interfaces';
import type { ICardType3 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType3/CardsType3.interfaces';
import type { ICardType4 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType4/CardsType4.interfaces';
import type { ICardType5 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType5/CardsType5.interfaces';
import type { ICardType6 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType6/CardsType6.interfaces';
import type { ICardType7 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType7/CardsType7.interfaces';
import type { ICardType8 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType8/CardsType8.interfaces';
import type { ICardType9 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType9/CardsType9.interfaces';

export enum ECardsVariants {
	TYPE_1 = 'type_1',
	TYPE_2 = 'single_big_card',
	TYPE_3 = 'type_3',
	TYPE_4 = 'founder',
	TYPE_5 = 'single_with_map_about',
	TYPE_6 = 'single_with_map_education',
	TYPE_7 = 'management',
	TYPE_8 = 'single_with_right_image',
	TYPE_9 = 'service_order',
	TYPE_10 = 'cybersecurity_services',
	TYPE_11 = 'small_with_icon_and_button',
	TYPE_12 = 'services_with_center_icon',
	TYPE_13 = 'current_links',
	TYPE_14 = 'partners',
}

export interface ICardsData1to11 {
	variant: ECardsVariants;
	max_row_count: number;
	style: string;
	cards_title: string;
	cards: (
		| ICardType1
		| ICardType2
		| ICardType3
		| ICardType4
		| ICardType5
		| ICardType6
		| ICardType7
		| ICardType8
		| ICardType9
		| ICardType10
		| ICardType11
		| ICardType12
		| ICardType13
		| ICardType14
	)[];
}

export interface ICardsData12 {
	variant: ECardsVariants.TYPE_12;
	max_row_count: number;
	style: string;
	cards_title: string;
	center_icon: Nullable<IMediaFile>;
	cards: ICardType12[];
}

export interface ICardsData13 {
	variant: ECardsVariants.TYPE_12;
	max_row_count: number;
	style: string;
	cards_title: string;
	cards: Array<ICardType13>;
}

export type ICardsData = ICardsData1to11 | ICardsData12 | ICardsData13;
