import { Field, useFormikContext } from 'formik';
import { useEffect, type FC, useRef } from 'react';

import { useModal } from 'shared/hooks';
import { Button } from 'shared/ui';
import { buttonAlignStyles } from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool.meta';
import { tuneAlignOptions } from 'shared/ui/Editor/EditorBlockTools/EditorBlockTools.meta';
import { ControlMenus } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { TextInput } from 'shared/ui/TextInput';

import type { IButtonToolData, IButtonToolFormProps } from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool.interfaces';

const ButtonToolForm: FC<IButtonToolFormProps> = ({ onDataChange, moveBlockUp, moveBlockDown, deleteBlock }) => {
	const { values, setFieldValue } = useFormikContext<IButtonToolData>();
	const { isModalOpen, openModal, closeModal } = useModal();
	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	useEffect(() => {
		onDataChange(values);
	}, [onDataChange, values]);

	const pickElement = (name: string) => {
		setFieldValue('align', name);
		closeModal();
	};

	return (
		<>
			<div className='mt-[12px] flex items-center justify-between'>
				<h2>Кнопка</h2>
				<ControlMenus
					menuRef={menuRef}
					checkedNames={[values.align]}
					openModal={openModal}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
					items={tuneAlignOptions}
					pickElement={pickElement}
				/>
			</div>
			<div className='flex gap-2'>
				<Field
					name='text'
					as={TextInput}
					placeholder='Введите текст для отображения на кнопке'
					label='Текст для отображения на кнопке'
				/>
				<Field
					name='url'
					as={TextInput}
					placeholder='Введите URL'
					label='URL'
				/>
				<Field
					name='style'
					as={TextInput}
					placeholder='Введите стиль'
					label='Стиль'
				/>
				<Button
					variant='contained'
					color='primary'
					className='min-h-[45px] !bg-enabled'
					sx={{
						alignSelf: buttonAlignStyles[values.align],
						textTransform: 'none',
					}}
				>
					{values.text}
				</Button>
			</div>
		</>
	);
};

export default ButtonToolForm;
