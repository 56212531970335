import { ESocialNetworks } from 'shared/interfaces/materials';

import type { ISocialNetworksPostsData } from 'shared/interfaces';

export const socialNetworks = [
	{ id: 1, title: ESocialNetworks.VK },
	{ id: 2, title: ESocialNetworks.Instagram },
	{ id: 3, title: ESocialNetworks.X },
	{ id: 4, title: ESocialNetworks.Facebook },
];

export const initialSocialNetworksPostsData: ISocialNetworksPostsData = {
	data: [],
};

export const toolboxParams = {
	title: 'Посты из соцсетей',
	icon: '',
};
