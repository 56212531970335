export enum ETagTypes {
	Users = 'Users',
	Roles = 'Roles',
	Materials = 'Materials',
	Media = 'Media',
	Locales = 'Locales',
	MenuBlocks = 'MenuBlocks',
	MenuItems = 'MenuItems',
	CommonData = 'CommonData',
	DefaultTitle = 'DefaultTitle',
	LogPort = 'LogPort',
	LogHost = 'LogHost',
	YandexMetrikaData = 'YandexMetrikaData',
	GoogleAnalytics = 'GoogleAnalytics',
	Backups = 'Backups',
	Jobs = 'Jobs',
	Lifecycles = 'Lifecycles',

	MATERIAL_TYPES_LIST = 'material_types_list',
	MATERIAL_TYPE = 'material_type',

	FORMS_LIST = 'forms_list',
	FORM = 'form',
	FORM_SELECT_OPTIONS = 'form_select_options',

	GET_SUBSCRIPTIONS_LIST = 'get_subscriptions_list',
	GET_SUBSCRIPTIONS_SETTINGS = 'get_subscriptions_settings',
}
