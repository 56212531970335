import { useEffect, useRef } from 'react';

export const useIsFirstRender = () => {
	const isFirstRenderRef = useRef(true);

	useEffect(() => {
		isFirstRenderRef.current = false;
	}, []);

	return isFirstRenderRef.current;
};
