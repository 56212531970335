import { TableCell, TableSortLabel } from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as ArrowDownIcon } from 'shared/assets/svgs/arrowdown.svg';
import { ReactComponent as BothArrowsIcon } from 'shared/assets/svgs/bothArrows.svg';
import { EOrderDirection } from 'shared/ui/CMSTable/CmsTable.interfaces';

import './CMSSorting.scss';

import type { IColumnsConfig } from 'shared/ui/CMSTable/CmsTable.interfaces';

interface IProps<T extends { id: string | number }> {
	onSortLabelClickHandler: (cell: IColumnsConfig<T>) => void;
	columnsConfigs: IColumnsConfig<T>[];
	sortBy?: string;
	sortDirection?: EOrderDirection;
}
const CmsSorting = <T extends { id: string | number }>({ onSortLabelClickHandler, columnsConfigs, sortBy, sortDirection }: IProps<T>) => {
	return (
		<>
			{columnsConfigs.map((cellConfig) => {
				const isActive = sortBy === cellConfig.valueForOrdering && (sortDirection === EOrderDirection.asc || sortDirection === EOrderDirection.desc);

				const labelStyles = classNames('tableSortWrapper', {
					iconWrapper: isActive,
				});

				return (
					<TableCell
						key={cellConfig.label}
						className={'headerRange'}
						{...{
							align: 'left',
							...cellConfig.headCellProps,
						}}
					>
						{cellConfig.valueForOrdering ? (
							<TableSortLabel
								data-id='TableSort'
								className={`${labelStyles}`}
								direction={sortDirection}
								active={sortBy === cellConfig.valueForOrdering}
								hideSortIcon={false}
								IconComponent={isActive ? ArrowDownIcon : BothArrowsIcon}
								onClick={() => onSortLabelClickHandler(cellConfig)}
								classes={{
									icon: 'min-w-[16px]',
								}}
							>
								{cellConfig.label}
							</TableSortLabel>
						) : (
							<span className='font-semibold'>{cellConfig.label}</span>
						)}
					</TableCell>
				);
			})}
		</>
	);
};

export default CmsSorting;
