import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const SketchMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width={20}
		height={20}
		viewBox='0 0 297 297'
	>
		<path d='M286.725 274.035c5.675 0 10.275-4.604 10.275-10.277V60.906c0-5.674-4.601-10.275-10.275-10.275h-35.8V33.242c0-5.676-4.601-10.277-10.275-10.277-5.677 0-10.277 4.602-10.277 10.277v17.389h-16.307V33.242c0-5.676-4.602-10.277-10.276-10.277-5.675 0-10.275 4.602-10.275 10.277v17.389h-16.308V33.242c0-5.676-4.603-10.277-10.277-10.277-5.675 0-10.276 4.602-10.276 10.277v17.389h-16.306V33.242c0-5.676-4.601-10.277-10.275-10.277-5.675 0-10.276 4.602-10.276 10.277v17.389h-16.309V33.242c0-5.676-4.601-10.277-10.276-10.277-5.674 0-10.276 4.602-10.276 10.277v17.389H66.627V33.242c0-5.676-4.601-10.277-10.278-10.277-5.673 0-10.273 4.602-10.273 10.277v17.389h-35.8C4.601 50.631 0 55.232 0 60.906v202.852c0 5.674 4.601 10.277 10.275 10.277h276.45zm-51.115-35.097v-26.324h26.313l-26.313 26.324zM20.551 71.186h25.524v17.383c0 5.676 4.601 10.277 10.273 10.277 5.678 0 10.278-4.602 10.278-10.277V71.186h16.307v17.383c0 5.676 4.603 10.277 10.276 10.277 5.675 0 10.276-4.602 10.276-10.277V71.186h16.309v17.383c0 5.676 4.602 10.277 10.276 10.277 5.675 0 10.275-4.602 10.275-10.277V71.186h16.306v17.383c0 5.676 4.602 10.277 10.276 10.277 5.675 0 10.277-4.602 10.277-10.277V71.186h16.308v17.383c0 5.676 4.601 10.277 10.275 10.277s10.276-4.602 10.276-10.277V71.186h16.307v17.383c0 5.676 4.601 10.277 10.277 10.277 5.675 0 10.275-4.602 10.275-10.277V71.186h25.524v120.873h-51.11c-5.677 0-10.277 4.6-10.277 10.277v51.143H20.551V71.186zm255.898 182.293h-26.31l26.31-26.324v26.324z' />
	</svg>
);
export default SketchMenuIcon;
