import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { ADD, tuneAddElement } from 'shared/ui/Editor/EditorBlockTools/EditorBlockTools.meta';
import { generateTabItem } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.lib';
import TabItem from 'shared/ui/Editor/EditorBlockTools/TabTool/ui/TabItem';
import { ControlMenus } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { TextInput } from 'shared/ui/TextInput';

import type { FC } from 'react';
import type { INavToolItem } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.interfaces';
import type { ITabData, ITabFormProps } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.interfaces';

const TabForm: FC<ITabFormProps> = ({ handleChange, deleteBlock, moveBlockDown, moveBlockUp }) => {
	const { isModalOpen, openModal, closeModal } = useModal();
	const { values, setFieldValue } = useFormikContext<ITabData>();

	useEffect(() => {
		handleChange(values);
	}, [handleChange, values]);

	const pickElement = (name: string) => {
		if (name === ADD) {
			const blankItem = generateTabItem(Object.keys(values.items).length);
			setFieldValue(`items.${blankItem.id}`, blankItem);
			closeModal();
		}
	};

	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	const handleSelectFile = (newValue: INavToolItem['media'], field: string) => {
		setFieldValue(field, newValue);
	};
	const handleDeleteImage = (imageField: string) => {
		setFieldValue(imageField, '');
	};

	useClickOutside(menuRef, closeModal);

	return (
		<Accordion>
			<AccordionSummary>
				<div className='flex w-full items-center justify-between'>
					<h2 key={values.headline}>Инструмент &quot;Вкладки&quot; ({values.headline})</h2>
					<ControlMenus
						menuRef={menuRef}
						openModal={openModal}
						closeModal={closeModal}
						isModalOpen={isModalOpen}
						deleteBlock={deleteBlock}
						moveBlockDown={moveBlockDown}
						moveBlockUp={moveBlockUp}
						items={tuneAddElement}
						pickElement={pickElement}
					/>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-3'>
					<Field
						name='style'
						as={TextInput}
						placeholder='Введите стиль'
						label='Стиль'
					/>
					<Field
						name='headline'
						as={TextInput}
						placeholder='Введите заголовок'
						label='Заголовок'
					/>
					<div className='mb-4 mt-4'>
						<hr className='h-[1px] text-border-secondary' />
					</div>

					{Object.values(values.items)
						.toSorted((a, b) => a.order - b.order)
						.map((tab) => (
							<TabItem
								handleDeleteImage={handleDeleteImage}
								handleSelectFile={handleSelectFile}
								key={`${tab.id}`}
								item={tab}
							/>
						))}
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default TabForm;
