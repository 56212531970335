import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';

import { Button } from 'shared/ui';
import { generateAdditionalInfo, generateBar } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.meta';
import { FormInputFileUploader } from 'shared/ui/FormInputFileUploader';
import { TextInput } from 'shared/ui/TextInput';

import type { TProps } from './SliderFormType8.types';
import type { IMediaFile } from 'shared/interfaces';
import type { ISliderData } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.interface';

const SliderFormType8 = ({ index }: TProps) => {
	const { values, setFieldValue } = useFormikContext<ISliderData>();

	const handleSelectFile = (newValue: Nullable<IMediaFile>, field: string) => {
		setFieldValue(field, newValue);
	};

	const handleDeleteImage = (field: string) => {
		setFieldValue(field, '');
	};

	const addBar = useCallback(() => {
		setFieldValue(
			`items.[${index}].bars`,
			values.items?.[index]?.bars?.length ? [...values.items[index].bars!, generateBar()] : [generateBar()],
			true
		);
	}, [index, setFieldValue, values.items]);

	const removeBar = useCallback(
		(barIndex: number) => {
			const newValue = values.items?.[index]?.bars?.filter((_, targetBarIndex) => barIndex !== targetBarIndex);

			setFieldValue(`items.[${index}].bars`, newValue, true);
		},
		[index, setFieldValue, values.items]
	);

	const addAdditionalInfoItem = useCallback(() => {
		setFieldValue(
			`items.[${index}].additionalData`,
			values.items?.[index]?.additionalData?.length ? [...values.items[index].additionalData!, generateAdditionalInfo()] : [generateAdditionalInfo()],
			true
		);
	}, [index, setFieldValue, values.items]);

	const removeAdditionalInfoItem = useCallback(
		(addAdditionalInfoItemIndex: number) => {
			const newValue = values.items?.[index]?.additionalData?.filter(
				(_, targetAdditionalInfoItemIndex) => addAdditionalInfoItemIndex !== targetAdditionalInfoItemIndex
			);

			setFieldValue(`items.[${index}].additionalData`, newValue, true);
		},
		[index, setFieldValue, values.items]
	);

	return (
		<>
			<div className='flex justify-between gap-x-4 p-5 text-base font-semibold'>
				<p>Слайд {index + 1}</p>
				<Field
					name={`items.[${index}].style`}
					placeholder='Стиль'
					as={TextInput}
				/>
			</div>
			<div className=''>
				<div className='mb-5 flex flex-col gap-4'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].image || null}
						name={`items.[${index}].image`}
						text='Картинка заднего фона'
					/>
					<Field
						name={`items.[${index}].subheadline`}
						placeholder='Подзаголовок'
						label='Подзаголовок'
						as={TextInput}
					/>
					<Field
						name={`items.[${index}].headline`}
						placeholder='Заголовок'
						label='Заголовок'
						as={TextInput}
					/>
					<Field
						name={`items.[${index}].description`}
						placeholder='Описание'
						label='Описание'
						as={TextInput}
					/>{' '}
					<Field
						name={`items.[${index}].button_text`}
						placeholder='Текст кнопки'
						label='Текст кнопки'
						as={TextInput}
					/>
					<Field
						name={`items.[${index}].button_link`}
						placeholder='Ссылка кнопки'
						label='Ссылка кнопки'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex flex-col gap-4'>
					<p>Плитка</p>
					{values.items?.[index]?.bars?.map((bar, barIndex) => {
						return (
							<div
								key={bar.uuid}
								className='flex flex-col gap-y-4 rounded-xl bg-gray-light p-4'
							>
								<p className=''>Плитка {barIndex + 1}</p>
								<div className='flex w-full items-center gap-x-4'>
									<div className='mb-5 flex w-full gap-4'>
										<div className=''>
											<FormInputFileUploader
												handleDeleteImage={handleDeleteImage}
												handleSelectFile={handleSelectFile}
												media={values.items?.[index]?.bars?.[barIndex]?.image || null}
												name={`items.[${index}].bars.[${barIndex}].image`}
												text='Изображение'
											/>
										</div>
										<div className='flex w-full flex-col gap-y-4'>
											<Field
												name={`items.[${index}].bars.[${barIndex}].style`}
												label='Стиль плитки'
												placeholder='Введите стиль плитки'
												type='text'
												as={TextInput}
											/>
											<Field
												name={`items.[${index}].bars.[${barIndex}].headline`}
												label='Текст на плитке'
												placeholder='Введите текст на плитке'
												type='text'
												as={TextInput}
											/>
										</div>
									</div>
									<Button
										variant='contained'
										type='button'
										onClick={() => removeBar(barIndex)}
									>
										Удалить плитку
									</Button>
								</div>
							</div>
						);
					})}
					<Button
						className='w-fit'
						variant='contained'
						type='button'
						onClick={addBar}
					>
						Добавить плитку
					</Button>
				</div>
				<div className='mb-5 flex flex-col gap-4'>
					<p>Дополниткльная информация</p>
					{values.items?.[index]?.additionalData?.map((additionalDataItem, additionalDataItemIndex) => {
						return (
							<div
								key={additionalDataItem.uuid}
								className='flex flex-col gap-y-4 rounded-xl bg-gray-light p-4'
							>
								<p className=''>Дополнительная информация {additionalDataItemIndex + 1}</p>
								<div className='flex w-full items-center gap-x-4'>
									<div className='mb-5 flex w-full gap-4'>
										<div className=''>
											<FormInputFileUploader
												handleDeleteImage={handleDeleteImage}
												handleSelectFile={handleSelectFile}
												media={values.items?.[index]?.additionalData?.[additionalDataItemIndex]?.image || null}
												name={`items.[${index}].additionalData.[${additionalDataItemIndex}].image`}
												text='Изображение для дополнительной информации'
											/>
										</div>
										<div className='flex w-full flex-col gap-y-4'>
											<Field
												name={`items.[${index}].additionalData.[${additionalDataItemIndex}].style`}
												label='Стиль дополнительной информации'
												placeholder='Введите стиль дополнительной информации'
												type='text'
												as={TextInput}
											/>
											<Field
												name={`items.[${index}].additionalData.[${additionalDataItemIndex}].headline`}
												label='Сопроводительный текст к дополнительной информации'
												placeholder='Введите сопроводительный текст к дополнительной информации'
												type='text'
												as={TextInput}
											/>
										</div>
									</div>
									<Button
										variant='contained'
										type='button'
										onClick={() => removeAdditionalInfoItem(additionalDataItemIndex)}
									>
										Удалить дополнительную информацию
									</Button>
								</div>
							</div>
						);
					})}
					<Button
						className='w-fit'
						variant='contained'
						type='button'
						onClick={addAdditionalInfoItem}
					>
						Добавить дополнительную информацию
					</Button>
				</div>
			</div>
		</>
	);
};

export default SliderFormType8;
