import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { IBarType1 } from 'shared/ui/Editor/EditorBlockTools/BarsTool/BarsVariants/BarsType1/BarsType1.interfaces';

interface IProps {
	barData: IBarType1;
	index: number;
	handleChangeBarData: (newData: IBarType1) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleBarType1 = ({ barData, index, handleChangeBarData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Плитка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex gap-m'>
					<div className='flex flex-col gap-m'>
						<div className='flex flex-col'>
							<div className=''>Иконка</div>
							<SelectImageWithControls
								handleSelectFile={(newFile) => {
									handleChangeBarData({ ...barData, icon: newFile });
								}}
								handleDeleteImage={() => handleChangeBarData({ ...barData, icon: null })}
								mediaItem={barData.icon}
							/>
						</div>
						<div className='flex flex-col'>
							<div className=''>Изображение</div>
							<SelectImageWithControls
								handleSelectFile={(newFile) => {
									handleChangeBarData({ ...barData, image_1: newFile });
								}}
								handleDeleteImage={() => handleChangeBarData({ ...barData, image_1: null })}
								mediaItem={barData.image_1}
							/>
						</div>
					</div>
					<div className='flex w-full flex-col gap-m '>
						<TextInput
							{...getFieldProps(`bars.${index}.style`)}
							label='Стиль'
							placeholder='Введите стиль'
						/>
						<TextInput
							{...getFieldProps(`bars.${index}.title`)}
							label='Заголовок'
							placeholder='Введите текст заголовка'
						/>
						<TextInput
							{...getFieldProps(`bars.${index}.subtitle`)}
							label='Подзаголовок'
							placeholder='Введите текст подзаголовка'
						/>
						<TextInput
							{...getFieldProps(`bars.${index}.description`)}
							label='Описание'
							placeholder='Введите текст описания'
						/>
						<div className='flex gap-m'>
							<TextInput
								{...getFieldProps(`bars.${index}.counter_text_before`)}
								label='Текст до счетчика'
								placeholder='Введите текст'
							/>
							<TextInput
								{...getFieldProps(`bars.${index}.counter_1_start`)}
								type='number'
								label='Начальное значение для счетчика'
								placeholder='Введите значение для счетчика'
							/>
							<TextInput
								{...getFieldProps(`bars.${index}.counter_1`)}
								type='number'
								label='Конечное значение для счетчика'
								placeholder='Введите значение для счетчика'
							/>
							<TextInput
								{...getFieldProps(`bars.${index}.counter_text_after`)}
								label='Текст после счетчика'
								placeholder='Введите текст'
							/>
						</div>
					</div>

					<SubTuner
						tuneItem={barData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

// 	title: string;
// 	subtitle: string;
// 	description: string;
// 	style: string;
