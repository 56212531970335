import type { ICardType1 } from './CardsType1.interfaces';

export const blankCardType1 = (): ICardType1 => ({
	id: crypto.randomUUID(),
	counter_1: 0,
	description: '',
	image_1: null,
	icon: null,
	style: '',
	subtitle: '',
	title: '',
});
