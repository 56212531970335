import { Field, useFormikContext } from 'formik';

import { FormInputFileUploader } from 'shared/ui/FormInputFileUploader';
import { TextInput } from 'shared/ui/TextInput';

import type { TProps } from './SliderFormType3.types';
import type { IMediaFile } from 'shared/interfaces';
import type { ISliderData } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.interface';

const SliderFormType3 = ({ index }: TProps) => {
	const { values, setFieldValue } = useFormikContext<ISliderData>();

	const handleSelectFile = (newValue: Nullable<IMediaFile>, field: string) => {
		setFieldValue(field, newValue);
	};

	const handleDeleteImage = (field: string) => {
		setFieldValue(field, '');
	};

	return (
		<>
			<div className='flex justify-between gap-x-4 p-5 text-base font-semibold'>
				<p>Слайд {index + 1}</p>
				<Field
					name={`items.[${index}].style`}
					placeholder='Стиль'
					as={TextInput}
				/>
			</div>
			<div className=''>
				<div className='mb-5 flex gap-2'>
					<Field
						name={`items.[${index}].goal`}
						placeholder='Зона ответственности'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<Field
						name={`items.[${index}].last_name`}
						placeholder='Фамилия'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<Field
						name={`items.[${index}].first_name_patronymic`}
						placeholder='Имя и отчетсво'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<Field
						name={`items.[${index}].position`}
						placeholder='Должность'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].phone_icon || null}
						name={`items.[${index}].phone_icon`}
						text='Иконка телефона'
					/>
					<Field
						name={`items.[${index}].phone`}
						placeholder='Телефон'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].fax_icon || null}
						name={`items.[${index}].fax_icon`}
						text='Иконка факса'
					/>
					<Field
						name={`items.[${index}].fax`}
						placeholder='Факс'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].email_icon || null}
						name={`items.[${index}].email_icon`}
						text='Иконка электронной почты'
					/>
					<Field
						name={`items.[${index}].email`}
						placeholder='Электронная почта'
						as={TextInput}
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].time_icon || null}
						name={`items.[${index}].time_icon`}
						text='Иконка время приема'
					/>
					<Field
						name={`items.[${index}].time`}
						placeholder='Время приема'
						as={TextInput}
					/>
				</div>
			</div>
		</>
	);
};

export default SliderFormType3;
