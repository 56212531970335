import type { ICardType12 } from './CardsType12.interfaces';

export const blankCardType12 = (): ICardType12 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	cardContent: { blocks: [] },
	title_image: null,
	link_text: '',
	link_url: '',
});
