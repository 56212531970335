import { IconButton } from '@mui/material';
import cn from 'classnames';

import { CloseFileIcon } from 'shared/assets/svgs';
import ArrowDown from 'shared/assets/svgs/ArrowDown';
import ArrowUp from 'shared/assets/svgs/ArrowUp';
import CopyIcon from 'shared/assets/svgs/CopyIcon';

import type { FC } from 'react';

interface ISubTunerProps {
	disableUp?: boolean;
	disableDown?: boolean;
	handleUp: (id: number | string) => void;
	handleDown: (id: number | string) => void;
	handleClose: (id: number | string) => void;
	tuneItem: number | string;
	pasteBlock?: (id: string | number) => void;
	index?: number;
	variant?: 'static' | 'absolute';
}

const SubTuner: FC<ISubTunerProps> = ({
	handleUp,
	handleDown,
	handleClose,
	tuneItem,
	pasteBlock,
	index,
	variant = 'absolute',
	disableDown = false,
	disableUp = false,
}) => {
	return (
		<div
			className={cn('right-[16px] top-[12px] flex items-center gap-s rounded bg-white px-2 py-1', {
				absolute: variant === 'absolute',
				static: variant === 'static',
			})}
		>
			<div className='flex w-[20px] flex-col items-center'>
				<div
					onClick={(e) => {
						e.stopPropagation();
						if (!disableUp) {
							handleUp(tuneItem);
						}
					}}
					className={cn('flex h-[20px] w-[20px] cursor-pointer items-center rounded-full p-[4px] ', {
						'hover:bg-gray-light': !disableUp,
						'opacity-50': disableUp,
					})}
				>
					<ArrowUp className='h-full w-full' />
				</div>
				<div
					onClick={(e) => {
						e.stopPropagation();
						if (!disableDown) {
							handleDown(tuneItem);
						}
					}}
					className={cn('flex h-[20px] w-[20px] cursor-pointer items-center rounded-full p-[4px] ', {
						'hover:bg-gray-light': !disableDown,
						'opacity-50': disableDown,
					})}
				>
					<ArrowDown className='h-full w-full' />
				</div>
			</div>
			<div
				onClick={(e) => {
					e.stopPropagation();
					handleClose(tuneItem);
				}}
				className='flex h-[20px] w-[20px] cursor-pointer items-center rounded-full hover:bg-gray-light'
			>
				<CloseFileIcon className='h-full w-full' />
			</div>
			{pasteBlock && (
				<IconButton
					onClick={(e) => {
						e.stopPropagation();
						pasteBlock(index ? index : 0);
					}}
					className='flex h-[13px] w-[13px] cursor-pointer items-center rounded-full hover:bg-gray-light'
				>
					<CopyIcon className='h-full w-full' />
				</IconButton>
			)}
		</div>
	);
};

export default SubTuner;
