import { type FC } from 'react';

import { FileUploader } from 'shared/ui/FileUploader';

import type { IUploaderTabProps } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

const UploaderTab: FC<IUploaderTabProps> = ({
	files,
	progress,
	setFiles,
	maxFileSizeForUploadMB,
	isLoading,
	accept,
	isValidSize,
	setIsValidSize,
	mediaTypes,
	withWatermark,
}) => {
	return (
		<div className='flex flex-col gap-4'>
			<FileUploader
				isLoading={isLoading}
				progress={progress}
				files={files}
				isValidFileSize={isValidSize}
				setFiles={setFiles}
				setIsValidFileSize={setIsValidSize}
				accept={accept}
				maxFileSize={maxFileSizeForUploadMB}
				mediaTypes={mediaTypes}
				withWatermark={withWatermark}
			/>
		</div>
	);
};

export default UploaderTab;
