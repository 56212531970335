import { Route, Routes } from 'react-router-dom';

import { renderRoute } from 'pages/_routes/Pages.lib';
import { routes } from 'pages/_routes/Pages.meta';
import { NotFound } from 'pages/notFound/NotFound';

import type { FC } from 'react';

const Pages: FC = () => {
	return (
		<Routes>
			{routes.map(renderRoute)}
			<Route
				path='*'
				element={<NotFound />}
			/>
		</Routes>
	);
};

export default Pages;
