import { Button } from '@mui/material';
import cn from 'classnames';
import Image from 'mui-image';
import React from 'react';

import { CloseFileIcon } from 'shared/assets/svgs';
import ButtonIcon from 'shared/assets/svgs/ButtonIcon';
import { useModal } from 'shared/hooks';
import { EMediaProcessingStatuses } from 'shared/lib/constants/processing';
import { Button as CustomButton } from 'shared/ui';
import { Spinner } from 'shared/ui';
import { imageMimes, imageTypes } from 'shared/ui/FileUploader/FileUploader.meta';
import { SelectMediaModal } from 'shared/ui/SelectMediaModal';
import { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

import type { FC } from 'react';
import type { INavToolItem } from 'shared/ui/Editor/EditorBlockTools/NavTool/NavTool.interfaces';

interface IProps {
	handleSelectFile: (newValue: INavToolItem['media'], field: string) => void;
	handleDeleteImage: (imageField: string) => void;
	index: number;
	item: INavToolItem;
}
const NavImageWithControls: FC<IProps> = ({ handleSelectFile, handleDeleteImage, index, item }) => {
	const { isModalOpen, openModal, closeModal } = useModal();
	const errorProcessingStyle = cn('relative flex flex-col justify-between justify-self-center shadow-md transition-shadow', {
		'shadow-red shadow-lg shadow-md transition-shadow': item.media?.status === EMediaProcessingStatuses.Processing_error,
	});
	return (
		<>
			{item.media?.url ? (
				<>
					<div className={`${errorProcessingStyle}`}>
						{item.media.status === EMediaProcessingStatuses.Processing && <Spinner className='absolute right-0 z-10' />}
						<Image
							src={item.media.url}
							alt=''
							className='max-w-full rounded-t-lg'
						/>
						<div
							className={cn(
								'drop-shadow(0 1px 2px white) drop-shadow(0 0 1px white)',
								'overflow-hidden text-ellipsis whitespace-nowrap p-1 text-center'
							)}
							title={item.media.name || ''}
						>
							{`${item.media.name}`}
							{item.media.status === EMediaProcessingStatuses.Processing_error && <div className='text-error'>ошибка обработки</div>}
						</div>
					</div>
					<Button
						onClick={() => handleDeleteImage(`items.${index}.media`)}
						className='w-full'
					>
						<CloseFileIcon />
					</Button>
				</>
			) : (
				<>
					<CustomButton
						variant='contained'
						onClick={openModal}
						classes={{
							root: 'h-full border-2 border-dashed border-enabled bg-dropzone p-3 text-enabled',
						}}
					>
						<div className='mr-2'>
							<ButtonIcon />
						</div>
						<div>Загрузить изображение</div>
					</CustomButton>
					<SelectMediaModal
						accept={imageMimes}
						isOpen={isModalOpen}
						onClose={closeModal}
						onSelectFile={(newValue) => handleSelectFile(newValue, `items.${index}.media`)}
						variant={ESelectFileVariant.IMAGE}
						mediaTypes={imageTypes}
					/>
				</>
			)}
		</>
	);
};

export default NavImageWithControls;
