export enum EEntryNames {
	Block = 'Блок меню',
	Menu = 'Пункт меню',
	ManyMenus = 'Пункты меню',
	Material = 'Материал',
	ManyMaterials = 'Материалы',
	ManyUsers = 'Пользователи',
	SingleUser = 'Пользователь',
	ManyRoles = 'Роли',
	SingleRole = 'Роль',
	AddedElements = 'Добавленные элементы',
	Folder = 'Папка',
	Profile = 'Профиль',
	Image = 'Изображение',
	LocaleContent = 'Язык контента',
	SingleLocale = 'Язык',
	ManyLocales = 'Языки',
	MediaFile = 'Медиафайл',
	ManyMediaFiles = 'Медиафайлы',
	Status = 'Статус',
	Settings = 'Настройки',
	Translation = 'Перевод',
	Backups = 'Бэкап',
	Job = 'Джоб',
	YandexMetrika = 'Ключ для яндекс метрики',
	GoogleAnalytics = 'Ключ для гугл аналитики',
	Subscription = 'Подписка',
	ManySubscriptions = 'Подписки',
}

export const eventNames = {
	delete: {
		singleFemale: 'удалена.',
		singleMale: 'удален.',
		singleNeutral: 'удалено.',
		many: 'удалены.',
	},
	save: {
		singleFemale: 'сохранена.',
		singleMale: 'сохранен.',
		singleNeutral: 'сохранено.',
		many: 'сохранены.',
	},
	create: {
		singleFemale: 'создана.',
		singleMale: 'создан.',
	},
	update: {
		singleFemale: 'обновлена.',
		singleMale: 'обновлен.',
		many: 'обновлены.',
	},
	copy: {
		singleMale: 'скопирован.',
	},
	upload: {
		singleMale: 'загружен.',
		many: 'загружены.',
	},
	uploadAndRegenerate: {
		singleMale: 'загружен и обрабатывается.',
		many: 'загружены и обрабатывается.',
	},
	regenerate: {
		singleMale: 'обрабатывается.',
	},
	change: {
		singleFemale: 'изменена.',
	},
	basket: {
		move: 'перемещен в корзину.',
		manyMove: 'перемещены в корзину.',
		delete: 'удален.',
		restore: 'всстановлен со статусом "Не опубликовано".',
		manyRestore: 'всстановлены со статусом "Не опубликовано".',
	},
	favorite: {
		add: 'перемещен в избранное',
		delete: 'удален из избранного',
	},
};

export const errorsNames = {
	notLoaded: {
		many: 'не загружены.',
	},
	hasBeenNotCreated: {
		single: 'не был создан.',
	},
	formatError: {
		single: 'не был создан - неверный формат.',
	},
};

export const toastMessages = {
	getSuccessMessage(dependentItem: EEntryNames, event: string) {
		return `Успешно. ${dependentItem} ${event}`;
	},
	getErrorMessage(dependentItem: EEntryNames, event: string) {
		return `${dependentItem} ${event}`;
	},
};

export const translationNotPublishMessage =
	'Материал не может быть сохранен со статусом "Опубликован", так как нет ни одного перевода со статусом "Опубликован".';

export const localesNotPublishMessage = `Перевод не может быть обновлен со статусом "Опубликован".
					 Предварительно необходимо перейти в раздел "Настройки.
					  Мультиязычность" и изменить статус языка на Опубликовано".`;

export const successDiagnostic = 'Диагностика прошла успешно.';
export const failedDiagnostic = 'Ошибка при диагностике.';
