import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store';
import { theme } from 'app/theme/theme';

import { SitemapTool } from './SitemapTool.component';
import { sitemapTooltip, getInitialSitemapData } from './SitemapTool.lib';

import type { ISitemapToolData } from './SitemapTool.interfaces';
import type { API, BlockAPI, BlockToolData, ConversionConfig, PasteConfig, SanitizerConfig, ToolboxConfig, ToolConfig } from '@editorjs/editorjs';
import type { BlockTool, BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools/block-tool';

export class CSitemapTool implements BlockTool {
	data: ISitemapToolData;

	api: API;

	blockApi?: BlockAPI;

	readOnly: boolean;

	config: ToolConfig;

	_element: HTMLElement;

	toolbox?: ToolboxConfig;

	pasteConfig?: PasteConfig;

	conversionConfig?: ConversionConfig;

	isInline?: boolean = false;

	sanitize?: SanitizerConfig;

	title?: string;

	static get toolbox() {
		return sitemapTooltip;
	}

	static get isReadOnlySupported() {
		return false;
	}

	constructor({ data, block, config, api, readOnly }: BlockToolConstructorOptions) {
		this.config = config;
		this.readOnly = readOnly;
		this.blockApi = block;
		this.api = api;
		this.data = {
			...getInitialSitemapData(),
			...data,
		};

		this._element = this.createRootElement();
	}

	createRootElement(): HTMLElement {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		// const handleDataChange = (newData: ISitemapToolData) => {
		// 	this.data = { ...newData };
		// };

		renderRoot.render(
			<BrowserRouter>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Provider store={store}>
							<SitemapTool
							// data={this.data}
							// handleDataChange={handleDataChange}
							/>
						</Provider>
					</ThemeProvider>
				</StyledEngineProvider>
			</BrowserRouter>
		);
		return rootNode;
	}

	render(): HTMLElement {
		return this._element;
	}

	save(): BlockToolData {
		return this.data;
	}
}
