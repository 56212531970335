import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store/core';
import { theme } from 'app/theme/theme';
import { DialogProvider } from 'shared/providers';

import FormTool from './FormTool';
import { toolboxParams } from './FormTool.meta';

import type { IFormData, IFormParams } from './FormTool.interface';
import type { API, BlockTool } from '@editorjs/editorjs';

export class CFormTool implements BlockTool {
	data: IFormData;

	api: API;

	constructor({ data, api }: IFormParams) {
		this.data = Object.keys(data).length ? data : { form_id: null, style: '', headline: '', subheadline: '' };
		this.api = api;
	}

	static get toolbox() {
		return toolboxParams;
	}

	save(): IFormData {
		return this.data;
	}

	render() {
		const rootNode = document.createElement('div');
		const renderRoot = createRoot(rootNode);

		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		const onDataChangeHandler = (newData: IFormData) => {
			this.data = newData;
		};

		renderRoot.render(
			<BrowserRouter>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Provider store={store}>
							<DialogProvider>
								<FormTool
									onDataChange={onDataChangeHandler}
									data={this.data}
									deleteBlock={deleteBlock}
									moveBlockUp={moveBlockUp}
									moveBlockDown={moveBlockDown}
								/>
							</DialogProvider>
						</Provider>
					</ThemeProvider>
				</StyledEngineProvider>
			</BrowserRouter>
		);

		return rootNode;
	}
}
