export const passwordMatch = 'Введенные пароли не совпадают!';
export const fieldRequired = 'Обязательное для заполнения поле. Введите значение.';
export const menuItemsSortRange =
	'Обязательное для заполнения поле, разрешены целочисленные значения и знак "-", максимальное количество символов 9.';
export const wrongFileFormat = 'Неверный формат медиафайла!';
export const moreThanOneFile = 'Разрешено загружать только 1 файл';

export const getMaxUploadSizeExceeded = (maxFileSize: number) => `Максимальный размер загружаемых медиа-файлов ${maxFileSize} Гб.`;

export const minLengthMessage = 'Неверная минимальная длина. Необходимо ввести больше символов.';

export const operationEndedWithError = 'Операция завершилась с ошибкой';

export const sEFURLCorresponds =
	'Алиас не должен содержать пробелы, должен содержать латинские символы, два или более дефиса запрещены, ' +
	'дефис в начале или в конце алиаса запрещен, использование специальных символов (~!@#$%^&*+=`|(){}[]:;\'<>,.?;–") запрещено!';
