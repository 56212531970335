import { Field, useFormikContext } from 'formik';

import { FormInputFileUploader } from 'shared/ui';
import { TextInput } from 'shared/ui/TextInput';

import type { TProps } from './SliderFormType1.types';
import type { IMediaFile } from 'shared/interfaces';
import type { ISliderData } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.interface';

const SliderFormType1 = ({ index }: TProps) => {
	const { values, setFieldValue } = useFormikContext<ISliderData>();

	const handleSelectFile = (newValue: Nullable<IMediaFile>, field: string) => {
		setFieldValue(field, newValue);
	};

	const handleDeleteImage = (field: string) => {
		setFieldValue(field, '');
	};

	return (
		<>
			<div className='flex justify-between gap-x-4 p-5 text-base font-semibold'>
				<p>Слайд {index + 1}</p>
				<Field
					name={`items.[${index}].style`}
					placeholder='Стиль'
					as={TextInput}
				/>
			</div>
			<div className=''>
				<div className='mb-4 border-b border-b-gray'>
					<h4 className=''>Заголовок</h4>
					<div className='mb-5 flex gap-2'>
						<Field
							name={`items.[${index}].block_1_headline`}
							placeholder='Заголовок'
							as={TextInput}
						/>
					</div>
				</div>
				<div className='mb-4 border-b border-b-gray'>
					<div className='flex items-center gap-x-4'>
						<h4 className=''>Блок 1</h4>
						<div className='mb-5 flex gap-2'>
							<Field
								name={`items.[${index}].block_1_style`}
								placeholder='Стиль блока 1'
								as={TextInput}
							/>
						</div>
					</div>
					<div className='mb-5 flex flex-col gap-2'>
						<FormInputFileUploader
							handleDeleteImage={handleDeleteImage}
							handleSelectFile={handleSelectFile}
							media={values.items[index].icon_block_1 || null}
							name={`items.[${index}].icon_block_1`}
							text='Иконка'
						/>
						<Field
							name={`items.[${index}].headline_block_1`}
							placeholder='Заголовок'
							required
							as={TextInput}
						/>
						<Field
							name={`items.[${index}].text_block_1`}
							placeholder='Текст'
							as={TextInput}
						/>
					</div>
				</div>
				<div className='mb-4 border-b border-b-gray'>
					<h4 className=''>Блок 2</h4>
					<div className='mb-5 flex flex-col gap-2'>
						<FormInputFileUploader
							handleDeleteImage={handleDeleteImage}
							handleSelectFile={handleSelectFile}
							media={values.items[index].img_block_2 || null}
							name={`items.[${index}].img_block_2`}
							text='Картинка'
						/>
					</div>
				</div>
				<div className='mb-4 border-b border-b-gray'>
					<div className='flex items-center gap-x-4'>
						<h4 className=''>Блок 3</h4>
						<div className='mb-5 flex gap-2'>
							<Field
								name={`items.[${index}].block_3_style`}
								placeholder='Стиль блока 3'
								as={TextInput}
							/>
						</div>
					</div>
					<div className='mb-5 flex flex-col gap-2'>
						<FormInputFileUploader
							handleDeleteImage={handleDeleteImage}
							handleSelectFile={handleSelectFile}
							media={values.items[index].icon_block_3 || null}
							name={`items.[${index}].icon_block_3`}
							text='Иконка'
						/>
						<Field
							name={`items.[${index}].headline_block_3`}
							placeholder='Заголовок'
							required
							as={TextInput}
						/>
						<Field
							name={`items.[${index}].text_block_3`}
							placeholder='Текст'
							as={TextInput}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SliderFormType1;
