export type TOrderableItem = { id: number | string; order: number };

export const getMaxOrderItem = <T extends TOrderableItem>(items: { [key: number | string]: T }) => {
	return Object.values(items).reduce(
		(acc, item) => {
			if (item.order > acc.maxOrder) return { maxOrder: item.order, foundItem: item };

			return acc;
		},
		{ maxOrder: 1, foundItem: null } as { maxOrder: number; foundItem: Nullable<T> }
	).foundItem;
};

export const getMinOrderItem = <T extends TOrderableItem>(items: { [key: number | string]: T }) => {
	return Object.values(items).reduce(
		(acc, item) => {
			if (item.order < acc.minOrder) return { minOrder: item.order, foundItem: item };

			return acc;
		},
		{ minOrder: 1, foundItem: null } as { minOrder: number; foundItem: Nullable<T> }
	).foundItem;
};
