import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import type { ButtonProps } from '@mui/material';
import type { FC } from 'react';

const CreateButton = styled(Button)(({ disabled = false, color, theme, variant }) => ({
	fontSize: '0.875rem',
	padding: '0.75rem 1.125rem',
	lineHeight: '23px',
	height: '40px',
	fontWeight: '600',
	textTransform: 'none',
	...(disabled && {
		'& .MuiButton-root, &.Mui-disabled': {
			backgroundColor: `${theme.palette.error.light}`,
			color: `${theme.palette.grey[50]}`,
		},
	}),
	...(color === 'enabledWhite' && {
		color: theme.palette.text.primary,
	}),
	...(variant === 'outlined' && {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		borderWidth: '2px',
		'&:hover': {
			borderWidth: '2px',
		},
	}),
}));

const CustomButton: FC<ButtonProps> = ({ children, className, ...rest }) => {
	return (
		<CreateButton
			classes={{ root: 'shadow-none hover:shadow-none' }}
			defaultValue={30}
			className={className}
			{...rest}
		>
			{children}
		</CreateButton>
	);
};

export default CustomButton;
