import { TextField } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, useEffect, useRef } from 'react';

import './TextInput.scss';
import type { TextFieldProps } from '@mui/material';
import type { KeyboardEvent } from 'react';

type TProps = {
	propsClassName?: string;
	maxLength?: number;
};

const TextInput = forwardRef<HTMLDivElement, TextFieldProps & TProps>(
	({ className, type = 'text', fullWidth = true, propsClassName, autoComplete = 'off', ...restProps }, ref) => {
		const inputClassNames = classNames('fieldWrapper', className);
		const inputRef = useRef<HTMLDivElement>(null);

		const autoCompleteProp: {
			form?: any;
		} = {};

		if (autoComplete === 'off')
			autoCompleteProp.form = {
				autocomplete: 'off',
			};

		const keyboardEventHandler = (e: KeyboardEvent<HTMLElement>) => {
			const target = e.target as { value: string } & EventTarget;

			if (!target.value && e.key === 'Backspace') {
				e.stopPropagation();
				e.preventDefault();
				return;
			}

			if (e.key === 'Enter') {
				e.stopPropagation();
			}
		};

		useEffect(() => {
			const handleWheel = (e: WheelEvent) => e.preventDefault();
			if (type === 'number') inputRef.current?.addEventListener('wheel', handleWheel);

			return () => {
				inputRef.current?.removeEventListener('wheel', handleWheel);
			};
		}, []);

		return (
			<div
				className='fieldWrapper'
				ref={ref}
			>
				<TextField
					{...restProps}
					ref={inputRef}
					FormHelperTextProps={{
						className: 'self-start',
					}}
					onKeyUp={keyboardEventHandler}
					onKeyDown={keyboardEventHandler}
					fullWidth={fullWidth}
					className={inputClassNames}
					inputProps={{
						className: propsClassName,
					}}
					InputLabelProps={{
						shrink: true,
					}}
					type={type}
				/>
			</div>
		);
	}
);

export default TextInput;
TextInput.displayName = 'TextInput';
