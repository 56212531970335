import { setCurrUser, setIsLoading } from '@store/reducers/auth';

import baseApi from './baseApi';

import type { ILogInPayload, LogInResponse } from 'shared/interfaces/auth';
import type { UserQueryReturnType } from 'shared/interfaces/user';

export const authApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		logIn: builder.mutation<LogInResponse, ILogInPayload>({
			query: (logInPayload: ILogInPayload) => ({
				method: 'POST',
				url: '/api/auth/login',
				data: logInPayload,
			}),
		}),

		logOut: builder.mutation<void, void>({
			query: () => ({
				method: 'POST',
				url: '/api/auth/logout',
			}),
		}),

		me: builder.query<UserQueryReturnType, void>({
			query: () => ({
				method: 'GET',
				url: '/api/auth/me',
			}),
			keepUnusedDataFor: 0,
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				dispatch(setIsLoading(true));

				try {
					const { data } = await queryFulfilled;

					if (typeof data === 'object' && 'id' in data.data) {
						dispatch(setCurrUser(data.data));
					}
				} finally {
					dispatch(setIsLoading(false));
				}
			},
		}),
	}),
});

export const { useLogInMutation, useLogOutMutation, useMeQuery, useLazyMeQuery } = authApi;
