export enum EContentType {
	Material = 'material',
	ExternalUrl = 'external_url',
	Separator = 'separator',
}

type IContentTypes = {
	[key in EContentType]: { label: string };
};

export const ContentTypes: IContentTypes = {
	[EContentType.Material]: { label: 'Материал' },
	[EContentType.ExternalUrl]: { label: 'Внешняя гиперссылка' },
	[EContentType.Separator]: { label: 'Разделитель' },
};
