import AudioFileIcon from '@mui/icons-material/AudioFile';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MovieIcon from '@mui/icons-material/Movie';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Button, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';

import { CheckBoxIcon, CheckBoxCheckedIcon, CloseFileIcon, CloseFileIconError } from 'shared/assets/svgs';
import { checkExtension } from 'shared/ui/FileUploader/FileUploader.lib';
import ProgressBar from 'shared/ui/ProgressBar/ProgressBar';

import type { SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/types';
import type { Dispatch, FC, SetStateAction, ChangeEvent } from 'react';
import type { IProgress } from 'shared/ui/FileUploadModal/FileUploadModal';
import type { TransformedFile } from 'shared/ui/FileUploader/FileUploader.lib';

const previewIcons: CurrentIcon = {
	audio: AudioFileIcon,
	document: TextSnippetIcon,
	image: ImageIcon,
	video: MovieIcon,
	others: InsertDriveFileIcon,
};

const FileItem: FC<FileItemProps> = ({ file, setFiles, mediaTypes, progress, isLoading, withWatermark }) => {
	const [isStartRead, setIsStartRead] = useState(false);

	const [loadedPercent, setLoadedPercent] = useState(0);

	const [error, setError] = useState<Nullable<boolean>>(false);

	const [dataUrl, setDataUrl] = useState('');

	const PreviewIcon = previewIcons[file.variant];

	const isExtensionExist = checkExtension(file, mediaTypes);

	useEffect(() => {
		return () => {
			if (dataUrl) URL.revokeObjectURL(dataUrl);
		};
	}, [dataUrl]);

	const onDeleteHandler = () => {
		setFiles((files) => files.filter((f) => f.id !== file.id));
	};

	const onOpenFileHandler = (url?: string) => {
		window.open(url ?? dataUrl, '_blank', 'noreferrer');
	};

	const onShowFileHandler = () => {
		setIsStartRead(true);
		const reader = new FileReader();

		reader.addEventListener('progress', (event) => {
			const percentLoaded = Math.round((event.loaded / event.total) * 100);
			setLoadedPercent(percentLoaded);
		});

		reader.addEventListener('load', () => {
			setLoadedPercent(100);
			const blob = new Blob([reader.result!], { type: file.originalFile.type });
			const objUrl = URL.createObjectURL(blob);
			setDataUrl(objUrl);
			onOpenFileHandler(objUrl);
		});
		reader.addEventListener('error', () => {
			setError(true);
		});
		reader.readAsArrayBuffer(file.originalFile);
	};

	const onChangeHandler = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setFiles((prevState) => prevState.map((el) => (el.id === file.id ? { ...el, isWatermark: checked } : { ...el })));
	};

	const isLoaded = loadedPercent === 100;

	const progressText = error ? 'Невозможно прочитать файл' : !isLoaded ? `${loadedPercent}%` : 'Загружено';

	return (
		<div>
			<div
				className={`flex justify-between rounded-lg p-4 transition-all duration-150 ease-in hover:bg-gray-light ${
					!error ? 'bg-mainBgColor' : 'bg-errorImg'
				}`}
			>
				<div className='flex flex-col justify-between'>
					<div
						data-id='UploadedFile'
						className='flex items-center gap-2'
					>
						<PreviewIcon className={`h-full w-[30px] ${!error ? '' : 'text-error'}`} />
						<div
							data-id='FileInfo'
							className={`text-sm ${!error ? '' : 'text-error'}`}
						>
							{file.originalFile.name} | {file.displaySize}
						</div>
					</div>

					<>
						{mediaTypes && withWatermark && !error && (
							<div className='ml-2 mt-4 flex items-center gap-2 text-xs'>
								<Checkbox
									icon={<CheckBoxIcon />}
									checkedIcon={<CheckBoxCheckedIcon />}
									disabled={!isExtensionExist}
									onChange={onChangeHandler}
									checked={file.isWatermark}
									className='p-0'
									color='primary'
								/>
								<span>Применить водяной знак</span>
							</div>
						)}
					</>
				</div>

				<div className={`${progress?.[file.id] ? 'flex w-[35%]' : ''} flex items-center gap-2`}>
					{!!progress?.[file.id] && <ProgressBar valueLoading={progress?.[file.id]?.progress || 0} />}
					{!error && (
						<Button
							data-id='PreviewButton'
							disabled={isLoading}
							onClick={isLoaded ? () => onOpenFileHandler() : onShowFileHandler}
							className='text-xs normal-case text-enabled'
						>
							Посмотреть файл
						</Button>
					)}
					<Button
						data-id='DeleteButton'
						disabled={isLoading}
						onClick={onDeleteHandler}
						className='min-w-[40px] cursor-pointer'
					>
						{!error ? <CloseFileIcon /> : <CloseFileIconError />}
					</Button>
				</div>
			</div>

			{isStartRead && !isLoaded && (
				<div className='relative z-10 mt-1 flex h-[25px] w-full items-center justify-center overflow-hidden rounded-xl border-2 border-gray-light'>
					<div
						className='absolute bottom-0 left-0 top-0 -z-10 bg-gray'
						style={{
							width: `${loadedPercent}%`,
						}}
					/>
					<div className='text-xs font-bold text-blue'>{progressText}</div>
				</div>
			)}
		</div>
	);
};

type CurrentIcon = Record<
	TransformedFile['variant'],
	OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
		muiName: string;
	}
>;

interface FileItemProps {
	file: TransformedFile;
	setFiles: Dispatch<SetStateAction<TransformedFile[]>>;
	progress?: IProgress;
	isLoading?: boolean;
	mediaTypes?: string[];
	withWatermark: boolean;
}

export default FileItem;
