import { EProcessingStatuses } from 'shared/lib/constants/processing';

import type { TMediaItem } from 'shared/interfaces';
import type { IColumnsConfig } from 'shared/ui/CMSTable/CmsTable.interfaces';

const STATUS = 'Статус';
const mediaColumnConfigs: IColumnsConfig<TMediaItem>[] = [
	{ valueForOrdering: 'name', label: 'Название', dataId: 'Name', renderRow: (row) => row.name },
	{
		label: STATUS,
		dataId: 'Status',
		renderRow: (row) => EProcessingStatuses[row.status],
	},
	{
		valueForOrdering: 'description',
		label: 'Описание',
		dataId: 'Description',
		renderRow: (row) => row.description || '-',
	},
	{ valueForOrdering: 'date', label: 'Дата', dataId: 'CreationDate', renderRow: (row) => row.date },
	{
		valueForOrdering: 'user',
		label: 'Пользователь',
		dataId: 'Author',
		renderRow: (row) => row.user?.login ?? '-',
	},
];

export const getMediaColumnConfigs = (withWatermark: boolean): IColumnsConfig<TMediaItem>[] =>
	Object.values(mediaColumnConfigs).filter((el) => {
		if (el.label === STATUS && withWatermark) {
			return el;
		} else if (el.label === STATUS && !withWatermark) {
			return false;
		} else {
			return el;
		}
	});
