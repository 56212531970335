import type { ICardType11 } from './CardsType11.interfaces';

export const blankCardType11 = (): ICardType11 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	icon: null,
	link_url: '',
	link_text: '',
});
