import type { IMediaFile } from 'shared/interfaces';
import type { EFilePreviewViewModes } from 'shared/ui/Editor/EditorBlockTools/_ui/FilePreview/FIlePreview.interfaces';

export enum EMediaToolFileAlignment {
	CENTER = 'center',
	LEFT = 'left',
	RIGHT = 'right',
	EMPTY = '',
}

export interface IMediaToolData {
	media: IMediaFile;
	captureText: string;
	style: string | null;
	viewMode: EFilePreviewViewModes;
	withBorder: boolean;
	withBackground: boolean;
	stretched: boolean;
	alignment: EMediaToolFileAlignment;
}
