export enum EAccess {
	ALL = 'all',
	GUESTS = 'guests',
	REGISTERED = 'registered',
	ADMINISTRATORS = 'administrators',
	TEST = 'test',
}

type IAccesses = {
	[key in EAccess]: { label: string };
};

export const Accesses: IAccesses = {
	[EAccess.ALL]: { label: 'Все' },
	[EAccess.GUESTS]: { label: 'Гости' },
	[EAccess.REGISTERED]: { label: 'Зарегистрированные' },
	[EAccess.ADMINISTRATORS]: { label: 'Администраторы' },
	[EAccess.TEST]: { label: 'Тест' },
};
