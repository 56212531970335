import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const PreviewMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		viewBox='0 0 24 24'
	>
		<path d='m23.589 22.261-2.102-2.101a4.978 4.978 0 0 0-3.115-7.594l-.033-.006V8.491a1.01 1.01 0 0 0-.233-.646l.001.002v-.005a.94.94 0 0 0-.06-.066l-.008-.009-.027-.027L10.572.3a.91.91 0 0 0-.065-.059l-.026-.018a.938.938 0 0 0-.05-.038l-.025-.018-.054-.034-.023-.012q-.034-.02-.075-.037l-.032-.013-.051-.018-.036-.011-.058-.015-.028-.006a.81.81 0 0 0-.086-.013H1.015A1.019 1.019 0 0 0 0 1.02v20.377a1.02 1.02 0 0 0 1.015 1.019H17.325a4.924 4.924 0 0 0 2.74-.827l-.018.011 2.102 2.102a1.019 1.019 0 0 0 1.439-1.44l.001.001zm-3.325-4.827a2.943 2.943 0 1 1-5.887.001 2.943 2.943 0 0 1 5.887-.002zM10.868 3.478l3.993 3.994h-3.993zm-8.83-1.44h6.793v6.453c0 .563.456 1.019 1.019 1.019h6.453v3.05c-2.278.487-3.962 2.483-3.962 4.873 0 1.109.362 2.133.975 2.96l-.01-.013H2.037z' />
	</svg>
);
export default PreviewMenuIcon;
