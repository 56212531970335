import type { ISVGProps } from '@assets/svgs/interfaces';

const PublicOff = ({ className }: ISVGProps) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		className={className}
	>
		<path d='M11.656 6.01h1.834c.46 0 .833.374.833.834v5.917c0 .69-.56 1.25-1.25 1.25h-.167a1.25 1.25 0 0 1-1.25-1.25V6.01Z' />
		<path
			fillRule='evenodd'
			d='M3.323 1.344A1.667 1.667 0 0 0 1.656 3.01v10a1.667 1.667 0 0 0 1.667 1.667h8.818c.007 0 .014 0 .02-.003a1.25 1.25 0 0 1-1.171-1.247V3.01a1.667 1.667 0 0 0-1.667-1.666h-6Zm-.167 3.333a.5.5 0 0 1 .5-.5H8.99a.5.5 0 0 1 0 1H3.656a.5.5 0 0 1-.5-.5Zm.5 1.5a.5.5 0 1 0 0 1H8.99a.5.5 0 0 0 0-1H3.656Zm1.538 3.017a.75.75 0 0 1 1.06 0l.413.412.412-.412a.75.75 0 1 1 1.06 1.06l-.412.413.413.412a.75.75 0 0 1-1.06 1.06l-.413-.412-.413.413a.75.75 0 1 1-1.06-1.06l.412-.413-.412-.413a.75.75 0 0 1 0-1.06Z'
			clipRule='evenodd'
		/>
	</svg>
);
export default PublicOff;
