import { Typography } from '@mui/material';

import { getMaxUploadSizeExceeded } from 'shared/lib/constants/validationMessages';

import type { FC } from 'react';

const FileUploaderValidationMessage: FC<IFileUploaderValidationMessageProps> = ({ isValidFileSize, validationError, maxFileSize }) => {
	const isThereError = !isValidFileSize || !!validationError;

	const validationMessage = !isValidFileSize ? getMaxUploadSizeExceeded(maxFileSize) : validationError;

	return (
		<div className='flex flex-col items-center gap-3'>
			{isThereError && (
				<Typography
					color='error'
					align='center'
				>
					{validationMessage}
				</Typography>
			)}
		</div>
	);
};

interface IFileUploaderValidationMessageProps {
	isValidFileSize: boolean;
	validationError: string;
	maxFileSize: number;
}

export default FileUploaderValidationMessage;
