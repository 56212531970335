import type { ICardType4 } from './CardsType4.interfaces';

export const blankCardType4 = (): ICardType4 => ({
	id: crypto.randomUUID(),
	style: '',
	subtitle: '',
	title: '',
	description_1: '',
	description_2: '',
	image_main: null,
});
