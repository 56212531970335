import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import cn from 'classnames';

import { ReactComponent as ArrowIcon } from 'shared/assets/svgs/arrowDownGray.svg';

import type { FilledInputProps } from '@mui/material/FilledInput';
import type { TextFieldProps } from '@mui/material/TextField';
import type { FocusEventHandler } from 'react';
import type { SyntheticEvent } from 'react';

type Props<T extends { id: Nullable<number | string> }> = {
	items: T[];
	setValue: (value: NonNullable<T>) => void;
	getOptionLabel?: (option: T | string) => string;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	nullValue?: T;
	defaultValue?: NonNullable<T>;
	value?: NonNullable<T>;
	loading?: boolean;
	disabled?: boolean;
	name?: string;
	isSearchable?: boolean;
	disableClearable?: boolean;
	freeSolo?: boolean;
	autocompleteClassName?: string;
	isEmptyValue?: string;
	InputProps?: Partial<FilledInputProps>;

	onBlur?: FocusEventHandler<HTMLDivElement>;
} & TextFieldProps;

const SelectWithInput = <T extends { id: Nullable<number | string> }>({
	defaultValue,
	setValue,
	getOptionLabel,
	isOptionEqualToValue,
	items,
	loading,
	disabled,
	value,
	isEmptyValue,
	name,
	onBlur,
	nullValue,
	freeSolo = false,
	isSearchable = true,
	InputProps = {
		classes: {
			root: '!py-3 !px-5',
			input: '!p-0',
		},
	},
	autocompleteClassName = '',
	...restProps
}: Props<T>) => {
	const onChangeHandler = (_: SyntheticEvent<Element, Event>, newValue: any) => {
		setValue(newValue);
	};

	const showDeleteIcon = !isEmptyValue;

	const buttonClearIconStyle = cn(' !pt-0 !pb-0', {
		'relative !right-[-11px]': isEmptyValue,
		'absolute !top-[calc(50%_-_12px)]': !isEmptyValue,
	});

	return (
		<Autocomplete
			defaultValue={defaultValue}
			fullWidth
			className={autocompleteClassName}
			disablePortal
			id={name}
			isOptionEqualToValue={isOptionEqualToValue}
			forcePopupIcon={true}
			options={nullValue ? [nullValue, ...items] : items}
			loading={loading}
			popupIcon={<ArrowIcon />}
			value={value}
			freeSolo={freeSolo}
			getOptionLabel={getOptionLabel}
			disableClearable={showDeleteIcon}
			clearIcon={<ClearIcon fontSize='small' />}
			onChange={onChangeHandler}
			onBlur={onBlur}
			disabled={disabled || loading}
			classes={{
				endAdornment: buttonClearIconStyle,
				clearIndicator: 'p-0',
			}}
			renderOption={(props, option) => (
				<MenuItem
					{...props}
					key={props?.id}
				>
					{getOptionLabel?.(option)}
				</MenuItem>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					{...restProps}
					name={name}
					InputLabelProps={{
						shrink: true,
						classes: {
							root: 'text-gray',
						},
					}}
					InputProps={{
						...params.InputProps,
						type: isSearchable ? 'search' : 'text',
						...InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress
										color='inherit'
										size={20}
									/>
								) : null}

								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export default SelectWithInput;
