import cn from 'classnames';
import videojs from 'video.js';

import styles from 'shared/ui/VideoPlayer/VideoPlayer.module.css';

import type Player from 'video.js/dist/types/player';
import type { QualityLevelList, QualityLevel } from 'videojs-contrib-quality-levels';

const Component = videojs.getComponent('ClickableComponent');

const mainMenuItems1 = [
	{ label: 'Качество видео', value: 'quality' },
	// { label: 'Субтитры', value: 'subtitles' },
	// { label: 'Маштаб', value: 'scale' },
];

const qualityMenuItems = [{ label: 'Авто', value: -1 }];

//@ts-ignore
class PlayerSettings extends Component {
	constructor(player: Player, options = {}) {
		super(player, options);
	}

	handleClick() {}

	createEl() {
		//@ts-ignore
		const { qualityLevels } = this.options_ as { qualityLevels: QualityLevelList };
		let isAutoChecked = true;

		const menuEl = videojs.dom.createEl('div', {
			className: cn(styles.custom_vjs__settingsMenu, styles.custom_vjs_hidden),
		});

		const menuIcon = videojs.dom.createEl('div', {
			className: cn(styles.custom_vjs__settingsIcon),
		});

		const menuButton = videojs.dom.createEl('div', {
			className: styles.custom_vjs__settingsButton,
			onclick: () => {
				menuEl.classList.toggle(styles.custom_vjs_hidden);
			},
		});

		const mainMenu = videojs.dom.createEl('div', {
			className: cn(styles.custom_vjs__settingsMenu_subMenu),
		});

		const qualityMenu = videojs.dom.createEl('div', {
			className: cn(styles.custom_vjs__settingsMenu_subMenu, styles.custom_vjs_hidden),
		});

		const generateQualityChecked = () => {
			return videojs.dom.createEl('div', {
				className: cn(styles.custom_vjs__qualityItemCheckbox),
			});
		};

		qualityMenuItems.forEach((item) => {
			const itemEl = videojs.dom.createEl('div', {
				className: cn(styles.custom_vjs__settingsItem, styles.custom_vjs__activeItem),
				innerText: item.label,
				id: 'quality-auto',
				onclick: () => {
					isAutoChecked = true;
					//@ts-ignore
					qualityLevels.selectedIndex_ = -1;
					qualityLevels.trigger({ type: 'change', selectedIndex: -1 });
					mainMenu.classList.remove(styles.custom_vjs_hidden);
					qualityMenu.classList.add(styles.custom_vjs_hidden);
				},
			});
			itemEl.appendChild(generateQualityChecked());
			qualityMenu.appendChild(itemEl);
		});

		mainMenuItems1.forEach((item) => {
			const itemEl = videojs.dom.createEl('div', {
				className: cn(styles.custom_vjs__settingsItem),
				innerText: item.label,
				onclick: (event: Event) => {
					event.stopImmediatePropagation();
					mainMenu.classList.add(styles.custom_vjs_hidden);
					qualityMenu.classList.remove(styles.custom_vjs_hidden);
				},
			});
			mainMenu.appendChild(itemEl);
		});

		menuEl.appendChild(mainMenu);
		menuEl.appendChild(qualityMenu);

		menuButton.appendChild(menuIcon);
		menuButton.appendChild(menuEl);

		qualityLevels.on('addqualitylevel', ({ qualityLevel }: { qualityLevel: QualityLevel }) => {
			const qualityItem = videojs.dom.createEl('div', {
				className: cn(styles.custom_vjs__settingsItem),
				id: `quality-${qualityLevel.height}`,
				innerText: qualityLevel.height,
				onclick: (clickEvent: Event) => {
					clickEvent.stopImmediatePropagation();

					isAutoChecked = false;
					//@ts-ignore
					const newLevelIndex: number = qualityLevels.levels_.findIndex((level) => level.height === qualityLevel.height);
					// @ts-ignore
					qualityLevels.selectedIndex_ = newLevelIndex;
					qualityLevels.trigger({ type: 'change', selectedIndex: newLevelIndex });
					mainMenu.classList.remove(styles.custom_vjs_hidden);
					qualityMenu.classList.add(styles.custom_vjs_hidden);
					menuEl.classList.add(styles.custom_vjs_hidden);
				},
			});
			qualityItem.appendChild(generateQualityChecked());
			qualityMenu.appendChild(qualityItem);
			const items = Array.from(qualityMenu.children);
			items.sort((aEl, bEl) => {
				const a = Number(aEl.id.replace('quality-', ''));
				const b = Number(bEl.id.replace('quality-', ''));
				if (Number.isNaN(a)) return 1;
				if (Number.isNaN(b)) return -1;
				if (a > b) return -1;
				if (a < b) return 1;
				return 0;
			});
			items.forEach((item) => {
				qualityMenu.appendChild(item);
			});
		});

		qualityLevels.on('change', () => {
			const currLevel = qualityLevels[qualityLevels.selectedIndex];

			const qualityItems = Array.from(qualityMenu.children);
			qualityItems.forEach((item) => {
				item.classList.remove(styles.custom_vjs__activeItem);
			});

			if (isAutoChecked) {
				const autoQualityItem = videojs.dom.$('#quality-auto');
				autoQualityItem.classList.add(styles.custom_vjs__activeItem);
			} else {
				const qualityItem = videojs.dom.$(`#quality-${currLevel.height}`);
				qualityItem.classList.add(styles.custom_vjs__activeItem);
			}

			// @ts-ignore
			for (let level: QualityLevel of qualityLevels) {
				if (isAutoChecked) {
					level.enabled = true;
					continue;
				}

				level.enabled = currLevel.height === level.height;
			}
		});

		return menuButton;
	}
}

export default PlayerSettings;
