import type { FormikErrors } from 'formik';

export const transformToFormikErrors = (
	rawErrors?: Record<string, string>
): FormikErrors<Record<string, any>> => {
	if (!rawErrors) return {};

	const resultErrors: Record<string, any> = {};

	Object.entries(rawErrors).forEach(([key, value]) => {
		const path = key.split('.');
		let ref = resultErrors;

		path.forEach((keyInPath, keyInPathIndex) => {
			if (path.length - 1 === keyInPathIndex) {
				ref[keyInPath] = value;
				return;
			}

			if (!ref[keyInPath]) {
				ref[keyInPath] = {};
			}

			ref = ref[keyInPath];
		});
	});

	return resultErrors;
};
