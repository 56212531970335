import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

import { useGetMaterialTypesSelectOptionsQuery } from 'shared/api/materialTypesApi';
import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { FIELD_TYPES_SELECT_OPTIONS } from 'shared/lib/constants';
import { SelectInput, TextInput } from 'shared/ui';
import { ControlMenus } from 'shared/ui/Editor/EditorBlockTools/_ui';

import type { FC } from 'react';
import type { INewsComponentData } from 'shared/interfaces';
import type { INewsToolFormProps } from 'shared/ui/Editor/EditorBlockTools/NewsTool/NewsTool.interfaces';

const NewsToolForm: FC<INewsToolFormProps> = ({ onDataChange, parentMaterials, moveBlockUp, moveBlockDown, deleteBlock }) => {
	const { isModalOpen, openModal, closeModal } = useModal();

	// material-types
	const { data: materialTypesSelectOptions } = useGetMaterialTypesSelectOptionsQuery();

	const { values, handleChange, getFieldProps, setFieldValue } = useFormikContext<INewsComponentData>();

	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	const materialTypeIndex = materialTypesSelectOptions?.data.findIndex((materialType) => materialType.id === values.material_type_id);

	useClickOutside(menuRef, closeModal);

	useEffect(() => {
		onDataChange(values);
	}, [onDataChange, values]);

	return (
		<div>
			<h2 className='mb-3'>Материалы</h2>
			<div className='absolute right-[20px] top-[10px]'>
				<ControlMenus
					menuRef={menuRef}
					openModal={openModal}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
				/>
			</div>
			<div className='mb-2 flex gap-4'>
				<TextInput
					{...getFieldProps('title')}
					label='Заголовок'
					placeholder='Введите заголовок'
				/>
				<TextInput
					{...getFieldProps('count')}
					label='Количество выводимых новостей'
					placeholder='Введите количество отображаемых новостей'
				/>
				<SelectInput
					label='Тип материала'
					className='h-[48px]'
					{...getFieldProps('material_type_id')}
					onChange={(event) => {
						setFieldValue('useFilters', false);
						setFieldValue('filters', undefined);

						handleChange(event);
					}}
				>
					<MenuItem value=''>-</MenuItem>
					{materialTypesSelectOptions?.data.map(({ id, name }) => (
						<MenuItem
							key={id}
							value={id}
						>
							{name}
						</MenuItem>
					))}
				</SelectInput>
				<SelectInput
					{...getFieldProps('material_id')}
					label='Родительский материал'
					className='h-[48px]'
				>
					<MenuItem value=''>-</MenuItem>
					{parentMaterials.map(({ id, translations }) => (
						<MenuItem
							key={id}
							value={id}
						>
							{translations.ru?.title}
						</MenuItem>
					))}
				</SelectInput>
			</div>
			<div className='flex gap-3'>
				<TextInput
					{...getFieldProps('external_url')}
					label='URL'
					placeholder='Введите URL'
				/>
				<TextInput
					{...getFieldProps('accompanying_text')}
					label='Сопроводительный текст для URL'
					placeholder='Введите сопроводительный текст для URL'
				/>
			</div>
			<div className='my-m flex flex-col'>
				<TextInput
					{...getFieldProps('style')}
					label='Стиль'
					placeholder='Введите стиль'
				/>
			</div>
			{materialTypeIndex && materialTypesSelectOptions?.data[materialTypeIndex]?.data ? (
				<div className='mb-6'>
					<FormControlLabel
						label='Использование фильтров по типам материалов'
						control={
							<Checkbox
								checked={values.useFilters ?? false}
								onChange={(event) => {
									const customEvent = {
										...event,
										target: {
											...event?.target,
											name: 'useFilters',
											value: event.target.checked,
										},
									};

									if (!event.target.checked) {
										setFieldValue('filters', undefined);
									}

									handleChange(customEvent);
								}}
							/>
						}
					/>
				</div>
			) : null}

			{values.useFilters && materialTypeIndex
				? materialTypesSelectOptions?.data[materialTypeIndex]?.data.map((materialTypeField) => {
						return (
							<div
								key={materialTypeField.key}
								className='mb-4 grid grid-cols-2 items-center'
							>
								<FormControlLabel
									label={materialTypeField.key}
									control={
										<Checkbox
											checked={values.filters?.[materialTypeField.key]?.active ?? false}
											onChange={(event) => {
												const customEvent = {
													...event,
													target: {
														...event?.target,
														name: `filters.${materialTypeField.key}.active`,
														value: event.target.checked,
													},
												};

												if (!event.target.checked) {
													setFieldValue(`filters.${materialTypeField.key}.field_type`, undefined);
												}

												handleChange(customEvent);
											}}
										/>
									}
								/>
								<SelectInput
									className='h-[48px]'
									label='Тип поля фильтра'
									placeholder='Выберите тип поля'
									disabled={!values.filters?.[materialTypeField.key]?.active}
									{...getFieldProps(`filters.${materialTypeField.key}.field_type`)}
									value={values.filters?.[materialTypeField.key]?.field_type}
								>
									{Object.entries(FIELD_TYPES_SELECT_OPTIONS).map(([value, label]) => (
										<MenuItem
											key={value}
											value={value}
										>
											{label}
										</MenuItem>
									))}
								</SelectInput>
							</div>
						);
				  })
				: null}
		</div>
	);
};

export default NewsToolForm;
