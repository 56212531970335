import icon1 from 'shared/assets/svgs/MenuIcon1.svg';
import icon1Active from 'shared/assets/svgs/MenuIcon1Active.svg';
import icon2 from 'shared/assets/svgs/MenuIcon2.svg';
import icon2Active from 'shared/assets/svgs/MenuIcon2Active.svg';
import icon3 from 'shared/assets/svgs/MenuIcon3.svg';
import icon3Active from 'shared/assets/svgs/MenuIcon3Active.svg';
import icon4 from 'shared/assets/svgs/MenuIcon4.svg';
import icon4Active from 'shared/assets/svgs/MenuIcon4Active.svg';
import icon5 from 'shared/assets/svgs/MenuIcon5.svg';
import icon5Active from 'shared/assets/svgs/MenuIcon5Active.svg';
import icon6 from 'shared/assets/svgs/MenuIcon6.svg';
import icon6Active from 'shared/assets/svgs/MenuIcon6Active.svg';
import { AllPermissions } from 'shared/lib/constants/permissions';
import { ERoutes } from 'shared/lib/constants/routes';

import type { TAccordionMenuItem } from 'app/ui/AccordionMenu/AccordionMenu.interfaces';

export const menuItems: TAccordionMenuItem[] = [
	{
		title: 'Пользователи',
		dataId: 'UsersManagement',
		img: icon1,
		imgActive: icon1Active,
		items: [
			{
				dataId: 'Users',
				title: 'Пользователи',
				to: ERoutes.USERS,
				permissions: AllPermissions.users,
			},
			{
				dataId: 'Roles',
				title: 'Роли',
				to: ERoutes.ROLES,
				permissions: AllPermissions.roles,
			},
		],
	},
	{
		title: 'Материалы',
		img: icon2,
		imgActive: icon2Active,
		dataId: 'MaterialsManagement',
		items: [
			{
				dataId: 'Materials',
				title: 'Менеджер материалов',
				to: ERoutes.MATERIALS,
				permissions: AllPermissions.materials,
			},
			{
				dataId: 'Material-Types',
				title: 'Менеджер типов материала',
				to: ERoutes.MATERIAL_TYPES,
				permissions: AllPermissions.materialTypes,
			},
			{
				dataId: 'Forms',
				title: 'Менеджер форм',
				to: ERoutes.FORMS,
				permissions: AllPermissions.forms,
			},
		],
	},
	{
		title: 'Меню',
		img: icon3,
		imgActive: icon3Active,
		dataId: 'MenuManagement',
		items: [
			{
				dataId: 'BlockMenus',
				title: 'Менеджер блоков меню',
				to: ERoutes.MENU_BLOCK,
				permissions: AllPermissions.menuBlocks,
			},
			{
				dataId: 'Menus',
				title: 'Менеджер пунктов меню',
				to: ERoutes.MENU_ITEMS,
				permissions: AllPermissions.menuItems,
			},
		],
	},
	{
		title: 'Медиаконтент',
		img: icon4,
		imgActive: icon4Active,
		dataId: 'MediaManagement',
		items: [
			{
				dataId: 'Media',
				title: 'Библиотека',
				to: ERoutes.MEDIA,
				permissions: AllPermissions.media,
			},
		],
	},
	{
		title: 'Настройки',
		img: icon5,
		imgActive: icon5Active,
		dataId: 'Settings',
		items: [
			{
				dataId: 'GeneralSettings',
				title: 'Общие настройки',
				to: ERoutes.GENERAL,
				permissions: AllPermissions.settings.common,
			},
			{
				dataId: 'Locales',
				title: 'Мультиязычность',
				permissions: AllPermissions.locales,
				to: ERoutes.LOCALES,
			},
			{
				dataId: 'SiteHeader',
				title: 'Шапка сайта',
				permissions: AllPermissions.settings.header,
				to: ERoutes.HEADER_SITE,
			},
			{
				dataId: 'SiteFooter',
				title: 'Подвал сайта',
				permissions: AllPermissions.settings.footer,
				to: ERoutes.FOOTER_SITE,
			},
			{
				dataId: 'EventLog',
				title: 'Журнал событий',
				to: ERoutes.EVENT_LOG,
				permissions: AllPermissions.settings.activities,
			},

			{
				dataId: 'Watermarks',
				title: 'Водяные знаки',
				to: ERoutes.WATERMARK,
				permissions: AllPermissions.settings.watermarks,
			},
			{
				dataId: 'SubscriptionsManger',
				title: 'Почта',
				to: ERoutes.MAIL,
				permissions: AllPermissions.settings.mail,
			},
		],
	},
	{
		title: 'Корзина',
		img: icon6,
		imgActive: icon6Active,
		dataId: 'Basket',
		items: [
			{
				dataId: 'Locales',
				title: 'Языки',
				to: ERoutes.BASKET_LOCALES,
				permissions: AllPermissions.locales,
			},
			{
				dataId: 'Materials',
				title: 'Материалы',
				to: ERoutes.BASKET_MATERIALS,
				permissions: AllPermissions.materials,
			},
			{
				dataId: 'Menus',
				title: 'Пункты меню',
				to: ERoutes.BASKET_MENU_ITEMS,
				permissions: AllPermissions.menuItems,
			},
		],
	},
];
