import { useNavigator } from 'shared/hooks/useNavigator';
import { ERoutes } from 'shared/lib/constants';
import { Button } from 'shared/ui';

// interface IProps {}

export const NoPermissionFallback = () => {
	const { navigate } = useNavigator();
	return (
		<div className='flex h-full w-full grow flex-col items-center justify-center gap-l'>
			<div className=''>У вас нет разрешений просматривать эту страницу</div>
			<div className=''>
				<Button
					variant='contained'
					onClick={() => navigate(ERoutes.HOME)}
				>
					На главную
				</Button>
			</div>
		</div>
	);
};
