import { Formik } from 'formik';

import ButtonToolForm from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ui/ButtonToolForm/ButtonToolForm';

import type { FC } from 'react';
import type { IButtonToolProps } from 'shared/ui/Editor/EditorBlockTools/ButtonTool/ButtonTool.interfaces';

const ButtonTool: FC<IButtonToolProps> = ({ data, onDataChange, deleteBlock, moveBlockUp, moveBlockDown }) => {
	return (
		<Formik
			initialValues={data}
			onSubmit={(values) => onDataChange(values)}
		>
			{() => (
				<ButtonToolForm
					onDataChange={onDataChange}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
				/>
			)}
		</Formik>
	);
};

export default ButtonTool;
