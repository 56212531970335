import ru from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { TextInput } from 'shared/ui';

import type { IFormRangeDatePickerProps } from './FormRangeDatePicker.types';

registerLocale('ru', ru);

const FormRangeDatePicker = ({ label = '', value = [null, null], name, helperText, onChange, ...restProps }: IFormRangeDatePickerProps) => {
	const targetStartDate = value[0] ? new Date(value[0]) : null;
	const targetEndDate = value[1] ? new Date(value[1]) : null;

	return (
		<DatePicker
			className='form-input'
			locale='ru'
			dateFormat='dd.MM.yyyy'
			autoComplete='off'
			selected={targetStartDate}
			startDate={targetStartDate}
			endDate={targetEndDate}
			selectsRange
			isClearable
			customInput={
				<TextInput
					autoComplete='off'
					label={label}
					helperText={helperText}
					fullWidth
				/>
			}
			{...restProps}
			onChange={([start_date, end_date]) => {
				const formattedStartDate = start_date ? dayjs(start_date).format('YYYY-MM-DD') : null;

				const formattedEndDate = end_date ? dayjs(end_date).format('YYYY-MM-DD') : null;

				onChange(name!, [formattedStartDate, formattedEndDate]);
			}}
		/>
	);
};

export default FormRangeDatePicker;
