import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType5Contact } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType5/CardsType5.interfaces';

interface IProps {
	cardContactData: ICardType5Contact;
	index: number;
	parentIndex: number;
	handleChangeCardContactData: (newData: ICardType5Contact) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleContact = ({
	handleChangeCardContactData,
	parentIndex,
	handleUp,
	handleClose,
	handleDown,
	cardContactData,
	index,
	getFieldProps,
}: IProps) => {
	return (
		<Accordion>
			<AccordionSummary>Контакт №{index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex w-full gap-m'>
					<div className='flex flex-col'>
						<div className=''>Иконка</div>
						<SelectImageWithControls
							handleSelectFile={(newFile) => {
								handleChangeCardContactData({ ...cardContactData, icon: newFile });
							}}
							handleDeleteImage={() => handleChangeCardContactData({ ...cardContactData, icon: null })}
							mediaItem={cardContactData.icon}
						/>
					</div>
					<div className='flex w-full flex-col gap-m'>
						<TextInput
							{...getFieldProps(`cards.${parentIndex}.contacts.${index}.icon_url`)}
							label='URL'
							placeholder='Введите URL'
						/>
						<TextInput
							{...getFieldProps(`cards.${parentIndex}.contacts.${index}.icon_text`)}
							label='Текст иконки'
							placeholder='Введите текст иконки'
						/>
					</div>
				</div>

				<SubTuner
					tuneItem={cardContactData.id}
					handleUp={handleUp}
					handleDown={handleDown}
					handleClose={handleClose}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
