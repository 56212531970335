import { LatLng } from 'leaflet';

import { initialMapCenter } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.constants';

import type { ILPoint } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

export const getBlankPoint = (): ILPoint => ({
	id: crypto.randomUUID(),
	name: '',
	coordinates: new LatLng(initialMapCenter.lat, initialMapCenter.lng),
});