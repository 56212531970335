import type { IContainerTemplate1Data, IContainerTemplate2Data, IContainerTemplate3Data, IContainerTemplate4Data } from './ContainerTemaplates';

export enum EContainerTemplates {
	TITLE_TEXT_TYPE = 'title_text_type',
	WITH_LIST = 'with_list',
	ALERT = 'alert',
	LICENCES = 'licences',
}

export enum EContainerTemplate1FieldTypes {
	TITLE = 'title',
	DESCRIPTION = 'description',
	LINK = 'link',
}

export interface IContainerTemplatesToolTemplate1Data {
	template: EContainerTemplates.TITLE_TEXT_TYPE;
	style: string;
	title: string;
	data: IContainerTemplate1Data;
}

export interface IContainerTemplatesToolTemplate2Data {
	template: EContainerTemplates.WITH_LIST;
	style: string;
	title: string;
	data: IContainerTemplate2Data;
}

export interface IContainerTemplatesToolTemplate3Data {
	template: EContainerTemplates.ALERT;
	style: string;
	title: string;
	data: IContainerTemplate3Data;
}

export interface IContainerTemplatesToolTemplate4Data {
	template: EContainerTemplates.LICENCES;
	style: string;
	title: string;
	data: IContainerTemplate4Data;
}

export type IContainerTemplatesToolData =
	| IContainerTemplatesToolTemplate1Data
	| IContainerTemplatesToolTemplate2Data
	| IContainerTemplatesToolTemplate3Data
	| IContainerTemplatesToolTemplate4Data;
