import type { ISitemapToolData } from './SitemapTool.interfaces';

export const sitemapTooltip = {
	title: 'Карта сайта',
	icon:
		'<svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n' +
		'\t viewBox="0 0 512 512" xml:space="preserve">\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M468.32,53.08H43.68C19.595,53.08,0,72.675,0,96.76V415.24c0,24.085,19.595,43.68,43.68,43.68H468.32\n' +
		'\t\t\tc24.085,0,43.68-19.595,43.68-43.68V96.76C512,72.675,492.405,53.08,468.32,53.08z M495.413,415.24\n' +
		'\t\t\tc0,14.939-12.154,27.093-27.093,27.093H43.68c-14.939,0-27.093-12.154-27.093-27.093V96.76c0-14.939,12.154-27.093,27.093-27.093\n' +
		'\t\t\tH468.32c14.939,0,27.093,12.154,27.093,27.093V415.24z"/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'<g>\n' +
		'\t<g>\n' +
		'\t\t<path d="M450.626,290.834c4.58,0,8.294-3.713,8.294-8.294v-53.08c0-4.58-3.713-8.294-8.294-8.294h-80.173v-36.492h27.093\n' +
		'\t\t\tc4.58,0,8.294-3.713,8.294-8.294V123.3c0-4.58-3.713-8.294-8.294-8.294H114.454c-4.58,0-8.294,3.713-8.294,8.294v53.08\n' +
		'\t\t\tc0,4.58,3.713,8.294,8.294,8.294h27.093v36.492H61.374c-4.58,0-8.294,3.713-8.294,8.294v53.08c0,4.58,3.713,8.294,8.294,8.294\n' +
		'\t\t\th27.093v36.492H61.374c-4.58,0-8.294,3.713-8.294,8.294v53.08c0,4.58,3.713,8.294,8.294,8.294h70.773\n' +
		'\t\t\tc4.58,0,8.294-3.713,8.294-8.294v-53.08c0-4.58-3.713-8.294-8.294-8.294h-27.093v-36.492h89.572v36.492h-27.093\n' +
		'\t\t\tc-4.58,0-8.294,3.713-8.294,8.294v53.08c0,4.58,3.713,8.294,8.294,8.294h70.773c4.58,0,8.294-3.713,8.294-8.294v-53.08\n' +
		'\t\t\tc0-4.58-3.713-8.294-8.294-8.294h-27.093v-36.492h27.093c4.58,0,8.294-3.713,8.294-8.294v-53.08c0-4.58-3.713-8.294-8.294-8.294\n' +
		'\t\t\th-80.173v-36.492h195.732v36.492h-80.173c-4.58,0-8.294,3.713-8.294,8.294v53.08c0,4.58,3.713,8.294,8.294,8.294h27.093v36.492\n' +
		'\t\t\th-27.093c-4.58,0-8.294,3.713-8.294,8.294v53.08c0,4.58,3.713,8.294,8.294,8.294h70.773c4.58,0,8.294-3.713,8.294-8.294v-53.08\n' +
		'\t\t\tc0-4.58-3.713-8.294-8.294-8.294h-27.093v-36.492h89.572v36.492h-27.093c-4.58,0-8.294,3.713-8.294,8.294v53.08\n' +
		'\t\t\tc0,4.58,3.713,8.294,8.294,8.294h70.773c4.58,0,8.294-3.713,8.294-8.294v-53.08c0-4.58-3.713-8.294-8.294-8.294h-27.093v-36.492\n' +
		'\t\t\tH450.626z M123.853,343.914v36.492H69.667v-36.492H123.853z M230.013,343.914v36.492h-54.186v-36.492H230.013z M230.013,237.754\n' +
		'\t\t\tv36.492H69.667v-36.492H230.013z M122.747,168.086v-36.492h266.505v36.492H122.747z M336.173,343.914v36.492h-54.186v-36.492\n' +
		'\t\t\tH336.173z M442.333,343.914v36.492h-54.186v-36.492H442.333z M281.987,274.246v-36.492h160.346v36.492H281.987z"/>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'</svg>',
};

const initialSitemapData: ISitemapToolData = {};

export const getInitialSitemapData = (params: { copyWith?: Partial<ISitemapToolData> } = {}): ISitemapToolData => {
	const { copyWith } = params;
	return {
		...initialSitemapData,
		...copyWith,
	};
};
