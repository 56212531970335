import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const StretchedMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={17}
		height={10}
		strokeWidth={0}
	>
		<path d='M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469A1.069 1.069 0 0 1 5.892 1.98L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z' />
	</svg>
);
export default StretchedMenuIcon;
