import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import classNames from 'classnames';
import { useCallback } from 'react';

import { SUPER_ADMIN_ROLE } from 'pages/usersManager/roles/Roles.meta';
import { ReactComponent as CheckBoxIcon } from 'shared/assets/svgs/checkbox.svg';
import { ReactComponent as CheckBoxCheckedIcon } from 'shared/assets/svgs/checkboxChecked.svg';
import { stopPropagation } from 'shared/lib/helpers';
import { EDisabledItems } from 'shared/ui/CMSTable/CmsTable.interfaces';
import { rowSlicer } from 'shared/ui/CMSTable/CmsTable.lib';
import CMSSearch from 'shared/ui/CMSTable/ui/CMSSearch/CMSSearch';
import { Pagination } from 'shared/ui/Pagination/Pagination';
import { Spinner } from 'shared/ui/Spinner/Spinner';

import './CMSTable.scss';
import { CMSTableHead } from './ui/CMSTableHead';

import type { EOrderDirection, TProps } from 'shared/ui/CMSTable/CmsTable.interfaces';

const CmsTable = <T extends { id: string | number }>({
	columnsConfigs,
	rows = [],
	totalRows,
	selectedRows,
	setSelectedRows,
	updateParams,
	params,
	onRowClick = () => {},
	header,
	areRowsFetching = false,
	isUserHasViewPermission = false,
	openEditionModal,
	isSearchable = false,
	singleSelectionMode = false,
	isNotCheckbox = false,
	disabledField,
	filters,
	isSliseText = true,
}: TProps<T>) => {
	const clearItemsSelection = useCallback(() => setSelectedRows(new Map()), [setSelectedRows]);

	const checkedCursor = classNames('TableCellRange', {
		'cursor-pointer': isUserHasViewPermission,
		'cursor-default': !isUserHasViewPermission,
	});

	const onSelect = (item: T) => {
		if (singleSelectionMode) {
			setSelectedRows((prevSelected) => {
				if (prevSelected.has(item.id)) {
					return new Map();
				}

				const copy = new Map();
				copy.set(item.id, item);

				return copy;
			});
			return;
		}

		setSelectedRows((prevSelected) => {
			const copy = new Map(prevSelected);

			if (!copy.has(item.id)) {
				copy.set(item.id, item);
			} else {
				copy.delete(item.id);
			}

			return copy;
		});
	};

	const isNoData = !areRowsFetching && !rows?.length;

	const showTableRow = (row: T) => {
		if (!isUserHasViewPermission) return;
		if (openEditionModal) {
			openEditionModal(row);
		}
	};

	const generateDisabledCondition = (row: any) => {
		switch (disabledField) {
			case EDisabledItems.users:
				return row.role.name === SUPER_ADMIN_ROLE;
			case EDisabledItems.locale:
				return row.code === 'ru';
			case EDisabledItems.role:
				return row.id === 1;
			default:
				return false;
		}
	};

	return (
		<>
			<div className='flex flex-wrap items-center justify-start gap-1'>{header}</div>
			<div
				data-id='Filters'
				className='flex flex-wrap items-center gap-4'
			>
				{isSearchable && (
					<CMSSearch
						searchQuery={params.title}
						clearItemsSelection={clearItemsSelection}
						updateParams={updateParams}
					/>
				)}
				{filters}
			</div>

			<Paper
				elevation={0}
				className={'flex h-full grow flex-col rounded-lg'}
			>
				<TableContainer
					className='relative mt-2 flex grow flex-col'
					data-id='TableContainer'
				>
					{/* extra div to keep Table rows slim */}
					<div className='grow p-2'>
						<Table aria-labelledby='tableTitle'>
							<CMSTableHead
								rows={rows}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								columnsConfigs={columnsConfigs}
								isUserHasViewPermission={isUserHasViewPermission}
								sortBy={params.sort_by}
								sortDirection={params.sort_direction as EOrderDirection}
								updateParams={updateParams}
								singleSelectionMode={singleSelectionMode}
								isNotCheckbox={isNotCheckbox}
							/>

							<TableBody>
								{rows.map((row) => {
									const isItemSelected = selectedRows.has(row.id);
									const labelId = `enhanced-table-checkbox-${row.id}`;

									return (
										<TableRow
											data-id='TableRow'
											hover
											onClick={() => onRowClick(row)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											{!isNotCheckbox && (
												<TableCell
													padding='checkbox'
													className='w-[40px] max-w-[40px]'
												>
													<Checkbox
														data-id='RowCheckBox'
														icon={<CheckBoxIcon />}
														checkedIcon={<CheckBoxCheckedIcon />}
														disabled={!isUserHasViewPermission || generateDisabledCondition(row)}
														color='primary'
														checked={isItemSelected}
														onChange={() => onSelect(row)}
														onClick={stopPropagation}
														title='Выбрать элемент'
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
											)}

											{columnsConfigs.map((column, index) => (
												<TableCell
													data-id={column.dataId}
													className={checkedCursor}
													key={column.label}
													onClick={index === 0 ? () => showTableRow(row) : () => {}}
													id={labelId}
													title={(column.renderRow(row) as string) ?? '-'}
													{...{
														align: 'left',
														...column.cellProps?.(row),
													}}
												>
													{isSliseText ? rowSlicer((column.renderRow(row) as string) ?? '-', 30) : (column.renderRow(row) as string)}
												</TableCell>
											))}
										</TableRow>
									);
								})}

								{isNoData && (
									<TableRow>
										<TableCell
											colSpan={columnsConfigs.length + 1}
											align='center'
										>
											<h3>Данные отсутствуют!</h3>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
			</Paper>
			<Pagination
				totalRows={totalRows}
				setSelectedRows={setSelectedRows}
				actualRowsOnPage={rows.length}
				page={params?.page}
				updateParams={updateParams}
				perPage={params?.per_page}
				clearItemsSelection={clearItemsSelection}
			/>

			{areRowsFetching && (
				<div className='absolute left-0 top-0 flex h-full min-h-[200px] w-full items-center justify-center bg-white/70'>
					<Spinner />
				</div>
			)}
		</>
	);
};

export default CmsTable;
