export enum EPermissionsPrefixes {
	ANALYTIC = 'analytics',
	USERS = 'users',
	ROLES = 'roles',
	FOLDERS = 'folders',
	PERMISSIONS = 'permissions',
	LOCALES = 'locales',
	MATERIALS = 'materials',
	MEDIAS = 'medias',
	MENUS = 'menus',
	BLOCK_MENUS = 'block_menus',
	SETTINGS = 'settings',
	ACTIVITIES = 'activities',
	FORMS = 'forms',
	MATERIAL_TYPES = 'material_types',
}

export enum EPermissionsPostfixes {
	VIEW = '.view',
	VIEW_ANY = '.viewAny',
	CREATE = '.create',
	UPDATE = '.update',
	BATCH_DELETE = '.batchDelete',
}

export enum EPermissionSettingKeys {
	HEADER = '.header',
	FOOTER = '.footer',
	IMAGE_WATERMARK = '.image_watermark',
	VIDEO_WATERMARK = '.video_watermark',
	DEFAULT_TITLE = '.default_title',
	SYSLOG_HOST = '.syslog_host',
	SYSLOG_PORT = '.syslog_port',
	BACKUPS = '.backups',
	MAIL_SETTINGS = '.mail',
	MAIL_MESSAGES = '.messages',
	MAIL_SUBSCRIPTIONS = '.subscription',
	GOOGLE_ANALYTICS_CREDENTIALS_KEY = '.google_analytics_credentials_key',
	GOOGLE_ANALYTICS_GTAG = '.google_analytics_gtag',
	GOOGLE_ANALYTICS_PROPERTY_ID = '.google_analytics_property_id',
	YANDEX_METRIKA_ACCESS_TOKEN = '.yandex_metrika_access_token',
	YANDEX_METRIKA_COUNTER_ID = '.yandex_metrika_counter_id',
	YANDEX_METRIKA_TAG = '.yandex_metrika_tag',
}

export const UserPermissions = ['users.create', 'users.update', 'users.batchDelete', 'users.viewAny', 'users.view'];

export const LocalesPermissions = ['locales.create', 'locales.update', 'locales.batchDelete', 'locales.viewAny', 'locales.view'];

export const MaterialsPermissions = ['materials.create', 'materials.update', 'materials.batchDelete', 'materials.viewAny', 'materials.view'];

export const MaterialTypesPermissions = [
	`${EPermissionsPrefixes.MATERIAL_TYPES}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.MATERIAL_TYPES}${EPermissionsPostfixes.CREATE}`,
	`${EPermissionsPrefixes.MATERIAL_TYPES}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.MATERIAL_TYPES}${EPermissionsPostfixes.BATCH_DELETE}`,
	`${EPermissionsPrefixes.MATERIAL_TYPES}${EPermissionsPostfixes.VIEW_ANY}`,
];

export const FormsPermissions = [
	`${EPermissionsPrefixes.FORMS}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.FORMS}${EPermissionsPostfixes.CREATE}`,
	`${EPermissionsPrefixes.FORMS}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.FORMS}${EPermissionsPostfixes.BATCH_DELETE}`,
	`${EPermissionsPrefixes.FORMS}${EPermissionsPostfixes.VIEW_ANY}`,
];

export const CommonSettingsPermissions = [
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.DEFAULT_TITLE}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.GOOGLE_ANALYTICS_CREDENTIALS_KEY}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.GOOGLE_ANALYTICS_GTAG}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.SYSLOG_HOST}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.GOOGLE_ANALYTICS_PROPERTY_ID}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.SYSLOG_PORT}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.YANDEX_METRIKA_TAG}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.YANDEX_METRIKA_COUNTER_ID}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.YANDEX_METRIKA_ACCESS_TOKEN}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.BACKUPS}${EPermissionsPostfixes.VIEW}`,
	// update
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.DEFAULT_TITLE}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.GOOGLE_ANALYTICS_CREDENTIALS_KEY}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.GOOGLE_ANALYTICS_GTAG}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.GOOGLE_ANALYTICS_PROPERTY_ID}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.SYSLOG_HOST}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.SYSLOG_PORT}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.YANDEX_METRIKA_ACCESS_TOKEN}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.YANDEX_METRIKA_TAG}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.YANDEX_METRIKA_COUNTER_ID}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.BACKUPS}${EPermissionsPostfixes.UPDATE}`,
];

export const FooterSettingsPermissions = [
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.FOOTER}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.FOOTER}${EPermissionsPostfixes.UPDATE}`,
];

export const HeaderSettingsPermissions = [
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.HEADER}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.HEADER}${EPermissionsPostfixes.UPDATE}`,
];

export const MailSettingsPermissions = [
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.MAIL_SUBSCRIPTIONS}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.MAIL_SUBSCRIPTIONS}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.MAIL_SETTINGS}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.MAIL_SETTINGS}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.MAIL_MESSAGES}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.MAIL_MESSAGES}${EPermissionsPostfixes.UPDATE}`,
];

export const ActivitiesSettingsPermissions = [`${EPermissionsPrefixes.ACTIVITIES}${EPermissionsPostfixes.VIEW_ANY}`];

export const WatermarksSettingsPermissions = [
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.IMAGE_WATERMARK}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.IMAGE_WATERMARK}${EPermissionsPostfixes.UPDATE}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.VIDEO_WATERMARK}${EPermissionsPostfixes.VIEW}`,
	`${EPermissionsPrefixes.SETTINGS}${EPermissionSettingKeys.VIDEO_WATERMARK}${EPermissionsPostfixes.UPDATE}`,
];

export const MenuItemsPermissions = ['menus.create', 'menus.update', 'menus.batchDelete', 'menus.viewAny', 'menus.view'];

export const MenuBlocksPermissions = [
	'block_menus.create',
	'block_menus.update',
	'block_menus.batchDelete',
	'block_menus.viewAny',
	'block_menus.view',
];

export const MediaPermissions = ['medias.create', 'medias.update', 'medias.batchDelete', 'medias.viewAny', 'medias.view'];

export const RolePermissions = ['roles.create', 'roles.update', 'roles.batchDelete', 'roles.viewAny', 'roles.view'];

export const AllPermissions = {
	users: UserPermissions,
	settings: {
		common: CommonSettingsPermissions,
		footer: FooterSettingsPermissions,
		header: HeaderSettingsPermissions,
		mail: MailSettingsPermissions,
		activities: ActivitiesSettingsPermissions,
		watermarks: WatermarksSettingsPermissions,
	},
	locales: LocalesPermissions,
	materials: MaterialsPermissions,
	materialTypes: MaterialTypesPermissions,
	forms: FormsPermissions,
	menuItems: MenuItemsPermissions,
	menuBlocks: MenuBlocksPermissions,
	media: MediaPermissions,
	roles: RolePermissions,
};
