import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const PersonSoloIcon: FC<ISVGProps> = ({ className }) => {
	return (
		<>
			<svg
				className={className}
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M11.8732 11.5977C13.3291 11.5977 14.5898 11.0755 15.6199 10.0452C16.65 9.01513 17.1722 7.75475 17.1722 6.29869C17.1722 4.84306 16.65 3.58255 15.6197 2.5521C14.5895 1.52216 13.3289 1 11.8732 1C10.4171 1 9.15666 1.52216 8.12659 2.55227C7.09653 3.58238 6.57415 4.84293 6.57415 6.29869C6.57415 7.75475 7.09649 9.0153 8.12676 10.0454C9.15704 11.0753 10.4176 11.5977 11.8732 11.5977ZM21.145 17.9172C21.1153 17.4885 21.0552 17.0209 20.9667 16.5271C20.8774 16.0295 20.7625 15.5592 20.6248 15.1294C20.4827 14.6851 20.2893 14.2464 20.0503 13.8259C19.8022 13.3895 19.5108 13.0094 19.1838 12.6968C18.8419 12.3696 18.4233 12.1066 17.9392 11.9147C17.4568 11.7239 16.9222 11.6272 16.3504 11.6272C16.1258 11.6272 15.9086 11.7194 15.4891 11.9925C15.191 12.1866 14.8919 12.3794 14.592 12.5709C14.3038 12.7545 13.9134 12.9266 13.4311 13.0823C12.9607 13.2346 12.483 13.3118 12.0115 13.3118C11.54 13.3118 11.0624 13.2346 10.5914 13.0823C10.1098 12.9267 9.71934 12.7547 9.43144 12.571C9.09757 12.3577 8.7955 12.163 8.53347 11.9923C8.11452 11.7192 7.89714 11.627 7.67258 11.627C7.10053 11.627 6.56611 11.7239 6.08387 11.9149C5.60012 12.1064 5.18134 12.3695 4.83909 12.6969C4.5123 13.0098 4.22076 13.3896 3.97299 13.8259C3.73417 14.2463 3.54081 14.6849 3.39845 15.1296C3.26095 15.5594 3.14601 16.0295 3.05671 16.5271C2.96824 17.0202 2.90817 17.488 2.87843 17.9177C2.84886 18.3501 2.83419 18.7834 2.83448 19.2169C2.83448 20.3653 3.19954 21.295 3.91945 21.9806C4.63047 22.6573 5.57124 23.0005 6.7153 23.0005H17.3086C18.4527 23.0005 19.3931 22.6575 20.1043 21.9807C20.8244 21.2955 21.1895 20.3656 21.1895 19.2167C21.1893 18.7734 21.1744 18.3361 21.145 17.9172Z'
					fill='#333333'
				/>
			</svg>
		</>
	);
};

export default PersonSoloIcon;
