import baseApi from 'shared/api/baseApi';
import { ETagTypes } from 'shared/lib/constants/tagTypes';

import type { AxiosProgressEvent } from 'axios';
import type { TMediaItem, IMediaFile } from 'shared/interfaces';

export const MEDIA_URL = '/api/medias';

const mediaApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		mediaList: builder.query<{ data: TMediaItem[]; meta: { total: number } }, Record<string, Undefinable<string | number>> | void>({
			query: (params) => ({
				method: 'GET',
				url: MEDIA_URL,
				params,
			}),
			providesTags: (result) => {
				if (result) {
					return [...result.data.map(({ id }) => ({ type: ETagTypes.Media, id } as const)), { type: ETagTypes.Media, id: 'LIST' }];
				}

				return [{ type: ETagTypes.Media, id: 'LIST' }];
			},
		}),
		mediaById: builder.query<{ data: TMediaItem }, string | number>({
			query: (id) => ({
				method: 'GET',
				url: `${MEDIA_URL}/${id}`,
			}),
			providesTags: (data) => (data?.data ? [{ type: ETagTypes.Media, id: data.data.id }] : []),
		}),
		getFileByUrl: builder.query<Blob, string>({
			query: (url) => ({
				url,
				method: 'GET',
				responseType: 'blob',
			}),
		}),
		uploadMedia: builder.mutation<IMediaFile, { dto: FormData; onUploadProgress?: (e: AxiosProgressEvent) => void; controller?: AbortSignal }>({
			query: (payload) => ({
				method: 'POST',
				url: MEDIA_URL,
				data: payload.dto,
				onUploadProgress: payload.onUploadProgress,
				signal: payload.controller,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
			transformResponse: (res) => res.data,
			invalidatesTags: [ETagTypes.Media],
		}),

		editMedia: builder.mutation<IMediaFile, Record<keyof IMediaFile, string>>({
			query: (mediaItem) => ({
				method: 'PUT',
				url: `${MEDIA_URL}/${mediaItem.id}`,
				data: mediaItem,
			}),

			transformResponse: (res) => res.data,

			invalidatesTags: (_result, error, item) => {
				if (error) return [];

				return [{ type: ETagTypes.Media, id: item.id }];
			},
		}),

		reloadMedia: builder.mutation<void, TMediaItem['id']>({
			query: (id) => ({
				method: 'POST',
				url: `${MEDIA_URL}/${id}`,
			}),
		}),

		deleteMedia: builder.mutation<void, TMediaItem['id'][]>({
			query: (ids: TMediaItem['id'][]) => ({
				method: 'DELETE',
				url: `${MEDIA_URL}?ids=${ids.join(',')}`,
			}),
			invalidatesTags: (_result, _error, _id) => [{ type: ETagTypes.Media, id: 'LIST' }],
		}),

		regenerateMedia: builder.mutation<void, { media_id: TMediaItem['id']; is_watermark: TMediaItem['is_watermark'] }>({
			query: (payload) => ({
				method: 'POST',
				url: MEDIA_URL + '/regenerate-conversion',
				data: payload,
			}),
			invalidatesTags: [ETagTypes.Media],
		}),
	}),
});

export const {
	useMediaListQuery,
	useDeleteMediaMutation,
	useRegenerateMediaMutation,
	useUploadMediaMutation,
	useEditMediaMutation,
	useMediaByIdQuery,
	useGetFileByUrlQuery,
} = mediaApi;
