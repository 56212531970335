import { Field, useFormikContext } from 'formik';

import { FormInputFileUploader } from 'shared/ui/FormInputFileUploader';
import { TextInput } from 'shared/ui/TextInput';

import type { TProps } from './SliderFormType5.types';
import type { IMediaFile } from 'shared/interfaces';
import type { ISliderData } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.interface';

const SliderFormType5 = ({ index }: TProps) => {
	const { values, setFieldValue } = useFormikContext<ISliderData>();

	const handleSelectFile = (newValue: Nullable<IMediaFile>, field: string) => {
		setFieldValue(field, newValue);
	};

	const handleDeleteImage = (field: string) => {
		setFieldValue(field, '');
	};
	return (
		<>
			<div className='flex justify-between gap-x-4 p-5 text-base font-semibold'>
				<p>Слайд {index + 1}</p>
				<Field
					name={`items.[${index}].style`}
					placeholder='Стиль'
					as={TextInput}
				/>
			</div>
			<div className=''>
				<div className='mb-5 flex gap-2'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].image || null}
						name={`items.[${index}].image`}
						text='Изображение лицензии'
					/>
				</div>
				<div className='mb-5 flex gap-2'>
					<FormInputFileUploader
						handleDeleteImage={handleDeleteImage}
						handleSelectFile={handleSelectFile}
						media={values.items[index].icon || null}
						name={`items.[${index}].icon`}
						text='Иконка'
					/>
				</div>
			</div>
		</>
	);
};

export default SliderFormType5;
