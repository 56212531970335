import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType4 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType4/CardsType4.interfaces';

interface IProps {
	cardData: ICardType4;
	index: number;
	handleChangeCardData: (newData: ICardType4) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType4 = ({ cardData, index, handleUp, handleChangeCardData, handleDown, handleClose, getFieldProps }: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex w-full gap-m'>
					<div className='flex flex-col'>
						<div className=''>Главное изображение</div>
						<SelectImageWithControls
							handleSelectFile={(newFile) => {
								handleChangeCardData({ ...cardData, image_main: newFile });
							}}
							handleDeleteImage={() => handleChangeCardData({ ...cardData, image_main: null })}
							mediaItem={cardData.image_main}
						/>
					</div>
					<div className='flex w-full flex-col gap-m'>
						<TextInput
							{...getFieldProps(`cards.${index}.style`)}
							label='Стиль'
							placeholder='Введите стиль'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.title`)}
							label='Заголовок'
							placeholder='Введите текст заголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle`)}
							label='Подзаголовок'
							placeholder='Введите текст подзаголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.description_1`)}
							label='Описание 1'
							placeholder='Введите текст описания 1'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.description_2`)}
							label='Описание 2'
							placeholder='Введите текст описания 2'
						/>
					</div>
				</div>

				<SubTuner
					tuneItem={cardData.id}
					handleUp={handleUp}
					handleDown={handleDown}
					handleClose={handleClose}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
