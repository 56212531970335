import { CircularProgress } from '@mui/material';
import React from 'react';

const CenteredSpinner = () => {
	return (
		<div className='flex h-full grow items-center justify-center'>
			<CircularProgress />
		</div>
	);
};

export default CenteredSpinner;
