import { TextInput } from 'shared/ui';
import { EvaEditor, TEXT_ONLY_TOOLS } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { IContainerTemplate4Data } from './ContainerTemplate4.interfaces';
import type { FieldInputProps } from 'formik/dist/types';

interface IProps {
	data: IContainerTemplate4Data;
	getFieldProps: (field: string) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
}

export const ContainerTemplate4 = ({ data, setFieldValue, getFieldProps }: IProps) => {
	return (
		<div className='flex flex-col gap-m'>
			<div className='flex gap-m'>
				<div className='flex flex-col'>
					<div className=''>Изображение</div>
					<SelectImageWithControls
						handleSelectFile={(newFile) => {
							setFieldValue('data.main_image', newFile);
						}}
						handleDeleteImage={() => setFieldValue('data.main_image', null)}
						mediaItem={data.main_image}
					/>
				</div>
				<div className='flex w-full flex-col gap-m'>
					<TextInput
						{...getFieldProps('data.subtitle')}
						label='Подзаголовк контейнера'
						placeholder='Введите подзаголовок контейнера'
					/>
					<TextInput
						{...getFieldProps('data.description')}
						label='Описание'
						placeholder='Введите описание'
					/>
					<TextInput
						{...getFieldProps('data.url')}
						label='URL'
						placeholder='Введите URL'
					/>
					<TextInput
						{...getFieldProps('data.url_text')}
						label='Текст URL-а'
						placeholder='Введите текст URL-а'
					/>
				</div>
			</div>
			<div className='w-full flex-col'>Данные контейнера:</div>
			<div className='w-full flex-col rounded-xl border-2 border-border-primary'>
				<EvaEditor
					data={data.content}
					allowedTools={TEXT_ONLY_TOOLS}
					handleChangeData={(newData) => {
						setFieldValue('data.content', newData);
					}}
				/>
			</div>
		</div>
	);
};
