import { Navigate } from 'react-router-dom';

import { useAppSelector } from '@store';
import { authStateSelector } from '@store/reducers/auth/selectors.auth';
import { useNavigator } from 'shared/hooks/useNavigator';
import { ERoutes } from 'shared/lib/constants/routes';
import { CenteredSpinner } from 'shared/ui';

import type { ReactElement } from 'react';

interface IProps {
	permissions?: string[];
	children: ReactElement;
}
const PrivateRoute = ({ permissions, children }: IProps) => {
	const { isLoading, currUser: user } = useAppSelector(authStateSelector);
	const { location } = useNavigator();

	if (isLoading) return <CenteredSpinner />;

	if (!user)
		return (
			<Navigate
				to={ERoutes.LOGIN}
				state={{ from: location }}
				replace
			/>
		);

	const hasPermission =
		!permissions ||
		permissions?.some((perm) => user?.role?.permissions?.find(({ name }) => perm === name));

	if (!hasPermission)
		return (
			<Navigate
				to={ERoutes.HOME}
				replace
			/>
		);

	return children;
};

export default PrivateRoute;
