import axios from 'axios';

import { store } from '@store';
import { logoutAction } from '@store/reducers/auth';
import { ERoutes } from 'shared/lib/constants/routes';
import * as storage from 'shared/lib/helpers/storageHelpers';

import { apiCallbacksObserver } from './api.lib';
const instance = axios.create();

instance.interceptors.request.use(
	(config) => {
		const token = storage.getToken();
		config.headers.Authorization = token ? `Bearer ${token}` : '';
		config.headers.Accept = 'application/json';

		return config;
	},
	(config) => Promise.reject(config)
);

instance.interceptors.response.use(
	(config) => config,
	async (error) => {
		const status = error?.response?.status;
		if (status === 401) {
			store.dispatch(logoutAction());

			if (window.location.pathname !== ERoutes.LOGIN) {
				window.location.pathname = ERoutes.LOGIN;
			}

			return Promise.reject(error);
		}
		const originalConfig = error.config;
		if (status === 418 && !originalConfig._retry) {
			if (!apiCallbacksObserver.isRefreshingPending) {
				originalConfig._retry = true;
				try {
					apiCallbacksObserver.isRefreshingPending = true;
					const { data } = await instance.post('/api/auth/refresh', undefined, {
						withCredentials: true,
					});
					storage.saveToken(data.token);
					apiCallbacksObserver.notifyRefreshed();
					return instance(originalConfig);
				} catch (refreshErr) {
					apiCallbacksObserver.refreshSubscribers = [];
					store.dispatch(logoutAction());
					window.location.pathname = ERoutes.LOGIN;

					return Promise.reject(refreshErr);
				} finally {
					apiCallbacksObserver.isRefreshingPending = false;
				}
			} else {
				return new Promise((resolve, reject) => {
					apiCallbacksObserver.subscribeToRefresh(() => {
						originalConfig._retry = true;
						instance(originalConfig).then(resolve).catch(reject);
					});
				});
			}
		}
		return Promise.reject(error);
	}
);

export default instance;
