import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useIsFirstRender } from 'shared/hooks/useIsFirstRender';

/**
 * Хук для работы с search-параметрами
 *
 * @param paramKey ключ search-параметра
 *
 * @param paramValidator проверяет корректность параметра.
 * Отработает один раз при старте хука
 * Если передан null - параметр удаляется
 *
 * @param transformParam преобразует параметр перед выдачей из хука
 *
 * @returns {{param, setParam}} ReturnObject
 *
 * @property ReturnObject.param - параметр возвращаемый transformParam()
 *
 * @property ReturnObject.setParam - ф-я для установки параметра.
 * Если передан null - параметр удаляется
 */
export function useQueryParam<T>(
	paramKey: string,
	{
		paramValidator = (param) => param,
		transformParam = (param) => param as T,
	}: {
		paramValidator?: (param: string | null) => string | null;
		transformParam?: (param: string | null) => T;
	} = {}
) {
	const [key] = useState(paramKey);
	const [searchParams, setSearchParams] = useSearchParams();
	const isFirstRender = useIsFirstRender();

	let param = searchParams.get(key);

	const setParam = useCallback(
		(value: string) => {
			const paramsCopy = new URLSearchParams(searchParams);
			paramsCopy.set(key, value);

			setSearchParams(paramsCopy);
		},
		[searchParams, setSearchParams, key]
	);

	const deleteParam = useCallback(() => {
		if (!searchParams.has(key)) return;

		const paramsCopy = new URLSearchParams(searchParams);
		paramsCopy.delete(key);

		setSearchParams(paramsCopy);
	}, [searchParams, setSearchParams, key]);

	const applyParam = useCallback(
		(value: string | null) => {
			if (value === null) {
				deleteParam();
			} else {
				setParam(value);
			}
		},
		[deleteParam, setParam]
	);

	if (isFirstRender) {
		const validatedParamValue = paramValidator(param);

		if (validatedParamValue === null) {
			deleteParam();
		} else if (param !== validatedParamValue) {
			setParam(validatedParamValue);
		}

		param = validatedParamValue;
	}

	return {
		param: useMemo(() => transformParam(param), [param, transformParam]),
		setParam: applyParam,
	};
}
