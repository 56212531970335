import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType11 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType11/CardsType11.interfaces';

interface IProps {
	cardData: ICardType11;
	index: number;
	handleChangeCardData: (newData: ICardType11) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType11 = ({ cardData, index, handleChangeCardData, handleUp, handleDown, handleClose, getFieldProps }: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<div className='flex gap-m'>
						<div className='flex flex-col gap-m'>
							<div className='flex flex-col'>
								<div className=''>Иконка</div>
								<SelectImageWithControls
									handleSelectFile={(newFile) => {
										handleChangeCardData({ ...cardData, icon: newFile });
									}}
									handleDeleteImage={() => handleChangeCardData({ ...cardData, icon: null })}
									mediaItem={cardData.icon}
								/>
							</div>
						</div>
						<div className='flex w-full flex-col gap-m '>
							<TextInput
								{...getFieldProps(`cards.${index}.style`)}
								label='Стиль'
								placeholder='Введите стиль'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.title`)}
								label='Заголовок'
								placeholder='Введите текст заголовка'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.link_url`)}
								label='URL кнопки'
								placeholder='Введите URL'
							/>
							<TextInput
								{...getFieldProps(`cards.${index}.link_text`)}
								label='Текст кнопки'
								placeholder='Введите текст кнопки'
							/>
						</div>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
