import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const ArrowRightIcon: FC<ISVGProps> = ({ className, onClick }) => {
	return (
		<>
			<svg
				className={className}
				width='16'
				height='16'
				viewBox='0 0 16 16'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				stroke='white'
				onClick={onClick}
			>
				<path
					d='M6 12L10 8L6 4'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</>
	);
};

export default ArrowRightIcon;
