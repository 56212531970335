import { Close as CloseIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material';

import { TextInput } from 'shared/ui';

import type { FieldInputProps } from 'formik/dist/types';
import type { ILPoint } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	pointData: ILPoint;
	index: number;
	getFieldProps: (propName: string) => FieldInputProps<string> | FieldInputProps<number>;
	handleDeletePoint: (id: ILPoint['id']) => void;
}

export const CityPointCard = ({ getFieldProps, index, pointData, handleDeletePoint }: IProps) => {
	return (
		<Accordion>
			<AccordionSummary>
				<div className='flex w-full items-center justify-between'>
					<div>Город №{index + 1}</div>
					<IconButton onClick={() => handleDeletePoint(pointData.id)}>
						<CloseIcon />
					</IconButton>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div className='flex flex-col gap-m'>
					<TextInput
						{...getFieldProps(`points.cities.${index}.name`)}
						label='Название города'
						placeholder='Введите название города'
					/>
					<div className='flex gap-m'>
						<TextInput
							{...getFieldProps(`points.cities.${index}.coordinates.lat`)}
							label='Широта'
							type='number'
							placeholder='Введите широту'
						/>
						<TextInput
							{...getFieldProps(`points.cities.${index}.coordinates.lng`)}
							label='Долгота'
							type='number'
							placeholder='Введите долготу'
						/>
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
