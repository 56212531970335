import type { ICardType2 } from './CardsType2.interfaces';

export const blankCardType2 = (): ICardType2 => ({
	id: crypto.randomUUID(),
	description: '',
	image_1: null,
	style: '',
	subtitle: '',
	title: '',
	additionalContent: { blocks: [] },
});
