import { Modal } from '@mui/material';
import cn from 'classnames';
import { memo } from 'react';

import type { FC, ReactNode } from 'react';

interface ModalWindowProps {
	isOpened: boolean;
	children: ReactNode;
	className?: string;
	wrapperClassName?: string;
	handleClose: () => void;
}

const ModalWindowComponent: FC<ModalWindowProps> = ({ isOpened, handleClose, className, children }) => (
	<Modal
		open={isOpened}
		onClose={handleClose}
		className='flex h-full w-full items-center justify-center'
	>
		<div
			data-id='ModalForm'
			className={cn(className, 'max-h-[80%] w-10/12 min-w-[30%] overflow-auto rounded-md bg-white p-5 outline-none')}
		>
			<div className='flex h-full max-h-[100%] w-full max-w-full flex-col overflow-hidden p-2'>{children}</div>
		</div>
	</Modal>
);

export const ModalWindow = memo(ModalWindowComponent);
