import EditorJS from '@editorjs/editorjs';
import { useEffect, useRef } from 'react';

import { stopPropagationHandler } from 'shared/lib/helpers/editorJsEvent';
import { EditorHolderId, getEditorConfigs } from 'shared/ui/Editor/Editor.lib';
import './Editor.css';

import 'shared/assets/EditorCustomToolsStyles/EditorCustomTools.scss';
import type { API, OutputData } from '@editorjs/editorjs';
import type { FC, MutableRefObject } from 'react';

interface IProps {
	data?: OutputData;
	handleChange: (newData: OutputData) => void;
	className?: string;
	autoFocus?: boolean;
	editorRef: MutableRefObject<Nullable<HTMLDivElement>>;
}

const Editor: FC<IProps> = ({ data, editorRef, handleChange, className, autoFocus = false }) => {
	const editor = useRef<EditorJS>();
	const editorWrapper = useRef<HTMLDivElement>(null);

	useEffect(() => {
		editorWrapper.current?.addEventListener('keydown', stopPropagationHandler);

		if (!editor.current) {
			editor.current = new EditorJS({
				...getEditorConfigs(),
				data,
				autofocus: autoFocus,
				onChange: async (api: API) => {
					const newData = await api.saver.save();
					handleChange(newData);
				},
				onReady() {
					if (editorWrapper.current) editorRef.current = editorWrapper.current;
				},
			});
		}

		return () => {
			if (editor.current && editor.current?.destroy) editor.current?.destroy();

			// eslint-disable-next-line react-hooks/exhaustive-deps
			editorWrapper.current?.removeEventListener('keydown', stopPropagationHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={`${className} editorWrapper`}>
			<div
				ref={editorWrapper}
				data-id='EditorContainer'
				id={EditorHolderId}
			/>
		</div>
	);
};

export default Editor;
