import cn from 'classnames';
import { memo, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAppSelector } from '@store';
import { currentUserPermissionsSelector } from '@store/reducers/auth/selectors.auth';
import LogoTransparent from 'shared/assets/svgs/LogoTransparent';
import iconArrow from 'shared/assets/svgs/arrow.svg';
import { useToggle } from 'shared/hooks';
import { ERoutes } from 'shared/lib/constants';

import { filterAllowedMenuItems, findActive } from './AccordionMenu.lib';
import MenuItem from './ui/MenuItem';

import type { TAccordionMenuItem } from './AccordionMenu.interfaces';

interface Props {
	menuItems: TAccordionMenuItem[];
	hideMenu?: () => void;
}

const AccordionMenu = ({ menuItems, hideMenu = () => {} }: Props) => {
	const location = useLocation();
	const userPermissions = useAppSelector(currentUserPermissionsSelector);
	const allowedItems = useMemo(() => filterAllowedMenuItems(menuItems, userPermissions), [menuItems, userPermissions]);
	const activeTab = useMemo(() => findActive(allowedItems, location.pathname), [allowedItems]);
	const { isOn: isOpenMenu, toggle: toggleIsOpenFullMenu } = useToggle(false);
	const [width, setWidth] = useState(window.innerWidth);
	const [activeItemId, setActiveItemId] = useState<string[]>(activeTab);

	useEffect(() => {
		const handleResize = (event: Event) => {
			setWidth((event.target as Window).innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const isOpenCollapsedMenu = isOpenMenu || width <= 1024;

	return (
		<nav className='flex min-h-[100vh] flex-col justify-between bg-gradient-to-r from-bgAccordionFrom to-bgAccordionTo px-[20px] pb-[20px] text-white'>
			<div>
				<Link
					data-id='Logo'
					to={ERoutes.HOME}
					className='flex items-center py-[25px] pb-[75px]'
				>
					<LogoTransparent />
				</Link>
				<ul
					className={`${isOpenCollapsedMenu ? 'w-auto' : 'w-[343px] md:w-[305px]'}`}
					data-id='SideMenuContainer'
				>
					{allowedItems.map((menuItem) => (
						<MenuItem
							item={menuItem}
							key={menuItem.title}
							hideMenu={hideMenu}
							isOpenCollapsedMenu={isOpenCollapsedMenu}
							activeItemId={activeItemId}
							setActiveItemId={setActiveItemId}
						/>
					))}
				</ul>
			</div>
			<div
				className='flex grow cursor-pointer items-end justify-end md:hidden'
				onClick={toggleIsOpenFullMenu}
			>
				<div className={cn('flex cursor-pointer transition-all duration-300', { 'rotate-180': !isOpenCollapsedMenu })}>
					<img
						src={iconArrow}
						alt='arrow'
					/>
				</div>
			</div>
		</nav>
	);
};

export default memo(AccordionMenu);
