import type { ICardType8 } from './CardsType8.interfaces';

export const blankCardType8 = (): ICardType8 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	image_main: null,
	description_1: '',
	description_2: '',
	subtitle_1_url: '',
	subtitle_2_url: '',
	icon_1_before_url_text: '',
	icon_2: null,
	icon_1_url: '',
	icon_1: null,
	subtitle_1_url_text: '',
	subtitle_1_url_text_inside: '',
	subtitle_1_add_url: '',
	subtitle_1_add_url_text: '',
	subtitle_2_url_text: '',
	subtitle_2_after_url_text: '',
	subtitle_1_after_url_text: '',
	subtitle_1_before_url_text: '',
	subtitle_2_before_url_text: '',
	icon_1_url_text: '',
	icon_3: null,
	icon_2_url: '',
	icon_1_after_url_text: '',
	icon_2_after_url_text: '',
	icon_2_url_text: '',
	icon_2_before_url_text: '',
	icon_3_after_url_text: '',
	icon_3_url_text: '',
	icon_3_before_url_text: '',
	icon_3_url: '',
	icon_4: null,
	icon_4_after_url_text: '',
	icon_4_url_text: '',
	icon_4_before_url_text: '',
	icon_4_url: '',
});
