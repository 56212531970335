import React, { useEffect, useState } from 'react';

import { useDebounce } from 'shared/hooks';
import TextInput from 'shared/ui/TextInput/TextInput';

import type { ChangeEvent } from 'react';
import type { IUpdateParams } from 'shared/ui/CMSTable/CmsTable.interfaces';

interface IProps {
	searchQuery: string;
	clearItemsSelection: () => void;
	updateParams: (arg: Partial<IUpdateParams>) => void;
}

const CmsSearch = ({ searchQuery, clearItemsSelection, updateParams }: IProps) => {
	const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
	const debounce = useDebounce();
	useEffect(() => {
		if (!searchQuery) setLocalSearchQuery('');
	}, [searchQuery]);

	const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalSearchQuery(event.target.value);
		debounce(() => {
			clearItemsSelection();
			updateParams({ page: 1, title: event.target.value });
		});
	};
	return (
		<div className='basis-[20%] '>
			<TextInput
				data-id='SearchInput'
				type='search'
				variant='outlined'
				placeholder='Поиск'
				value={localSearchQuery}
				onChange={searchHandler}
				className='bg-white'
				propsClassName='px-5 py-[8.5px]'
				InputProps={{
					classes: {
						root: '!py-[8.5px] !px-5',
						input: '!p-0 text-ellipsis',
					},
				}}
			/>
		</div>
	);
};

export default CmsSearch;
