import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@store';
import { DialogProvider } from 'shared/providers';
import { pdfFileFormat, pdfToolBoxParams, pdfTuneOptions } from 'shared/ui/Editor/EditorBlockTools/PDFTool/PDFTool.meta';
import { EFilePreviewViewModes } from 'shared/ui/Editor/EditorBlockTools/_ui/FilePreview/FIlePreview.interfaces';
import FileTool from 'shared/ui/Editor/EditorBlockTools/_ui/FileTool/FileTool';
import { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

import type { API } from '@editorjs/editorjs';
import type { BlockTool, BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools/block-tool';
import type { ToolConfig } from '@editorjs/editorjs/types/tools/tool-config';
import type { IPDFToolData } from 'shared/ui/Editor/EditorBlockTools/PDFTool/PDFTool.interfaces';

class CPDFTool implements BlockTool {
	api: API;

	readOnly: boolean;

	data: IPDFToolData;

	renderNodes: {
		holder: Nullable<HTMLElement>;
		settings: Nullable<HTMLElement>;
	};

	config: ToolConfig;

	static get toolbox() {
		return pdfToolBoxParams;
	}

	constructor({ data, config, api, readOnly }: BlockToolConstructorOptions<IPDFToolData>) {
		this.api = api;
		this.readOnly = readOnly;
		this.data = {
			media: data.media || null,
			viewMode: data.viewMode || EFilePreviewViewModes.PREVIEW,
			captureText: data.captureText || '',
			style: data.style || null,
			withBorder: data.withBorder || false,
			stretched: data.stretched || false,
		};

		this.config = config;

		this.renderNodes = {
			holder: null,
			settings: null,
		};
	}

	render() {
		const rootNode = document.createElement('div');
		this.renderNodes.holder = rootNode;

		const handlePDFChange = (newData: IPDFToolData) => {
			this.data = {
				...newData,
			};
		};
		const deleteEmptyBlock = () => {
			if (!this.data.media?.url) {
				const currentBlockIndex = this.api.blocks.getCurrentBlockIndex() - 1;
				if (currentBlockIndex < 0) return;
				this.api.blocks.delete(currentBlockIndex);
			}
		};
		const deleteBlock = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.delete(blockId);
		};

		const moveBlockUp = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId - 1);
		};
		const moveBlockDown = () => {
			const api = this.api.blocks;
			const blockId = api.getCurrentBlockIndex();
			api.move(blockId, blockId + 1);
		};

		const renderRoot = createRoot(rootNode);
		renderRoot.render(
			<BrowserRouter>
				<Provider store={store}>
					<DialogProvider>
						<FileTool
							variant={ESelectFileVariant.PDF}
							handleChange={handlePDFChange}
							data={this.data}
							deleteEmptyBlock={deleteEmptyBlock}
							accept={pdfFileFormat}
							deleteBlock={deleteBlock}
							moveBlockUp={moveBlockUp}
							moveBlockDown={moveBlockDown}
							tunesOptions={pdfTuneOptions}
						/>
					</DialogProvider>
				</Provider>
			</BrowserRouter>
		);

		return this.renderNodes.holder;
	}

	save() {
		return this.data;
	}
}

export default CPDFTool;
