// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonPropsColorOverrides } from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';

import type { PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface Palette {
		enabledWhite?: PaletteColorOptions;
	}
	interface PaletteOptions {
		enabledWhite?: PaletteColorOptions;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		enabledWhite?: true;
	}
}

const { palette } = createTheme();
export const theme = createTheme({
	palette: {
		primary: {
			main: '#5E4DAA',
			dark: '#8274C2',
			light: '#FFF',
		},
		error: {
			main: '#DB2424',
			dark: '#FC4D4D',
			light: '#FFF',
		},
		grey: {
			'50': '#ABABAB',
		},
		secondary: {
			main: '#FFF',
			dark: '#8274C2',
			light: '#333',
		},
		enabledWhite: palette.augmentColor({
			color: {
				main: '#F8F7FF',
				dark: '#F0EEFF',
				light: '#c0ff78',
			},
		}),
		text: {
			primary: '#333',
		},
	},
	typography: {
		fontFamily: ['Gilroy'].join(','),
	},
});
