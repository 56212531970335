import { HyperLinkMenuIcon, PreviewMenuIcon, BorderMenuIcon, StretchedMenuIcon } from '@assets/svgs';
import { EMimeTypes } from 'shared/ui/FileUploader/FileUploader.interfaces';

export const pdfToolBoxParams = {
	title: 'Документ .pdf',
	icon: '<svg width="17" height="15" viewBox="0 0 336 276" stroke-width="0" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
};

export const pdfTuneOptions = [
	{
		title: 'Гиперссылка',
		icon: <HyperLinkMenuIcon />,
		name: 'link',
	},
	{
		title: 'Предпросмотр',
		icon: <PreviewMenuIcon />,
		name: 'preview',
	},
	{
		title: 'Границы',
		icon: <BorderMenuIcon />,
		name: 'withBorder',
	},
	{
		title: 'Растянуть',
		icon: <StretchedMenuIcon />,
		name: 'stretched',
	},
];

export const LINK = 'link';
export const PREVIEW = 'preview';
export const WITH_BORDER = 'withBorder';
export const STRETCHED = 'stretched';

export const pdfFileFormat = [EMimeTypes.PDF];
