import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const AddMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		viewBox='0 0 24 24'
	>
		<path
			stroke='#1C274C'
			strokeLinecap='round'
			strokeWidth={1.5}
			d='M14.5 6.5h3m0 0h3m-3 0v3m0-3v-3M2.55 15.5c.065-.65.21-1.088.536-1.414.586-.586 1.528-.586 3.414-.586 1.886 0 2.828 0 3.414.586.586.586.586 1.528.586 3.414 0 1.886 0 2.828-.586 3.414-.586.586-1.528.586-3.414.586-1.886 0-2.828 0-3.414-.586-.31-.31-.456-.718-.524-1.313'
		/>
		<path
			stroke='#1C274C'
			strokeWidth={1.5}
			d='M2.5 6.5c0-1.886 0-2.828.586-3.414C3.672 2.5 4.614 2.5 6.5 2.5c1.886 0 2.828 0 3.414.586.586.586.586 1.528.586 3.414 0 1.886 0 2.828-.586 3.414-.586.586-1.528.586-3.414.586-1.886 0-2.828 0-3.414-.586C2.5 9.328 2.5 8.386 2.5 6.5ZM13.5 17.5c0-1.886 0-2.828.586-3.414.586-.586 1.528-.586 3.414-.586 1.886 0 2.828 0 3.414.586.586.586.586 1.528.586 3.414 0 1.886 0 2.828-.586 3.414-.586.586-1.528.586-3.414.586-1.886 0-2.828 0-3.414-.586-.586-.586-.586-1.528-.586-3.414Z'
		/>
	</svg>
);
export default AddMenuIcon;
