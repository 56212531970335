import * as Yup from 'yup';

import { ESocialNetworks } from 'shared/interfaces/materials';

export const isValidVKLink: Yup.TestFunction<string | undefined, any> = (link) => {
	const regex = /VK\.Widgets\.Post\("vk_post_(.*?)",\s*(-?\d+),\s*(-?\d+),\s*'([^']*)'(?:,\s*({[^}]*}))?/;

	return !!link && regex.test(link);
};

export const validationLinkSchema = Yup.object().shape({
	data: Yup.array()
		.typeError('МОй ТЕКСТ')
		.of(
			Yup.object().shape({
				link: Yup.string().when('socialNetwork', {
					is: ESocialNetworks.VK,
					then: (schema) => schema.test('isValidVKLink', 'Некорректная ссылка VK', isValidVKLink),
					otherwise: (schema) => schema.required('Это обязательное поле'),
				}),
				socialNetwork: Yup.string().oneOf(Object.values(ESocialNetworks)),
			})
		),
});
