import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';

import { ReactComponent as CheckBoxIcon } from 'shared/assets/svgs/checkbox.svg';
import { ReactComponent as CheckBoxCheckedIcon } from 'shared/assets/svgs/checkboxChecked.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from 'shared/assets/svgs/checkboxIndeterminate.svg';
import { EOrderDirection } from 'shared/ui/CMSTable/CmsTable.interfaces';
import CmsSorting from 'shared/ui/CMSTable/ui/CMSTableHead/_ui/CMSSorting/CMSSorting';

import type { Dispatch, SetStateAction } from 'react';
import type { IColumnsConfig, IUpdateParams } from 'shared/ui/CMSTable/CmsTable.interfaces';

interface IProps<T extends { id: string | number }> {
	rows: T[];
	selectedRows: Map<T['id'], T>;
	setSelectedRows: Dispatch<SetStateAction<Map<T['id'], T>>>;
	columnsConfigs: IColumnsConfig<T>[];
	isUserHasViewPermission: boolean;
	updateSortParams?: (sortBy?: string, sortDirection?: EOrderDirection) => void;
	sortDirection?: EOrderDirection;
	sortBy?: string;
	singleSelectionMode?: boolean;
	updateParams: (arg: Partial<IUpdateParams>) => void;
	isNotCheckbox?: boolean;
}

const CMSTableHead = <T extends { id: string | number }>({
	rows,
	selectedRows,
	setSelectedRows,
	columnsConfigs,
	isUserHasViewPermission,
	sortDirection,
	sortBy,
	singleSelectionMode = false,
	updateParams,
	isNotCheckbox = false,
}: IProps<T>) => {
	const onSelectAllClick = () => {
		const isAllSelected = selectedRows.size === rows.length || (selectedRows.size === 1 && singleSelectionMode);

		if (isAllSelected) {
			setSelectedRows(new Map());
		} else {
			setSelectedRows(new Map(rows.map((item) => [item.id, item])));
		}
	};

	const isAllSelected = rows.length > 0 && selectedRows.size === rows.length;
	const checkboxTitle = isAllSelected ? 'Отменить выбор' : 'Выбрать все';

	const onSortLabelClickHandler = (cell: IColumnsConfig<T>) => {
		const newSortDirection = {
			asc: 'desc',
			desc: '',
		};

		if (cell.valueForOrdering !== sortBy) {
			updateParams({ sort_by: cell.valueForOrdering as string, sort_direction: EOrderDirection.asc });
			return;
		}
		const value = newSortDirection[(sortDirection as 'asc') ?? ''];
		if (!value) {
			updateParams({ sort_by: undefined, sort_direction: undefined });

			return;
		}
		updateParams({ sort_by: sortBy, sort_direction: value as EOrderDirection });
	};
	const isCheckboxDisabled = !isUserHasViewPermission || (singleSelectionMode && selectedRows.size === 0);

	return (
		<TableHead>
			<TableRow>
				{!isNotCheckbox && (
					<TableCell
						padding='checkbox'
						className='w-[40px] max-w-[40px]'
					>
						<Checkbox
							data-id='GeneralCheckBox'
							icon={<CheckBoxIcon />}
							checkedIcon={<CheckBoxCheckedIcon />}
							disabled={isCheckboxDisabled}
							indeterminateIcon={<CheckboxIndeterminateIcon />}
							color='primary'
							indeterminate={selectedRows.size > 0 && selectedRows.size < rows.length}
							checked={isAllSelected}
							onChange={onSelectAllClick}
							inputProps={{
								'aria-label': checkboxTitle,
							}}
							title={checkboxTitle}
						/>
					</TableCell>
				)}
				<CmsSorting
					onSortLabelClickHandler={onSortLabelClickHandler}
					columnsConfigs={columnsConfigs}
					sortBy={sortBy}
					sortDirection={sortDirection}
				/>
			</TableRow>
		</TableHead>
	);
};

export default CMSTableHead;
