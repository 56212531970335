const swapToTopArrayItems = <T extends { id: number | string }>(
	id: number | string,
	items: Array<T>
) => {
	const cardIndex = items.findIndex((acc) => acc.id === id);
	if (cardIndex === -1) return;
	const swappedItems = [...items];
	const currItem = swappedItems[cardIndex];
	const indexForSwap = cardIndex === 0 ? items.length - 1 : cardIndex - 1;
	const itemForSwap = items[indexForSwap];
	swappedItems[indexForSwap] = currItem;
	swappedItems[cardIndex] = itemForSwap;
	return swappedItems;
};

const swapToBottomArrayItems = <T extends { id: number | string }>(
	id: number | string,
	items: Array<T>
) => {
	const cardIndex = items.findIndex((acc) => acc.id === id);
	if (cardIndex === -1) return;
	const swappedItems = [...items];
	const currItem = swappedItems[cardIndex];
	const indexForSwap = cardIndex === items.length - 1 ? 0 : cardIndex + 1;
	const itemForSwap = items[indexForSwap];
	swappedItems[indexForSwap] = currItem;
	swappedItems[cardIndex] = itemForSwap;
	return swappedItems;
};

export { swapToTopArrayItems, swapToBottomArrayItems };
