import type { ICardType5 } from './CardsType5.interfaces';

export const blankCardType5 = (): ICardType5 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	yandexUrl: '',
	yandexCode: '',
	description_1: '',
	contacts: [],
	subtitle_1: '',
	subtitle_2: '',
	description_2: '',
	description_3: '',
	subtitle_3: '',
});
