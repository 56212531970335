import type { IMediaFile } from 'shared/interfaces';

export interface INavToolData {
	title: string;
	styletitle: string;
	items: INavToolItem[];
	variant: TVariant;
	lists: 'ol' | 'ul' | null;
}

export enum ENavVariant {
	inListView = 'in-list-view',
	inSketchView = 'in-sketch-view',
}

export enum ENavList {
	ul = 'ul',
	ol = 'ol',
}

export type TVariant = 'in-list-view' | 'in-sketch-view';
export interface INavToolItem {
	id: number;
	anchor: string;
	style: string;
	text: string;
	url: string;
	media: Nullable<IMediaFile>;
}
