import { CircularProgress } from '@mui/material';

import type { CircularProgressProps } from '@mui/material';

export const Spinner = ({ 'aria-label': ariaLabel = 'Загрузка...', title = 'Загрузка...', ...restProps }: CircularProgressProps) => (
	<CircularProgress
		data-id='Spinner'
		aria-label={ariaLabel}
		title={title}
		{...restProps}
	/>
);
