import { lazy } from 'react';

import { getRoute, getRouteWithChildren } from 'pages/_routes/Pages.lib';
import {
	ERoutes,
	FormsPermissions,
	LocalesPermissions,
	MaterialsPermissions,
	MaterialTypesPermissions,
	MediaPermissions,
	MenuBlocksPermissions,
	MenuItemsPermissions,
	RolePermissions,
	AllPermissions,
	UserPermissions,
} from 'shared/lib/constants';

import type { IPageRoute, IPageRouteWithChildren } from 'pages/_routes/Pages.interfaces';

const Login = lazy(() => import('pages/login').then((module) => ({ default: module.Login })));
const Home = lazy(() => import('pages/home').then((module) => ({ default: module.Home })));
const Profile = lazy(() => import('pages/profile').then((module) => ({ default: module.Profile })));

const MediaManagerContainer = lazy(() => import('pages/mediaManager').then((module) => ({ default: module.MediaManagerContainer })));
const Media = lazy(() => import('pages/mediaManager').then((module) => ({ default: module.Media })));

const BasketLocales = lazy(() => import('pages/baskets').then((module) => ({ default: module.BasketLocales })));
const BasketMaterials = lazy(() => import('pages/baskets').then((module) => ({ default: module.BasketMaterials })));
const BasketMenuItems = lazy(() => import('pages/baskets').then((module) => ({ default: module.BasketMenuItems })));
const Locales = lazy(() => import('pages/settings').then((module) => ({ default: module.Locales })));

const HeaderSite = lazy(() => import('pages/settings').then((module) => ({ default: module.HeaderSite })));
const FooterSite = lazy(() => import('pages/settings').then((module) => ({ default: module.FooterSite })));

const SettingsContainer = lazy(() => import('pages/settings').then((module) => ({ default: module.SettingsContainer })));

const EventLog = lazy(() => import('pages/settings').then((module) => ({ default: module.EventLog })));

const BasketContainer = lazy(() => import('pages/baskets').then((module) => ({ default: module.BasketContainer })));

const UsersManagerContainer = lazy(() => import('pages/usersManager').then((module) => ({ default: module.UsersManagerContainer })));

const Roles = lazy(() => import('pages/usersManager').then((module) => ({ default: module.Roles })));
const Users = lazy(() => import('pages/usersManager').then((module) => ({ default: module.Users })));

const MaterialsManagerContainer = lazy(() => import('pages/materialsManager').then((module) => ({ default: module.MaterialsManagerContainer })));

const MaterialTypesManager = lazy(() => import('pages/MaterialTypesManager').then((module) => ({ default: module.MaterialTypesManager })));

const FormsManager = lazy(() => import('pages/FormsManager').then((module) => ({ default: module.FormsManager })));

const Materials = lazy(() => import('pages/materialsManager').then((module) => ({ default: module.Materials })));

const MenuManagerContainer = lazy(() => import('pages/menuManager').then((module) => ({ default: module.MenuManagerContainer })));

const MenuBlock = lazy(() => import('pages/menuManager').then((module) => ({ default: module.MenuBlocks })));
const MenuItems = lazy(() => import('pages/menuManager').then((module) => ({ default: module.MenuItems })));

const Watermark = lazy(() => import('pages/settings').then((module) => ({ default: module.Watermark })));

const GeneralSettings = lazy(() => import('pages/settings').then((module) => ({ default: module.GeneralSettings })));

const MailSetting = lazy(() => import('pages/settings/mail').then((module) => ({ default: module.Mail })));

const routes: Array<IPageRouteWithChildren | IPageRoute> = [
	getRoute(ERoutes.LOGIN, Login),
	getRoute(ERoutes.HOME, Home, null),
	getRoute(ERoutes.PROFILE, Profile, null),
	getRouteWithChildren(ERoutes.MEDIA_MANAGER, MediaManagerContainer, [...MediaPermissions], [getRoute(ERoutes.MEDIA, Media, MediaPermissions)]),
	getRouteWithChildren(
		ERoutes.MENU_MANAGER,
		MenuManagerContainer,
		[...MenuBlocksPermissions, ...MenuItemsPermissions],
		[getRoute(ERoutes.MENU_BLOCK, MenuBlock, MenuBlocksPermissions), getRoute(ERoutes.MENU_ITEMS, MenuItems, MenuItemsPermissions)]
	),
	getRouteWithChildren(
		ERoutes.MATERIALS_MANAGER,
		MaterialsManagerContainer,
		[...MaterialsPermissions, ...MaterialTypesPermissions, ...FormsPermissions],
		[
			getRoute(ERoutes.MATERIALS, Materials, MaterialsPermissions),
			getRoute(ERoutes.FORMS, FormsManager, FormsPermissions),
			getRoute(ERoutes.MATERIAL_TYPES, MaterialTypesManager, MaterialTypesPermissions),
		]
	),
	getRouteWithChildren(
		ERoutes.USERS_MANAGER,
		UsersManagerContainer,
		[...RolePermissions, ...UserPermissions],
		[getRoute(ERoutes.ROLES, Roles, RolePermissions), getRoute(ERoutes.USERS, Users, UserPermissions)]
	),
	getRouteWithChildren(
		ERoutes.SETTINGS,
		SettingsContainer,
		[
			...LocalesPermissions,
			...AllPermissions.settings.common,
			...AllPermissions.settings.watermarks,
			...AllPermissions.settings.footer,
			...AllPermissions.settings.header,
			...AllPermissions.settings.activities,
		],
		[
			getRoute(ERoutes.GENERAL, GeneralSettings, AllPermissions.settings.common),
			getRoute(ERoutes.EVENT_LOG, EventLog, AllPermissions.settings.activities),
			getRoute(ERoutes.LOCALES, Locales, LocalesPermissions),
			getRoute(ERoutes.HEADER_SITE, HeaderSite, AllPermissions.settings.header),
			getRoute(ERoutes.FOOTER_SITE, FooterSite, AllPermissions.settings.footer),
			getRoute(ERoutes.WATERMARK, Watermark, AllPermissions.settings.watermarks),
			getRoute(ERoutes.MAIL, MailSetting, AllPermissions.settings.mail),
		]
	),
	getRouteWithChildren(
		ERoutes.BASKET,
		BasketContainer,
		[...LocalesPermissions, ...MaterialsPermissions, ...MenuItemsPermissions],
		[
			getRoute(ERoutes.BASKET_LOCALES, BasketLocales, LocalesPermissions),
			getRoute(ERoutes.BASKET_MATERIALS, BasketMaterials, MaterialsPermissions),
			getRoute(ERoutes.BASKET_MENU_ITEMS, BasketMenuItems, MenuItemsPermissions),
		]
	),
];

export { routes };
