import axios from 'axios';
import { toast } from 'react-toastify';

import type { AxiosError } from 'axios';
import type { AxiosErrorResponse } from 'shared/api/baseApi';

const notShownErrors = new Map([['api/auth/login', new Set([401])]]);

const isPassError = (err: AxiosError) => !!notShownErrors.get(err.response?.config?.url!)?.has(err?.response?.status!);

class RequestError {
	err: Error | AxiosError<AxiosErrorResponse>;

	constructor(err: Error | AxiosError<AxiosErrorResponse>) {
		this.err = err;
	}

	public static notify(err: Error | AxiosError<AxiosErrorResponse>) {
		if (!axios.isAxiosError(err)) {
			toast.error(err.message);
			return;
		}

		if (axios.isAxiosError(err) && !isPassError(err) && err.response?.status !== 418) {
			const message = err.response?.data?.message;

			if (message) {
				toast.error(message);
			}
		}
	}
}

export { RequestError };
