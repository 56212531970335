import { Internal } from './Internal';
import { International } from './International';

import type { Map as LMap } from 'leaflet';
import type { ILeafletMapData } from 'shared/ui/Editor/EditorBlockTools/LeafletMapTool/LeafletMapTool.interfaces';

interface IProps {
	lines: ILeafletMapData['lines'];
	map: LMap;
	zoom: number
}

export const LinesLayer = ({ lines, map, zoom }: IProps) => {

	return <>
		<International data={lines.international} map={map} zoom={zoom} />
		<Internal data={lines.internal} map={map} zoom={zoom} />
	</>;
};