import type { API, BlockAPI, OutputData } from '@editorjs/editorjs';
import type { IMediaFile } from 'shared/interfaces';

export enum ESliderType {
	CUSTOM = 'custom',
	TYPE_1 = 'type_1',
	TYPE_2 = 'type_2',
	TYPE_3 = 'type_3',
	TYPE_4 = 'type_4',
	TYPE_5 = 'type_5',
	TYPE_6 = 'type_6',
	TYPE_7 = 'type_7',
	TYPE_8 = 'type_8',
}

type TSliderType = {
	[key in ESliderType]: { label: string };
};

export const SliderTypesSelectOptions: TSliderType = {
	[ESliderType.CUSTOM]: { label: 'Собственный' },
	[ESliderType.TYPE_1]: { label: 'Наши преимущества' },
	[ESliderType.TYPE_2]: { label: 'Услуги' },
	[ESliderType.TYPE_3]: { label: 'Контакты' },
	[ESliderType.TYPE_4]: { label: 'Партнеры' },
	[ESliderType.TYPE_5]: { label: 'Лицензии и сертификаты' },
	[ESliderType.TYPE_6]: { label: 'Целевая аудитория + Сферы применени' },
	[ESliderType.TYPE_7]: { label: 'Банер' },
	[ESliderType.TYPE_8]: { label: 'Банер (версия 2)' },
};
export interface ISliderToolProps {
	onDataChange: (data: ISliderData) => void;
	data: ISliderData;
	deleteBlock: () => void;
	moveBlockUp: () => void;
	moveBlockDown: () => void;
}

export interface ISliderItem {
	id: string | number;
	style: string;
	data: OutputData;
	// TODO: refactor fields below
	icon_block_1?: IMediaFile | null;
	img_block_2?: IMediaFile | null;
	icon_block_3?: IMediaFile | null;
	image?: IMediaFile | null;
	icon?: IMediaFile | null;
	phone_icon?: IMediaFile | null;
	fax_icon?: IMediaFile | null;
	email_icon?: IMediaFile | null;
	time_icon?: IMediaFile | null;
	icon_1?: IMediaFile | null;
	icon_2?: IMediaFile | null;
	bars?: Array<{
		uuid?: string | null;
		style?: string | null;
		headline?: string | null;
		image?: IMediaFile | null;
	}>;
	additionalData?: Array<{
		uuid?: string | null;
		style?: string | null;
		headline?: string | null;
		image?: IMediaFile | null;
	}>;
}

export interface ISocialNetwork {
	uuid: string;
	name?: string | null;
	link?: string | null;
	icon?: IMediaFile | null;
}

export interface ISliderData {
	style: string;
	type: ESliderType;
	items_amount: number;
	delay: number;
	headline: string;
	subHeadLine: string;
	items: Array<ISliderItem>;
	socialNetworks: Array<ISocialNetwork>;
	socialNetworksHeadline: string;
}

export interface ISliderToolParams {
	data: ISliderData;
	api: API;
	block: BlockAPI;
}

export interface ISliderItemProps {
	item: ISliderItem;
	index: number;
}

export interface ISliderFormProps extends Omit<ISliderToolProps, 'data'> {}
