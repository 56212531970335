import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type TAnyEnum = {
	[key: string]: string;
};

type TSchema = Record<string, 'string' | 'number' | TAnyEnum>;

type IParams<T extends TSchema> = {
	[key in keyof T]: T[key] extends 'number'
		? number
		: T[key] extends 'string'
		? string
		: T[key] extends Record<string, string>
		? string
		: T[key] extends infer R
		? R
		: never;
};

const useQueryParameters = <T extends TSchema>(schema: T, defaultParams?: Partial<IParams<T>>) => {
	const [query, setQuery] = useSearchParams();
	const [controlledParams, setControlledParams] = useState(() => {
		return Object.entries(schema).reduce((curr, [key, value]) => {
			if (value === 'string') curr[key] = query.get(key) || defaultParams?.[key] || '';
			if (value === 'number') {
				const queryValue = query.get(key);
				curr[key] = (queryValue && parseInt(queryValue, 10)) || defaultParams?.[key] || -1;
			}
			if (typeof value === 'object') curr[key] = query.get(key) || Object.values(value)[0];
			return curr;
		}, {} as Record<string, any>);
	});

	useEffect(() => {
		setControlledParams(() => {
			return Object.entries(schema).reduce((curr, [key, value]) => {
				if (value === 'string') curr[key] = query.get(key) || defaultParams?.[key] || '';
				if (value === 'number') {
					const queryValue = query.get(key);
					curr[key] = (queryValue && parseInt(queryValue, 10)) || defaultParams?.[key] || -1;
				}
				if (typeof value === 'object') curr[key] = query.get(key) || Object.values(value)[0];
				return curr;
			}, {} as Record<string, any>);
		});
	}, [query]);

	const updateParams = useCallback(
		(params: Partial<IParams<T>>) => {
			const newParams = { ...controlledParams, ...params };
			setControlledParams(newParams);
			const newQuery = new URLSearchParams(query);
			Object.entries(newParams).forEach(([paramName, newValue]) => {
				if (!newValue) {
					newQuery.delete(paramName);
				} else {
					newQuery.set(paramName, newValue + '');
				}
			});
			setQuery(newQuery);
		},
		[setQuery]
	);

	return {
		controlledParams: controlledParams as IParams<T>,
		updateParams,
	};
};

export default useQueryParameters;
