import { IconButton } from '@mui/material';

import { EditorCancelIcon, EditorMenuIcon } from '@assets/svgs';
import ArrowRightIcon from 'shared/assets/svgs/tsx/ArrowRight';
import { SelectedMenu } from 'shared/ui/Editor/EditorBlockTools/_ui';

import type { FC, MutableRefObject, ReactNode } from 'react';

interface IProps {
	checkedNames?: (string | boolean)[];
	isModalOpen?: boolean;
	openModal?: () => void;
	menuRef?: MutableRefObject<Nullable<HTMLDivElement>>;
	closeModal?: () => void;
	moveBlockUp: () => void;
	deleteBlock: () => void;
	pickElement?: (name: string) => void;
	copyBlock?: () => void;
	moveBlockDown: () => void;
	items?: {
		icon: ReactNode;
		title: string;
		name: string;
	}[];
}

const ControlMenus: FC<IProps> = ({ openModal, isModalOpen, menuRef, moveBlockDown, moveBlockUp, deleteBlock, pickElement, items, checkedNames }) => {
	return (
		<>
			<div
				className='relative flex gap-3'
				onClick={(e) => e.stopPropagation()}
			>
				{items?.length && (
					<>
						<IconButton
							className='self-center'
							onClick={openModal}
						>
							<EditorMenuIcon />
						</IconButton>

						<SelectedMenu
							checkedNames={checkedNames}
							menuRef={menuRef}
							isModalOpen={isModalOpen}
							items={items}
							pickElement={pickElement}
						/>
					</>
				)}
				<div className={'flex flex-col self-center'}>
					<IconButton
						onClick={moveBlockUp}
						classes={{
							root: '!p-0',
						}}
					>
						<ArrowRightIcon className='rotate-[-90deg] ' />
					</IconButton>
					<IconButton
						onClick={moveBlockDown}
						classes={{
							root: '!p-0',
						}}
					>
						<ArrowRightIcon className='rotate-90' />
					</IconButton>
				</div>
				<IconButton
					className='self-center'
					onClick={deleteBlock}
					classes={{
						root: '!p-0',
					}}
				>
					<EditorCancelIcon />
				</IconButton>
			</div>
		</>
	);
};

export default ControlMenus;
