import type { ICardType13 } from './CardsType13.interface';

export const blankCardType13 = (): ICardType13 => ({
	id: crypto.randomUUID(),
	style: '',
	title: '',
	cardContent: { blocks: [] },
	title_image: null,
	link_text: '',
	link_url: '',
});
