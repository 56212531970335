import type { TableCellProps } from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

export interface IColumnsConfig<T> {
	label: string;
	renderRow: (row: T) => ReactNode;
	valueForOrdering?: keyof T | string;
	cellProps?: (row: T) => TableCellProps;
	headCellProps?: TableCellProps;
	dataId?: string;
}

export enum EOrderDirection {
	desc = 'desc',
	asc = 'asc',
}

export interface IUpdateParams {
	page: number;
	per_page: number;
	sort_by: string;
	sort_direction: string;
	title: string;
}

interface IBaseProps<T extends { id: string | number }> {
	columnsConfigs: IColumnsConfig<T>[];
	rows: Undefinable<T[]>;
	selectedRows: Map<T['id'], T>;
	setSelectedRows: Dispatch<SetStateAction<Map<T['id'], T>>>;
	onRowClick?: (row: T) => void;
	header?: JSX.Element;
	isSearchable?: boolean;
	areRowsFetching?: boolean;
	isUserHasViewPermission?: boolean;
	openEditionModal?: (row: T) => void;
	totalRows: number;
	singleSelectionMode?: boolean;
	filters?: JSX.Element;
	updateParams: (arg: Partial<IUpdateParams>) => void;
	params: IUpdateParams;
	isNotCheckbox?: boolean;
	disabledField?: EDisabledItems;
	isSliseText?: boolean;
}

export type TProps<T extends { id: string | number }> = IBaseProps<T>;

export enum EDisabledItems {
	locale = 'locale',
	users = 'users',
	role = 'role',
}
