import baseApi from 'shared/api/baseApi';
import { ETagTypes } from 'shared/lib/constants/tagTypes';

import type {
	TCreateMaterialTypePayload,
	TCreateMaterialTypeResponse,
	TDeleteMaterialTypePayload,
	TDeleteMaterialTypeResponse,
	TGetMaterialTypePayload,
	TGetMaterialTypeRepsonse,
	TGetMaterialTypesListPayload,
	TGetMaterialTypesListResponse,
	TGetMaterialTypesSelecOptionsPayload,
	TGetMaterialTypesSelecOptionsResponse,
	TUpdateMaterialTypePayload,
	TUpdateMaterialTypeResponse,
	TUpdateMaterialTypeStatusPayload,
	TUpdateMaterialTypeStatusResponse,
} from './materialTypesApi.types';

const BASE_URL = '/api/material-types';

export const materialTypesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getMaterialTypesList: builder.query<TGetMaterialTypesListResponse, TGetMaterialTypesListPayload>({
			query: ({ params }) => ({
				method: 'GET',
				url: `${BASE_URL}`,
				params,
			}),
			providesTags: [ETagTypes.MATERIAL_TYPES_LIST],
		}),
		getMaterialType: builder.query<TGetMaterialTypeRepsonse, TGetMaterialTypePayload>({
			query: ({ materialTypeId }) => ({
				method: 'GET',
				url: `${BASE_URL}/${materialTypeId}`,
			}),
			providesTags: [ETagTypes.MATERIAL_TYPE],
		}),
		getMaterialTypesSelectOptions: builder.query<TGetMaterialTypesSelecOptionsResponse, TGetMaterialTypesSelecOptionsPayload>({
			query: () => ({
				method: 'GET',
				url: `${BASE_URL}/select-options`,
			}),
			providesTags: [ETagTypes.FORM_SELECT_OPTIONS],
		}),
		createMaterialType: builder.mutation<TCreateMaterialTypeResponse, TCreateMaterialTypePayload>({
			query: ({ data }) => ({
				method: 'POST',
				url: `${BASE_URL}`,
				data,
			}),
			invalidatesTags: [ETagTypes.MATERIAL_TYPES_LIST],
		}),
		updateMaterialTypeStatus: builder.mutation<TUpdateMaterialTypeStatusResponse, TUpdateMaterialTypeStatusPayload>({
			query: (params) => ({
				method: 'PATCH',
				url: `${BASE_URL}/update-statuses`,
				params,
			}),
			invalidatesTags: [ETagTypes.MATERIAL_TYPES_LIST],
		}),
		updateMaterialType: builder.mutation<TUpdateMaterialTypeResponse, TUpdateMaterialTypePayload>({
			query: ({ materialTypeId, data }) => ({
				method: 'PUT',
				url: `${BASE_URL}/${materialTypeId}`,
				data,
			}),
			invalidatesTags: [ETagTypes.MATERIAL_TYPES_LIST, ETagTypes.MATERIAL_TYPE],
		}),
		daleteMaterialType: builder.mutation<TDeleteMaterialTypeResponse, TDeleteMaterialTypePayload>({
			query: ({ materialTypeId }) => ({
				method: 'DELETE',
				url: `${BASE_URL}/${materialTypeId}`,
			}),
			invalidatesTags: [ETagTypes.MATERIAL_TYPES_LIST],
		}),
	}),
});

export const {
	useGetMaterialTypesListQuery,
	useLazyGetMaterialTypesListQuery,
	useGetMaterialTypeQuery,
	useLazyGetMaterialTypeQuery,
	useGetMaterialTypesSelectOptionsQuery,
	useLazyGetMaterialTypesSelectOptionsQuery,
	useCreateMaterialTypeMutation,
	useUpdateMaterialTypeStatusMutation,
	useUpdateMaterialTypeMutation,
	useDaleteMaterialTypeMutation,
} = materialTypesApi;
