import { polygon } from 'leaflet';
import { useEffect } from 'react';

import RB from './BelarusGeojson.json';
import './Polygons.css';

import type { LatLngTuple, Map as LMap } from 'leaflet';

interface IProps {
	map: LMap;
}

export const PolygonsLayer = ({ map }: IProps) => {

	useEffect(() => {
		const RBpoly = polygon(RB.geometry.coordinates as LatLngTuple[][][], {
			className: 'rb-gradient',
			fillOpacity: 0.8,
			fill: true,
			fillColor: 'url(#polygon-rb-gradient)'
			// fillRule: 'nonzero'
		});
		RBpoly.addTo(map);
		const svg = document.querySelector('svg.leaflet-zoom-animated')

		if (svg) {
			const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs')
			const linearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient')
			linearGradient.id = '#polygon-rb-gradient'
			linearGradient.setAttributeNS(null, 'gradientUnits', 'userSpaceOnUse')
			linearGradient.setAttributeNS(null, 'x1', '110.22%')
			linearGradient.setAttributeNS(null, 'y1', '21.89%')
			linearGradient.setAttributeNS(null, 'x2', '-10.22%')
			linearGradient.setAttributeNS(null, 'y2', '78.11%')
			const stop1= document.createElementNS('http://www.w3.org/2000/svg', 'stop')
			stop1.setAttributeNS(null,'offset', '.113')
			stop1.setAttributeNS(null,'stop-color', '#F18426')
			const stop2= document.createElementNS('http://www.w3.org/2000/svg', 'stop')
			stop2.setAttributeNS(null,'offset', '.506')
			stop2.setAttributeNS(null,'stop-color', '#95C11F')
			const stop3= document.createElementNS('http://www.w3.org/2000/svg', 'stop')
			stop3.setAttributeNS(null,'offset', '.911')
			stop3.setAttributeNS(null,'stop-color', '#009B3E')

			linearGradient.append(stop1)
			linearGradient.append(stop2)
			linearGradient.append(stop3)
			defs.append(linearGradient)

			svg.prepend(defs)
		}
	}, [map]);

	return null;
};

//<defs>
// <linearGradient id='g1' gradientUnits='userSpaceOnUse' x1='110.22%' y1='21.89%' x2='-10.22%' y2='78.11%'>
// <stop offset='.113' stop-color='#F18426'/>
// <stop offset='.506' stop-color='#95C11F'/>
// <stop offset='.911' stop-color='#009B3E'/>
// </linearGradient>
// </defs>