import { audioTypes, documentTypes, imageTypes, videoTypes } from './FileUploader.meta';

import type { IMediaFile } from 'shared/interfaces';

type FileVariant = 'image' | 'audio' | 'video' | 'document' | 'others';
interface TransformedFile {
	originalFile: File;
	displaySize: string;
	variant: FileVariant;
	isWatermark: boolean;
	id: string;
}

const convertBytes = (size: number) => {
	const dimensions = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'];
	const degree = Math.floor(Math.log(size) / Math.log(1024));
	const currentDimension = dimensions[degree];
	const convertedSize = (size / Math.pow(1024, degree)).toFixed(2);
	return convertedSize + currentDimension;
};

const fileVariants: Record<FileVariant, string[]> = {
	image: imageTypes,
	audio: audioTypes,
	video: videoTypes,
	document: documentTypes,
	others: [],
};

export const hashVariants: Record<string, FileVariant> = {};

Object.entries(fileVariants).forEach((type) => {
	const variant = type[0] as FileVariant;
	type[1].forEach((key) => {
		hashVariants[key] = variant;
	});
});

class FileTransformer {
	private readonly file: File;

	public isValidFile = true;

	private variant: FileVariant | null = null;

	private isWatermark = true;

	private availableMimes: string;

	constructor(file: File, availableMimes: string) {
		this.file = file;
		this.availableMimes = availableMimes;
		this.validate();
	}

	private validate() {
		const fileExt = '.' + this.file.name.split('.').pop();
		this.variant = hashVariants[fileExt] ?? 'others';
		if (this.availableMimes === '*') return;
		this.isValidFile = this.availableMimes.split(', ').some((mime) => mime === this.file.type);
	}

	public toLiteral(): TransformedFile {
		const id = Date.now() + Math.random().toFixed(3) + this.file.size;
		return {
			originalFile: this.file,
			displaySize: convertBytes(this.file.size),
			variant: this.variant!,
			isWatermark: this.isWatermark,
			id,
		};
	}
}

const serializeFiles = (files: FileList, availableMimes: string) => {
	const transformedFiles = Array.from(files).map((file) => new FileTransformer(file, availableMimes));
	return transformedFiles.some((file) => !file.isValidFile) ? [] : transformedFiles.map((file) => file.toLiteral());
};

const checkIsSumFileSizeValid = (files: TransformedFile[], maxFileSize: number) => {
	const sizeBytes = files.reduce((acc, cur) => {
		return acc + cur.originalFile.size;
	}, 0);
	return Math.ceil(sizeBytes / Math.pow(1024, 3)) <= maxFileSize;
};

export { checkIsSumFileSizeValid };

export { FileTransformer, convertBytes, serializeFiles };

export type { TransformedFile };

export enum EFileUploaderVariants {
	ALL,
	IMAGE,
	VIDEO,
}

const getCorrectExtension = (type: string, mediaTypes: string[]) => {
	const result = type.split('/')[1];
	const extension = `.${result}`;

	return mediaTypes.includes(extension);
};
export const checkExtension = (file: TransformedFile | IMediaFile, mediaTypes?: string[]): Undefinable<boolean> => {
	if (!mediaTypes) return;
	if ('originalFile' in file) {
		return getCorrectExtension(file.originalFile.type, mediaTypes);
	} else {
		return getCorrectExtension(file.mime_type, mediaTypes);
	}
};

export const addWatterMarkFields = (files: TransformedFile[], mediaTypes: Undefinable<string[]>) =>
	files.map((file) => {
		const isExtensionExist = checkExtension(file, mediaTypes);
		const cloneFile = { ...file };
		cloneFile.isWatermark = !!isExtensionExist;
		return cloneFile;
	});
