export enum EFieldTypes {
	TEXT = 'text',
	TEXT_AREA = 'textarea',
	SUBTITLE = 'subtitle',
	SELECT = 'select',
	CHECKBOX = 'checkbox',
	RADIO_BUTTONS_GROUP = 'radio_buttons_group',
	IMAGE_MEDIA = 'image_media',
	PDF_MEDIA = 'pdf_media',
	OTHER_MEDIA = 'other_media',
	DATE_PICKER = 'date_picker',
	DATE_RANGE_PICKER = 'date_range_picker',
}

export const FIELD_TYPES_SELECT_OPTIONS = {
	[EFieldTypes.TEXT]: 'Текстовое поле',
	[EFieldTypes.TEXT_AREA]: 'Большое текстовое поле',
	[EFieldTypes.SUBTITLE]: 'Подзаголовок формы',
	[EFieldTypes.SELECT]: 'Выподающий список со значениями',
	[EFieldTypes.CHECKBOX]: 'Флажок',
	[EFieldTypes.RADIO_BUTTONS_GROUP]: 'Группа радиокнопок',
	[EFieldTypes.DATE_PICKER]: 'Дата',
	[EFieldTypes.DATE_RANGE_PICKER]: 'Диапазон дат',
};

export const FIELD_TYPES_SELECT_OPTIONS_MATERIAL_TYPE = {
	[EFieldTypes.TEXT]: 'Текстовое поле',
	[EFieldTypes.TEXT_AREA]: 'Большое текстовое поле',
	[EFieldTypes.SELECT]: 'Выподающий список со значениями',
	[EFieldTypes.CHECKBOX]: 'Флажок',
	[EFieldTypes.PDF_MEDIA]: 'Файл .pdf',
	[EFieldTypes.IMAGE_MEDIA]: 'Изображение',
	[EFieldTypes.OTHER_MEDIA]: 'Другие медиафайлы',
	[EFieldTypes.RADIO_BUTTONS_GROUP]: 'Группа радиокнопок',
	[EFieldTypes.DATE_PICKER]: 'Дата',
	[EFieldTypes.DATE_RANGE_PICKER]: 'Диапазон дат',
};
