import { MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { useModal } from 'shared/hooks';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { swapToBottomArrayItems, swapToTopArrayItems } from 'shared/lib/helpers/arrayHelpers';
import { Button, FormInputFileUploader } from 'shared/ui';
import { EEditorTools } from 'shared/ui/Editor/Editor.interfaces';
import { ADD, tuneAddElement } from 'shared/ui/Editor/EditorBlockTools/EditorBlockTools.meta';
import {
	SliderTypesSelectOptions,
	type ISliderData,
	type ISliderFormProps,
	ESliderType,
} from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.interface';
import { generateSliderItem, generateSocialNetwork } from 'shared/ui/Editor/EditorBlockTools/SliderTool/SliderTool.meta';
import { ControlMenus, EvaEditor, INLINE_TOOLS, SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import { SelectInput } from 'shared/ui/SelectInput';
import { TextInput } from 'shared/ui/TextInput';

import {
	SliderFormType1,
	SliderFormType2,
	SliderFormType3,
	SliderFormType4,
	SliderFormType5,
	SliderFormType6,
	SliderFormType7,
	SliderFormType8,
	SliderItem,
} from './_ui';

import type { IMediaFile } from 'shared/interfaces';

const SliderForm = ({ onDataChange, deleteBlock, moveBlockDown, moveBlockUp }: ISliderFormProps) => {
	const { isModalOpen, openModal, closeModal } = useModal();
	const { values, setFieldValue } = useFormikContext<ISliderData>();

	useEffect(() => {
		onDataChange(values);
	}, [onDataChange, values]);

	const pickElement = (name: string) => {
		if (name === ADD) {
			setFieldValue('items', [...values.items, generateSliderItem()]);
		}
	};
	const menuRef = useRef<NonNullable<HTMLDivElement>>(null);

	useClickOutside(menuRef, closeModal);

	const changeTypeHandler = useCallback(
		(newType: ESliderType) => {
			setFieldValue('type', newType, true);
			setFieldValue('items', [generateSliderItem()]);
		},
		[setFieldValue]
	);

	const addSocialNetwork = useCallback(() => {
		setFieldValue('socialNetworks', [...values.socialNetworks, generateSocialNetwork()], true);
	}, [setFieldValue, values.socialNetworks]);

	const removeSocialNetwork = useCallback(
		(index: number) => {
			const newValue = values.socialNetworks.filter((_, socialNetnworkIndex) => index !== socialNetnworkIndex);

			setFieldValue('socialNetworks', newValue, true);
		},
		[setFieldValue, values.socialNetworks]
	);

	const handleSelectFile = (newValue: Nullable<IMediaFile>, field: string) => {
		setFieldValue(field, newValue);
	};

	const handleDeleteImage = (field: string) => {
		setFieldValue(field, '');
	};

	return (
		<div className='border border-black p-4'>
			<h2 className=''>Слайдер</h2>
			<div className='absolute right-[17px] top-[10px]'>
				<ControlMenus
					menuRef={menuRef}
					openModal={openModal}
					closeModal={closeModal}
					isModalOpen={isModalOpen}
					deleteBlock={deleteBlock}
					moveBlockDown={moveBlockDown}
					moveBlockUp={moveBlockUp}
					items={[...tuneAddElement]}
					pickElement={pickElement}
				/>
			</div>
			<div className='mb-5 grid grid-cols-3 gap-4'>
				<Field
					name='style'
					label='Стиль'
					placeholder='Стиль'
					as={TextInput}
				/>
				<SelectInput
					value={values.type}
					onChange={(e) => changeTypeHandler(e.target.value as ESliderType)}
					label='Тип'
					className='bg-white'
				>
					{Object.entries(SliderTypesSelectOptions).map(([key, value]) => (
						<MenuItem
							key={key}
							value={key}
						>
							{value.label}
						</MenuItem>
					))}
				</SelectInput>
				<Field
					name='headline'
					label='Заголовок слайдера'
					placeholder='Заголовок слайдера'
					as={TextInput}
				/>
				<Field
					name='subHeadLine'
					label='Подзаголовок слайдера'
					placeholder='Подзаголовок слайдера'
					as={TextInput}
				/>
				<Field
					name='items_amount'
					label='Количество элементов на 1 слайд'
					placeholder='Количество элементов на 1 слайд'
					type='number'
					as={TextInput}
				/>
				<Field
					name='delay'
					label='Задержка при перелистывании'
					placeholder='Задержка при перелистывании'
					type='number'
					as={TextInput}
				/>
				<div className='col-span-3'>
					<Field
						name='socialNetworksHeadline'
						label='Заголовок для блока социальные сети'
						placeholder='Введите заголовок для блока социальные сети'
						type='text'
						as={TextInput}
					/>
				</div>
				<div className='col-span-4 flex flex-col gap-4'>
					{values.socialNetworks?.map((socialNetwork, index) => {
						return (
							<div
								key={socialNetwork.uuid}
								className='grid grid-cols-5'
							>
								<div className='col-span-4 grid grid-cols-3 gap-4'>
									<Field
										name={`socialNetworks.[${index}].name`}
										label='Наименование социальной сети'
										placeholder='Введите наименование социальной сети'
										type='text'
										as={TextInput}
									/>
									<Field
										name={`socialNetworks.[${index}].link`}
										label='Ссылка на социальную сеть'
										placeholder='Введите ссылку на социальную сеть'
										type='text'
										as={TextInput}
									/>
									<div className=''>
										<FormInputFileUploader
											handleDeleteImage={handleDeleteImage}
											handleSelectFile={handleSelectFile}
											media={values.socialNetworks[index].icon || null}
											name={`socialNetworks.[${index}].icon`}
											text='Иконка социальной сети'
										/>
									</div>
								</div>
								<Button onClick={() => removeSocialNetwork(index)}>Удалить</Button>
							</div>
						);
					})}
					<Button onClick={addSocialNetwork}>Добавить социальную сеть</Button>
				</div>
			</div>
			<div className='mb-3 mt-3'>
				<hr className='h-[1px] text-border-secondary' />
			</div>
			<div className='flex flex-col gap-y-4'>
				{values.items.map((slide, index) => {
					switch (values.type) {
						case ESliderType.TYPE_1: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType1 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
						}
						case ESliderType.TYPE_2: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType2 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<EvaEditor
										allowedTools={[EEditorTools.PARAGRAPH, ...INLINE_TOOLS, EEditorTools.LIST]}
										data={slide.data}
										handleChangeData={(newData) => setFieldValue(`items.${index}.data`, newData)}
									/>
									<SubTuner
										index={index}
										tuneItem={slide.id}
										handleUp={(id) => setFieldValue('items', swapToTopArrayItems(id, values.items))}
										handleDown={(id) => setFieldValue('items', swapToBottomArrayItems(id, values.items))}
										handleClose={(id) =>
											setFieldValue(
												'items',
												values.items.filter((slideItem) => slideItem.id !== id)
											)
										}
									/>
									{/*<SliderItem*/}
									{/*	index={index}*/}
									{/*	item={slide}*/}
									{/*/>*/}
								</div>
							);
						}
						case ESliderType.TYPE_3: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType3 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
						}
						case ESliderType.TYPE_4: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType4 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
						}
						case ESliderType.TYPE_5: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType5 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
						}
						case ESliderType.TYPE_6: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType6 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
						}
						case ESliderType.TYPE_7: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType7 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
						}
						case ESliderType.TYPE_8: {
							return (
								<div
									key={slide.id}
									className='relative border border-black p-4'
								>
									<SliderFormType8 index={index} />
									<h4 className=''>Дополнительные блоки:</h4>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
						}

						default:
							return (
								<div
									key={slide.id}
									className='relative'
								>
									<SliderItem
										index={index}
										item={slide}
									/>
								</div>
							);
					}
				})}
			</div>
		</div>
	);
};

export default SliderForm;
