import type { IUser } from 'shared/interfaces/user';

const TOKEN_KEY = 'token';

export const saveToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

const USER_KEY = 'user';

export const saveUser = (user: Partial<IUser>) =>
	localStorage.setItem(USER_KEY, JSON.stringify(user));

export const getUser = () => {
	const result = localStorage.getItem(USER_KEY);

	if (result) {
		return JSON.parse(result) as IUser;
	}

	return null;
};

export const removeUser = () => localStorage.removeItem(USER_KEY);
