import cn from 'classnames';
import Image from 'mui-image';

import { EMediaProcessingStatuses } from 'shared/lib/constants/processing';
import { getExtension } from 'shared/lib/helpers/getExtension';
import { Spinner, TextInput } from 'shared/ui';
import { EMediaToolFileAlignment } from 'shared/ui/Editor/EditorBlockTools/MediaTool/MediaTool.interfaces';
import { EFilePreviewViewModes } from 'shared/ui/Editor/EditorBlockTools/_ui/FilePreview/FIlePreview.interfaces';
import { imageTypes } from 'shared/ui/FileUploader/FileUploader.meta';
import { ESelectFileVariant } from 'shared/ui/SelectMediaModal/SelectMediaModal.interfaces';

import type { FC } from 'react';
import type { IMediaFile } from 'shared/interfaces';

interface IProps {
	media: IMediaFile;
	variant: ESelectFileVariant;
	viewMode?: EFilePreviewViewModes | false;
	openEditModal?: () => void;
	providedText?: string;
	onProvidedChanged?: (value: string) => void;
	style: string | null;
	onChangeStyle: (value: string | null) => void;
	stretched?: boolean;
	withBorder?: boolean;
	withBackground?: boolean;
	alignment?: EMediaToolFileAlignment | false | '';
}

const FilePreview: FC<IProps> = ({
	media,
	variant,
	viewMode,
	providedText,
	onProvidedChanged,
	stretched = false,
	withBorder = false,
	withBackground = false,
	alignment = false,
	style = null,
	onChangeStyle,
}) => {
	let preview = <></>;

	const elementsClassnames = cn('p-5', {
		'w-full': stretched,
		'border-solid border border-black': withBorder,
		'w-[200px]': !stretched,
	});

	const mediaClassnames = cn('relative my-5 flex  flex-col justify-between justify-self-center shadow-md transition-shadow', {
		'bg-gray-light': withBackground,
		'flex items-center': alignment === EMediaToolFileAlignment.CENTER,
		'flex items-start': alignment === EMediaToolFileAlignment.LEFT,
		'flex items-end': alignment === EMediaToolFileAlignment.RIGHT,
		'shadow-red shadow-lg shadow-md transition-shadow': media.status === EMediaProcessingStatuses.Processing_error,
	});

	const mediaTextName = (
		<span
			className={cn('drop-shadow(0 1px 2px white) drop-shadow(0 0 1px white)', 'overflow-hidden text-ellipsis whitespace-nowrap p-1 text-start')}
			title={media.name}
		>
			{`${media.name}`}
			{media.status === EMediaProcessingStatuses.Processing_error && <div className='text-error'>ошибка обработки</div>}
		</span>
	);

	if (variant === ESelectFileVariant.PDF) {
		preview = (
			<iframe
				className={elementsClassnames}
				title={providedText}
				src={media.url}
			/>
		);
	}

	if (variant === ESelectFileVariant.MEDIA) {
		const extension = getExtension(media.url);

		if (imageTypes.includes(extension))
			preview = (
				<div className={`${mediaClassnames}`}>
					{media.status === EMediaProcessingStatuses.Processing && <Spinner className='absolute right-0 z-10' />}
					<img
						className={elementsClassnames}
						src={media.url}
						alt='preview'
					/>
					{mediaTextName}
				</div>
			);
		else {
			preview = (
				<div className={`${mediaClassnames}`}>
					{media.status === EMediaProcessingStatuses.Processing && <Spinner className='absolute right-0 z-10' />}
					<video
						className={elementsClassnames}
						controls
						src={media.url}
					/>
					{mediaTextName}
				</div>
			);
		}
	}

	if (variant === ESelectFileVariant.OTHERS || viewMode === EFilePreviewViewModes.LINK) {
		preview = (
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			<a
				className='mb-5 block text-center'
				href={'#'}
				download={media.url}
			>
				{media.url}
			</a>
		);
	}

	if (variant === ESelectFileVariant.IMAGE) {
		preview = <Image src={media.url} />;
	}

	return (
		<div>
			{preview}
			{onProvidedChanged && (
				<div className='flex flex-col gap-y-6'>
					<TextInput
						className='relative z-0 mt-2'
						value={style}
						onChange={(event) => onChangeStyle(event.target.value)}
						label='Стиль'
						placeholder='Введите стиль'
					/>
					<TextInput
						className='relative z-0 mt-2'
						value={providedText}
						onChange={(event) => onProvidedChanged(event.target.value)}
						label={variant === ESelectFileVariant.OTHERS ? 'Текст для отображения' : 'Сопроводительный текст'}
						placeholder={variant === ESelectFileVariant.OTHERS ? 'Введите текст для отображения' : 'Введите сопроводительный текст'}
					/>
				</div>
			)}
		</div>
	);
};

export default FilePreview;
