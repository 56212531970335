import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType6Social } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType6/CardsType6.interfaces';

interface IProps {
	cardSocialData: ICardType6Social;
	index: number;
	parentIndex: number;
	handleChangeCardSocialData: (newData: ICardType6Social) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleSocial = ({
	handleChangeCardSocialData,
	parentIndex,
	handleUp,
	handleClose,
	handleDown,
	cardSocialData,
	index,
	getFieldProps,
}: IProps) => {
	return (
		<Accordion>
			<AccordionSummary>Социальная сеть №{index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex w-full gap-m'>
					<div className='flex flex-col'>
						<div className=''>Иконка</div>
						<SelectImageWithControls
							handleSelectFile={(newFile) => {
								handleChangeCardSocialData({ ...cardSocialData, icon: newFile });
							}}
							handleDeleteImage={() => handleChangeCardSocialData({ ...cardSocialData, icon: null })}
							mediaItem={cardSocialData.icon}
						/>
					</div>
					<div className='flex w-full flex-col gap-m'>
						<TextInput
							{...getFieldProps(`cards.${parentIndex}.contacts.${index}.icon_url`)}
							label='URL'
							placeholder='Введите URL'
						/>
						<TextInput
							{...getFieldProps(`cards.${parentIndex}.contacts.${index}.icon_text`)}
							label='Текст иконки'
							placeholder='Введите текст иконки'
						/>
					</div>
				</div>

				<SubTuner
					tuneItem={cardSocialData.id}
					handleUp={handleUp}
					handleDown={handleDown}
					handleClose={handleClose}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
