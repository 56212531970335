import { createSlice } from '@reduxjs/toolkit';

import * as storage from 'shared/lib/helpers/storageHelpers';

import type { IAuthSlice } from './auth.interfaces';
import type { AppDispatch } from '@store/interfaces';

const initialState: IAuthSlice = {
	isLoading: false,
	currUser: storage.getUser(),
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setIsLoading: (state, { payload }: { payload: boolean }) => {
			state.isLoading = payload;
		},
		setCurrUser: (state, { payload }: { payload: IAuthSlice['currUser'] }) => {
			state.currUser = payload;

			if (payload) {
				storage.saveUser(payload);
			}
		},
	},
});

const logout = () => async (dispatch: AppDispatch) => {
	storage.removeToken();
	storage.removeUser();
	dispatch(authSlice.actions.setCurrUser(null));
};

export const { setIsLoading, setCurrUser, logoutAction } = {
	...authSlice.actions,
	logoutAction: logout,
};

export default authSlice.reducer;
