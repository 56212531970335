import { v4 as uuidv4 } from 'uuid';

import { createDialog } from './createDialog';

// eslint-disable-next-line no-restricted-imports
import type { RenderContext } from '../container';
import type { OutputData } from '@editorjs/editorjs';

interface LayoutBlockItemContentData {
	[index: string]: Pick<OutputData, 'blocks'> | undefined;
}

interface ValidatedLayoutBlockItemContentData extends LayoutBlockItemContentData {}

interface RenderItemContentProps extends RenderContext {
	data: OutputData;
	itemContentId: string;
}

const renderItemContent = ({ EditorJS, data, dispatchData, editorJSConfig, itemContentId, readOnly }: RenderItemContentProps) => {
	const editorJSHolderID = uuidv4();
	const wrapper = document.createElement('div');

	wrapper.id = editorJSHolderID;

	if (readOnly) {
		wrapper.classList.add('read-only');
	} else {
		wrapper.addEventListener('click', () => {
			const dialog = createDialog({
				EditorJS,
				data,
				editorJSConfig,
				onClose: async ({ editorJSData }) =>
					dispatchData(({ itemContent, layout }) => ({
						itemContent: {
							...itemContent,
							[itemContentId]: {
								blocks: editorJSData.blocks,
							},
						},
						layout: layout,
					})),
			});

			dialog.openModal();
			document.body.append(dialog.wrapper);
		});
	}

	new EditorJS({
		...editorJSConfig,
		holder: editorJSHolderID,
		data,
		minHeight: 0,
		readOnly: true,
	});

	const styleElement = document.createElement('style');
	styleElement.textContent = `
    #${CSS.escape(editorJSHolderID)} {
      cursor: pointer;
      height: 100%;
    }

    #${CSS.escape(editorJSHolderID)}.read-only {
      cursor: unset;
    }

    #${CSS.escape(editorJSHolderID)} .codex-editor__loader {
      display: none;
    }
  `;
	wrapper.append(styleElement);

	return wrapper;
};

export { renderItemContent };
export type { LayoutBlockItemContentData, RenderItemContentProps, ValidatedLayoutBlockItemContentData };
