import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TextInput } from 'shared/ui';
import { EvaEditor, SubTuner } from 'shared/ui/Editor/EditorBlockTools/_ui';
import SelectImageWithControls from 'shared/ui/Editor/EditorBlockTools/_ui/SelectImageWithControls/SelectImageWithControls';

import type { FieldInputProps } from 'formik/dist/types';
import type { ICardType2 } from 'shared/ui/Editor/EditorBlockTools/CardsTool/CardsVariants/CardsType2/CardsType2.interfaces';

interface IProps {
	cardData: ICardType2;
	index: number;
	handleChangeCardData: (newData: ICardType2) => void;
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
	setFieldValue: (key: string, newValue: unknown) => void;
	handleUp: (id: string | number) => void;
	handleDown: (id: string | number) => void;
	handleClose: (id: string | number) => void;
}

export const SingleCardType2 = ({
	cardData,
	index,
	handleChangeCardData,
	handleUp,
	handleDown,
	handleClose,
	getFieldProps,
	setFieldValue,
}: IProps) => {
	return (
		<Accordion defaultExpanded={false}>
			<AccordionSummary>Карточка {index + 1}</AccordionSummary>
			<AccordionDetails>
				<div className='flex gap-m'>
					<div className='flex flex-col gap-m'>
						<div className='flex flex-col'>
							<div className=''>Изображение asasd</div>
							<SelectImageWithControls
								handleSelectFile={(newFile) => {
									handleChangeCardData({ ...cardData, image_1: newFile });
								}}
								handleDeleteImage={() => {
									handleChangeCardData({ ...cardData, image_1: null });
								}}
								mediaItem={cardData.image_1}
							/>
						</div>
					</div>
					<div className='flex w-full flex-col gap-m '>
						<TextInput
							{...getFieldProps(`cards.${index}.style`)}
							label='Стиль'
							placeholder='Введите стиль'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.title`)}
							label='Заголовок'
							placeholder='Введите текст заголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.subtitle`)}
							label='Подзаголовок'
							placeholder='Введите текст подзаголовка'
						/>
						<TextInput
							{...getFieldProps(`cards.${index}.description`)}
							label='Описание'
							placeholder='Введите текст описания'
						/>
						<div className='flex flex-col gap-m rounded-2xl border-[1px] p-m'>
							<div>Дополнительные блоки:</div>
							<div>
								<EvaEditor
									data={cardData.additionalContent}
									handleChangeData={(newData) => {
										setFieldValue(`cards.${index}.additionalContent`, newData);
									}}
								/>
							</div>
						</div>
					</div>

					<SubTuner
						tuneItem={cardData.id}
						handleUp={handleUp}
						handleDown={handleDown}
						handleClose={handleClose}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
