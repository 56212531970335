import Checklist from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import ColorPlugin from 'editorjs-text-color-plugin';

import { EEditorTools } from 'shared/ui/Editor/Editor.interfaces';
import { editorI18nConfig } from 'shared/ui/Editor/Editor.meta';
import {
	CAnyFileTool,
	CBlockContainerTool,
	CButtonTool,
	CCardsTool,
	CContainerTemplatesTool,
	CFormTool,
	CGoogleMapTool,
	CLeafletMapTool,
	CMediaTool,
	CNavTool,
	CNewsTool,
	CPDFTool,
	CSitemapTool,
	CYandexMapTool,
} from 'shared/ui/Editor/EditorBlockTools';
import { fourColumns, layout, threeColumns, twoColumns } from 'shared/ui/Editor/EditorBlockTools/LayoutTool/LayoutTool.meta';
import { CTabTool } from 'shared/ui/Editor/EditorBlockTools/TabTool/TabTool.class';

import { CAccordionTool, CSliderTool, CAnchorTool, CBarsTool } from './EditorBlockTools';

import type { EditorConfig } from '@editorjs/editorjs';
import type { ToolConstructable } from '@editorjs/editorjs/types/tools';

export const EditorHolderId = 'editorHolderId';

export const getEditorConfigs = (): EditorConfig => ({
	holder: EditorHolderId,
	defaultBlock: 'paragraph',
	i18n: editorI18nConfig,
	tools: {
		// slideShow: CSlideShowTool,
		// carousel: CCarouselTool,
		[EEditorTools.ACCORDION]: CAccordionTool,
		[EEditorTools.YANDEX_MAP]: CYandexMapTool,
		[EEditorTools.GOOGLE_MAP]: CGoogleMapTool,
		// blockView: {
		// 	class: CBlockViewTool as unknown as ToolConstructable,
		// },
		[EEditorTools.BUTTON]: CButtonTool,
		[EEditorTools.FILE]: CAnyFileTool,
		[EEditorTools.MEDIA]: CMediaTool,
		[EEditorTools.PDF]: CPDFTool,
		[EEditorTools.TABS]: CTabTool,
		// counter: {
		// 	class: CCounterTool as unknown as ToolConstructable,
		// 	inlineToolbar: true,
		// },
		[EEditorTools.HEADER]: {
			class: Header,
			inlineToolbar: true,
		},
		[EEditorTools.PARAGRAPH]: {
			class: Paragraph,
			inlineToolbar: true,
		},
		[EEditorTools.TABLE]: {
			class: Table,
			inlineToolbar: true,
		},
		[EEditorTools.ANCHOR]: CAnchorTool,
		[EEditorTools.NAV]: CNavTool,
		[EEditorTools.BARS]: CBarsTool,
		[EEditorTools.LEAFLET_MAP]: CLeafletMapTool,
		[EEditorTools.CARDS]: CCardsTool,
		[EEditorTools.SITEMAP]: CSitemapTool,
		[EEditorTools.CONTAINER_TEMPLATES]: CContainerTemplatesTool,
		[EEditorTools.CHECKLIST]: {
			class: Checklist,
			inlineToolbar: true,
		},
		[EEditorTools.LIST]: {
			class: List,
			inlineToolbar: true,
		},
		[EEditorTools.QUOTE]: {
			class: Quote,
			inlineToolbar: true,
			config: {
				quotePlaceholder: 'Enter a quote',
				captionPlaceholder: "Quote's author",
			},
		},
		[EEditorTools.DELIMITER]: {
			class: Delimiter,
			inlineToolbar: true,
		},
		[EEditorTools.LAYOUT]: layout,
		[EEditorTools.TWO_COLUMNS]: twoColumns,
		[EEditorTools.THREE_COLUMNS]: threeColumns,
		[EEditorTools.FOUR_COLUMNS]: fourColumns,
		[EEditorTools.COLOR]: {
			class: ColorPlugin,
			config: {
				// colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
				defaultColor: '#FF1300',
				type: 'text',
				customPicker: true,
			},
		},
		[EEditorTools.MARKER]: {
			class: ColorPlugin,
			config: {
				defaultColor: '#FFBF00',
				customPicker: true,
				type: 'marker',
				icon: '<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>',
			},
		},
		// code: {
		// 	class: CCodeTool as unknown as ToolConstructable,
		// },
		// latest_material: CNewsTool,
		// socialNetworksPosts: CSocialNetworksPostsTool,
		// container: CBlockContainerTool as unknown as ToolConstructable,
		// form: CFormTool,
		// ======= inline tools =================
		// bold: {
		// 	class: CBoldTool,
		// },
		// colorText: CColorTool,
		// crossedText: CCrossedOutTextTool,
		// italic: {
		// 	class: CCursiveTool,
		// },
		// inlineCode: {
		// 	class: CInlineCodeTool,
		// },
		// link: {
		// 	class: CLinkTool,
		// },
		// lowerCaseText: CLowerCaseTool,
		// monospacedText: CMonospacedTool,
		// underline: CUnderlineTool,
		// upperCaseText: CUpperCaseTool,
		// Marker: {
		// 	class: CMarkerTool,
		// },
		[EEditorTools.LATEST_MATERIAL]: CNewsTool,
		[EEditorTools.SLIDER]: CSliderTool,
		[EEditorTools.CONTAINER]: CBlockContainerTool as unknown as ToolConstructable,
		[EEditorTools.FORM]: CFormTool,
	},
});
