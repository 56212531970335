import type { ISVGProps } from '@assets/svgs/interfaces';
import type { FC } from 'react';

const HyperLinkMenuIcon: FC<ISVGProps> = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		viewBox='0 0 512 512'
	>
		<g data-name='40 Hyperlink'>
			<g
				fillRule='evenodd'
				data-name='Group 103'
			>
				<path
					d='M503.466 132.268 379.733 8.534a29.17 29.17 0 0 0-41.234 0L214.767 132.268a29.15 29.15 0 0 0 0 41.265l20.6 20.6L359.1 70.4l82.5 82.5-123.733 123.733 20.633 20.632a29.169 29.169 0 0 0 41.234 0l123.731-123.732a29.207 29.207 0 0 0 .001-41.265Z'
					data-name='Path 51'
				/>
				<path
					d='M276.633 317.866 152.9 441.6l-82.5-82.5 123.733-123.732-20.633-20.632a29.167 29.167 0 0 0-41.233 0L8.534 338.468a29.208 29.208 0 0 0 0 41.265l123.733 123.733a29.169 29.169 0 0 0 41.233 0l123.733-123.733a29.152 29.152 0 0 0 0-41.265Z'
					data-name='Path 52'
				/>
				<path
					d='M173.5 338.468a29.153 29.153 0 0 0 41.266 0L338.5 214.736a29.157 29.157 0 0 0-41.265-41.2L173.5 297.265a29.133 29.133 0 0 0 0 41.203Z'
					data-name='Path 53'
				/>
			</g>
		</g>
	</svg>
);
export default HyperLinkMenuIcon;
