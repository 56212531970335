import type { ISVGProps } from '@assets/svgs/interfaces';

const CopyIcon = ({ className }: ISVGProps) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		className={className}
	>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M12 5.333H6c-.737 0-1.334.597-1.334 1.334v6c0 .736.597 1.333 1.333 1.333h6c.737 0 1.334-.597 1.334-1.333v-6c0-.737-.597-1.334-1.334-1.334Z'
		/>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M4.121 11.086h-.667a1.333 1.333 0 0 1-1.333-1.333v-6a1.333 1.333 0 0 1 1.333-1.334h6a1.333 1.333 0 0 1 1.334 1.334v.666'
		/>
	</svg>
);
export default CopyIcon;
