import EditorJS from '@editorjs/editorjs';
import { useEffect, useId, useRef } from 'react';

import { stopPropagationHandler } from 'shared/lib/helpers/editorJsEvent';
import { getEditorConfigs } from 'shared/ui/Editor/Editor.lib';
import { ALL_TOOLS, removeBannedTools } from 'shared/ui/Editor/EditorBlockTools/_ui/Eva/EvaEditor.lib';

import type { API, OutputData } from '@editorjs/editorjs';
import type { EEditorTools } from 'shared/ui/Editor/Editor.interfaces';

interface IProps {
	data: OutputData;
	allowedTools?: EEditorTools[];
	handleChangeData: (newData: OutputData) => void;
}

export const EvaEditor = ({ data, handleChangeData, allowedTools = ALL_TOOLS }: IProps) => {
	const editorRef = useRef<EditorJS>();
	const editorHolderRef = useRef<HTMLDivElement>(null);
	const holderId = useId();

	useEffect(() => {
		if (!editorRef.current) {
			const configs = getEditorConfigs();
			removeBannedTools({ allowedTools, config: configs });
			editorRef.current = new EditorJS({
				...configs,
				data,
				minHeight: 100,
				autofocus: true,
				holder: `editor-holder-${holderId}`,
				onReady() {
					editorHolderRef.current?.addEventListener('keydown', stopPropagationHandler);
					editorHolderRef.current?.addEventListener('mousedown', stopPropagationHandler);
				},
				async onChange(api: API) {
					const newData = await api.saver.save();
					handleChangeData(newData);
				},
			});
			// editorRef.current?.selection.expandToTag()
		}

		return () => {
			if (editorRef && editorRef.current && editorRef.current?.destroy) {
				editorRef.current?.destroy();
			}
		};
	}, []);

	return (
		<div
			ref={editorHolderRef}
			data-id={`editor-holder-${holderId}`}
			id={`editor-holder-${holderId}`}
		/>
	);
};
