import cn from 'classnames';
import { Link } from 'react-router-dom';

import { isAccordionItemGuard } from 'app/ui/AccordionMenu/AccordionMenu.lib';
import Accordion from 'app/ui/AccordionMenu/ui/Accordion';

import type { TAccordionMenuItem } from 'app/ui/AccordionMenu/AccordionMenu.interfaces';

interface Props {
	item: TAccordionMenuItem;
	hideMenu: () => void;
	isOpenCollapsedMenu: boolean;
	activeItemId: string[];
	setActiveItemId: (value: string[]) => void;
}

const MenuItem = ({ item, hideMenu, isOpenCollapsedMenu, activeItemId, setActiveItemId }: Props) => {
	const linkClassnames = cn('flex cursor-pointer flex-col font-normal hover:bg-dropzone', {
		'px-8 py-3': isOpenCollapsedMenu,
		'p-[12px] pl-[30px]': !isOpenCollapsedMenu,
	});

	if (isAccordionItemGuard(item)) {
		return (
			<Accordion
				key={item.title}
				item={item}
				hideMenu={hideMenu}
				isOpenCollapsedMenu={isOpenCollapsedMenu}
				activeItemId={activeItemId}
				setActiveItemId={setActiveItemId}
			/>
		);
	}
	return (
		<Link
			data-id={item.dataId}
			to={item.to}
			key={item.title}
			onClick={hideMenu}
			className={linkClassnames}
		>
			{item.title}
		</Link>
	);
};

export default MenuItem;
