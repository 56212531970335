import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useState, type FC } from 'react';

import { Spinner } from 'shared/ui/Spinner/Spinner';

const MapFrame: FC<IMapFrameProps> = ({ url, onDeleteMap }) => {
	const [iframeState, setIframeState] = useState({
		isLoading: true,
		error: '',
	});

	if (!url) return null;

	const onIframeInitialize =
		(error = '') =>
		() => {
			setIframeState({
				isLoading: false,
				error,
			});
		};

	return (
		<div className='flex items-center justify-between gap-4'>
			<div className='relative grow self-stretch'>
				<iframe
					title={url}
					src={url}
					className='flex h-72 w-full border-0 border-none'
					onLoad={onIframeInitialize()}
					onError={onIframeInitialize('Не удалось загрузить карту')}
				/>
				{iframeState.isLoading && <Spinner className='absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2' />}
				{!!iframeState.error && (
					<div className='absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 font-bold text-red'>{iframeState.error}</div>
				)}
			</div>
			<IconButton
				disabled={iframeState.isLoading}
				onClick={onDeleteMap}
			>
				<Close />
			</IconButton>
		</div>
	);
};

interface IMapFrameProps {
	url: Nullable<string>;
	onDeleteMap: () => void;
}

export default MapFrame;
