import {
	blankContainerTemplate1Data,
	blankContainerTemplate2Data,
	blankContainerTemplate3Data,
	blankContainerTemplate4Data,
} from './ContainerTemaplates';
import { EContainerTemplates } from './ContainerTemplatesTool.interfaces';

import type { IContainerTemplatesToolData } from './ContainerTemplatesTool.interfaces';

export const containerTemplatesTooltip = {
	title: 'Шаблонный контейнер',
	icon:
		'<svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n' +
		'\t viewBox="0 0 512 512" xml:space="preserve">\n' +
		'<g transform="translate(1 1)">\n' +
		'\t<g>\n' +
		'\t\t<g>\n' +
		'\t\t\t<path d="M229.4,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133\n' +
		'\t\t\t\tC237.933,284.013,234.52,280.6,229.4,280.6z"/>\n' +
		'\t\t\t<path d="M178.2,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133\n' +
		'\t\t\t\tC186.733,284.013,183.32,280.6,178.2,280.6z"/>\n' +
		'\t\t\t<path d="M127,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133\n' +
		'\t\t\t\tC135.533,284.013,132.12,280.6,127,280.6z"/>\n' +
		'\t\t\t<path d="M75.8,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133\n' +
		'\t\t\t\tC84.333,284.013,80.92,280.6,75.8,280.6z"/>\n' +
		'\t\t\t<path d="M434.2,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133\n' +
		'\t\t\t\tC442.733,284.013,439.32,280.6,434.2,280.6z"/>\n' +
		'\t\t\t<path d="M383,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133\n' +
		'\t\t\t\tC391.533,284.013,388.12,280.6,383,280.6z"/>\n' +
		'\t\t\t<path d="M331.8,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533\n' +
		'\t\t\t\tV289.133C340.333,284.013,336.92,280.6,331.8,280.6z"/>\n' +
		'\t\t\t<path d="M280.6,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133\n' +
		'\t\t\t\tC289.133,284.013,285.72,280.6,280.6,280.6z"/>\n' +
		'\t\t\t<path d="M499.907,286.573C506.733,279.747,511,270.36,511,260.12v-13.653c0-5.12-3.413-8.533-8.533-8.533h-23.04l-158.72-63.147\n' +
		'\t\t\t\tc-4.267-1.707-9.387,0.853-11.093,5.12s0.853,9.387,5.12,11.093l117.968,46.933H80.323l146.756-54.354\n' +
		'\t\t\t\tc7.434,12.211,20.85,20.221,36.455,20.221c23.893,0,42.667-18.773,42.667-42.667c0-20.48-14.507-37.547-34.133-41.813v-10.212\n' +
		'\t\t\t\tc19.681-3.86,34.133-20.878,34.133-41.841V7.533c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533V32.93\n' +
		'\t\t\t\tc-7.09-5.25-15.924-8.33-25.6-8.33s-18.51,3.08-25.6,8.33V7.533c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v59.733\n' +
		'\t\t\t\tc0,20.963,14.452,37.981,34.133,41.841V127c0,5.12,3.413,8.533,8.533,8.533c14.507,0,25.6,11.093,25.6,25.6\n' +
		'\t\t\t\tc0,14.507-11.093,25.6-25.6,25.6c-8.208,0-15.322-3.554-19.953-9.266l19.822-7.341l5.251,2.101\n' +
		'\t\t\t\tc1.707,0.853,2.56,0.853,3.413,0.853c3.413,0,6.827-1.707,7.68-5.973c1.707-4.267-0.853-9.387-5.12-11.093l-5.853-2.341\n' +
		'\t\t\t\tc-1.514-1.04-3.317-1.628-5.125-1.597c-0.037-0.002-0.075,0-0.112-0.001c-0.04,0.001-0.08-0.001-0.12,0.001\n' +
		'\t\t\t\tc-1.697-0.029-3.387,0.489-4.841,1.413l-20.643,7.646c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533\n' +
		'\t\t\t\tc0,2.097,0.153,4.152,0.433,6.161L30.573,237.933H7.533c-5.12,0-8.533,3.413-8.533,8.533v13.653\n' +
		'\t\t\t\tc0,10.24,4.267,19.627,11.093,26.453c3.413,3.413,5.973,9.387,5.973,14.507v146.773c0,5.973-2.56,10.24-5.973,14.507\n' +
		'\t\t\t\tC3.267,469.187-1,478.573-1,488.813v13.653c0,5.12,3.413,8.533,8.533,8.533h494.933c5.12,0,8.533-3.413,8.533-8.533v-13.653\n' +
		'\t\t\t\tc0-10.24-4.267-19.627-11.093-26.453c-4.267-4.267-5.973-9.387-5.973-14.507V301.08\n' +
		'\t\t\t\tC493.933,295.107,496.493,290.84,499.907,286.573z M237.933,67.267c0-14.507,11.093-25.6,25.6-25.6\n' +
		'\t\t\t\tc14.507,0,25.6,11.093,25.6,25.6s-11.093,25.6-25.6,25.6C249.027,92.867,237.933,81.773,237.933,67.267z M487.96,474.307\n' +
		'\t\t\t\tc4.267,4.267,5.973,9.387,5.973,14.507v5.12H16.067v-5.12c0-5.973,2.56-10.24,5.973-14.507\n' +
		'\t\t\t\tc6.827-6.827,11.093-16.213,11.093-26.453V301.08c0-9.387-4.267-19.627-11.093-26.453c-3.413-4.267-5.973-9.387-5.973-14.507V255\n' +
		'\t\t\t\th477.867v5.12c0,5.12-2.56,10.24-5.973,14.507c-6.827,6.827-11.093,16.213-11.093,26.453v146.773\n' +
		'\t\t\t\tC476.867,458.093,481.133,467.48,487.96,474.307z"/>\n' +
		'\t\t</g>\n' +
		'\t</g>\n' +
		'</g>\n' +
		'</svg>',
};

export const getInitialContainerTemplatesData = (template?: EContainerTemplates): IContainerTemplatesToolData => {
	switch (template) {
		case EContainerTemplates.TITLE_TEXT_TYPE: {
			return {
				data: blankContainerTemplate1Data(),
				style: '',
				title: '',
				template: EContainerTemplates.TITLE_TEXT_TYPE,
			};
		}
		case EContainerTemplates.WITH_LIST: {
			return {
				data: blankContainerTemplate2Data(),
				style: '',
				title: '',
				template: EContainerTemplates.WITH_LIST,
			};
		}
		case EContainerTemplates.ALERT: {
			return {
				data: blankContainerTemplate3Data(),
				style: '',
				title: '',
				template: EContainerTemplates.ALERT,
			};
		}
		case EContainerTemplates.LICENCES: {
			return {
				data: blankContainerTemplate4Data(),
				style: '',
				title: '',
				template: EContainerTemplates.LICENCES,
			};
		}
		default: {
			return {
				data: blankContainerTemplate1Data(),
				style: '',
				title: '',
				template: EContainerTemplates.TITLE_TEXT_TYPE,
			};
		}
	}
};

export const selectContainerTemplateOptions = [
	{
		value: EContainerTemplates.TITLE_TEXT_TYPE,
		label: 'С элементами',
	},
	{
		value: EContainerTemplates.WITH_LIST,
		label: 'Со списком',
	},
	{
		value: EContainerTemplates.ALERT,
		label: 'Уведомление',
	},
	{
		value: EContainerTemplates.LICENCES,
		label: 'Лицензии',
	},
];
