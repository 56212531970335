import type { ISVGProps } from './interfaces';
import type { FC } from 'react';

const ArrowDown: FC<ISVGProps> = ({ className }) => (
	<svg
		className={className}
		width='10'
		height='6'
		viewBox='0 0 10 6'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M1 1L5 5L9 1'
			stroke='white'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default ArrowDown;
